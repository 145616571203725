import REMOVE_INTEGRATION from './removeIntegration.gql';
import REMOVE_BANK_ACCOUNT from './removeBankAccount.gql';
import SELECT_BANK_ACCOUNT from './selectBankAccount.gql';
import ME from './me.gql';

export {
  REMOVE_INTEGRATION,
  SELECT_BANK_ACCOUNT,
  REMOVE_BANK_ACCOUNT,
  ME,
};
