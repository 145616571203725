import {
  Alert,
} from 'components/gyramais';
import {
  Modal,
  Button,
} from 'components/sdk';
import React, {
  createContext, useContext, useState,
} from 'react';

const defaultCallback = () => {
  throw new Error('Você precisa chamar esse contexto dentro do provider!');
};

export const ConfirmModalContext = createContext({
  requestConfirmation: defaultCallback,
});

const ConfirmModalProvider = ({ children }) => {
  const initialState = {
    onOk: () => undefined,
    onCancel: () => undefined,
  };

  const [title, setTitle] = useState('Fechar esta aba? As alterações não serão salvas.');
  const [message, setMessage] = useState((
    <h6>
      Você precisa completar
      {' '}
      <b className="secondary-text">todas as etapas</b>
      {' '}
      desta aba para que suas alterações sejam salvas.
      Caso feche agora, toda nova informação inserida
      {' '}
      <b className="secondary-text">será perdida</b>
      .
      Deseja continuar?
    </h6>
  ));
  const [state, setState] = useState(initialState);
  const [visible, setVisible] = useState(false);

  const resetState = async () => {
    setVisible(false);

    await new Promise((res) => setTimeout(res, 500));

    setState(initialState);
  };

  const requestConfirmation = ({ onOk, onCancel }) => {
    setVisible(true);
    setState({
      onOk: () => {
        onOk?.();
        resetState();
      },

      onCancel: () => {
        onCancel?.();
        resetState();
      },
    });
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        requestConfirmation,
        setRequestConfirmationTitle: setTitle,
        setRequestConfirmationMessage: setMessage,
      }}
    >
      <Modal
        visible={visible}
        title={title}
        closable={false}
        onCancel={state.onCancel}
        maskClosable={false}
        zIndex={9999}
        id="close-drawer"
        centered
        okButton={{
          text: 'Fechar aba',
          onClick: state.onOk,
        }}
        cancelButton={{
          text: 'Cancelar',
          onClick: state.onCancel,
        }}
        footer={(
          <>
            <Button
              link
              onClick={state.onCancel}
              className="context-confirm-modal-cancel-btn"
            >
              Cancelar
            </Button>

            <Button onClick={state.onOk}>
              Fechar aba
            </Button>
          </>
        )}
      >
        <Alert
          className="alert-modal"
          message={message}
          color="gray"
        />
      </Modal>
      {children}
    </ConfirmModalContext.Provider>
  );
};

export default ConfirmModalProvider;

/**
 * @returns {{
 *  requestConfirmation: ({
 *    onCancel: any,
 *    onOk: any,
 *  }) => any
 *  setRequestConfirmationTitle: (title: string) => any
 *  setRequestConfirmationMessage: (message: string) => any
 * }}
 */
export function useConfirmModalContext() {
  return useContext(ConfirmModalContext);
}
