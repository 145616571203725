import ADD_SESSION from './addSession.gql';
import PARTNER_LEAD from './partnerLead.gql';
import SAVE_SELLER from './saveSeller.gql';
import CREATE_PASSWORD from './createPassword.gql';
import PARTNER from './partner.gql';
import CHECK_IF_USER_EXISTS from './checkIfUserExists.gql';
import GET_USER_INFO_BY_OLIST_CODE from './getUserInfoByOlistCode.gql';

export {
  ADD_SESSION,
  PARTNER_LEAD,
  SAVE_SELLER,
  CREATE_PASSWORD,
  PARTNER,
  CHECK_IF_USER_EXISTS,
  GET_USER_INFO_BY_OLIST_CODE,
};
