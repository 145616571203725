// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receivable-list-card {
  padding: 2rem;
  position: relative;
}
.receivable-list-card .receivable-list-card-collapse {
  background-color: white;
}
.receivable-list-card .receivable-list-card-collapse .ant-collapse-header {
  margin-right: 0;
  padding: 0px;
}
@media (max-width: 768px) {
  .receivable-list-card .receivable-list-card-collapse .ant-collapse-header .anticon-right {
    border-left: none !important;
  }
}
.receivable-list-card .receivable-list-card-collapse .ant-collapse-arrow {
  color: var(--primary-button-color);
}
.receivable-list-card .receivable-list-card-collapse .ant-collapse-content-box {
  padding: 0px;
}
.receivable-list-card .receivable-list-card-collapse .ant-collapse-item {
  border-bottom: none;
}
.receivable-list-card .receivable-list-card-collapse .receivable-list-card-header {
  display: flex;
  align-items: center;
}
.receivable-list-card .receivable-list-card-collapse .receivable-list-card-header .anticon {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--primary-button-color);
}
.receivable-list-card .receivable-item {
  padding: 1.5rem 0;
}
.receivable-list-card .receivable-item .receivable-item-icon {
  width: 100%;
}
.receivable-list-card .receivable-item .vr {
  height: 3rem;
  width: 1px;
  background-color: #C9D3CC;
}
.receivable-list-card .receivable-item .small-text {
  font-size: 0.875rem;
  color: #3E4C42;
}
.receivable-list-card .receivable-item:nth-child(2n) {
  background-color: #F6F8F7;
}
@media (max-width: 768px) {
  .receivable-list-card .receivable-item {
    padding: 0.8rem 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/PrePaymentScreen/components/ReceivableList/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AAHA;EAKI,uBAAA;AACJ;AANA;EAQM,eAAA;EACA,YAAA;AACN;AAEQ;EAAA;IACE,4BAAA;EACR;AACF;AAfA;EAmBM,kCAAA;AADN;AAlBA;EAuBM,YAAA;AAFN;AArBA;EA2BM,mBAAA;AAHN;AAxBA;EA+BM,aAAA;EACA,mBAAA;AAJN;AA5BA;EAmCQ,eAAA;EACA,oBAAA;EACA,kCAAA;AAJR;AAjCA;EA2CI,iBAAA;AAPJ;AApCA;EA8CM,WAAA;AAPN;AAvCA;EAkDM,YAAA;EACA,UAAA;EACA,yBAAA;AARN;AA5CA;EAwDM,mBAAA;EACA,cAAA;AATN;AAYI;EACE,yBAAA;AAVN;AAaI;EAAA;IACE,iBAAA;EAVJ;AACF","sourcesContent":[".receivable-list-card {\n  padding: 2rem;\n  position: relative;\n\n  .receivable-list-card-collapse {\n    background-color: white;\n\n    .ant-collapse-header {\n      margin-right: 0;\n      padding: 0px;\n      \n      .anticon-right {\n        @media(max-width: @md-screen) {\n          border-left: none !important;          \n        }\n      }\n    }\n\n    .ant-collapse-arrow {\n      color: var(--primary-button-color);\n    }\n\n    .ant-collapse-content-box {\n      padding: 0px\n    }\n\n    .ant-collapse-item {\n      border-bottom: none;\n    }\n\n    .receivable-list-card-header {\n      display: flex;\n      align-items: center;\n  \n      .anticon {\n        font-size: 1rem;\n        margin-right: 0.5rem;\n        color: var(--primary-button-color);\n      }\n    }\n  }\n\n  .receivable-item {\n    padding: 1.5rem 0;\n\n    .receivable-item-icon {\n      width: 100%;\n    }\n\n    .vr {\n      height: 3rem;\n      width: 1px;\n      background-color: #C9D3CC;\n    }\n\n    .small-text {\n      font-size: .875rem;\n      color: #3E4C42;\n    }\n    \n    &:nth-child(2n) {\n      background-color: #F6F8F7;\n    }\n\n    @media(max-width: @md-screen) {\n      padding: .8rem 0;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
