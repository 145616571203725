import {
  makeVar,
} from '@apollo/client';
import dayjs from 'dayjs';
import { changeColors, isBrightColor } from 'utils/colors';

const GYRA_PATH_KEY = 'integration-gyra-redirect-path';

const getMe = () => {
  try {
    return localStorage.getItem('me') !== undefined ? JSON.parse(localStorage.getItem('me')) : null;
  } catch (e) {
    localStorage.clear();
    return null;
  }
};

const getIntegration = () => (
  localStorage.getItem('integration') !== undefined ? localStorage.getItem('integration') : null
);

const getRedirect = () => {
  try {
    return localStorage.getItem('redirect') !== undefined ? JSON.parse(localStorage.getItem('redirect')) : null;
  } catch (e) {
    localStorage.clear();
    return null;
  }
};

export const getGyraRedirectPath = () => (
  localStorage.getItem(GYRA_PATH_KEY) !== undefined ? localStorage.getItem(GYRA_PATH_KEY) : null
);

export const getRedirectId = () => (
  localStorage.getItem('integration-redirect-id') !== undefined ? localStorage.getItem('integration-redirect-id') : null
);

export const getReintegrationId = () => (
  localStorage.getItem('integration-reintegration-id') !== undefined ? localStorage.getItem('integration-reintegration-id') : null
);
export const getStep = () => (
  localStorage.getItem('step') !== undefined ? Number(localStorage.getItem('step')) : null
);

/**
 * @returns {{
 *  colors: {
 *    primary: string1
 *    primaryButton: string1
 *    secondaryButton: string1
 *    linkButton: string1
 *  }
 *  logoUrl: string
 *  isOnlyScore: boolean
 * }}
 */
export const getWhiteLabelData = () => {
  let value;

  try {
    value = localStorage.getItem('whiteLabelData') !== undefined
      ? JSON.parse(localStorage.getItem('whiteLabelData'))
      : null;
  } catch (error) {
    localStorage.removeItem('whiteLabelData');
    window.location.reload();
  }

  if (Array.isArray(value)) {
    localStorage.removeItem('whiteLabelData');
    window.location.reload();
  }

  return value;
};

/**
 * @param {{
 *  colors: any
 *  darkLogoUrl: string
 *  lightLogoUrl: string
 *  isOnlyScore?: boolean
 * }} args
 * @returns
 */
export const setWhiteLabelData = ({
  colors,
  darkLogoUrl,
  lightLogoUrl,
  // necessário existir no localStorage para a tela de login conseguir ter essa informação
  isOnlyScore,
}) => {
  const data = {
    colors,
    logoUrl: colors && isBrightColor(colors?.primary)
      // caso não tem o necessário, usar o que tem mesmo
      ? darkLogoUrl || lightLogoUrl
      : lightLogoUrl || darkLogoUrl,

    // sempre reutiliza oq já existe caso não seja informado
    isOnlyScore: getWhiteLabelData()?.isOnlyScore,
  };

  if (isOnlyScore !== undefined) {
    data.isOnlyScore = isOnlyScore;
  }

  localStorage.setItem('whiteLabelData', JSON.stringify(data));

  if (colors) {
    changeColors(colors);
  }
  return data;
};

export const getPromoRenegotiationLastViewDate = () => {
  let value;

  try {
    value = localStorage.getItem('promoRenegotiationLastViewDate') !== undefined
      ? localStorage.getItem('promoRenegotiationLastViewDate')
      : null;
  } catch (error) {
    localStorage.removeItem('promoRenegotiationLastViewDate');
    window.location.reload();
  }

  return value && dayjs(value);
};

export const getPromoRenegotiationCountdown = (chargePolicyId) => {
  let value;
  const key = `promoRenegotiationCountdown-${chargePolicyId}`;

  try {
    value = localStorage.getItem(key) !== undefined
      ? localStorage.getItem(key)
      : null;
  } catch (error) {
    localStorage.removeItem(key);
    window.location.reload();
  }

  return value;
};

const getDrawer = () => ({ loanId: null, visible: false, __typename: 'LoanDrawer' });

const getLoggedOut = () => ({ loggedOut: null });

const getModalSeen = !!localStorage.getItem('motivationalModalSeen');

const getMenuCollapsed = () => JSON.parse(localStorage.getItem('menu-collapsed'));

const meVar = makeVar(getMe());
const integrationVar = makeVar(getIntegration());
const redirectVar = makeVar(getRedirect());
const drawerVar = makeVar(getDrawer());
const loggedOutVar = makeVar(getLoggedOut());
const stepVar = makeVar(getStep());
const isVideoMotivationalSeen = makeVar(getModalSeen);
const menuCollapsedVar = makeVar(getMenuCollapsed());

export const setReintegrationId = (value) => localStorage.setItem('integration-reintegration-id', value);
export const setRedirectId = (value) => localStorage.setItem('integration-redirect-id', value);
export const setGyraRedirectPath = (value) => localStorage.setItem(GYRA_PATH_KEY, value);
export const setStep = (value) => localStorage.setItem('step', value);
export const setPromoRenegotiationLastViewDate = () => localStorage.setItem('promoRenegotiationLastViewDate', new Date().toISOString());
export const setPromoRenegotiationCountdown = ({ deadLine, chargePolicyId }) => localStorage.setItem(`promoRenegotiationCountdown-${chargePolicyId}`, deadLine.toISOString());
export const setMenuCollapsedVar = (value) => {
  localStorage.setItem('menu-collapsed', JSON.stringify(value));
  menuCollapsedVar(value);
};

export {
  meVar,
  integrationVar,
  redirectVar,
  drawerVar,
  loggedOutVar,
  stepVar,
  isVideoMotivationalSeen,
  menuCollapsedVar,
};
export default meVar;
