import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import { formatAndNotificateError, states, formatString } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import {
  POSTAL_CODE_TO_ADDRESS,
} from './graphql';

const { Item } = Form;
const { Option } = Select;

/**
 * @typedef {import('antd').ColProps & {
 *   fieldOrder: number
 * }} AddressFomCol
 *
 * @type {React.FC<{
 *  address?: Object
 *  form: any
 *  flow?: string
 *  onlyColumns?: boolean
 *  postalCodePlaceholder?: string
 *  fieldProps: {
 *    postalCode: AddressFomCol
 *    district: AddressFomCol
 *    street: AddressFomCol
 *    number: AddressFomCol
 *    complement: AddressFomCol
 *    state: AddressFomCol
 *    city: AddressFomCol
 *  }
 *  business: Boolean
 * }>}
 */
const AddressForm = ({
  address: {
    id,
    postalCode,
    district,
    street,
    number,
    complement,
    state,
    city,
  } = {},
  form,
  flow,
  postalCodePlaceholder = 'CEP da sua empresa',
  onlyColumns = false,
  fieldProps = {
    postalCode: {
      fieldOrder: 1,
      xs: 24,
      sm: 24,
      md: 12,
    },
    district: {
      fieldOrder: 2,
      xs: 24,
      sm: 24,
      md: 12,
    },
    street: {
      fieldOrder: 3,
      xs: 24,
      sm: 24,
      md: 24,
    },
    number: {
      fieldOrder: 4,
      xs: 24,
      sm: 24,
      md: 12,
    },
    complement: {
      fieldOrder: 5,
      xs: 24,
      sm: 24,
      md: 12,
    },
    state: {
      fieldOrder: 6,
      xs: 24,
      sm: 24,
      md: 12,
    },
    city: {
      fieldOrder: 7,
      xs: 24,
      sm: 24,
      md: 12,
    },
  },
  business,
}) => {
  const [autoFilledFields, setAutoFilledFields] = useState([]);
  const [searched, setSearched] = useState(false);

  const handleError = (error) => {
    setAutoFilledFields([]);
    formatAndNotificateError(error);
    setSearched(flow !== 'badesc');

    form.setFieldsValue({
      postalCode: '',
      street: '',
      district: '',
      city: '',
      state: '',
    });
  };

  const [postalCodeToAddress, { loading }] = useMutation(POSTAL_CODE_TO_ADDRESS, {
    onError: (error) => handleError(error),
    onCompleted: ({ postalCodeToAddress }) => {
      const fills = [];
      if (postalCodeToAddress) {
        const {
          street,
          district,
          city,
          state,
        } = postalCodeToAddress;

        if (business && flow === 'badesc' && state !== 'SC') {
          return handleError({ message: 'O CEP digitado não pertence ao estado de Santa Catarina' });
        }

        if (street?.length > 0) fills.push('street');
        if (district?.length > 0) fills.push('district');
        if (city?.length > 0) fills.push('city');
        if (state?.length > 0) fills.push('state');

        setAutoFilledFields(fills);
        setSearched(true);

        form.setFieldsValue({
          street,
          district,
          city,
          state,
        });
      }

      return true;
    },
  });

  useEffect(() => {
    if (!id) {
      return;
    }

    const fill = [];
    if (district) fill.push('district');
    if (street) fill.push('street');
    if (number) fill.push('number');
    if (state) fill.push('state');
    if (city) fill.push('city');

    setAutoFilledFields(fill);
    setSearched(true);

    form.setFieldsValue({
      id,
      postalCode: postalCode ? formatString(postalCode, 'cep') : '',
      district,
      street,
      number,
      complement,
      state,
      city,
    });
  }, [id]);

  const getProps = (fieldName) => {
    const props = fieldProps[fieldName];

    if (!props) {
      return {};
    }

    delete props.fieldOrder;

    return props;
  };

  const fields = {
    postalCode: (
      <Col {...getProps('postalCode')} key="postalCode">
        <Item name="postalCode" rules={[{ required: true, message: 'Por favor insira o CEP.' }]}>
          <Input
            type="masked"
            className="ant-input"
            guide
            mask="cep"
            placeholder={postalCodePlaceholder}
            onKeyUp={(e) => {
              const postalCode = e.target.value.replace(/[_-\s]/g, '');
              if (postalCode.replace(/[_-\s]/g, '').length === 8 && !loading) {
                postalCodeToAddress({ variables: { postalCode } });
              }
            }}
          />
        </Item>
      </Col>
    ),

    district: (
      <Col {...getProps('district')} key="district">
        <Item name="district" rules={[{ required: true, message: 'Por favor insira o bairro.' }]}>
          <Input
            loading={loading}
            disabled={!searched || autoFilledFields.includes('district')}
            type="text"
            placeholder="Bairro"
          />
        </Item>
      </Col>
    ),

    street: (
      <Col {...getProps('street')} key="street">
        <Item name="street">
          <Input
            loading={loading}
            disabled={!searched || autoFilledFields.includes('street')}
            type="text"
            placeholder="Endereço"
          />
        </Item>
      </Col>
    ),

    number: (
      <Col {...getProps('number')} key="number">
        <Item
          name="number"
          rules={[{
            required: true,
            whitespace: true,
            message: 'Por favor insira um número válido.',
            max: 10,
            pattern: '^[0-9]*$',
          }]}
        >
          <Input
            disabled={!searched}
            loading={loading}
            type="text"
            placeholder="Número"
          />
        </Item>
      </Col>
    ),

    complement: (
      <Col {...getProps('complement')} key="complement">
        <Item
          name="complement"
          rules={[{ required: false, max: 49, message: 'Complemento com no máximo 49 caracteres' }]}
        >
          <Input
            loading={loading}
            type="text"
            disabled={!searched}
            placeholder="Complemento"
          />
        </Item>
      </Col>
    ),

    state: (
      <Col {...getProps('state')} key="state">
        <Item
          name="state"
          rules={[{ required: true, message: 'Por favor insira o UF do Estado.', len: 2 }]}
        >
          <Input
            loading={loading}
            type="text"
            disabled={!searched || autoFilledFields.includes('state')}
            placeholder="Estado"
          />
        </Item>
      </Col>
    ),

    city: (
      <Col {...getProps('city')} key="city">
        <Item
          name="city"
          rules={[{ required: true, message: 'Por favor selecione o estado.' }]}
        >
          <Select
            showSearch
            disabled={!searched || autoFilledFields.includes('city') || loading}
            className="gyramais-input"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children
              .toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder="Cidade"
            style={{ width: '100%' }}
          >
            {Object.keys(states).map((key) => (
              <Option key={key} value={key}>
                {states[key]}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    ),
  };

  const renderFields = () => {
    const orderedFields = Object.entries(fieldProps)
      .map(([fieldName, props]) => ({
        fieldName,
        ...props,
      }))
      .sort((a, b) => {
        if (!a?.fieldOrder && !b?.fieldOrder) {
          return 0;
        }

        if (!a?.fieldOrder || a.fieldOrder > b.fieldOrder) {
          return 1;
        }

        if (!b?.fieldOrder || a.fieldOrder < b.fieldOrder) {
          return -1;
        }

        return 0;
      });

    // adicionando os campos que nao foram especificadas props
    for (const fieldName of Object.keys(fields)) {
      if (!fieldProps[fieldName]) {
        orderedFields.push({ fieldName });
      }
    }

    return orderedFields.map(({ fieldName }) => fields[fieldName]);
  };

  const wrapContent = (content) => {
    if (onlyColumns) {
      return content;
    }

    return (
      <Form
        layout="vertical"
        form={form}
      >
        <Row gutter={12}>
          {content}
        </Row>
      </Form>
    );
  };

  return wrapContent(renderFields());
};

export default AddressForm;
