import ME from './me.gql';
import SAVE_LOAN_EVENT from './saveLoanEvent.gql';
import SAVE_LOAN from './saveLoan.gql';
import SAVE_REQUESTED_VALUES from './saveRequestedValues.gql';

export {
  ME,
  SAVE_LOAN_EVENT,
  SAVE_LOAN,
  SAVE_REQUESTED_VALUES,
};
