import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
  Form,
  Row,
  Select,
  Col,
  Checkbox,
  Divider,
} from 'antd';
import {
  Input,
  UploadCard,
} from 'components';
import {
  Alert,
} from 'components/gyramais';
import MaskedTextInput from 'react-text-mask';
import { formatAndNotificateError } from 'utils';
import { useQuery } from '@apollo/client';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { BANKS } from './graphql';

import './styles.less';

const { Step } = Steps;
const { Item } = Form;
const { Option } = Select;

const BankAccountForm = ({
  bank: {
    id,
    tutorial,
  } = {},
  form,
  step,
  onCompleted,
  disableOpenBanks,
  statementFiles,
}) => {
  const [statements, setStatements] = useState([]);
  const [bank, setBank] = useState({});

  const { currentContext } = useBusinessLoanContext();
  const loanType = useMemo(() => currentContext?.loan?.type?.value, [currentContext]);

  const {
    data: {
      banks = [],
    } = {},
  } = useQuery(BANKS, {
    variables: {
      limit: 9999,
      disableOpenBanks,
    },
    onError: (error) => formatAndNotificateError(error),
  });

  useEffect(() => {
    form.setFieldsValue({
      bankId: id,
    });
  }, [id]);

  useEffect(() => {
    if (onCompleted) {
      const {
        bankId, branchCode, accountNumber, currentAccount, confirmation, key, type,
      } = bank;
      if (bankId && branchCode && accountNumber && currentAccount) {
        onCompleted({
          bankId,
          branchCode,
          accountNumber,
          currentAccount: currentAccount === 'corrente',
          statements,
          confirmation,
          key,
          type,
          step,
        });
      }
    }
  }, [bank, statements]);

  useEffect(() => {
    if (statementFiles) {
      setStatements(statementFiles);
    }
  }, [statementFiles]);

  const steps = [
    {
      title: 'Dados da conta',
      content: (
        <>
          <Alert
            message={(
              <p>
                <b>
                  Importante: Trabalhamos
                  {' '}
                  <b className="secondary-text">APENAS</b>
                  {' '}
                  com conta bancária PJ (Pessoa Jurídica).
                  {' '}
                  <b className="secondary-text">NÃO</b>
                  {' '}
                  trabalhamos com PF (Pessoa Física). Pode ser necessário
                  {' '}
                  <b className="secondary-text">completar com ZEROS (à esquerda) </b>
                  {' '}
                  para finalizar o cadastro da sua conta.
                </b>
              </p>
            )}
          />

          <Form
            form={form}
          >
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Item name="bankId" rules={[{ required: true, message: 'Por favor selecione o banco.' }]}>
                  <Select
                    disabled={Boolean(id)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children
                      .toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    className="gyramais-input"
                    dropdownClassName="gyramais-input-options"
                    placeholder="Selecione o banco"
                    value={id}
                  >
                    {banks.map(({ id, code, name }) => (
                      <Option key={id} value={id}>
                        {`${code} - ${name}`}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>

              <Col xs={24} md={12}>
                <Item name="currentAccount" rules={[{ required: true, message: 'Por favor selecione o tipo de conta.' }]}>
                  <Select
                    className="gyramais-input"
                    placeholder="Selecione o tipo de conta"
                    onChange={() => setBank(form.getFieldsValue())}
                  >
                    <Option key="corrente" value="corrente">Conta corrente</Option>
                    <Option key="poupanca" value="poupanca">Conta poupança</Option>
                  </Select>
                </Item>
              </Col>

              <Col xs={24} md={12}>
                <Item name="branchCode" rules={[{ required: true, message: 'Por favor preencha a agência.' }]}>
                  <MaskedTextInput
                    className="ant-input gyramais-input"
                    guide
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    placeholder="Agência (sem dígito)"
                    onChange={() => setBank(form.getFieldsValue())}
                  />
                </Item>
              </Col>

              <Col xs={24} md={12}>
                <Item
                  name="accountNumber"
                  onBlur={(args) => {
                    const digits = args.target.value.replace(/\D/g, '');
                    if (digits) {
                      form.setFieldsValue({
                        accountNumber: `${'0'.repeat(10 - (digits.length - 2))}${digits.substring(0, digits.length - 1)}-${digits.substring(digits.length - 1)}`,
                      });
                    }
                  }}
                  rules={[
                    { required: true, message: 'Por favor preencha a conta corrente.' },
                    () => ({
                      validator(rule, value) {
                        if (!value || value.replace(/\D/g, '').length === 12) {
                          return Promise.resolve();
                        }
                        const emailsDontMatch = new Error('Preencha todos os digitos');
                        return Promise.reject(emailsDontMatch);
                      },
                    }),
                  ]}
                >
                  <MaskedTextInput
                    className="ant-input gyramais-input"
                    guide
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
                    placeholder="Conta corrente (com dígito)"
                    onChange={() => setBank(form.getFieldsValue())}
                  />
                </Item>
              </Col>

              <Divider className="divider" />

              <Col xs={24} md={12}>
                <Item name="type" rules={loanType === 'receivables-guarantee' && [{ required: true, message: 'Por favor selecione o tipo da chave pix.' }]}>
                  <Select
                    className="gyramais-input"
                    placeholder="Selecione o tipo de PIX"
                    onChange={() => setBank(form.getFieldsValue())}
                  >
                    <Option key="email" value="email">Email</Option>
                    <Option key="cpf" value="cpf">CPF</Option>
                    <Option key="cnpj" value="cnpj">CNPJ</Option>
                    <Option key="phone" value="phone">Celular</Option>
                    <Option key="random" value="random">Aleatório</Option>
                  </Select>
                </Item>
              </Col>
              <Col xs={24} md={12}>
                <Item name="key" rules={loanType === 'receivables-guarantee' && [{ required: true, message: 'Por favor preencha a chave pix.' }]}>
                  <Input
                    type={['cpf', 'cnpj', 'phone'].includes(form.getFieldValue('type')) ? 'masked' : 'text'}
                    mask={['cpf', 'cnpj', 'phone'].includes(form.getFieldValue('type')) && form.getFieldValue('type')}
                    placeholder="Digite a chave PIX"
                    onChange={() => setBank(form.getFieldsValue())}
                    alert="Necessário cadastrar chave PIX para envio de recebíveis"
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },
    {
      title: 'Upload de extrato',
      content: (
        <>
          <Alert
            className="bank-account-alert"
            message="Insira o extrato bancário completo dos últimos 90 dias da conta adicionada. Não se esqueça de que aceitamos apenas extratos no formato PDF gerados no site do seu banco.  O extrato será usado para confirmar a titularidade da sua conta."
          />
          <Form
            layout="vertical"
            form={form}
          >
            <Item className="hidden" name="statementFileIds" />
            <Item>
              <UploadCard
                id="statement-upload"
                accept="application/pdf"
                actualFile={statements}
                label="Extrato bancário"
                loadingText="Importando extrato..."
                multiples
                onCompleted={async ({ id, name, url }) => {
                  const ids = form.getFieldValue('statementFileIds') || [];
                  ids.push(id);

                  const files = [...statements];
                  files.push({ uid: id, name, url });
                  setStatements(files);

                  await form.setFieldsValue({ statementFileIds: ids });
                }}
                onDelete={async ({ id }) => {
                  const ids = form.getFieldValue('statementFileIds');
                  const newArrayOfIds = [];
                  ids.map((index) => index !== id && newArrayOfIds.push(index));

                  const files = statements.filter(({ uid }) => newArrayOfIds.includes(uid));
                  setStatements(files);

                  await form.setFieldsValue({ statementFileIds: newArrayOfIds });
                }}
              />
            </Item>

            <Item
              className="term-area"
              name="confirmation"
              valuePropName="checked"
              rules={[{ required: true, message: 'Confirme a condição' }]}
            >
              <Checkbox
                disabled={statements.length === 0}
                onChange={({ target: { checked } }) => setBank({ ...bank, confirmation: checked })}
              >
                <p>
                  <b>
                    Confirmo que o extrato em PDF importado acima foi baixado diretamente
                    do Internet Banking do meu banco e qualquer divergência implicará na não
                    aprovação do empréstimo.
                  </b>
                </p>
              </Checkbox>
            </Item>
          </Form>
        </>
      ),
    },
  ];
  return (
    <div className="add-bank">
      <div className={`add-bank-body ${!tutorial ? 'no-tutorial' : ''}`}>
        <Steps current={step} direction="horizontal">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <div className="steps-content">
          {steps[step].content}
        </div>
      </div>
    </div>
  );
};

BankAccountForm.propTypes = {
  bank: PropTypes.shape(),
  form: PropTypes.shape(),
  step: PropTypes.number,
  onCompleted: PropTypes.func,
  disableOpenBanks: PropTypes.bool,
  statementFiles: PropTypes.shape(),
};

export default BankAccountForm;
