import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Tabs,
  Form,
  Carousel,
  notification,
  Skeleton,
} from 'antd';
import {
  PlayCircleOutlined,
} from '@ant-design/icons';
import {
  Card,
  SubHeader,
  AccountCard,
  BanksList,
  CustomStep,
} from 'components/gyramais';
import {
  Button,
  Modal,
  Layout,
  Progress,
} from 'components/sdk';
import {
  BankAccountForm,
  BusinessForm,
  BusinessUploadForm,
  BankAccountDrawer,
  BusinessUserCard,
  BusinessUserDrawer,
  FindUserDrawer,
  LoanChances,
  LoanChancesAlertModal,
  IncreaseChancesDrawer,
  IntegrationVideoMotivationModal,
  BankAccountOptinDrawer,
} from 'components';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  dateDiff,
  formatAndNotificateError,
  needToGetToRegisterScreen,
} from 'utils';
import {
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  ME,
  BANKS,
  SAVE_BUSINESS,
  SAVE_ADDRESS,
  SAVE_BANK_ACCOUNT,
  SETTINGS,
} from './graphql';
import { stepVar, setStep, isVideoMotivationalSeen } from '../../services/graphql/cache';

import './styles.less';

const { Content } = Layout;
const { useForm } = Form;
const { TabPane } = Tabs;

let carouselRef;

const BusinessRegisterScreen = () => {
  const history = useHistory();
  const [form] = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentRange, setCurrentRange] = useState({ min: 0, max: 1 });
  const [bankDrawer, setBankDrawer] = useState(false);
  const [bank, setBank] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [confirmBankModal, setConfirmBankModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState();
  const [findUserDrawer, setFindUserDrawer] = useState(false);
  const [selectedBusinessUser, setSelectedBusinessUser] = useState();
  const [businessUserDrawer, setBusinessUserDrawer] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [businessUserType, setBusinessUserType] = useState();
  const [bankStepActive, setBankStepActive] = useState(true);
  const [businessFilesStepActive, setBusinessFilesStepActive] = useState(true);
  const [businessUserStepActive, setBusinessUserStepActive] = useState(true);
  const [statusValue, setStatusValue] = useState();
  const [isReceivableGuarantee, setIsReceivableGuarantee] = useState(false);
  const [editedBankAccount, setEditedBankAccount] = useState();
  const [businessUsersRevised, setBusinessUsersRevised] = useState([]);
  const [businessDocumentsRevised, setBusinessDocumentsRevised] = useState([]);
  const [isCreation, setIsCreation] = useState(true);

  const [chances, setChances] = useState(0);
  const [chancesAlertModal, setChancesAlertModal] = useState(false);
  const [increaseChancesDrawer, setIncreaseChancesDrawer] = useState(false);
  const [bankAccountOptInDrawer, setBankAccountOptInDrawer] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const validationModalSeen = useReactiveVar(isVideoMotivationalSeen);

  const { loadingActions, checkActions } = usePartnerActionsContext();
  const { currentContext } = useBusinessLoanContext();

  const { partner } = currentContext;
  const headerProps = {
    business_form_step: {
      title: (
        <>
          Vamos prosseguir com o básico.
          Preencha os dados da sua empresa.
        </>
      ),
      subtitle: 'É preciso reconhecer e confiar para dividir um sonho com alguém. Queremos crescer junto com você, e pra isso, precisamos te conhecer melhor!',
    },

    business_files_step: {
      title: statusValue === 'check-documents'
        ? ('Revise os dados da sua empresa')
        : ('Envie pra gente os documentos da empresa cadastrada.'),
      subtitle: 'Somos contra a burocracia, mas tem horas que é inevitável. Vamos precisar desses documentos para concluir sua aprovação. Falta pouco para acabar!',
    },

    bank_step: {
      title: statusValue === 'check-documents'
        ? ('Revise o extrato bancário da conta recusada.')
        : ('Selecione os bancos e adicione seus extratos.'),
      subtitle: statusValue === 'check-documents'
        ? ('Nossa equipe encontrou algo de errado durante a análise. Confira seus dados e revise sua(s) conta(s) bancária(s). Em caso de dúvidas, entre em contato conosco.')
        : (
          <>
            Adicione
            {' '}
            <b className="white-label-text">TODOS</b>
            {' '}
            os bancos que sua empresa tem conta.
            Na lista de bancos conectados, selecione qual será utilizado para receber o empréstimo.
          </>
        ),
    },

    business_user_step: {
      title: statusValue === 'check-documents'
        ? ('Revise os dados pessoais da(s) pessoa(s) abaixo.')
        : ('Insira os dados dos sócios/representantes/avalistas\nda empresa.'),
      subtitle: statusValue === 'check-documents'
        ? ('Nossa equipe encontrou algo de errado durante a análise. Confira os dados das pessoas abaixo e revise o necessário. Em caso de dúvidas, entre em contato conosco.')
        : (
          <>
            Para receber seu empréstimo,
            {' '}
            <b className="white-label-text">
              TODOS
            </b>
            {' '}
            devem assinar o contrato.
            As validações para assinatura serão feitas por e-mail e SMS,
            por isso é muito importante que as informações estejam corretas.
          </>
        ),
    },
  };

  const {
    data: {
      me = {},
    } = {},
    refetch,
    loading: meLoading,
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ me: { currentBusiness: { loans } = {} } = {} }) => {
      setStatusValue(loans[0]?.status?.value);

      const pendingLoan = loans.find(({ status: { value } = {} } = {}) => value === 'pending');
      setIsReceivableGuarantee(pendingLoan?.typeValue === 'receivables-guarantee');

      if (!needToGetToRegisterScreen(loans)) {
        setStep(-1);
        history.push('/cadastro/status');
        // eslint-disable-next-line
      } else if (stepVar() == -1) {
        setStep(0);
      }

      if (partner?.flow === 'e2f') {
        headerProps.bank_step = {
          title: `Adicione sua conta do ${partner.accountName}.`,
          subtitle: `Sua conta do ${partner.accountName} será utilizada para receber o seu empréstimo.`,
        };
      }

      if (loans?.[0]?.status?.value === 'review') {
        const formattedSteps = me?.currentBusiness?.revision.map(({ completedAt, status }, i) => {
          if (checkActions(['partner-is-banker']) && status === 'pending-bank-account') {
            return undefined;
          }

          if (!completedAt) {
            return i;
          }
          if ((status === 'pending-users') && (completedAt)) {
            headerProps.business_user_step = {
              title: 'Estamos salvando suas informações',
              // eslint-disable-next-line max-len
              subtitle: 'Aguarde enquanto estamos salvando as alterações.',
            };
          } else {
            headerProps.business_user_step = {
              title: 'Insira os dados dos responsáveis da empresa.',
              subtitle: (
                <>
                  Para receber seu empréstimo,
                  {' '}
                  <b className="white-label-text">TODOS</b>
                  {' '}
                  os sócios devem assinar o contrato que enviamos por SMS.
                  Por isso, é muito importante que as informações estejam corretas.
                </>
              ),
            };
          }
          return undefined;
        }).filter((i) => i !== undefined);

        if ((!formattedSteps.includes(currentStep) && (currentStep < currentRange.max - 1))) {
          setCurrentStep(currentStep + 1);
        }
      }
    },
  });

  const {
    data: {
      settings: {
        necessaryDocumentsFlow,
      } = {},
    } = {},
  } = useQuery(SETTINGS);

  const businessUsers = me?.currentBusiness?.users;

  const handleNextCarouselStep = () => {
    if (currentStep < currentRange.max - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setRedirectLoading(true);
      setTimeout(() => {
        history.push('/cadastro/status');
      }, 5000);
    }
  };

  const filteredBusinessUsers = [{
    label: 'Sócios',
    type: 'socio',
    users: businessUsers
      ?.filter(({ types }) => types.some(({ value }) => value === 'socio'))
      .sort((a, b) => Number(!!a.completedAt) - Number(!!b.completedAt)),
  }, {
    label: 'Avalistas',
    type: 'avalista',
    users: businessUsers
      ?.filter(({ types }) => types.some(({ value }) => value === 'avalista'))
      .sort((a, b) => Number(!!a.completedAt) - Number(!!b.completedAt)),
  }, {
    label: 'Representantes Legais',
    type: 'representantelegal',
    users: businessUsers
      ?.filter(({ types }) => types.some(({ value }) => value === 'representantelegal'))
      .sort((a, b) => Number(!!a.completedAt) - Number(!!b.completedAt)),
  }];

  const isE2F = partner?.flow === 'e2f';
  const progressPercent = (100 / (currentRange.max + 1)) * (currentStep + 1);
  const isOlist = partner?.flow === 'olist';
  const isIugu = partner?.flow === 'iugu';
  const isBadesc = partner?.flow === 'badesc';
  const needBankAccountOptin = useMemo(() => {
    if (!me?.id) {
      return false;
    }

    const optins = me.optIns || [];

    return isReceivableGuarantee && !optins.some(({ type }) => type === 'BANK-ACCOUNT-AUTHORIZATION');
  }, [isReceivableGuarantee, me]);

  useQuery(BANKS, {
    skip: !isE2F,
    onCompleted: async ({ banks = [] }) => {
      setSelectedBank(banks[0]);
    },
  });

  const [saveBusiness, { loading: businessLoading }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      handleNextCarouselStep();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveAddress, { loading: addressLoading }] = useMutation(SAVE_ADDRESS, {
    oonError: (error) => formatAndNotificateError(error),
  });

  const [saveBankAccount, { loading: bankLoading }] = useMutation(SAVE_BANK_ACCOUNT, {
    onCompleted: () => {
      handleNextCarouselStep();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const isLoading = businessLoading
    || addressLoading
    || bankLoading
    || redirectLoading
    || meLoading;
  const checkForm = async (keys) => {
    const params = {};
    const formsVariable = await form.getFieldsValue();
    Object.keys(formsVariable).map((key) => {
      if (keys.includes(key)) params[key] = formsVariable[key];
      return true;
    });
    return params;
  };

  const validateStatements = (statements) => {
    const statementsWithValue = statements.filter(({ value }) => Boolean(Number(value)));
    return statementsWithValue.length >= 6;
  };

  const handleEditBankAccount = ({ bankAccount }) => {
    setEditedBankAccount(bankAccount);
    setBankDrawer(true);
  };

  const steps = useMemo(() => {
    const array = [
      (statusValue !== 'check-documents') && !isOlist && {
        // ====== BUSINESS_FORM_STEP ========
        name: 'business_form_step',
        pending: 'pending-business-infos',
        content: (
          <div className={`carousel-content ${statusValue !== 'check-documents' ? 'current' : ''}`} key={currentStep}>
            <Row align="middle">
              <Col xs={24}>
                <Card
                  className="business-form-card"
                  title={(
                    <>
                      <h3 className="no-margin-bottom secondary-text">Queremos te conhecer</h3>
                      <h3 className="no-margin-bottom">
                        Insira as informações básicas da sua empresa
                      </h3>
                    </>
                  )}
                >
                  <BusinessForm
                    form={form}
                    flow={partner?.flow}
                    address={me?.currentBusiness?.address || {}}
                    business={me?.currentBusiness}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        ),
        handleSubmit: async () => {
          const addressParams = await checkForm(['postalCode', 'street', 'district', 'number', 'state', 'city', 'complement']);

          const postalCodeLength = addressParams.postalCode.replace(/[_-\s]/g, '').length;

          if (postalCodeLength !== 8) {
            notification.error({ message: 'Preencha o CEP com 8 dígitos' });
            return;
          }
          const response = await saveAddress({
            variables: {
              ...addressParams,
              businessId: me?.currentBusiness?.id,
              id: me?.currentBusiness?.address?.id,
            },
          });
          if (!response) {
            return;
          }

          const params = await checkForm(['name', 'legalName', 'cnpj', 'phoneNumber']);
          await saveBusiness({
            variables: { ...params, id: me?.currentBusiness?.id },
          });
        },
      },
      businessFilesStepActive && {
        // ====== BUSINESS_FILES_STEP ========
        name: 'business_files_step',
        pending: 'pending-docs',
        content: (
          <div className="carousel-content current" key={currentStep}>
            <Row gutter={12}>
              <Col span={24}>
                <BusinessUploadForm
                  form={form}
                  refetch={refetch}
                  flow={partner?.flow}
                  line={me?.currentBusiness?.additionalInfo?.clientCreditLine}
                  type={me?.currentBusiness?.type?.split(' ')[0]}
                  files={{
                    proofOfAddress: me?.currentBusiness?.address?.proofOfAddress,
                    bylaws: me?.currentBusiness?.bylaws,
                    revenueStatement: me?.currentBusiness?.revenueStatement,
                    cadastur: me?.currentBusiness?.cadastur,
                    images: me?.currentBusiness?.images,
                    majorityPartnerSelfie: me?.currentBusiness?.majorityPartnerSelfie,
                  }}
                  statements={me?.currentBusiness?.revenueStatementValues}
                  validateStatements={validateStatements}
                  segment={me?.currentBusiness?.segment?.value}
                  necessaryDocumentsFlow={necessaryDocumentsFlow}
                  lemitValidated={me?.currentBusiness?.address?.lemitValidated}
                  address={me?.currentBusiness?.address}
                  hideNotAnalyzedFiles={statusValue === 'check-documents'}
                  businessDocumentsRevised={businessDocumentsRevised}
                  setBusinessDocumentsRevised={setBusinessDocumentsRevised}
                />
              </Col>
            </Row>
          </div>
        ),
        handleSubmit: async () => {
          let valuesToCheck = [];

          if (statusValue !== 'check-documents') {
            valuesToCheck = [
              'bylawsFileId',
              'revenueStatementFilesIds',
              'cadasturFileId',
              'revenueStatementValues',
              'imagesFilesIds',
              'proofOfAddressFileId',
            ];
          }
          if (necessaryDocumentsFlow !== 'B') {
            valuesToCheck.push('proofOfAddressFileId', 'majorityPartnerSelfieFileId');
          }
          if (statusValue === 'check-documents') {
            if (me?.currentBusiness?.address?.analysis?.approved === false) {
              valuesToCheck.push('proofOfAddressFileId');
            }
            if (me?.currentBusiness?.bylaws?.analysis?.approved === false) {
              valuesToCheck.push('bylawsFileId');
            }
          }

          const params = await checkForm(valuesToCheck);

          if (statusValue === 'check-documents') {
            if ((Object.keys(params).length !== businessDocumentsRevised.length)
              || businessDocumentsRevised.length === 0) {
              notification.error({ message: 'Revise os documentos antes de prosseguir.' });
              return;
            }
          }

          if (statusValue !== 'check-documents') {
            if (!params?.bylawsFileId) {
              notification.error({ message: 'Faça upload do contrato social antes de prosseguir.' });
              return;
            }

            if ((necessaryDocumentsFlow === 'A' || (necessaryDocumentsFlow === 'B' && !me?.currentBusiness?.address?.lemitValidated)) && !params?.proofOfAddressFileId) {
              notification.error({ message: 'Faça upload do comprovante de endereço antes de prosseguir.' });
              return;
            }
          }

          if (partner?.flow === 'badesc' && statusValue !== 'check-documents') {
            if (params.revenueStatementFilesIds.length === 0) {
              notification.error({ message: 'Preencha as informações referentes ao faturamento' });
            } else if (!validateStatements(params.revenueStatementValues)) {
              notification.error({ message: 'Preencha os valores referentes ao faturamento' });
            } else {
              await saveBusiness({
                variables: { ...params, id: me?.currentBusiness?.id },
              });
            }
          } else {
            await saveBusiness({
              variables: { ...params, id: me?.currentBusiness?.id },
            });
          }
        },
      },
      bankStepActive && {
        // ====== BANK_STEP ========
        name: 'bank_step',
        pending: 'pending-bank-account',
        content: (
          <div className="carousel-content current" key={currentStep}>
            {isE2F && (
              <Row>
                <BankAccountForm
                  form={form}
                  bank={selectedBank}
                  onCompleted={(bank) => setBank(bank)}
                  step={bank?.step || 0}
                />
              </Row>
            )}

            {!isE2F && (
              <Tabs
                className="bank-actions"
                activeKey={activeTab}
                onChange={(e) => setActiveTab(`${e}`)}
              >
                <TabPane
                  tab={(
                    <h5 className="no-margin-bottom">
                      {statusValue === 'check-documents' ? 'Conectar bancos' : 'Lista de bancos'}
                    </h5>
                  )}
                  key="1"
                >
                  <Row>
                    <BanksList
                      onSet={(selectedBank) => {
                        setSelectedBank(selectedBank);
                        setBankDrawer(true);
                      }}
                    />
                  </Row>
                </TabPane>

                <TabPane
                  tab={(
                    <h5 className={`bank-tooltip no-margin-bottom ${me?.currentBusiness?.bankAccounts?.length === 0 ? ' gray-text' : ''}`}>
                      {`${statusValue === 'check-documents'
                        ? 'Extratos bancários'
                        : 'Suas contas'} (${me?.currentBusiness?.bankAccounts?.length || '0'})`}
                    </h5>
                  )}
                  key="2"
                  disabled={me?.currentBusiness?.bankAccounts?.length === 0}
                >
                  {me?.currentBusiness?.bankAccounts?.map((account) => (
                    <AccountCard
                      key={account.id}
                      id={account.id}
                      code={account.bank.code}
                      flow={partner?.flow}
                      selected={me?.currentBusiness?.bankAccount?.id === account.id}
                      status={{ name: 'CONECTADA', value: 'active' }}
                      logo={account?.bank?.logo?.url}
                      refetchBankAccount={refetch}
                      platformName={account?.bank?.name}
                      account={{
                        branchCode: account.branchCode,
                        accountNumber: account.accountNumber,
                        currentAccount: account.currentAccount ? 'corrente' : 'poupanca',
                        statementFileIds: account.statements.map((statement) => statement.id),
                        bank: account?.bank,
                        statementFiles: account.statements,
                        id: account.id,
                      }}
                      type="BankAccount"
                      bypass
                      currentAccount={account.currentAccount}
                      createdByPluggy={account.origin === 'pluggy'}
                      approved={account.analysis?.approved}
                      onEdit={handleEditBankAccount}
                    />
                  ))}
                </TabPane>
              </Tabs>
            )}
          </div>
        ),
        handleSubmit: async () => {
          if (statusValue === 'check-documents'
            && me?.currentBusiness?.bankAccounts?.some(
              (account) => account.analysis?.approved === false,
            )) {
            notification.error({ message: 'Para continuar é necessário revisar suas informações bancárias.' });
            return;
          }
          if (isE2F) {
            // E2F Não tem listagem de bancos, apenas o formulário
            if (bank?.step !== 1) setBank({ ...bank, step: 1 });
            else {
              const {
                bankId, branchCode, accountNumber, currentAccount, statement: { uid } = {},
              } = bank;
              await saveBankAccount({
                variables: {
                  businessId: me?.currentBusiness?.id,
                  bankId,
                  branchCode,
                  accountNumber,
                  currentAccount,
                  statementFileId: uid,
                  origin: 'pending-screen',
                },
              });
            }
          } else if (activeTab === '1') {
            // Caso estiver na lista de bancos para se cadastrar, ir para a lista de bancos cadastrada
            setActiveTab('2');
          } else if (isIugu) {
            if (me?.currentBusiness?.bankAccount?.bank?.code === '401') {
              // Se for iugu e não tiver cadastrado o banco da iugu (401), não pode continuar
              setConfirmBankModal(true);
            } else {
              // Tudo certo, pode confirmar e continuar
              notification.error({ message: 'Seu banco principal precisa ser IUGU' });
            }
          } else if (me?.currentBusiness?.bankAccounts?.length < 2
            || me?.currentBusiness?.bankAccounts
              ?.filter((bankAccount) => bankAccount.origin === 'pluggy').length === 1) {
            setStep(-1);
            handleNextCarouselStep();

          } else {
            // Tudo certo, pode confirmar e continuar
            setConfirmBankModal(true);
          }
        },
      },
      businessUserStepActive && {
        // ====== BUSINESS_USER_STEP ========
        name: 'business_user_step',
        pending: 'pending-users',
        content: (statusValue !== 'review' && !isLoading) && (
          <div className="carousel-content current" key={currentStep}>
            <Tabs tabPosition="top" animated>
              {filteredBusinessUsers.map(({
                label,
                type,
                users = [],
              }, index) => {
                const usersIds = users?.map(({ user: { id } }) => id);
                const civilPartnerIds = type === 'avalista' ? users.map(({ user }) => user?.civilPartner?.id) : [];
                const count = new Set([
                  ...usersIds.filter((i) => i),
                  ...civilPartnerIds.filter((i) => i),
                ]).size;

                return (
                  <TabPane
                    tab={(
                      <h5 className="no-margin-bottom">
                        {label}
                        {' '}
                        (
                        {count}
                        )
                      </h5>
                    )}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index + 1}
                  >
                    <Row gutter={[14, 14]} className="add-business-user-buttons-container">
                      <Col xs={24} sm={24} md={8} lg={6} xl={5}>
                        <Button
                          block
                          onClick={() => {
                            setBusinessUserType('socio');
                            setFindUserDrawer(true);
                            setIsCreation(true);
                          }}
                        >
                          Adicionar sócio(a)
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={5}>
                        <Button
                          block
                          color="white"
                          onClick={() => {
                            setBusinessUserType('avalista');
                            setFindUserDrawer(true);
                            setIsCreation(true);
                          }}
                        >
                          Adicionar avalista
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={7} xl={6}>
                        <Button
                          block
                          color="white"
                          onClick={() => {
                            setBusinessUserType('representantelegal');
                            setFindUserDrawer(true);
                            setIsCreation(true);
                          }}
                        >
                          Adicionar representante legal
                        </Button>
                      </Col>

                    </Row>

                    <br />

                    <div className="business-users-list-container">
                      {users?.map((businessUser) => (
                        <BusinessUserCard
                          ids={users.map(({ user: { id } }) => id)}
                          revised={businessUsersRevised.includes(businessUser?.id)}
                          businessUser={businessUser}
                          loanStatus={statusValue}
                          key={businessUser.id}
                          refetch={refetch}
                          onClickEdit={() => {
                            setSelectedBusinessUser(businessUser);
                            setBusinessUserType(type);
                            setBusinessUserDrawer(true);
                            setIsCreation(false);
                          }}
                          section={{
                            type,
                            label,
                          }}
                        />
                      ))}
                    </div>
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        ),
        handleSubmit: async () => {
          if (me?.currentBusiness?.users.length === 0) {
            notification.error({
              message: 'Adicione ao menos um responsável da empresa',
            });
            return;
          }

          if (me?.currentBusiness?.businessRequests.some((businessRequest) => businessRequest.type.value === 'add-avalist')) {
            notification.error({
              message: 'É necessário a adição de um novo avalista.',
            });
            return;
          }

          if (me?.currentBusiness?.users.some(
            ({ user }) => user?.address?.analysis?.approved === false
              || user?.proofOfIncome?.analysis?.approved === false,
          )
            && statusValue === 'check-documents'
          ) {
            notification.error({
              message:
                'Revise todos os usuários antes de continuar.',
            });
            return;
          }

          if (me?.currentBusiness?.users.some((user) => !user?.completedAt)) {
            notification.error({
              message:
                'Complete as informações de todos os responsáveis antes de continuar',
            });
            return;
          }

          if (!me?.currentBusiness?.users.some((user) => user.types.find((type) => type.value === 'socio'))) {
            notification.error({
              message: 'É necessário pelo menos um sócio para continuar',
            });
            return;
          }

          if (!me?.currentBusiness?.users.some((user) => user.types.find((type) => type.value === 'representantelegal'))) {
            notification.error({
              message: 'É necessário pelo menos um representante legal para continuar',
            });
            return;
          }

          const users = businessUsers.map(({
            user: {
              birthDate,
              id,
            },
          }) => ({ id, birthDate })) || [];
          const civilPartners = businessUsers
            .map(({ user }) => user?.civilPartner
              && ({ birthDate: user.civilPartner.birthDate, id: user?.civilPartner.id }))
            .filter((i) => i) || [];
          if (![...users, ...civilPartners].some(({ birthDate }) => dateDiff(new Date(birthDate), new Date(), 'years') >= 25 && dateDiff(new Date(birthDate), new Date(), 'years') <= 65)) {
            notification.error({
              message: 'Adicione um avalista com idade entre 25 anos e 65 anos',
              description: 'Conforme nossa política de crédito, é necessário incluir um avalista adicional com idade entre 25 anos e 65 anos para continuar',
            });
            return;
          }

          if (statusValue !== 'check-documents' && !isBadesc && chances < 5) {
            setChancesAlertModal(true);
            return;
          }

          if (needBankAccountOptin) {
            setBankAccountOptInDrawer(true);
            return;
          }

          setStep(-1);
          await saveBusiness({
            variables: { id: me?.currentBusiness?.id, completed: true },
          });
        },
      },
    ];

    return array.filter(Boolean);
  }, [
    businessFilesStepActive,
    statusValue,
    bankStepActive,
    businessUserStepActive,
    me,
    businessDocumentsRevised,
    statusValue,
    isLoading,
    isBadesc,
    chances,
    filteredBusinessUsers,
    currentStep,
    selectedBank,
    isE2F,
    isOlist,
  ]);

  useEffect(() => {
    if (stepVar() && stepVar() !== -1) {
      if (statusValue !== 'check-documents') {
        setCurrentStep(stepVar());
      }
    }
  }, []);

  useEffect(() => {
    refetch();

    if (!carouselRef) {
      return;
    }
    if (statusValue !== 'check-documents' && stepVar() !== -1 && currentStep !== -1) {
      setStep(currentStep);
    }

    if (statusValue === 'review') {
      setCurrentStep(currentStep - 1);
    }

    setTimeout(() => carouselRef.goTo(currentStep), 200);
  }, [currentStep]);

  const isSubmitButtonDisabled = () => {
    const {
      bankAccount,
    } = me?.currentBusiness || {};

    switch (steps[currentStep]?.name) {
      case 'bank_step': {
        if (!bankAccount && !isE2F) {
          return true;
        }

        if (isE2F) {
          if (!bank) {
            return true;
          }

          if (bank.step === 1 && (!bank.statements[0] || bank.confirmation !== true)) {
            return true;
          }
        }
        break;
      }

      case 'business_user_step': {
        if (me?.currentBusiness?.users?.length === 0) {
          return true;
        }
        break;
      }

      default:
        return false;
    }

    return false;
  };

  const hasBackButton = currentStep > 0
    && !(steps[currentStep]?.name === 'business_files_step' && isOlist)
    && ((statusValue !== 'review') || (currentStep - 1) >= 0);

  const closeMotivationModal = () => {
    isVideoMotivationalSeen(true);
    localStorage.setItem('motivationalModalSeen', true);
  };

  useEffect(() => {
    isVideoMotivationalSeen(true);
  }, []);

  useEffect(() => {
    if (checkActions(['partner-is-banker'])) {
      setBankStepActive(false);
    }
  }, [loadingActions]);

  useEffect(() => {
    if (statusValue === 'check-documents') {
      if (me?.currentBusiness?.address?.analysis?.approved !== false
        && me?.currentBusiness?.bylaws?.analysis?.approved !== false) {
        setBusinessFilesStepActive(false);
      }
      if (!me
        ?.currentBusiness
        ?.bankAccounts
        .some((bankAccount) => bankAccount?.analysis?.approved === false)) {
        setBankStepActive(false);
      }
      const verificationAddressBusinessUser = businessUsers.some(
        ({ user }) => user?.address?.analysis?.approved === false,
      );
      const verificationIncomeBusinessUser = businessUsers.some(
        ({ user }) => user?.proofOfIncome?.analysis?.approved === false,
      );

      const verificationRequestToAddAvalist = me?.currentBusiness?.businessRequests?.some((businessRequest) => businessRequest?.type?.value === 'add-avalist');

      if (!verificationAddressBusinessUser
        && !verificationIncomeBusinessUser
        && !verificationRequestToAddAvalist) {
        setBusinessUserStepActive(false);
      }
      setActiveTab('2');
    }
    if (!meLoading) {
      setStep(0);
      setContentLoading(false);
    }
  }, [statusValue]);

  useEffect(() => {
    setCurrentRange({ min: 0, max: steps?.length || 0 });
    if (steps?.length === 0 && !isLoading) {
      setStep(-1);
      history.push('/cadastro/status');
    }
  }, [steps?.length]);

  return (
    <Layout id="business-register-screen">
      <SubHeader
        title={headerProps[steps[currentStep]?.name]?.title}
        subtitle={headerProps[steps[currentStep]?.name]?.subtitle}
        extra={!isBadesc && statusValue !== 'check-documents' && (
          <LoanChances business={me?.currentBusiness} setChances={(e) => setChances(e)} />
        )}
        extraButtons={(steps[currentStep]?.name === 'bank_step' && statusValue !== 'check-documents') ? (
          <Button
            onClick={() => {
              isVideoMotivationalSeen(!validationModalSeen);
            }}
            icon={<PlayCircleOutlined className="white-label-text" />}
            color="white"
            link
          >
            <span className="white-label-text underline">Assista nosso vídeo explicativo sobre Plataformas!</span>
          </Button>
        ) : ([])}
        extraButtonsPlacement="bottom"
      />
      {!contentLoading ? (
        <>
          <Progress
            percent={progressPercent}
            bordered
            rounded={false}
            borderPlacement="bottom"
            progressColor="primary-button"
          />

          <Content>
            {steps.length > 0 && (
              <Carousel
                ref={(c) => { carouselRef = c; }}
                dots={false}
                swipe={false}
                swipeToSlide={false}
                draggable={false}
                accessibility={false}
              >
                <CustomStep steps={steps} current={currentStep} />
              </Carousel>
            )}
          </Content>
        </>
      ) : (
        <Skeleton active />
      )}

      <div className="global-footer-wrapper">
        <div className={`footer ${(!hasBackButton || statusValue === 'check-documents') && 'no-back-btn'}`}>
          {hasBackButton && statusValue !== 'check-documents' && (
            <Button
              link
              disabled={isLoading}
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Voltar'}
            </Button>
          )}

          <Button
            id="business-register-next-button"
            disabled={isSubmitButtonDisabled()}
            loading={isLoading}
            onClick={steps[currentStep]?.handleSubmit}
            data-cy="business-register-next-button"
          >
            Confirmar e prosseguir
          </Button>
        </div>
      </div>

      {steps[currentStep]?.name === 'bank_step' && (
        <>
          <BankAccountDrawer
            onPending
            visible={bankDrawer}
            selectedBank={selectedBank}
            selectedBankAccount={editedBankAccount}
            refetch={refetch}
            onClose={() => {
              setBankDrawer(false);
              setSelectedBank();
              setEditedBankAccount();
            }}
            onSelectMain={() => {
              setBankDrawer(false);
              setSelectedBank();
              setActiveTab('2');
            }}
          />

          {me?.currentBusiness?.bankAccount && (
            <Modal
              visible={confirmBankModal}
              onCancel={() => setConfirmBankModal(false)}
              centered
              title="Escolha a conta na qual o crédito será desembolsado"
              okButton={{
                id: 'confirm-bank-account-button',
                text: 'Desembolsar nesta conta',
                onClick: () => {
                  setConfirmBankModal(false);
                  handleNextCarouselStep();
                },
              }}
            >
              <Row gutter={[16, 16]}>
                {me?.currentBusiness?.bankAccounts?.map((account) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <AccountCard
                      key={account.id}
                      refetch={refetch}
                      id={account.id}
                      code={account.bank.code}
                      flow={partner?.flow}
                      selected={me?.currentBusiness?.bankAccount?.id === account.id}
                      status={{ name: 'CONECTADA', value: 'active' }}
                      logo={account?.bank?.logo?.url}
                      refetchBankAccount={refetch}
                      platformName={account?.bank?.name}
                      account={{
                        branchCode: account.branchCode,
                        accountNumber: account.accountNumber,
                      }}
                      type="BankAccount"
                      currentAccount={account.currentAccount}
                      mobileStyle
                      createdByPluggy={account.origin === 'pluggy'}
                      currentBankAccountCreatedByPluggy={me
                        ?.currentBusiness
                        ?.bankAccount
                        ?.origin === 'pluggy'}
                      removable={false}
                    />
                  </Col>
                ))}
              </Row>
            </Modal>
          )}
        </>
      )}

      {steps[currentStep]?.name === 'business_user_step' && (
        <>
          <FindUserDrawer
            businessId={me?.currentBusiness?.id}
            visible={findUserDrawer}
            onClose={() => setFindUserDrawer(false)}
            refetch={refetch}
            businessUsers={businessUsers}
            type={businessUserType}
            onAddNewUser={(cpf) => {
              setFindUserDrawer(false);
              setSelectedBusinessUser({ id: undefined, cpf, types: [businessUserType] });
              setBusinessUserDrawer(true);
            }}
          />

          <BusinessUserDrawer
            businessId={me?.currentBusiness?.id}
            flow={partner?.flow}
            visible={businessUserDrawer}
            onClose={() => {
              setBusinessUserDrawer(false);
              setSelectedBusinessUser();
            }}
            type={businessUserType}
            businessUsersRevised={businessUsersRevised}
            setBusinessUsersRevised={setBusinessUsersRevised}
            setSelectedBusinessUser={setSelectedBusinessUser}
            refetch={refetch}
            isCreation={isCreation}
            businessUser={selectedBusinessUser}
            me={selectedBusinessUser?.user?.id === me?.id}
            onAddNewUser={() => {
              setBusinessUserDrawer(false);
              setSelectedBusinessUser();
              setFindUserDrawer(true);
            }}
            necessaryDocumentsFlow={necessaryDocumentsFlow}
            loanStatus={statusValue}
          />

          {!isBadesc && (
            <>
              <LoanChancesAlertModal
                visible={chancesAlertModal}
                business={me?.currentBusiness}
                onClose={() => setChancesAlertModal(false)}
                onClickContinue={async () => {
                  if (needBankAccountOptin) {
                    setChancesAlertModal(false);
                    setBankAccountOptInDrawer(true);
                    return;
                  }

                  setStep(-1);
                  setChancesAlertModal(false);
                  await saveBusiness({
                    variables: { id: me?.currentBusiness?.id, completed: true },
                  });
                }}
                onClickIncrease={() => {
                  setIncreaseChancesDrawer(true);
                  setChancesAlertModal(false);
                }}
              />
              <IncreaseChancesDrawer
                visible={increaseChancesDrawer}
                onClose={() => setIncreaseChancesDrawer(false)}
                business={me?.currentBusiness}
                refetch={refetch}
              />
            </>
          )}

          <BankAccountOptinDrawer
            visible={bankAccountOptInDrawer}
            onFinish={() => {
              setBankAccountOptInDrawer(false);
              setStep(-1);
              saveBusiness({
                variables: { id: me?.currentBusiness?.id, completed: true },
              });
            }}
            onClose={() => setBankAccountOptInDrawer(false)}
          />
        </>
      )}

      <IntegrationVideoMotivationModal
        onClose={closeMotivationModal}
        visible={!validationModalSeen}
      />
    </Layout>
  );
};

export default BusinessRegisterScreen;
