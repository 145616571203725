import AddressForm from './AddressForm';
import BankAccountForm from './BankAccountForm';
import BusinessForm from './BusinessForm';
import BusinessUploadForm from './BusinessUploadForm';
import CivilPartnerForm from './CivilPartnerForm';
import IntegrationsForm from './IntegrationsForm';
import LostPasswordForm from './LostPasswordForm';
import PasswordForm from './PasswordForm';
import LoginWithLinkForm from './LoginWithLinkForm';
import NewPartnerForm from './NewPartnerForm';
import RacialSearchForm from './RacialSearchForm';
import SignatureForm from './SignatureForm';
import DocumentsForm from './DocumentsForm';
import DigitalSignatureForm from './DigitalSignatureForm';
import PrepaymentBankAccountForm from './PrepaymentBankAccountForm';

export {
  AddressForm,
  BankAccountForm,
  BusinessForm,
  BusinessUploadForm,
  IntegrationsForm,
  LostPasswordForm,
  PasswordForm,
  LoginWithLinkForm,
  NewPartnerForm,
  RacialSearchForm,
  SignatureForm,
  CivilPartnerForm,
  DocumentsForm,
  DigitalSignatureForm,
  PrepaymentBankAccountForm,
};
