import PrepaymentBankAccount from './PrepaymentBankAccount';
import BusinessUsers from './BusinessUsers';
import PrepaymentSummary from './PrepaymentSummary';
import CAF from './CAF';

export default {
  'prepayment-bank-account-step': PrepaymentBankAccount,
  'business-users-step': BusinessUsers,
  'prepayment-summary-step': PrepaymentSummary,
  'caf-step': CAF,
};
