import INTEGRATIONS_TYPES from './integrationsTypes.gql';
import SAVE_INTEGRATION from './saveIntegration.gql';
import ME from './me.gql';
import CONNECTORS from './connectors.gql';

export {
  INTEGRATIONS_TYPES,
  SAVE_INTEGRATION,
  ME,
  CONNECTORS,
};
