// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#olist-faq .olist-faq-questions {
  padding: 0 !important;
}
#olist-faq .ant-collapse-borderless {
  background-color: white;
}
#olist-faq .ant-collapse-header {
  font-size: 1.25rem;
}
#olist-faq .ant-collapse-item-active .ant-collapse-header {
  color: #03738C;
  font-weight: bold;
}
#olist-faq .olist-button {
  background-color: #03738C;
  color: white;
}
#olist-faq .center-image {
  text-align: center;
}
@media (max-width: 768px) {
  #olist-faq .olist-button {
    margin-bottom: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/sections/OlistFaq/styles.less"],"names":[],"mappings":"AAAA;EAEI,qBAAA;AAAJ;AAFA;EAMI,uBAAA;AADJ;AALA;EAUI,kBAAA;AAFJ;AARA;EAeM,cAAA;EACA,iBAAA;AAJN;AAZA;EAqBI,yBAAA;EACA,YAAA;AANJ;AAhBA;EA0BI,kBAAA;AAPJ;AAUE;EAAA;IAEI,mBAAA;EARJ;AACF","sourcesContent":["#olist-faq{\n  .olist-faq-questions {\n    padding: 0 !important;\n  }\n\n  .ant-collapse-borderless {\n    background-color: white;\n  }\n\n  .ant-collapse-header {\n    font-size: 1.25rem;\n  }\n\n  .ant-collapse-item-active {\n    .ant-collapse-header {\n      color: #03738C;\n      font-weight: bold;\n    }\n  }\n\n  .olist-button {\n    background-color: #03738C;\n    color: white;\n  }\n\n  .center-image {\n    text-align: center;\n  }\n\n  @media(max-width: @md-screen) {\n    .olist-button {\n      margin-bottom: 1rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
