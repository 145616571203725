import React, { memo } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  Layout,
} from 'components/sdk';
import { NotebookAndMobileMock } from 'assets/images';

const {
  Content,
} = Layout;

const AboutGyra = () => (
  <Content id="about-gyra" className="dark">
    <br />
    <br />

    <Row gutter={[0, 20]} justify="space-between">
      <Col xs={24} sm={24} md={8}>
        <h2>
          <b className="white-text">
            Sobre a GYRA+
          </b>
        </h2>

        <h6 className="white-text">
          A GYRA+ é uma plataforma que tem como
          propósito ampliar os limites de cada
          empreendedor para a construção dos seus
          sonhos.
          <br />
          <br />
          Com o uso inteligente dos dados, a GYRA+
          conecta em plataformas parceiras para
          consultar dados e oferecer uma análise de
          crédito rápida, customizada e segura, sem a
          burocracia dos grandes bancos.
        </h6>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <div align="center">
          <img src={NotebookAndMobileMock} width="90%" alt="notebook" />
        </div>
      </Col>
    </Row>

    <br />
    <br />
  </Content>
);

export default memo(AboutGyra);
