// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-option-account-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.confirm-option-account-body .confirm-modal-image {
  max-height: 10.813rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/ConfirmLoanConfigModal/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;AALA;EAOI,qBAAA;EACA,mBAAA;EACA,qBAAA;AACJ","sourcesContent":[".confirm-option-account-body {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;;\n\n  .confirm-modal-image {\n    max-height: 10.813rem;\n    margin-top: .75rem;\n    margin-bottom: 1.5rem;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
