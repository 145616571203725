// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#documents-form {
  padding-left: 0.3125rem;
}
#documents-form .statement-area-files {
  display: flex;
  overflow-x: auto;
}
#documents-form .statement-area-files .statement-area-file {
  margin-right: 1.75rem;
}
#documents-form .ant-form-item {
  margin-bottom: 2.6rem;
}
#documents-form .gyramais-alert {
  margin-bottom: 0.5rem;
}
#documents-form .upload-card.incomplete {
  animation: warning 1.5s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/DocumentsForm/styles.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAFA;EAGI,aAAA;EACA,gBAAA;AAEJ;AANA;EAOM,qBAAA;AAEN;AATA;EAYI,qBAAA;AAAJ;AAZA;EAgBI,qBAAA;AADJ;AAKI;EACE,gCAAA;AAHN","sourcesContent":["#documents-form{\n  padding-left: 0.3125rem;\n  .statement-area-files {\n    display: flex;\n    overflow-x: auto;\n\n    .statement-area-file {\n      margin-right: 1.75rem;\n    }\n  }\n\n  .ant-form-item {\n    margin-bottom: 2.6rem;\n  }\n\n  .gyramais-alert {\n    margin-bottom: .5rem;\n  }\n\n  .upload-card {\n    &.incomplete{\n      animation: warning 1.5s infinite;\n    }\n  }\n}\n\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
