import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

/**
 * @param {{
 *  invoiceDueDate?: string
 *  installmentCountSelected: number
 *  installmentDiscount: number
 *  cashDiscount: number
 *  maxInstallmentCount: number
 *  noDiscount: boolean
 * }} args
 */
const useSubHeaderFields = ({
  invoiceDueDate,
  installmentCountSelected,
  cashDiscount,
  maxInstallmentCount,
  noDiscount,
}) => {
  const hasInvoice = invoiceDueDate;
  const [daysDiff, setDaysDiff] = useState(0);

  useEffect(() => {
    if (!invoiceDueDate || daysDiff) {
      return;
    }

    setDaysDiff(dayjs(invoiceDueDate).diff(dayjs(), 'days'));
  }, [invoiceDueDate]);

  const daysPlural = daysDiff > 1
    ? 'dias'
    : 'dia';

  const discountText = installmentCountSelected === 1
    ? cashDiscount
    : '';

  const title = useMemo(() => {
    if (hasInvoice) {
      return `O boleto do seu contrato com ${discountText}% de desconto está aguardando ser pago!`; // com boleto
    }

    if (noDiscount) {
      return 'Está com dificuldades em manter o pagamento de seu empréstimo em dia e deseja reduzir o valor das parcelas?';
    }

    return 'Oportunidade incrível para você e seu negócio:';
  }, [hasInvoice, noDiscount]);

  const subtitleDiscount = hasInvoice
    ? `Você tem ${daysDiff} ${daysPlural} para pagar este boleto antes que sua validade expire. Não perca tempo: aproveite esta ótima oportunidade para reabilitar seu nome!` // com boleto
    : `Quite seu contrato com ${discountText}% de desconto à vista`; // 1 vez sem boleto

  const subtitleInstallmentDiscount = `Renegocie seu contrato em até ${maxInstallmentCount} vezes.`; // x vezes sem boleto

  const subtitle = installmentCountSelected > 1
    ? subtitleInstallmentDiscount : subtitleDiscount;

  return {
    title,
    subtitle,
  };
};

export {
  // eslint-disable-next-line import/prefer-default-export
  useSubHeaderFields,
};
