import gql from 'graphql-tag';

const LOAN_UPDATED = gql`
  subscription loanUpdated ($ids: [ID!]!) {
    loanUpdated(ids: $ids) {
      id
    }
  }
`;

const LOAN_CREATED = gql`
  subscription loanCreated {
    loanCreated {
      id
    }
  }
`;

export {
  LOAN_UPDATED,
  LOAN_CREATED,
};
