import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Spin,
  Tabs,
} from 'antd';
import {
  formatAndNotificateError,
  formatCurrency,
} from 'utils';
import {
  BranchesOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import {
  SubHeader,
  Footer,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import {
  AccountCard,
  IntegrationMotivationModal,
  IntegrationVideoMotivationModal,
  AcquirerIntegrationModal,
} from 'components';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  useQuery,
  useMutation,
  useReactiveVar,
} from '@apollo/client';
import ConnectorsList from 'components/others/ConnectorsList';
import {
  INTEGRATIONS,
  COMPLETE_INTEGRATION,
  ME,
  BANK_ACCOUNTS,
  IMPROVE_LOAN_WITH_INTEGRATION,
  SETTINGS,
} from './graphql';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {
  isVideoMotivationalSeen,
} from '../../services/graphql/cache';

import './styles.less';

const { Content } = Layout;
const { TabPane } = Tabs;

const FirstIntegrationScreen = () => {
  const history = useHistory();
  const [finishing, setFinishing] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [acquirerModal, setAcquirerModal] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [integrationMessage, setIntegrationMessage] = useState();
  const [reintegrationVariables, setReintegrationVariables] = useState(false);

  const { width } = useWindowDimensions();

  const { loadingActions, checkActions } = usePartnerActionsContext();
  const { currentContext, contextLoading } = useBusinessLoanContext();

  const isOnlyScore = checkActions(['only-score']);
  const isOnlyByAnnualBilling = checkActions(['show-only-bi-anual-billing']);
  const showFinishModalAction = checkActions(['show-confirm-integration-modal']);

  const validationModalSeen = useReactiveVar(isVideoMotivationalSeen);
  const [specificsIds, setSpecificsIds] = useState([]);

  /* *        Queries and Mutations        * */

  const {
    data: {
      settings: {
        revenueAmountWithoutIntegration = 45000,
      } = {},
    } = {},
  } = useQuery(SETTINGS, { fetchPolicy: 'network-only' });

  const {
    loading: loadingMessage,
    data: {
      me: {
        currentBusiness: {
          id: businessId,
          revenueAmount,
          mei,
        } = {},
      } = {},
    } = {},
    refetch: refetchBusinessRequests,
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me: { currentBusiness: { businessRequests = [] } } = {} }) => {
      const businessRequestsPendings = businessRequests.filter(({ completedAt, type }) => !completedAt && type?.value === 'add-integration');
      const ids = [];
      if (businessRequestsPendings.length > 0) {
        businessRequestsPendings.forEach(({ specificsIds }) => {
          if (!specificsIds || specificsIds.length === 0) {
            ids.push('not-specified');
          } else if (specificsIds && specificsIds.length > 0) {
            ids.push(specificsIds[0]);
          }
        });
      }
      setSpecificsIds(ids);
    },
  });
  const showMotivationVideo = !loadingMessage
    && !validationModalSeen
    && !loadingActions
    && !isOnlyScore;

  const {
    data: {
      integrations = [],
    } = {},
    refetch: refetchIntegration,
  } = useQuery(INTEGRATIONS, {
    variables: { businessId },
    skip: !businessId,
  });

  const {
    data: {
      bankAccounts = [],
    } = {},
    refetch: refetchBankAccount,
  } = useQuery(BANK_ACCOUNTS, {
    variables: { businessId },
    skip: !businessId,
    fetchPolicy: 'cache-and-network',
  });

  const [completeIntegration, { loading: completeLoading }] = useMutation(COMPLETE_INTEGRATION, {
    onCompleted: () => {
      if (currentContext?.partner?.flow === 'badesc') {
        return;
      }
      if (isOnlyScore) {
        // o business se torna integrado então já tacamos ele para a página correta
        history.push('/cadastro/status');
      } else {
        history.push('/');
      }
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    improveLoanWithIntegration,
    { loading: improveLoanWithIntegrationLoading },
  ] = useMutation(IMPROVE_LOAN_WITH_INTEGRATION, {
    onCompleted: () => {
      history.push('/cadastro/status');
    },
    onError: (error) => formatAndNotificateError(error),
  });

  /* *        Functions        * */

  const improvingLoanStatuses = ['pre-approved', 'pending'];
  const hasLoanInImprovingStatus = improvingLoanStatuses
    .includes(currentContext?.loan?.status?.value);

  const handleReintegration = ({ id, typeId }) => {
    setReintegrationVariables({
      integrationId: id,
      integrationTypeId: typeId,
    });
  };

  const closeMotivationModal = () => {
    isVideoMotivationalSeen(true);
    localStorage.setItem('motivationalModalSeen', true);
  };

  const handleSendSolicitation = () => {
    if (hasLoanInImprovingStatus && !isOnlyByAnnualBilling) {
      improveLoanWithIntegration();
    } else if (showFinishModalAction) {
      setFinishing(true);
      setHelpModal(true);
    } else {
      const hasAcquirerIntegration = integrations.find(({ type }) => type.type === 'Meio de pagamento');

      if (!hasAcquirerIntegration && !isOnlyByAnnualBilling) {
        setAcquirerModal(true);
      } else {
        completeIntegration({
          variables: {
            localIntegrationCount: Number(localStorage.getItem('integrationCount')) || 0,
            contextId: currentContext?.id,
          },
        });
      }

    }
  };

  const handleIntegrationMessage = () => {
    if (integrationMessage) {
      return integrationMessage;
    }

    if (isOnlyScore && finishing) {
      return 'Que tal adicionar mais contas no seu painel ? Não se esqueça de que quanto <b class="secondary-text">MAIS</b> contas adicionadas, <b class="secondary-text">MELHORES</b> serão as suas <b class="secondary-text">OFERTAS!</b>';
    }

    if (isOnlyScore && !finishing) {
      return 'Iremos <b class="secondary-text">visualizar</b> seu histórico de faturamento, <b class="secondary-text">em modo leitura</b>, através das contas cadastradas por você a seguir. Não se preocupe: <b class="secondary-text">seus dados estão protegidos e criptografados para sua segurança.</b>';
    }

    if (finishing) {
      return 'Ao conectar <b class="secondary-text">mais contas</b> na GYRA+, <b class="secondary-text">maior será a chance</b> do seu crédito ser aprovado! Tenha certeza de que você realmente integrou o maior número possível de contas com <b class="secondary-text">mais de 6 meses de faturamento</b> antes de enviar sua solicitação';
    }

    return 'Para avaliar sua solicitação de empréstimo, a Gyra+ irá <b class="secondary-text">visualizar seu histórico de faturamento</b>, em <b class="secondary-text">modo leitura</b>, através das contas cadastradas por você a seguir. Não se preocupe: nós não temos acesso aos seus dados!';
  };

  const handleSubHeader = () => {
    const firstLoanStatus = currentContext?.loan?.status;

    let title = firstLoanStatus?.title;
    let subtitle = revenueAmount && firstLoanStatus?.subtitle?.replace('{revenueAmount}', formatCurrency(revenueAmount));

    if (hasLoanInImprovingStatus) {
      title = 'Vamos melhorar sua proposta?';
      subtitle = 'Ao comprovar um faturamento superior, conseguimos melhorar todas as condições. Verifique nas opções abaixo quais contas sua empresa usa, conecte com o maior número possível e nós iremos te avisar assim que finalizarmos a análise.';
      if (Number(localStorage.getItem('integrationCount')) >= integrations.length + bankAccounts.length) {
        subtitle += `\n\nVocê tem ${Number(localStorage.getItem('integrationCount'))} conta(s) conectada(s), para conseguir melhorar sua proposta, conecte mais contas.`;
      }
    }

    let buttons;

    if (isOnlyScore) {
      title = 'Compartilhe seu histórico financeiro!';
      subtitle = 'Abaixo, selecione a(s) plataforma(s) que você utiliza, e insira suas credenciais para autorizar o compartilhamento de dados.';
    }

    if (!isOnlyScore && !loadingActions) {
      buttons = (
        <Button
          onClick={() => {
            isVideoMotivationalSeen(!validationModalSeen);
          }}
          icon={<PlayCircleOutlined className="white-label-text" />}
          color="white"
          link
        >
          <span className="white-label-text underline">Assista nosso vídeo explicativo sobre Plataformas!</span>
        </Button>
      );
    }

    return (
      <SubHeader
        title={title}
        subtitle={subtitle}
        loading={!currentContext?.id || loadingActions}
        buttonsPlacement="bottom"
        buttons={buttons}
      />
    );
  };

  const handleContinueButtonMessage = () => {
    if (hasLoanInImprovingStatus) {
      return 'Melhorar proposta';
    }

    if (integrations.length === 0 && bankAccounts.length === 0) {
      if (revenueAmount >= revenueAmountWithoutIntegration) {
        if (!mei) {
          return 'Seguir sem conectar';
        }
      }
    }

    return 'Continuar';
  };

  const handleDisabledButton = useMemo(() => {
    if (currentContext?.partner?.flow === 'badesc') {
      return true;
    }

    if (completeLoading || improveLoanWithIntegrationLoading || specificsIds.length > 0) {
      return true;
    }

    if (
      (revenueAmount < revenueAmountWithoutIntegration || mei)
      && [...integrations, ...bankAccounts].length === 0
    ) {
      return true;
    }

    const lastIntegrationCountInCache = Number(localStorage.getItem('integrationCount'));
    const integrationCount = (integrations.length + bankAccounts.length);
    const hasLessOrEqualIntegrationsThanLastCache = lastIntegrationCountInCache >= integrationCount;

    if (
      hasLoanInImprovingStatus
      && hasLessOrEqualIntegrationsThanLastCache
    ) {
      return true;
    }

    return false;
  }, [
    specificsIds.length,
    completeLoading,
    improveLoanWithIntegrationLoading,
    revenueAmount,
    mei,
    integrations,
    bankAccounts,
    hasLoanInImprovingStatus,
  ]);

  useEffect(() => {
    if (loadingActions) {
      return false;
    }
    if (!currentContext) {
      return false;
    }

    const isPartnerAnalyzeCredit = checkActions(['partner-analyze-credit']);
    if (isPartnerAnalyzeCredit) {
      history.goBack();
    }

    const { loan, partner } = currentContext;

    if (partner?.integrationMessage) {
      setIntegrationMessage(partner.integrationMessage);
    }

    const isOnlyScore = checkActions('only-score');
    if (!isOnlyScore && !['new', 'lead', 'integrating', 'pre-approved', 'pending', 'check-documents'].includes(loan?.status?.value)) {
      history.push('/');
    }

    return true;
  }, [currentContext, loadingActions]);

  const knowMoreText = isOnlyScore
    ? 'Quanto mais contas conectadas, melhores são as suas condições!'
    : 'Quanto mais contas conectadas maior será sua chance de ser aprovado!';

  return (
    <Layout id="first-integration-screen">
      {handleSubHeader()}
      <Content>
        <Spin spinning={loadingActions}>
          <Tabs
            activeKey={activeKey}
            animated
            onChange={(e) => setActiveKey(e)}
            className="tabs"
          >
            <TabPane tab={(<h5 className="no-margin-bottom">Lista de plataformas</h5>)} key="1">
              <ConnectorsList
                refetchBankAccount={refetchBankAccount}
                refetchIntegration={refetchIntegration}
                handleSendSolicitation={handleSendSolicitation}
                sendSolicitationLoading={completeLoading}
                reintegrationVariables={reintegrationVariables}
                sendSolicitation={!isOnlyScore}
                businessRequestsIds={specificsIds}
                refetchBusinessRequests={refetchBusinessRequests}
                loanStatus={currentContext?.loan?.status?.value}
              />
            </TabPane>

            <TabPane
              tab={(
                <h5 className={`no-margin-bottom ${(integrations.length + bankAccounts.length) === 0 ? ' gray-text' : ''}`}>
                  Conectadas ({integrations.length + bankAccounts.length})
                </h5>
              )}
              disabled={integrations.length === 0 && bankAccounts.length === 0}
              key="2"
            >
              {integrations.map(({
                id, credentials, type, removedAt,
              }) => (
                <AccountCard
                  id={id}
                  key={id}
                  status={{ name: `${removedAt ? 'REINTEGRAR' : 'CONECTADA'}`, value: `${removedAt ? 'review' : 'active'}` }}
                  type="Integration"
                  typeInfo={type}
                  removedAt={removedAt}
                  platformName={type.name}
                  logo={type.icon?.url}
                  account={JSON.parse(credentials)}
                  refetch={refetchIntegration}
                  reintegration={handleReintegration}
                />
              ))}
              {bankAccounts.map(({
                id, bank, branchCode, accountNumber,
              }) => (
                <AccountCard
                  id={id}
                  key={id}
                  status={{ name: 'CONECTADA', value: 'active' }}
                  type="BankAccount"
                  platformName={bank?.name}
                  logo={bank?.logo?.url}
                  account={{
                    branchCode,
                    accountNumber,
                  }}
                  refetchBankAccount={refetchBankAccount}
                  bypass
                />
              ))}
            </TabPane>
          </Tabs>
        </Spin>
      </Content>

      <Footer
        leftContent={width > 768 && (
          <div>
            <h2 className="no-margin-bottom">
              <strong className="white-label-text">
                SUAS CONTAS
              </strong>
            </h2>

            <div className="your-accounts-container">
              <h1 className="secondary-text no-margin-bottom">
                <BranchesOutlined />
              </h1>

              <h1 className="white-label-text no-margin-bottom your-accounts-container-label">
                {integrations.length + bankAccounts.length}
              </h1>

              <h5 className="white-label-text no-margin-bottom">
                CONTAS
                <br />
                CONECTADAS
              </h5>
            </div>
          </div>
        )}
        middleContent={width > 768 && (
          <div>
            <p>
              <b className="white-label-text">
                <ExclamationCircleOutlined className="secondary-text footer-icon" />
                {knowMoreText}
              </b>
            </p>

            <Button
              link
              className="secondary-text"
              icon={<QuestionCircleOutlined />}
              onClick={() => setHelpModal(true)}
            >
              Saiba mais
            </Button>
          </div>
        )}
        okButton={{
          text: handleContinueButtonMessage(),
          onClick: handleSendSolicitation,
          loading: (contextLoading || completeLoading || improveLoanWithIntegrationLoading),
          disabled: handleDisabledButton,
          id: 'complete-integration-button',
          className: (integrations.length === 0 && bankAccounts.length === 0)
            || (Number(localStorage.getItem('integrationCount')) >= integrations.length + bankAccounts.length) || specificsIds.length > 0 ? '' : 'pulse-animation',
        }}
        cancelButton={hasLoanInImprovingStatus && Number(localStorage.getItem('integrationCount')) >= integrations.length + bankAccounts.length && {
          onClick: () => history.goBack(),
        }}
      />

      <IntegrationVideoMotivationModal
        title="Mantenha-se! A Gyra+ não tem acesso aos seus dados."
        onClose={closeMotivationModal}
        visible={showMotivationVideo}
      />

      <IntegrationMotivationModal
        title={isOnlyScore
          ? 'Você está protegido! Seu dados estão encriptados.'
          : 'Por que integrar a sua conta?'}
        onClose={() => setHelpModal(false)}
        visible={!finishing && !loadingMessage && helpModal}
        message={handleIntegrationMessage()}
      />

      {showFinishModalAction && (
        <IntegrationMotivationModal
          title={
            isOnlyScore
              ? 'Sucesso!'
              : 'Deseja enviar sua solicitação agora? Você pode integrar mais contas!'
          }
          checkListIcon={isOnlyScore}
          cancelButtonOnlyClose={isOnlyScore}
          onClose={() => {
            setHelpModal(false);
            setTimeout(() => {
              setFinishing(false);
            }, 300);
          }}
          loading={completeLoading}
          onComplete={() => {
            const hasAcquirerIntegration = integrations.find(({ type }) => type.type === 'Meio de pagamento');

            if (!hasAcquirerIntegration) {
              setAcquirerModal(true);
            } else {
              completeIntegration({
                variables: {
                  localIntegrationCount: Number(localStorage.getItem('integrationCount')) || 0,
                  contextId: currentContext?.id,
                },
              });
            }
          }}
          visible={finishing && !loadingMessage && helpModal}
          message={handleIntegrationMessage()}
        />
      )}

      <AcquirerIntegrationModal
        visible={acquirerModal}
        onClose={() => setAcquirerModal(false)}
        onSkip={() => {
          setAcquirerModal(false);
          setHelpModal(false);
          setTimeout(() => {
            setFinishing(false);
          }, 300);

          completeIntegration({
            variables: {
              localIntegrationCount: Number(localStorage.getItem('integrationCount')) || 0,
              contextId: currentContext?.id,
            },
          });
        }}
      />
    </Layout>
  );
};

export default FirstIntegrationScreen;
