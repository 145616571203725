import {
  message,
} from 'antd';
import {
  ApolloClient,
  InMemoryCache,
  gql,
  split,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloLink } from '@apollo/client/core';
import {
  meVar,
  integrationVar,
  redirectVar,
  drawerVar,
  loggedOutVar,
} from './cache';

const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url, options) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const opts = {
      status: xhr.status,
      statusText: xhr.statusText,
      headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
    };
    opts.url = 'responseURL' in xhr
      ? xhr.responseURL
      : opts.headers.get('X-Request-URL');
    const body = 'response' in xhr ? xhr.response : xhr.responseText;
    resolve(new Response(body, opts));
  };
  xhr.onerror = () => {
    reject(new TypeError('Network request failed'));
  };
  xhr.ontimeout = () => {
    reject(new TypeError('Network request failed'));
  };
  xhr.open(options.method, url, true);

  Object.keys(options.headers).forEach((key) => {
    xhr.setRequestHeader(key, options.headers[key]);
  });

  if (xhr.upload) {
    xhr.upload.onprogress = options.onProgress;
  }

  xhr.send(options.body);
});

const customFetch = (uri, options) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const httpLink = createUploadLink({
  uri: process.env.GRAPHQL_URI,
  fetch: customFetch,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: (meVar() && meVar().sessionToken) || null,
    // bypassing validation tunnel security reminder
    ...(
      process.env.TUNNEL_VALIDATION
        ? { 'Bypass-Tunnel-Reminder': 1 }
        : {}
    ),
  },
}));

const typeDefs = gql`
  input SortInput {
    asceding: String
    descending:  String
  }


  input RevenueStatement {
    value: Float!
    reference: Date!
    mesano: String!
  }

  input FinalDigitalSignature {
    nonce: String!
    cifrado: String!
  }
  
  extend type Mutation {
    addSession: Boolean
    addIntegrationId: Boolean
    removeSession: Boolean
    setLoggedOut: Boolean
  }
`;

const resolvers = {
  Mutation: {
    setLoanDrawer: (_, { visible, loanId }) => drawerVar({ loanDrawer: { loanId: loanId || null, visible, __typename: 'LoanDrawer' } }),
    addSession: async (parent, { me }) => {
      localStorage.setItem('me', JSON.stringify(me));
      meVar(me);
      return true;
    },
    addIntegrationId: async (_, { integrationId }) => {
      localStorage.setItem('integration', integrationId);
      integrationVar({ integrationId });
      return true;
    },
    removeSession: async () => {
      localStorage.removeItem('me');
      localStorage.removeItem('currentContextIds');
      meVar();
      // eslint-disable-next-line no-use-before-define
      await client.clearStore();
      // eslint-disable-next-line no-use-before-define
      await client.resetStore();
      return true;
    },
    setLoggedOut: async () => {
      loggedOutVar({ loggedOut: true });
      return true;
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes: {
    Business: ['Customer', 'Partner', 'Seller'],
    CustomerOrSeller: ['Customer', 'Seller'],
  },
  typePolicies: {
    Query: {
      fields: {
        currentSession: {
          read() {
            return meVar();
          },
        },
        integrationId: {
          read() {
            return integrationVar();
          },
        },
        loanDrawer: {
          read() {
            return drawerVar();
          },
        },
        redirect: {
          read() {
            return redirectVar();
          },
        },
        loggedOut: {
          read() {
            return null;
          },
        },
      },
    },
  },
});

const logLink = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation;

  return (
    forward(operation).map((result) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug({
          operationName,
          variables,
          responseData: result?.data[operationName],
          errors: result?.errors,
        });
      }
      return result;
    }));
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message: text }) => {
      if (text === 'Token de acesso inválido.') {
        localStorage.removeItem('me');
        localStorage.removeItem('currentContextIds');
        meVar();
        window.location.reload();
      }
      if (text === 'Estamos realizando uma manutenção no momento, tente novamente dentro de alguns minutos') {
        message.warning(text, 10);
      }
      return null;
    });
  }
});

const wsLink = new WebSocketLink({
  uri: process.env.GRAPHQL_WEBSOCKET_URI,
  options: {
    reconnect: true,
    connectionParams: meVar() && {
      authorization: meVar().sessionToken,
    },
  },
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  logLink.concat(httpLink),
);

const client = new ApolloClient({
  connectToDevTools: process.env.ENVIRONMENT !== 'production',
  link: errorLink.concat(authLink.concat(splitLink)),
  cache,
  resolvers,
  typeDefs,
});

export default client;
