// @ts-check

import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { getPromoRenegotiationCountdown, setPromoRenegotiationCountdown } from 'services/graphql/cache';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

const useDeadline = () => {
  const [deadline, setDeadline] = useState(undefined);
  const deadlineRef = useRef();

  const {
    chargePolicyId,
  } = usePromoRenegotiationContext();

  // único jeito de referenciar state dentro de uma função de cleanup
  useEffect(() => {
    deadlineRef.current = deadline;
  }, [deadline]);

  useEffect(() => {
    const storedDeadlineString = getPromoRenegotiationCountdown(chargePolicyId);

    let currentDeadline = storedDeadlineString
      ? dayjs(storedDeadlineString)
      : dayjs().add(2, 'days');

    const isDeadlineEnded = currentDeadline.isBefore(dayjs());

    // @ts-ignore
    currentDeadline = isDeadlineEnded
      ? dayjs().add(2, 'days').toDate()
      : currentDeadline.toDate();

    setDeadline(currentDeadline);

    const persistCountdown = () => {
      setPromoRenegotiationCountdown({
        chargePolicyId,
        deadLine: deadlineRef.current,
      });
    };

    // sempre que o usuário sair da página persistir o countdown
    window.addEventListener('beforeunload', persistCountdown);

    return () => {
      window.removeEventListener('beforeunload', persistCountdown);
      // ao destruir o componente salvar a deadline atual
      persistCountdown();
    };
  }, []);

  return deadline;
};

export {
  useDeadline,
};
