// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chances-alert-modal {
  margin-bottom: 1rem;
}
.chances-alert-modal .chances-in-alert-modal .loan-chances-title-container h4 {
  color: #000;
}
.chances-alert-modal .chances-in-alert-modal .loan-chances-title-container .icon {
  color: #000 !important;
}
.chances-alert-modal .chances-in-alert-modal .loan-chances-level-title {
  color: #000 !important;
  font-weight: 300;
}
.chances-alert-modal .chances-in-alert-modal h6 {
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/LoanChancesAlertModal/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAMQ,WAAA;AADR;AALA;EASQ,sBAAA;AADR;AARA;EAaM,sBAAA;EACA,gBAAA;AAFN;AAZA;EAkBM,WAAA;AAHN","sourcesContent":[".chances-alert-modal {\n  margin-bottom: 1rem;\n\n  .chances-in-alert-modal {\n    .loan-chances-title-container {\n      h4 {\n        color: #000;\n      }\n      .icon {\n        color: #000 !important;\n      }\n    }\n    .loan-chances-level-title {\n      color: #000 !important;\n      font-weight: 300;\n    }\n\n    h6 {\n      color: #000;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
