import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getPromoRenegotiationLastViewDate, setPromoRenegotiationLastViewDate, meVar } from 'services/graphql/cache';
import dayjs from 'dayjs';
import {
  CHANGE_BUSINESS,
  LOAN_AVAILABLE_FOR_PROMO_RENEGOTIATE,
  ME_BUSINESS_ID,
} from '../graphql';

const usePromoRenegotiationFlow = () => {
  const [changeBusiness] = useMutation(CHANGE_BUSINESS, { fetchPolicy: 'no-cache' });
  const { pathname, state } = useLocation();
  const history = useHistory();
  const loggedIn = useReactiveVar(meVar);
  const loggedInNow = state?.params?.loggedInNow;

  const [
    getPromoAvailableLoan,
    {
      data,
      loading,
    },
  ] = useLazyQuery(LOAN_AVAILABLE_FOR_PROMO_RENEGOTIATE, {
    fetchPolicy: 'network-only',
  });

  const { data: meData, loading: loadingMe } = useQuery(ME_BUSINESS_ID, {
    fetchPolicy: 'network-only',
    skip: !loggedIn,
  });

  const promoPagePath = 'promo-renegociacao';

  const flowDone = pathname?.includes(promoPagePath)
    || pathname?.includes('login')
    || loading;

  useEffect(() => {
    if (flowDone) {
      return;
    }

    if (loggedInNow) {
      getPromoAvailableLoan();
    }
  }, [loggedInNow]);

  useEffect(() => {
    if (flowDone) {
      return;
    }

    if (!loggedIn) {
      return;
    }

    const lastDate = getPromoRenegotiationLastViewDate();

    if (!lastDate || dayjs().diff(lastDate, 'days') >= 1) {
      getPromoAvailableLoan();
    }
  }, []);

  useEffect(() => {
    const tryToOpenModal = async () => {
      if (!loggedIn || pathname?.includes('login')) {
        return;
      }

      if (loading || loadingMe) {
        return;
      }

      if (!data || !meData) {
        return;
      }

      const loanBusinessId = data.loanAvailableForPromoRenegotiate?.business?.id;

      if (!loanBusinessId) {
        return;
      }

      const {
        noDiscount,
      } = data.loanAvailableForPromoRenegotiate?.chargePolicy || {};

      if (noDiscount) {
        return;
      }

      const {
        cashPaymentDiscount,
        installmentPaymentDiscount,
      } = data.loanAvailableForPromoRenegotiate?.chargePolicy?.activeDiscount || {};

      if (!cashPaymentDiscount && !installmentPaymentDiscount) {
        return;
      }

      const businessId = meData.me.currentBusiness.id;

      if (!businessId) {
        return;
      }

      if (businessId !== loanBusinessId) {
        await changeBusiness({
          variables: {
            id: loanBusinessId,
          },
        });
      }

      if (window.location.pathname.includes(promoPagePath)) {
        return;
      }

      setPromoRenegotiationLastViewDate();
      history.push(`/${promoPagePath}`);
    };

    tryToOpenModal();
  }, [data, loading, meData, loadingMe]);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  usePromoRenegotiationFlow,
};
