// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyra-card {
  padding: 2.5rem 5rem;
  box-shadow: none;
  border: 0.063rem solid #C9D3CC;
  border-radius: 1.25rem;
  transition: 0.3s;
}
.gyra-card.gyra-card-selected {
  border-color: var(--primary-button-color);
  box-shadow: inset 0 0 0 1px var(--primary-button-color);
}
.gyra-card:not(.gyra-card-without-content) .title-container {
  margin-bottom: 1.5rem;
}
.gyra-card.gyra-card-small {
  padding: 2.5rem;
}
@media (max-width: 1200px) {
  .gyra-card {
    padding: 2rem 3rem !important;
  }
}
@media (max-width: 768px) {
  .gyra-card {
    padding: 1.5rem !important;
  }
}
.gyra-card.gyra-card-primary {
  background-color: var(--primary-color);
  border: none;
  color: var(--primary-text-color);
}
.gyra-card.gyra-card-secondary {
  background-color: var(--primary-button-color);
  border: none;
  color: var(--primary-button-text-color);
}
.gyra-card.gyra-card-clickable {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/Card/style.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,8BAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACE,yCAAA;EACA,uDAAA;AACJ;AAEE;EAEI,qBAAA;AADN;AAKE;EACE,eAAA;AAHJ;AAME;EAAA;IACE,6BAAA;EAHF;AACF;AAME;EAAA;IACE,0BAAA;EAHF;AACF;AAKE;EACE,sCAAA;EACA,YAAA;EACA,gCAAA;AAHJ;AAME;EACE,6CAAA;EACA,YAAA;EACA,uCAAA;AAJJ;AAOE;EACE,eAAA;AALJ","sourcesContent":[".gyra-card {\n  padding: 2.5rem 5rem;\n  box-shadow: none;\n  border: .063rem solid #C9D3CC;\n  border-radius: @border-radius;\n  transition: .3s;\n\n  &.gyra-card-selected {\n    border-color: var(--primary-button-color);\n    box-shadow: inset 0 0 0 1px var(--primary-button-color);\n  }\n  \n  &:not(.gyra-card-without-content) {\n    .title-container {\n      margin-bottom: 1.5rem;\n    }\n  }\n\n  &.gyra-card-small {\n    padding: 2.5rem;\n  }\n\n  @media(max-width: @xl-screen) {\n    padding: 2rem 3rem !important;\n  }\n\n\n  @media(max-width: @md-screen) {\n    padding: 1.5rem !important;\n  }\n\n  &.gyra-card-primary {\n    background-color: var(--primary-color);\n    border: none;\n    color: var(--primary-text-color);\n  }\n\n  &.gyra-card-secondary {\n    background-color: var(--primary-button-color);\n    border: none;\n    color: var(--primary-button-text-color);\n  }\n\n  &.gyra-card-clickable {\n    cursor: pointer;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
