import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Input } from 'components/gyramais';
import { Button } from 'components/sdk';

const { Item } = Form;

const UserForm = ({
  form,
  onFinish,
  credentialIsCpf,
  onClickBack,
  user,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      fullName: user?.fullName,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
    });
  }, [user]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Button
        link
        onClick={onClickBack}
        icon={(<LeftOutlined />)}
      >
        Voltar
      </Button>
      <br />

      <h2>
        Crie seu
        {' '}
        <span className="secondary-text">
          cadastro
        </span>!
      </h2>

      <p className="no-margin-bottom">
        Complete seu cadastro para acessar e
        {' '}
        <span className="secondary-text">
          acompanhar as propostas
        </span>
        {' '}
        da empresa
      </p>
      <br />

      <Item
        name="fullName"
        rules={[{ required: true, message: 'Por favor insira o nome.' }]}
      >
        <Input placeholder="Nome completo" />
      </Item>

      {credentialIsCpf ? (
        <Item
          name="email"
          rules={[{ required: true, message: 'Por favor insira o email.' }]}
        >
          <Input placeholder="Email" />
        </Item>
      ) : (
        <Item
          name="cpf"
          rules={[{ required: true, message: 'Por favor insira o cpf.' }]}
        >
          <Input
            placeholder="CPF"
            type="masked"
            mask="cpf"
          />
        </Item>
      )}

      <Item
        name="phoneNumber"
        rules={[{ required: true, message: 'Por favor insira o telefone.' }]}
      >
        <Input
          placeholder="Telefone"
          type="masked"
          mask="phone"
        />
      </Item>
      <br />

      <Button
        block
        id="cnpj-enter-button"
        htmlType="submit"
      >
        Próximo
      </Button>
    </Form>
  );
};

UserForm.propTypes = {
  form: PropTypes.shape(),
  credentialIsCpf: PropTypes.bool,
  onFinish: PropTypes.func,
  onClickBack: PropTypes.func,
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

export default UserForm;
