// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#civil-partner-form .files-area {
  display: flex;
}
#civil-partner-form .files-area .file-container {
  margin-left: 1rem;
  width: 12rem !important;
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/CivilPartnerForm/styles.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;AAAJ;AAFA;EAIM,iBAAA;EACA,uBAAA;EACA,kBAAA;AACN","sourcesContent":["#civil-partner-form {\n  .files-area {\n    display: flex;\n    .file-container {\n      margin-left: 1rem;\n      width: 12rem !important;\n      overflow-x: hidden;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
