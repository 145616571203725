// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-tag {
  width: fit-content;
  height: 2.875rem;
  padding: 0.6rem 1rem;
  border-radius: 0.75rem;
  border: 1px solid #C9D3CC;
  background-color: #F6F8F7;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.user-tag .user-tag-content {
  font-size: 1.125rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #000000;
}
.user-tag .anticon {
  font-size: 1.125rem;
  margin-right: 0.2rem;
  color: #0BB599;
}
`, "",{"version":3,"sources":["webpack://./src/components/others/UserTag/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,oBAAA;EAEA,sBAAA;EACA,yBAAA;EACA,yBAAA;EAEA,qBAAA;EACA,gBAAA;EACA,uBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;AADF;AAbA;EAiBI,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAnBA;EAwBI,mBAAA;EACA,oBAAA;EACA,cAAA;AAFJ","sourcesContent":[".user-tag {\n  width: fit-content;\n  height: 2.875rem;\n  padding: .6rem 1rem;\n\n  border-radius: .75rem;\n  border: 1px solid #C9D3CC;\n  background-color: #F6F8F7;\n\n  word-break: break-all;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n\n  .user-tag-content {\n    font-size: 1.125rem;\n    line-height: 1.8rem;\n    font-weight: 400;\n    color: #000000;\n  }\n  \n  .anticon {\n    font-size: 1.125rem;\n    margin-right: .2rem;\n    color: #0BB599;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
