import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SAVE_PROMO_RENEGOTIATE } from '../graphql';

const useLoanLoaded = ({
  loan,
  loading,
  onLoad,
  canShowInstallment,
  installmentOptionsData,
}) => {
  const history = useHistory();

  const [savePromoRenegotiate] = useMutation(SAVE_PROMO_RENEGOTIATE);

  useEffect(() => {
    if (!loan && !loading) {
      history.push('/');
      return;
    }

    if (loading || !loan) {
      return;
    }

    const {
      noDiscount,
    } = loan?.chargePolicy || {};

    const {
      cashPaymentDiscount,
      installmentPaymentDiscount,
    } = loan?.chargePolicy?.activeDiscount || {};

    const allowed = cashPaymentDiscount || installmentPaymentDiscount || noDiscount;

    if (!allowed || !loan) {
      history.push('/');
      return;
    }

    savePromoRenegotiate({
      variables: {
        loanId: loan.id,
      },
    });

    let defaultInstallmentCountSelected = 2;

    if (cashPaymentDiscount) {
      defaultInstallmentCountSelected = 1;
    }

    if (noDiscount) {
      const installmentsToShow = installmentOptionsData?.filter(
        (data) => canShowInstallment?.(data),
      );

      defaultInstallmentCountSelected = installmentsToShow[0].installmentCount;
    }

    onLoad({ defaultInstallmentCountSelected });
  }, [loan]);
};

export {
  useLoanLoaded,
};
