import BANKS from './banks.gql';
import ME from './me.gql';
import SAVE_BUSINESS from './saveBusiness.gql';
import SAVE_ADDRESS from './saveAddress.gql';
import SAVE_BANK_ACCOUNT from './saveBankAccount.gql';
import SETTINGS from './settings.gql';

export {
  BANKS,
  ME,
  SAVE_BUSINESS,
  SAVE_ADDRESS,
  SAVE_BANK_ACCOUNT,
  SETTINGS,
};
