import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PlatformCard,
} from 'components/gyramais';
import { Bank } from 'assets/images';
import {
  useQuery,
} from '@apollo/client';
import { Col, Row } from 'antd';
import { SearchBar } from 'components/others';
import {
  BANKS,
} from './graphql';

import './styles.less';

const BanksList = ({
  disableOpenBanks,
  onSet,
}) => {
  const [bankSearch, setBankSearch] = useState('');

  const {
    data: {
      banks = [],
    } = {},
  } = useQuery(BANKS, {
    variables: {
      limit: 500,
      visible: true,
      disableOpenBanks,
    },
    fetchPolicy: 'network-only',
  });

  const banksFiltered = banks.filter(
    ({ name }) => !bankSearch || name?.toLowerCase().includes(bankSearch),
  );

  return (
    <>
      <Row className="search-area">
        <Col xs={24} sm={24} md={12} lg={8}>
          <SearchBar
            onlySearch
            onChangeInput={setBankSearch}
          />
        </Col>
      </Row>

      <Row gutter={[12, 24]} className="bank-row">
        {banksFiltered.map(({
          id,
          logo,
          name,
          code,
          tutorial,
        }) => (
          <Col key={id}>
            <PlatformCard
              id={`bank-${code}`}
              logo={logo}
              name={name}
              onClick={async () => {
                await onSet({
                  id,
                  logo,
                  name,
                  code,
                  tutorial,
                });
              }}
            />
          </Col>
        ))}

        <Col>
          <PlatformCard
            logo={{ url: Bank }}
            name="OUTROS"
            id="other-banks"
            onClick={async () => {
              await onSet({ id: undefined, code: undefined, tutorial: undefined });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

BanksList.propTypes = {
  disableOpenBanks: PropTypes.bool,
  onSet: PropTypes.func,
};

export default BanksList;
