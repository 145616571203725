import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Images } from '../../../assets';
import './styles.less';

const {
  LogoGyraWhite, LogoGyraDark,
} = Images;

const PoweredBy = ({ className, isHeaderDark }) => (
  <>
    <div id="powered-area" className={className || ''}>
      Powered by
      <img src={isHeaderDark ? LogoGyraWhite : LogoGyraDark} className="powered-icon" alt="logo" />
    </div>
  </>
);

PoweredBy.propTypes = {
  className: PropTypes.string,
  isHeaderDark: PropTypes.bool,
};

export default memo(PoweredBy);
