import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  notification,
  Form,
  Select,
  Row,
  Col,
} from 'antd';
import {
  Drawer,
  Input,
} from 'components/gyramais';
import {
  Progress,
} from 'components/sdk';
import { formatAndNotificateError } from 'utils';
import { useMutation, useQuery } from '@apollo/client';
import {
  CHECK_CREDENTIALS,
  ABLE_TO_CREATE_NEW_LOAN,
  BUSINESS_PLATFORM_TYPES,
  BUSINESS_SECTORS,
  BUSINESS_SEGMENTS,
  ME,
  SAVE_LOAN,
  REASONS_FOR_LOAN,
} from './graphql';

import './styles.less';

const { useForm, Item } = Form;
const { Option } = Select;
const amounts = [
  'R$ 15.000',
  'R$ 20.000',
  'R$ 30.000',
  'R$ 40.000',
  'R$ 50.000',
  'R$ 60.000',
  'R$ 70.000',
  'R$ 80.000',
  'R$ 90.000',
  'R$ 100.000',
];
const installments = [
  '6 meses',
  '9 meses',
  '12 meses',
  '18 meses',
];

const NewLoanDrawer = ({
  visible,
  onClose,
  bypass,
}) => {
  const history = useHistory();
  const [form] = useForm();
  const [enabled, setEnabled] = useState(false);

  const [
    checkCredentials,
  ] = useMutation(CHECK_CREDENTIALS, {
    onCompleted: async () => setEnabled(true),
    onError: (error) => {
      formatAndNotificateError(error);
      onClose();
      history.push('/plataformas-cadastradas');
    },
  });

  const [
    ableToCreateNewLoan,
  ] = useMutation(ABLE_TO_CREATE_NEW_LOAN, {
    onCompleted: async ({ ableToCreateNewLoan } = {}) => {
      if (ableToCreateNewLoan) await checkCredentials();
    },
    onError: (error) => {
      formatAndNotificateError(error);
      onClose();
    },
  });

  const {
    data: {
      reasonsForLoan = [],
    } = {},
  } = useQuery(REASONS_FOR_LOAN, {
    skip: !visible || !enabled,
  });

  const {
    data: {
      me: {
        currentBusiness: {
          id: businessId,
        } = {},
      } = {},
    } = {},
  } = useQuery(ME, {
    skip: !visible || !enabled,
    fetchPolicy: 'cache-and-network',
    onError: (error) => formatAndNotificateError(error),
  });

  const {
    data: {
      businessPlatformTypes,
    } = {},
  } = useQuery(BUSINESS_PLATFORM_TYPES, {
    skip: !visible || !enabled,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSegments,
    } = {},
  } = useQuery(BUSINESS_SEGMENTS, {
    skip: !visible || !enabled,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSectors,
    } = {},
  } = useQuery(BUSINESS_SECTORS, {
    skip: !visible || !enabled,
    fetchPolicy: 'cache-and-network',
  });

  const [saveLoan, { loading: saveLoading }] = useMutation(SAVE_LOAN, {
    onCompleted: () => {
      onClose();
      notification.success({
        message: 'Recebemos sua solicitação',
        description: 'Aguarde enquanto analisamos suas informações. É bem rápido :D',
      });
      history.push('/cadastro/status');
    },
    onError: (error) => formatAndNotificateError(error),
  });

  useEffect(() => {
    if (bypass) setEnabled(true);
    else if (visible) ableToCreateNewLoan();
  }, [visible]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      className="new-loan-drawer"
      title="Solicitar um novo empréstimo"
      primaryButton={{
        loading: saveLoading,
        onClick: async () => {
          await form.validateFields();
          const values = form.getFieldsValue();
          await saveLoan({
            variables: {
              ...values,
              businessId,
              employeesNumber: Number(values.employeesNumber),
              revenueAmount: Number(values.revenueAmount),
              amount: Number(values.amount.replace(/[R$.]/g, '')),
              installmentsCount: Number(values.installmentsCount.replace(/[meses]/g, '')),
              reason: values.reason,
              reasonInput: values.reasonInput.trim(),
            },
          });
        },
      }}
    >
      {!enabled ? (
        <div className="loading-redirect-screen">
          <h3>Checando informações...</h3>
          <p>Este processo pode levar algum tempo.</p>
          <Progress
            percent={100}
          />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12}>
              <Item
                name="amount"
                rules={[{ required: true, message: 'Por favor selecione o valor.' }]}
              >
                <Select
                  className="gyramais-input"
                  placeholder="De quanto você precisa?"
                  style={{ width: '100%' }}
                >
                  {amounts.map((amount) => (
                    <Option key={amount}>{amount}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                name="revenueAmount"
                rules={[{ required: true, message: 'Preencha o faturamento mensal' }]}
              >
                <Input
                  placeholder="Faturamento mensal (média 12 meses)"
                  type="currency"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                name="installmentsCount"
                rules={[{ required: true, message: 'Por favor selecione o número de parcelas.' }]}
              >
                <Select
                  className="gyramais-input"
                  placeholder="Em quantas vezes?"
                  style={{ width: '100%' }}
                >
                  {installments.map((installment) => (
                    <Option key={installment}>{installment}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                name="reason"
                rules={[{ required: true, message: 'Selecione a razão do empréstimo' }]}
              >
                <Select
                  className="gyramais-input"
                  placeholder="Qual a razão do empréstimo?"
                  style={{ width: '100%' }}
                >
                  {reasonsForLoan.map((reason) => (
                    <Option key={reason.id} value={reason.value}>{reason.name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col span={24}>
              <Item
                name="reasonInput"
                rules={[{ required: true, message: 'Descreva um pouco mais sobre o empréstimo' }]}
              >
                <Input
                  type="textarea"
                  placeholder="Poderia nos contar um pouco mais sobre o motivo da sua solicitação?"
                  autoSize={{ minRows: 6, maxRows: 6 }}
                />
              </Item>
            </Col>
          </Row>

          <hr className="divider" />

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12}>
              <Item
                name="employeesNumber"
                rules={[{ required: true, message: 'Preencha o número de funcionários' }]}
              >
                <Input placeholder="Número de funcionários" />
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                name="segment"
                rules={[{ required: true, message: 'Selecione o segmento' }]}
              >
                <Select
                  className="gyramais-input"
                  placeholder="Selecione o segmento"
                >
                  {businessSegments?.map(({ id, name }) => (
                    <Option key={id} value={id}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                name="sector"
                rules={[{ required: true, message: 'Selecione o setor' }]}
              >
                <Select className="gyramais-input" placeholder="Selecione o setor">
                  {businessSectors?.map(({ id, name }) => (
                    <Option key={id} value={id}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                name="platformType"
                rules={[{ required: true, message: 'Selecione o ramo de atuação' }]}
              >
                <Select className="gyramais-input" placeholder="Selecione o ramo de atuação">
                  {businessPlatformTypes?.map(({ id, name }) => (
                    <Option key={id} value={id}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
        </Form>
      )}
    </Drawer>
  );
};

NewLoanDrawer.propTypes = {
  visible: PropTypes.bool,
  bypass: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NewLoanDrawer;
