import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Card,
  Col,
  notification,
  Collapse,
} from 'antd';
import {
  SubHeader,
  ProposalCard,
} from 'components/gyramais';
import {
  Layout,
} from 'components/sdk';
import {
  NewLoanDrawer,
} from 'components';
import { formatDate, formatAndNotificateError } from 'utils';

import { meVar } from 'services/graphql/cache';

import { BtnPlus } from 'assets/images';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';

import './styles.less';

import {
  useQuery,
} from '@apollo/client';

import {
  ME,
} from './graphql';

const { Content } = Layout;
const { Panel } = Collapse;

const BusinessCreditProposalScreen = () => {
  const history = useHistory();

  const { changeBusinessAndLoan } = useBusinessLoanContext();

  const [newLoanVisible, setNewLoanVisible] = useState(false);

  const {
    data: {
      me: {
        allContexts = [],
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => formatAndNotificateError(error),
  });

  const contextsByBusiness = useMemo(() => {
    const businessIds = [...new Set(allContexts.map(({ business }) => business.id))];
    const contextsByBusinesses = {};

    businessIds.forEach((businessId) => {
      contextsByBusinesses[businessId] = allContexts
        .filter(({ business }) => business.id === businessId);
    });

    return contextsByBusinesses;
  }, [allContexts]) ?? {};

  const handleShowTypeBusiness = (business) => {
    const myBusinessUser = business.businessUser.find(({ user }) => user.id === meVar().id);

    if (myBusinessUser?.types.find((u) => u.name === 'Sócio')) {
      return 'Sócio';
    }

    if (myBusinessUser?.types.find((u) => u.name === 'Representante Legal')) {
      return 'Representante Legal';
    }

    return 'Avalista';
  };

  const selectContext = ({
    contextId, business, loan, partner,
  }) => {
    changeBusinessAndLoan({
      id: contextId, businessId: business.id, loanId: loan?.id, partnerId: partner.id,
    });

    notification.info({
      message: 'Perfil de empresa alterado.',
      description: (
        <>
          Agora você está visualizando informações relacionadas à
          {' '}
          <b>{business.name || business.legalName}</b>
          {' '}
          (
          <b>{partner.name || partner.legalName}</b>
          )
          .
        </>
      ),
    });
    history.push('/');
  };

  return (
    <Layout id="business-credit-screen">
      <NewLoanDrawer
        visible={newLoanVisible}
        onClose={() => setNewLoanVisible(false)}
      />
      <SubHeader
        title="Escolha um de seus parceiros para seguir:"
        extraButtonsPlacement="bottom"
      />
      {Object.keys(contextsByBusiness)[0] && (
        <Content>
          <div className="proposal-carousel-content">
            <Row>
              <Col xs={24}>
                <Collapse defaultActiveKey={Object.keys(contextsByBusiness)} expandIconPosition="right" className="accordion-container">
                  {Object.keys(contextsByBusiness).map((businessId, index) => {
                    const { business } = contextsByBusiness[businessId][0];
                    return (
                      <Panel
                        header={`${business.legalName ?? business.name ?? 'Sem nome'} (${handleShowTypeBusiness(business)})`}
                        key={business?.id}
                        className={`panel-${index}`}
                      >
                        <Row align="middle">
                          {contextsByBusiness[businessId].map(({ id, loan, partner }) => {
                            const currentStatus = loan?.status ?? business.status;
                            return (
                              <ProposalCard
                                id={loan?.id ?? partner?.id}
                                name={partner?.name}
                                logo={partner?.darkLogo}
                                number={loan?.number}
                                loanDate={loan?.date && formatDate(new Date(loan?.date))}
                                status={currentStatus}
                                datacy={`proposal-card-${index}`}
                                onClick={() => {
                                  selectContext({
                                    contextId: id,
                                    business,
                                    loan,
                                    partner,
                                  });
                                }}
                              />
                            );
                          })}

                        </Row>
                      </Panel>
                    );
                  })}
                </Collapse>
              </Col>
            </Row>
          </div>
        </Content>
      )}
      <NewLoanDrawer
        bypass
        visible={newLoanVisible}
        onClose={() => setNewLoanVisible(false)}
      />
    </Layout>
  );
};

export default BusinessCreditProposalScreen;
