import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, notification,
} from 'antd';
import { Button } from 'components/sdk';
import { UserTag } from 'components/others';
import { formatAndNotificateError } from 'utils';
import { useMutation } from '@apollo/client';
import { SEND_EMAIL_LOGIN_WITH_LINK } from './graphql';

const LoginWithLinkForm = ({ email, tag }) => {
  const [
    sendEmailLoginWithLink,
    { loading: loadingLoginWithLink },
  ] = useMutation(SEND_EMAIL_LOGIN_WITH_LINK, {
    onCompleted: () => {
      notification.success({ message: 'E-mail enviado com sucesso!' });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  return (
    <Form layout="vertical">
      <h2>
        Cansado de
        {' '}
        <span className="secondary-text">digitar</span>
        {' '}
        sua senha?
      </h2>

      <UserTag>
        {tag}
      </UserTag>

      <br />

      <p className="justified">
        <b>
          Seu link está a caminho! Enviamos um e-mail para
          {' '}
          <b className="email-text">{email}</b>
          {' '}
          Com ele, você conseguirá fazer login sem precisar digitar sua senha.
          Não compartilhe-o com ninguém!
        </b>
      </p>

      <br />

      <Button
        block
        loading={loadingLoginWithLink}
        className="button-login-without-password"
        onClick={async () => {
          await sendEmailLoginWithLink({ variables: { email } });
        }}
      >
        Reenviar e-mail de login
      </Button>
    </Form>
  );
};

LoginWithLinkForm.propTypes = {
  email: PropTypes.string,
  tag: PropTypes.string,
};

export default LoginWithLinkForm;
