import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  notification,
} from 'antd';
import {
  Drawer,
} from 'components/gyramais';
import { formatAndNotificateError } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import { Button } from 'components/sdk';
import {
  BankAccountOptin,
} from 'assets/images';
import {
  SAVE_OPTIN,
} from './graphql';

import './styles.less';

const BankAccountOptinDrawer = ({
  visible,
  onFinish,
  onClose,
}) => {
  const [confirmation, setConfirmation] = useState(false);
  const [ipLoading, setIpLoading] = useState(false);

  const [saveOptIn, { loading }] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      onFinish?.();
      setConfirmation(false);
    },
    onError: (error) => formatAndNotificateError(error),
  });

  return (
    <Drawer
      visible={visible}
      onClose={() => {
        onClose?.();
        setConfirmation(false);
      }}
      id="bank-account-optin-drawer"
      title="Abertura de conta bancária"
      primaryButton={{
        loading: loading || ipLoading,
        id: 'confirm-bank-account-optin-button',
        onClick: () => {
          if (!confirmation) {
            notification.error({ message: 'Você precisa aceitar os termos para continuar' });
            return;
          }

          setIpLoading(true);
          fetch('https://api.ipify.org/?format=json')
            .then((response) => response.json())
            .then(({ ip }) => {
              saveOptIn({
                variables: {
                  ip,
                  accepted: true,
                  types: ['BANK-ACCOUNT-AUTHORIZATION'],
                },
              });
              setIpLoading(false);
            })
            .catch(() => { notification.error({ message: 'Não conseguimos localizar seu IP' }); });
        },
      }}
    >
      <div className="drawer-content">
        <BankAccountOptin alt="Checkbox Gif" />

        <h4 className="secondary-text no-margin-bottom">
          Espere um pouco!
        </h4>
        <h4>
          Precisamos criar uma conta para você no Banco Iugu
        </h4>

        <Divider />

        <p className="no-margin-bottom">
          <b>
            Empréstimos com trava de recebíveis necessitam de uma
            conta especial que irá armazenar 100% dos valores recebidos,
            e utilizaremos uma parte deles para quitar o seu crédito.
            Para dar continuidade à sua solicitação, abriremos uma conta
            no Banco Iugu em seu nome
            {' '}
            <strong>
              após a aprovação do crédito.
            </strong>
          </b>
        </p>
        <br />

        <Checkbox id="bank-account-optin-checkbox" onChange={({ target: { checked } }) => setConfirmation(checked)}>
          <p className="no-margin-bottom left-text">
            <b>
              Concordo que li e aceito os
              {' '}
              <Button
                text
                onClick={() => window.open('https://faq.gyramais.com.br/abertura-de-conta/', '_blank')}
              >
                <b className="secondary-text underline">
                  Termos
                </b>
              </Button>
              {' '}
              e autorizo a abertura da minha
              conta bancária no Banco Iugu após a aprovação do crédito.
            </b>
          </p>
        </Checkbox>
      </div>
    </Drawer>
  );
};

BankAccountOptinDrawer.propTypes = {
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default BankAccountOptinDrawer;
