import React, { memo } from 'react';
import {
  SendingLetter,
} from 'assets/images';
import './styles.less';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';

const WhatsappOptIn = () => {
  const { currentContext } = useBusinessLoanContext();

  const productType = currentContext?.loan?.partner?.flow === 'badesc' ? 'seu financiamento' : 'sua dívida';

  return (
    <div className="whatsapp-opt-in-content">
      <img src={SendingLetter} alt="Sending Letter" />

      <p>
        Você pode receber atualizações sobre {productType}
        {' '}
        diretamente no seu celular. Ative as mensagens de
        {' '}
        alerta da GYRA+ no seu Whatsapp!
      </p>
    </div>
  );
};

export default memo(WhatsappOptIn);
