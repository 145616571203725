/* eslint-disable max-len */
import React from 'react';
import {
  Row,
  Col,
  Skeleton,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PromoRenegotiationCard from './Components/PromoRenegotiationCard';
import PromoRenegotiatedInvoice from './Components/PromoRenegotiatedInvoice';
import CashPaymentSuccessModal from './Components/CashPaymentSuccessModal';
import InstallmentPaymentConfirmModal from './Components/InstallmentPaymentConfirmModal';
import PromoRenegotiationProvider, { PromoRenegotiationContext } from './PromoRenegotiationContext';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {
  SubHeader,
} from '../../components/gyramais';
import {
  Layout,
  Button,
} from '../../components/sdk';

import './styles.less';

const { Content } = Layout;

const PromoRenegotiationScreen = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  return (
    <Layout id="promo-renegotiation-screen" className="dashboard-layout" data-cy="promo-renegotiation-screen">
      <PromoRenegotiationProvider>
        <PromoRenegotiationContext.Consumer>
          {(
            /** @type {ReturnType<import('./PromoRenegotiationContext').usePromoRenegotiationContext>} */
            {
              loading,
              pendingInvoice,
              title,
              subtitle,
            },
          ) => (
            <>
              <SubHeader
                loading={loading}
                title={title}
                subtitle={subtitle}
                buttons={pendingInvoice && (
                  <Button
                    // @ts-ignore
                    primary
                    onClick={() => {
                      window.open(pendingInvoice.url, '_blank');
                    }}
                  >
                    Visualizar boleto
                  </Button>
                )}
                buttonsPlacement="bottom"
              />

              <Content>
                {loading && (
                  <Row className="loading-row">
                    <Skeleton.Button
                      active
                      shape="square"
                      className="loans-loading"
                    />
                  </Row>
                )}

                <Row>
                  {!loading && (
                    <Col span={24}>
                      {pendingInvoice ? (
                        <PromoRenegotiatedInvoice />
                      ) : (
                        <PromoRenegotiationCard />
                      )}
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={6}>
                    <br />

                    <Button
                      id="promo-back-button"
                      link
                      block={width <= 768}
                      onClick={() => history.push('/')}
                      data-cy="promo-home-button"
                    >
                      Voltar para a Página Inicial
                    </Button>
                  </Col>
                </Row>

              </Content>

              <CashPaymentSuccessModal />
              <InstallmentPaymentConfirmModal />
            </>
          )}
        </PromoRenegotiationContext.Consumer>
      </PromoRenegotiationProvider>
    </Layout>
  );
};

export default React.memo(PromoRenegotiationScreen);
