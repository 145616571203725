/* eslint-disable react/prop-types */

import React, { useState, memo } from 'react';
import {
  Card,
  Information,
} from 'components/gyramais';
import {
  Checkbox,
  Col,
  Row,
} from 'antd';
import { formatString } from 'utils';

import './styles.less';
/**
 *
 * @param {{
*  integration: {
*    id: string,
*    type: {
*      name: string,
*      icon: {
*        url: string,
*      },
*    },
*  },
*  key?: string,
* }} props
* @returns {React.FC}
*/
const IntegrationReceivableCard = ({
  integrationType: {
    id,
    name,
    icon: {
      url,
    } = {},

  },
  summary,
  selectable,
  onSelect,
  key,
  loading,
  selected,
}) => (
  <Card
    id={id}
    key={key ?? id}
    className="integration-receivable-card"
    selected={selectable && selected}
    onClick={() => {
      if (!selectable) {
        return;
      }

      onSelect();
    }}
  >
    <Row gutter={[16, 16]} align="middle">
      <Col span={12}>
        <img src={url} alt={name} className="integration-icon" />
      </Col>

      <Col span={12} align="right">
        {selectable ? (
          <Checkbox
            checked={selected}
            disabled={loading}
          />
        ) : null}
      </Col>

      <Col span={24}>
        <Information
          label={selectable ? 'Valor identificado' : 'Valor disponível'}
          value={selectable ? formatString(summary?.totalPresentValue ?? 0, 'currency') : formatString(summary?.totalAvailableAmount ?? 0, 'currency')}
          size="large"
          loading={!selectable}
        />
      </Col>
    </Row>
  </Card>
);
export default memo(IntegrationReceivableCard);
