import {
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NEXT_PENDING_SIGNATURE_LOAN } from 'routes/graphql';
import {
  ME_BUSINESS_ID,
} from '../graphql';

const handleLogin = () => {
  const history = useHistory();
  const {
    changeBusinessAndLoan,
    businessId: contextBusinessId,
  } = useBusinessLoanContext();
  const { state } = useLocation();

  const loggedInNow = state?.params?.loggedInNow;
  const usedRedirect = state?.params?.usedRedirect;
  const customerId = state?.params?.customerId;

  const [
    getNextPendingSignatureLoan, { data, loading },
  ] = useLazyQuery(NEXT_PENDING_SIGNATURE_LOAN);

  const { data: meData, loading: loadingMe } = useQuery(ME_BUSINESS_ID, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const tryToOpenModal = async () => {
      if (!loggedInNow) {
        return;
      }

      if (loading || loadingMe) {
        return;
      }

      if (!data || !meData) {
        return;
      }

      const nextPendingSignatureBusinessId = data.nextPendingSignatureLoan?.business?.id;
      const businessId = meData.me.currentBusiness.id;

      if (!businessId) {
        return;
      }
      if (contextBusinessId) {
        return;
      }

      if (nextPendingSignatureBusinessId) {
        const { nextPendingSignatureLoan } = data;

        const rightContext = meData.me.allContexts.find((context) => (
          context.business.id === nextPendingSignatureLoan?.business?.id
          && context.partner.id === nextPendingSignatureLoan?.partner?.id
        ));

        changeBusinessAndLoan({
          id: rightContext.id,
          businessId: rightContext.business.id,
          loanId: nextPendingSignatureLoan?.id,
          partnerId: rightContext.partner?.id,
        });
        return;
      }

      if (customerId) {
        const { me } = meData;
        const rightContext = me.allContexts.find((context) => (
          context.business.id === customerId
        ));

        changeBusinessAndLoan({
          id: rightContext.id,
          businessId: rightContext.business.id,
          loanId: rightContext.loan?.id,
          partnerId: rightContext.partner?.id,
        });

        return;
      }

      const priorityStatus = ['active', 'awaiting-signature', 'awaiting-signature-additive', 'registered'];
      const loansOnPriorityStatus = meData.me.allContexts.filter(
        ({ loan }) => priorityStatus.includes(loan?.status?.parent?.value),
      );

      if (loansOnPriorityStatus.length === 1 || meData.me.allContexts.length === 1) {
        const contextToSelect = loansOnPriorityStatus[0] ?? meData.me.allContexts[0];

        changeBusinessAndLoan({
          id: contextToSelect.id,
          businessId: contextToSelect.business.id,
          loanId: contextToSelect.loan?.id,
          partnerId: contextToSelect.partner.id,
        });
        return;
      }

      if (usedRedirect) {
        const currentBusinessId = meData.me.currentBusiness.id;
        const contexts = meData.me.allContexts;

        const context = contexts.find(({ business: { id } }) => id === currentBusinessId);

        changeBusinessAndLoan({
          id: context.id,
          businessId: context.business.id,
          loanId: context.loan?.id,
          partnerId: context.partner.id,
        });
        return;
      }

      history.push('/propostas');
    };

    tryToOpenModal();
  }, [data, loading, meData, loadingMe]);

  return {
    tryToChangeBusinessAndRedirect: () => {
      getNextPendingSignatureLoan();
    },
  };
};

export {
  // eslint-disable-next-line import/prefer-default-export
  handleLogin,
};
