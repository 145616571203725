import React, { useEffect } from 'react';
import {
  Spin,
} from 'antd';
import {
  Layout,
} from 'components/sdk';
import { useLazyQuery, useMutation } from '@apollo/client';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import {
  GET_EMAIL_BY_TOKEN,
  REMOVE_SESSION,
  LOG_OUT,
} from './graphql';
import { meVar } from '../../services/graphql/cache';

const { Content } = Layout;

const OldPasswordScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    'password-token': passwordToken,
    'verification-code': verificationCode,
    id,
  } = queryString.parse(location.search);

  const [getEmailByToken, { loading }] = useLazyQuery(GET_EMAIL_BY_TOKEN, {
    skip: !passwordToken,
    onCompleted: ({ getEmailByToken }) => {
      if (!getEmailByToken) {
        history.push('/login');
      }

      let url = `/login?email=${getEmailByToken}`;
      if (id) {
        url += `&id=${id}`;
      }
      if (verificationCode) {
        url += `&verification-code=${verificationCode}`;
      }

      history.push(url);
    },
    onError: () => history.push('/login'),
  });

  const [removeSession] = useMutation(REMOVE_SESSION);

  const [logOut] = useMutation(LOG_OUT, {
    onCompleted: async () => {
      await removeSession();
    },
  });

  useEffect(async () => {
    if (meVar()) {
      await logOut();
    }

    if (!passwordToken) {
      history.push('/login');
    }

    await getEmailByToken({ variables: { token: passwordToken } });
  }, []);

  return (
    <Layout className="register-layout">
      <Content>
        <Spin spinning={loading} />
      </Content>
    </Layout>
  );
};

export default OldPasswordScreen;
