import { useHistory } from 'react-router-dom';
import { setGyraRedirectPath, setRedirectId, setReintegrationId } from '../../../../services/graphql/cache';

let timeoutRef;

// eslint-disable-next-line import/prefer-default-export
export const useHelpers = ({
  internalIntegrationType,
  carouselRef,
}) => {

  const history = useHistory();
  const hasPlatformSelectionStep = window.location.pathname.includes('plataformas-cadastradas');

  /**
   * @param {
    * "platform-selection" |
    * "integration-form" |
    * "integration-loading" |
    * "success" |
    * "loading-redirect" |
   * } name
   */
  const getStep = (name) => {
    switch (name) {
      case 'platform-selection':
        return 0;

      case 'loading-redirect': {
        let loadingRedirect = 1;

        if (!hasPlatformSelectionStep) {
          loadingRedirect--;
        }

        return loadingRedirect;
      }

      case 'integration-form': {
        let integrationFormIndex = 2;

        if (!hasPlatformSelectionStep) {
          integrationFormIndex--;
        }

        return integrationFormIndex;
      }

      case 'integration-loading': {
        let integrationLoadingIndex = 3;

        if (!hasPlatformSelectionStep) {
          integrationLoadingIndex--;
        }

        return integrationLoadingIndex;
      }

      case 'success': {
        let successIndex = 4;

        if (!hasPlatformSelectionStep) {
          successIndex--;
        }

        if (!internalIntegrationType) {
          successIndex -= 2;
        }

        return successIndex;
      }

      default:
        return 0;
    }
  };

  // se drawer não esta aberto,
  // demora um pouco para o ref estar ativo
  const goToWaitingDrawerToOpen = (index, count = 0) => {
    if (count > 50) {
      // provavelmente num loop infinito
      return;
    }

    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    if (carouselRef?.current) {
      carouselRef.current.goTo(index);
      return;
    }

    timeoutRef = setTimeout(() => goToWaitingDrawerToOpen(index, count + 1), 100);
  };

  const clearQueryParams = () => {
    if (window.location.search) {
      history.push(window.location.pathname);
    }
  };

  const handleRemoveRedirectInfo = () => {
    setRedirectId('');
    setReintegrationId('');
    setGyraRedirectPath('');
  };

  return {
    getStep,
    goToWaitingDrawerToOpen,
    clearQueryParams,
    hasPlatformSelectionStep,
    handleRemoveRedirectInfo,
  };
};
