// @ts-check

import { useCallback, useMemo } from 'react';
import { formatDate } from 'utils';

/**
 * @param {{
 *  loans: {
 *    status: {
 *      value: string
 *      subtitle: string
 *      parent: {
 *        value: string
 *        subtitle: string
 *      }
 *    }
 *    portfolioName: string
 *  }[]
 *  pendingDownPaymentInvoice: any
 *  showPromoSubHeader: boolean
 *  lateInstallments: any[]
 *  nextPendingInstallment: any
 *  awaitingSignatureLoan: boolean
 *  isBureau: boolean
 *  noDiscountPolicy: boolean
 *  flow: string
 *  actions: any
 *  activeStatuses: string[]
 *  lateInstallment: any
 * }} args
 * @returns
 */
const useSubtitle = (args) => {
  const {
    loans,
    pendingDownPaymentInvoice,
    showPromoSubHeader,
    lateInstallments,
    awaitingSignatureLoan,
    isBureau,
    flow,
    actions = [],
    activeStatuses,
    lateInstallment,
    nextPendingInstallment,
    noDiscountPolicy,
  } = args;

  const replaceDate = useCallback((value) => {
    if (!value) {
      return '-';
    }

    return value?.replace('{date}', nextPendingInstallment && formatDate(new Date(nextPendingInstallment.dueDate)));
  }, [nextPendingInstallment]);

  return useMemo(() => {
    const activeLoan = loans?.[0];
    const currentStatus = activeLoan?.status;
    const partnerIsBanker = actions.find((action) => action.value === 'partner-is-banker');

    if (pendingDownPaymentInvoice) {
      return 'Evite o cancelamento da sua renegociação: Pague o boleto de entrada antes que o tempo acabe! Você poderá copiar o código de barras ou realizar o pagamento via Pix.';
    }

    if (awaitingSignatureLoan) {
      let subtitle = currentStatus?.subtitle;
      if (flow === 'badesc') {
        subtitle = subtitle.replace('empréstimo', 'financiamento');
      }
      return subtitle;
    }

    if (showPromoSubHeader && !noDiscountPolicy) {
      return lateInstallments.length < 2
        ? 'Evite o pagamento de multas e juros. Pague agora sua parcela em atraso ou renegocie seu contrato.'
        : 'Lembre-se de manter suas obrigações em dia. Evite acumular pendências e o cadastro de inadimplência. Você também pode renegociar seu contrato clicando no botão abaixo.';
    }

    if (isBureau) {
      return 'Queremos que saiba que ainda há tempo de resolver essa situação. Por isso, entre em contato imediatamente para regularizar suas pendências.';
    }

    if (flow === 'badesc') {
      if (currentStatus?.parent?.value === 'awaiting-re-evaluation') {
        const { portfolioName } = activeLoan;
        const normName = portfolioName.includes('Emergencial') ? 'Pronampe Emergencial' : portfolioName;

        return `Sua proposta foi indeferida por não atender aos requisitos mínimos exigidos pelas normas do ${normName}.`;
      }
      if (currentStatus?.value === 'signed') {
        return 'Em breve você receberá um e-mail informando a data da liberação dos recursos.';
      }
      if (currentStatus?.value === 'completed') {
        return '';
      }

    }

    if (partnerIsBanker && currentStatus?.value === 'completed') {
      return 'É ISSO AI';
    }

    if (loans.length === 0) {
      return 'Solicite agora seu empréstimo e tenha a liberação em até 24 horas!';
    }

    if (
      activeStatuses.includes(currentStatus?.value)
      && !lateInstallment
      && currentStatus?.subtitle
    ) {
      return replaceDate(currentStatus?.subtitle);
    }

    if (activeStatuses.includes(currentStatus?.value) && lateInstallment) {
      return lateInstallments.length < 2
        ? 'Evite o pagamento de multas e juros. Pague agora sua parcela em atraso.'
        : 'Lembre-se de manter suas obrigações em dia. Evite acumular pendências e o cadastro de inadimplência.';
    }

    if (currentStatus?.subtitle && flow !== 'olist') {
      return replaceDate(currentStatus?.subtitle);
    }

    if (currentStatus?.parent?.subtitle && flow !== 'olist') {
      return replaceDate(currentStatus?.parent?.subtitle);
    }

    return '-';
  }, [args]);
};

export default useSubtitle;
