// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prepayment-value-card {
  padding: 2rem;
  position: relative;
}
.prepayment-value-card .prepayment-value-card-container {
  height: 100%;
}
.prepayment-value-card .prepayment-value-card-container .prepayment-value-card-hint {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #3B3940;
}
.prepayment-value-card .prepayment-value-card-footer {
  display: flex;
  align-items: end;
}
@media (max-width: 768px) {
  .prepayment-value-card .prepayment-value-card-footer {
    margin-top: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/PrePaymentScreen/components/PrepaymentValueCard/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AAHA;EAKI,YAAA;AACJ;AANA;EAQM,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAAN;AAZA;EAiBI,aAAA;EACA,gBAAA;AAFJ;AAII;EAAA;IACE,gBAAA;EADJ;AACF","sourcesContent":[".prepayment-value-card {\n  padding: 2rem;\n  position: relative;\n\n  .prepayment-value-card-container {\n    height: 100%;\n\n    .prepayment-value-card-hint {\n      font-weight: 400;\n      font-size: 12px;\n      line-height: 12px;\n  \n      color: #3B3940;\n    }\n  }\n\n  .prepayment-value-card-footer {\n    display: flex;\n    align-items: end;\n\n    @media(max-width: @md-screen) {\n      margin-top: 1rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
