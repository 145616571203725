import React from 'react';
import PropTypes from 'prop-types';
import {
  Information,
  Status,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import {
  Checkbox,
  Form,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { formatDate, interestRateByPortfolio } from 'utils';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import {
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import './styles.less';

const { Item } = Form;

const LoanData = ({
  acceptedCheckbox,
  setAcceptedCheckbox,
  acceptedAvalistCheckbox,
  setAcceptedAvalistCheckbox,
  alreadySigned,
  loan: {
    id,
    date,
    finalAmount,
    finalInstallmentsCount,
    firstPaymentDate,
    gyraMaisFeeAmount,
    installmentAmount,
    installmentsCount,
    interestRate,
    partnerFeeAmount,
    totalAmount,
    amount,
    cet,
    signatures = [],
    additive,
    status,
    bankerId,
    bankerValue,
    ccb,
    debitAuthorization,
    authorizationTerm,
    couponStatusName,
    beforeCouponValues,
    partner,
    portfolioName,
  } = {},
}) => {
  const { checkActions } = usePartnerActionsContext();
  const badescPortfolios = ['Pronampe Emergencial SE', 'Pronampe Emergencial CP', 'Pronampe Geral', 'Pronampe Mulher'];
  const isBadesc = badescPortfolios.includes(portfolioName);

  if (!id) {
    return null;
  }

  const handleCheckboxMessage = () => {

    if (checkActions(['debit-authorization-term'])) {
      return (
        <p>
          <b>
            Declaro que li a CCB e o Termo de Autorização
            e estou de acordo com seus termos.
            <br />
            Esta CCB e o Termo de Autorização são gerados
            e assinados eletronicamente, tendo as suas autenticidades,
            integridades e validades garantidas na forma da Lei.
            Ao clicar no botão Assinar, reconheço como válidas as
            assinaturas eletrônicas da CCB e do Termo de Autorização.
          </b>
        </p>
      );
    }
    return (
      <p>
        <b>
          Esta CCB é gerada e assinada
          {isBadesc ? ' digitalmente' : ' eletronicamente'}
          , tendo a sua autenticidade,
          integridade e validade garantidas na forma da Lei.
          Eu reconheço como válida a assinatura
          {isBadesc ? ' digital ' : ' eletrônica '}
          dessa CCB.
          {isBadesc && (
            <>
              <br />
              Confirmo que todos os avalistas são portadores de e-CPF
            </>
          )}
        </b>
      </p>
    );
  };

  const handleContracts = () => {
    const contracts = [];

    const flow = partner?.flow;
    const isAwaitingSignature = status.value === 'awaiting-signature';

    contracts.push({
      message: 'Visualizar Contrato',
      onClick: async () => {
        if (additive) {
          return window.open(additive.url);
        }

        if (isAwaitingSignature && !isBadesc && bankerValue === 'money-plus') {
          const moneyPlusUrl = `${process.env.MONEYPLUS_URI}&code=${bankerId}`;
          return window.open(moneyPlusUrl);
        }

        return window.open(ccb.url);
      },
    });

    if (isAwaitingSignature) {
      if (checkActions(['debit-authorization-term'])) {
        contracts.push({
          message: 'Visualizar Termo de Autorização',
          onClick: async () => {
            window.open(debitAuthorization.url);
          },
        });
      }

      if (flow === 'ideal') {
        contracts.push({
          message: 'Visualizar Termo de Autorização',
          onClick: async () => {
            window.open(authorizationTerm.url);
          },
        });
      }
    }

    return contracts;
  };

  const handleSignatureStatus = (signedAt, status) => {
    if (!status) {
      if (signedAt) {
        return 'assinatura ok';
      }
      return 'Pendente assinatura';
    }
    return status.name;
  };

  const sizes = isBadesc ? ({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  }) : ({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
  });

  return (
    <div id="loan-data">
      <div className="users-data">
        {signatures?.map(({
          id,
          signedAt,
          user: {
            fullName,
            email,
            phoneNumber,
          } = {},
          status,
        }) => (
          <Row className="loan-data-users" key={id} align="middle" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
              <Information
                label="Nome"
                value={fullName}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <Information
                label="Email"
                value={email}
              />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={5}>
              <Information
                label="Telefone"
                value={phoneNumber}
              />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={5} className="loan-data-users-status">
              <Status
                value={status.value === 'signed' ? 'active' : 'pending'}
                name={handleSignatureStatus(signedAt, status)}
              />
            </Col>
          </Row>
        ))}
      </div>

      <div className="loan-data">
        <Row align="middle" gutter={[16, 16]}>
          <Col {...sizes}>
            <Information
              label="Data"
              value={`${date ? new Date(date).toLocaleDateString('pt-br') : '-'}`}
            />
          </Col>

          <Col {...sizes}>
            <Information
              label="Valor Total"
              type={totalAmount && 'currency'}
              value={`${totalAmount || '-'}`}
              originalValue={couponStatusName !== 'Expirada' && beforeCouponValues?.totalAmount && totalAmount !== beforeCouponValues?.totalAmount ? `${beforeCouponValues?.totalAmount}` : null}
            />
          </Col>

          {!isBadesc && (
            <Col {...sizes}>
              <Information
                label="Valor Líquido"
                type={(finalAmount || amount) && 'currency'}
                value={`${finalAmount || amount || '-'}`}
              />
            </Col>
          )}

          <Col {...sizes}>
            <Information
              label={
                isBadesc
                  ? (
                    <span>
                      Taxa de Juros
                      <Tooltip className="interest-rate-tooltip" title={`Caso atrase será SELIC* + ${interestRateByPortfolio[portfolioName]} a.a`}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  )
                  : 'Taxa de Juros'
              }
              type={isBadesc ? null : 'percentage'}
              value={`${isBadesc ? `SELIC* + ${interestRateByPortfolio[portfolioName]} a.a` : interestRate || '0'}`}
              originalValue={couponStatusName !== 'Expirada' && beforeCouponValues?.interestRate && interestRate !== beforeCouponValues?.interestRate ? `${beforeCouponValues?.interestRate}` : null}
            />
          </Col>

          {
            !isBadesc
            && !checkActions(['partner-is-banker'])
            && (
              <Col {...sizes}>
                <Information
                  label="Taxa Gyra+"
                  type="currency"
                  value={`${gyraMaisFeeAmount || '0'}`}
                />
              </Col>

            )
          }

          {!isBadesc && (
            <Col {...sizes}>
              <Information
                label="Valor da Parcela"
                type={installmentAmount && 'currency'}
                value={`${installmentAmount || '-'}`}
                originalValue={couponStatusName !== 'Expirada' && beforeCouponValues?.installmentAmount && installmentAmount !== beforeCouponValues?.installmentAmount ? `${beforeCouponValues?.installmentAmount}` : null}
              />
            </Col>
          )}

          <Col {...sizes}>
            <Information
              label="N° de Parcelas"
              value={`${finalInstallmentsCount || installmentsCount || '-'}`}
            />
          </Col>
          {
            !isBadesc && (
              <Col {...sizes}>
                <Information
                  label="Data da 1ª Parcela"
                  value={`${firstPaymentDate ? formatDate(new Date(firstPaymentDate)) : '-'}`}
                  originalValue={couponStatusName !== 'Expirada' && beforeCouponValues?.firstPaymentDate && firstPaymentDate !== beforeCouponValues?.firstPaymentDate ? `${formatDate(new Date(beforeCouponValues?.firstPaymentDate))}` : null}
                />
              </Col>
            )
          }
          {
            !checkActions(['partner-is-banker'])
            && !isBadesc
            && (
              <Col {...sizes}>
                <Information
                  label="Taxa de Crédito"
                  type="currency"
                  value={`${partnerFeeAmount || '0'}`}
                />
              </Col>
            )
          }

          {!isBadesc && (
            <Col {...sizes}>
              <Information
                label="CET Mensal"
                type="percentage"
                value={`${cet || '0'}`}
              />
            </Col>
          )}
        </Row>
      </div>

      <Row className="loan-data-checkbox">
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
          {!alreadySigned && (
            <>
              <Item className="loan-data-checkbox-item">
                <Checkbox
                  checked={acceptedCheckbox}
                  id="loan-checkbox"
                  onChange={({ target: { checked } }) => setAcceptedCheckbox(checked)}
                >
                  {handleCheckboxMessage()}
                </Checkbox>
              </Item>
              {!isBadesc && (
                <Item className="loan-data-checkbox-item">
                  <Checkbox
                    checked={acceptedAvalistCheckbox}
                    id="avalist-checkbox"
                    onChange={({ target: { checked } }) => setAcceptedAvalistCheckbox(checked)}
                  >
                    <p>
                      <b>
                        Eu confirmo que meu avalista está ciente da minha
                        solicitação de crédito e será cobrado em caso de inadimplência.
                      </b>
                    </p>
                  </Checkbox>
                </Item>
              )}
            </>
          )}
        </Col>

        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} align="end">
          {handleContracts().map(({ onClick, message }) => (
            <Row className="contracts-area">
              <Col span={24}>
                <Button
                  link
                  icon={<EyeOutlined />}
                  className="view-contract-btn"
                  size="small"
                  onClick={onClick}
                >
                  {message}
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </div>
  );
};

LoanData.propTypes = {
  acceptedCheckbox: PropTypes.bool,
  setAcceptedCheckbox: PropTypes.func,
  acceptedAvalistCheckbox: PropTypes.bool,
  setAcceptedAvalistCheckbox: PropTypes.func,
  alreadySigned: PropTypes.bool,
  loan: PropTypes.shape(),
};

export default LoanData;
