import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Alert,
} from 'antd';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  BankAccountCard,
  PrepaymentBankAccountForm,
} from 'components';
import { Button } from 'components/sdk';
import { formatAndNotificateError } from 'utils';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import client from 'services/graphql';
import {
  BANK_ACCOUNTS,
  SELECT_PREPAYMENT_BANK_ACCOUNT,
} from './graphql';
import { useSignatureContext } from '../..';

import './styles.less';

const PrepaymentBankAccount = () => {
  const { businessId } = useBusinessLoanContext();
  const {
    prepayment,
    setStepVariables,
    setStepHandle,
    setStepLoading,
    updatePrepayment,
  } = useSignatureContext();

  const [addingAccount, setAddingAccount] = useState(false);

  const {
    data: {
      bankAccounts = [],
    } = {},
  } = useQuery(BANK_ACCOUNTS, {
    skip: !businessId,
    variables: { businessId },
  });

  const [selectPrepaymentBankAccount, { loading }] = useMutation(SELECT_PREPAYMENT_BANK_ACCOUNT, {
    onCompleted: ({ selectPrepaymentBankAccount }) => {
      setStepVariables({
        bankAccountId: selectPrepaymentBankAccount.id,
        done: true,
      });

      const bankAccount = bankAccounts.find(({ id }) => id === selectPrepaymentBankAccount.id);
      updatePrepayment({
        ...prepayment,
        bankAccount,
      });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  useEffect(() => setStepHandle(undefined), []);
  useEffect(() => setStepLoading(loading), [loading]);
  useEffect(() => {
    const { bankAccount } = prepayment || {};

    if (bankAccount) {
      setStepVariables({ done: true });
    }
  }, [prepayment, bankAccounts.length]);

  return (
    <>
      <Row gutter={[24, 24]} className="domicile-bank-account-step-container">
        <Col span={24}>
          <Alert showIcon message="Escolha abaixo uma conta bancária para receber o valor da sua antecipação ou adicione uma nova conta." />
        </Col>

        {bankAccounts.map((bankAccount) => (
          <Col key={bankAccount.id} xs={24} sm={24} md={24} lg={24} xl={12}>
            <BankAccountCard
              className="domicile-bank-account-card"
              bankAccount={bankAccount}
              selected={prepayment?.bankAccount?.id === bankAccount.id}
              onClick={() => {
                if (prepayment?.bankAccount?.id !== bankAccount.id) {
                  selectPrepaymentBankAccount({
                    variables: {
                      bankAccountId: bankAccount.id,
                      prepaymentId: prepayment.id,
                    },
                  });
                }
              }}
            />
          </Col>
        ))}

        <Col span={24}>
          <Button
            icon={addingAccount ? <CloseCircleOutlined className="cancel-icon" /> : <PlusCircleOutlined className="adding-account-icon" />}
            color="basic"
            sm
            onClick={() => setAddingAccount(!addingAccount)}
            disabled={loading}
          >
            {addingAccount ? 'Cancelar adição' : 'Adicionar nova conta'}
          </Button>
        </Col>
      </Row>

      {addingAccount && (
      <PrepaymentBankAccountForm
        onClose={() => setAddingAccount(false)}
        onSaveSuccess={({ saveBankAccount }) => {
          client.writeQuery({
            query: BANK_ACCOUNTS,
            variables: { businessId },
            data: {
              bankAccounts: [
                ...bankAccounts,
                saveBankAccount,
              ],
            },
          });
          setAddingAccount(false);
        }}
      />
      )}
    </>
  );
};

export default {
  id: 'prepayment-bank-account-step',
  title: 'Conta domicílio',
  component: <PrepaymentBankAccount />,
};
