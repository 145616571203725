import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './styles.less';
import ArrowComponent from './components/ArrowComponent';

const ImagesCarousel = ({ imagesArray = [] }) => (
  <Carousel
    dots={false}
    arrows
    slide
    className="image-carousel"
    nextArrow={<ArrowComponent Icon={RightOutlined} imagesNumber={imagesArray.length} direction="next" />}
    prevArrow={<ArrowComponent Icon={LeftOutlined} imagesNumber={imagesArray.length} direction="prev" />}
  >
    {imagesArray.map((Item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className="carousel-image-container">
        {typeof Item === 'string' ? (
          <img src={Item} alt="Imagem de exemplo de documento" />
        ) : (
          <Item />
        )}
      </div>
    ))}
  </Carousel>
);

ImagesCarousel.propTypes = {
  imagesArray: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
};

export default ImagesCarousel;
