import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Row,
  Col,
  Carousel,
  Form,
  notification,
} from 'antd';
import {
  Button,
} from 'components/sdk';
import {
  BankAccountForm,
  SuccessMessage,
  TutorialDrawer,
} from 'components';
import {
  CloseOutlined,
} from '@ant-design/icons';
import { formatAndNotificateError } from 'utils';
import { useMutation, useQuery } from '@apollo/client';
import { TutorialPanel } from 'components/others';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import { SAVE_BANK_ACCOUNT, ME } from './graphql';

import './styles.less';

const { useForm } = Form;

let carouselRef;

const CAROUSEL_PAGES = {
  FORM_PAGE: 0,
  SUCCESS_PAGE: 1,
};

const FORM_PAGES = {
  ACCOUNT_DETAILS: 0,
  STATEMENT_FILES: 1,
};

const BankAccountDrawer = ({
  onClose,
  visible,
  selectedBank: bank,
  onPending,
  onSelectMain,
  onCompleted,
  refetch,
  integrationScreen,
  disableOpenBanks,
  selectedBankAccount,
}) => {
  const [form] = useForm();

  const [carouselPage, setCarouselPage] = useState(CAROUSEL_PAGES.FORM_PAGE);
  const [formStep, setFormStep] = useState(FORM_PAGES.ACCOUNT_DETAILS);

  const [tutorialDrawerVisible, setTutorialDrawerVisible] = useState(false);
  const [selectedBank, setSelectedBank] = useState({
    id: undefined,
    code: undefined,
    tutorial: undefined,
  });
  const [bankAccountInfos, setBankAccountInfos] = useState();
  carouselRef = useRef(null);

  const { requestConfirmation } = useConfirmModalContext();

  const [saveBankAccount, { loading }] = useMutation(SAVE_BANK_ACCOUNT, {
    onCompleted: (data) => {
      onCompleted?.(data);
      setCarouselPage(CAROUSEL_PAGES.SUCCESS_PAGE);
      setBankAccountInfos();
      setFormStep(FORM_PAGES.ACCOUNT_DETAILS);
      setTimeout(() => {
        form.resetFields();
        setSelectedBank({
          id: undefined,
          code: undefined,
          tutorial: undefined,
        });
      }, 600);
      refetch?.();
      document.documentElement.scrollTop = 0;
    },

    onError: (error) => formatAndNotificateError(error),
  });

  const {
    data: {
      me: {
        currentBusiness,
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (carouselRef) {
      setTimeout(() => {
        carouselRef.goTo?.(carouselPage);
      }, (carouselPage !== 0 || !bank) ? 200 : 0);
    }
  }, [carouselPage]);

  useEffect(() => {
    if (carouselRef) {
      setSelectedBank(bank);
      setCarouselPage(CAROUSEL_PAGES.FORM_PAGE);
    }
  }, [bank]);

  useEffect(() => {
    setSelectedBank(selectedBankAccount?.bank);
    setBankAccountInfos(selectedBankAccount);
    form.setFieldsValue(selectedBankAccount);
  }, [selectedBankAccount]);

  const getSuccessText = () => {
    if (integrationScreen) {
      return '';
    }

    return (
      <>
        Você já pode prosseguir para a próxima etapa, mas não se esqueça de que quanto
        {' '}
        <b className="secondary-text">MAIS extratos </b>
        {' '}
        adicionados,
        {' '}
        <b className="secondary-text">MAIOR</b>
        {' '}
        será a sua chance de
        {' '}
        <b className="secondary-text">aprovação de crédito</b>
        !
      </>
    );
  };

  const handleOrigin = () => {
    const { pathname } = window.location;

    if (pathname === '/cadastro/integracoes') {
      return 'integration-screen';
    }

    if (pathname === '/plataformas-cadastradas') {
      return 'account-screen';
    }

    return 'pending-screen';
  };

  const handleClose = () => {
    setFormStep(FORM_PAGES.ACCOUNT_DETAILS);

    setTimeout(() => {
      form.resetFields();
      setSelectedBank({
        id: undefined,
        code: undefined,
        tutorial: undefined,
      });
    }, 600);

    onClose();

    if (!onPending) {
      setCarouselPage(CAROUSEL_PAGES.FORM_PAGE);
    }
  };

  return (
    <>
      <Drawer
        visible={visible}
        destroyOnClose
        width="90%"
        contentWrapperStyle={{
          maxWidth: '90.5rem',
        }}
        className="bank-account-drawer"
        closeIcon={null}
        onClose={handleClose}
        footer={visible && carouselPage === CAROUSEL_PAGES.FORM_PAGE && (
          <Button
            id="bank-account-next-button"
            loading={loading}
            onClick={async () => {
              await form.validateFields();
              if (formStep === FORM_PAGES.ACCOUNT_DETAILS) {
                const { type, key } = form.getFieldsValue();
                if ((type && !key) || (!type && key)) {
                  return notification.error({
                    message: 'Complete as informações referentes ao PIX',
                  });
                }

                await setBankAccountInfos({
                  ...bankAccountInfos,
                  ...form.getFieldsValue(),
                  currentAccount: form.getFieldsValue().currentAccount === 'corrente',
                });
                setFormStep(FORM_PAGES.STATEMENT_FILES);
              }
              if (formStep === FORM_PAGES.STATEMENT_FILES) {
                if (!form.getFieldsValue().confirmation) {
                  return notification.error({
                    message: 'Confirme a condição',
                  });
                }

                if (bankAccountInfos.branchCode.includes('_')) {
                  setFormStep(FORM_PAGES.ACCOUNT_DETAILS);
                  return notification.error({
                    message: 'Agência inválida',
                  });
                }

                const { statementFileIds = [] } = form.getFieldsValue();
                if (statementFileIds.length === 0) {
                  return notification.error({ message: 'Adicione o extrato bancário' });
                }

                await saveBankAccount({
                  variables: {
                    ...bankAccountInfos,
                    pix: {
                      key: bankAccountInfos.key,
                      type: bankAccountInfos.type,
                    },
                    statementFileIds,
                    businessId: currentBusiness?.id,
                    origin: handleOrigin(),
                  },
                });
              }
              return true;
            }}
          >
            Confirmar e prosseguir
          </Button>
        )}
      >
        <div className="drawer-body">
          <Carousel
            ref={(c) => { carouselRef = c; }}
            dots={false}
            swipeToSlide={false}
            swipe={false}
            draggable={false}
            accessibility={false}
          >
            <div className="carousel-content">
              <Row className="carousel-row no-padding">
                <Col xs={24} sm={24} md={selectedBank?.tutorial ? 16 : 24}>
                  <Row className="bank-form-section">
                    <Col span={24}>
                      <Button
                        className="close-button"
                        link
                        onClick={() => {
                          requestConfirmation({
                            onOk: () => {
                              handleClose();
                            },
                            onCancel: () => {},
                          });
                        }}
                      >
                        <CloseOutlined className="close-icon" />
                        Fechar
                      </Button>
                    </Col>

                    <Col span={24} className="top-side">
                      <h3 className="title">Confirme as informações da sua conta bancária</h3>
                    </Col>

                    {selectedBank?.tutorial && (
                    <Col className="open-helper-button-col">
                      <Button
                        className="open-helper-button"
                        link
                        onClick={() => setTutorialDrawerVisible(true)}
                      >
                        Como conseguir seu extrato bancário?
                      </Button>
                    </Col>
                    )}

                    <Col span={24}>
                      <BankAccountForm
                        disableOpenBanks={disableOpenBanks}
                        form={form}
                        bank={selectedBank}
                        step={formStep}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={0} sm={0} md={selectedBank?.tutorial ? 8 : 0} className="helper-area">
                  <TutorialPanel tutorial={selectedBank?.tutorial} />
                </Col>
              </Row>
            </div>

            <div className="carousel-content">
              <Row className="carousel-row">
                <Col span={24}>
                  <Button
                    className="close-button"
                    link
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseOutlined className="close-icon" />
                    Fechar
                  </Button>
                </Col>
              </Row>

              <SuccessMessage
                title="Extrato bancário adicionado."
                subtitle={getSuccessText()}
                centered
                primaryButton={integrationScreen ? {
                  text: 'Voltar para tela de integrações',
                  id: 'accountSuccess',
                  onClick: () => {
                    onClose();
                    setCarouselPage(CAROUSEL_PAGES.FORM_PAGE);
                  },
                } : {
                  text: 'Adicionar outra conta bancária',
                  onClick: () => {
                    if (onPending) return onClose();

                    return setCarouselPage(CAROUSEL_PAGES.FORM_PAGE);
                  },
                }}
                secondaryButton={!integrationScreen && carouselPage && onSelectMain && {
                  text: 'Visualizar contas adicionadas',
                  id: 'select-account-button',
                  onClick: onSelectMain,
                }}
              />
            </div>
          </Carousel>
        </div>
      </Drawer>

      <TutorialDrawer
        tutorial={selectedBank?.tutorial}
        visible={tutorialDrawerVisible}
        onClose={() => setTutorialDrawerVisible(false)}
      />
    </>
  );
};

BankAccountDrawer.propTypes = {
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  onSelectMain: PropTypes.func,
  visible: PropTypes.bool,
  selectedBank: PropTypes.shape(),
  onPending: PropTypes.bool,
  refetch: PropTypes.func,
  integrationScreen: PropTypes.bool,
  disableOpenBanks: PropTypes.bool,
  selectedBankAccount: PropTypes.shape(),
};

export default BankAccountDrawer;
