// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#digital-signature-form {
  padding-top: 3rem;
}
#digital-signature-form .certificate-password-input {
  margin-top: 2rem;
}
#digital-signature-form .digital-signature-alert {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
#digital-signature-form .digital-signature-alert-feedback {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
#digital-signature-form .digital-signature-alert-feedback .ant-alert-description {
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.8;
}
@media (max-width: 768px) {
  #digital-signature-form .try-digital-sign-button {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/DigitalSignatureForm/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAGI,gBAAA;AAEJ;AALA;EAMI,gBAAA;EACA,mBAAA;AAEJ;AATA;EAUI,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AAfA;EAeM,qBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AAGN;AAAE;EAAA;IAEI,WAAA;EAEJ;AACF","sourcesContent":["#digital-signature-form {\n  padding-top: 3rem;\n  .certificate-password-input {\n    margin-top: 2rem;\n  }\n  .digital-signature-alert {\n    margin-top: 2rem;\n    margin-bottom: 1rem;\n  }\n  .digital-signature-alert-feedback {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    display: flex;\n    align-items: center;\n    .ant-alert-description {\n      margin-bottom: .2rem;\n      margin-left: .5rem;\n      font-weight: 500;\n      font-size: 1rem;\n      opacity: .8;\n    }\n  }\n  @media(max-width: @md-screen) {\n    .try-digital-sign-button {\n      width: 100%;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
