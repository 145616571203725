// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-day-card {
  padding: 2.5rem !important;
  padding-bottom: 3.5rem !important;
  max-width: 34.438rem;
}
@media (max-width: 768px) {
  .payment-day-card {
    padding: 1.5rem 1rem !important;
  }
}
.payment-day-card .ant-row {
  padding-top: 2rem;
}
@media (max-width: 768px) {
  .payment-day-card .ant-row {
    padding-top: 1rem;
  }
}
.payment-day-card .ant-col {
  justify-content: center;
}
.payment-day-card .options-title {
  margin-bottom: 2rem;
}
.payment-day-card .option {
  width: 4.25rem;
  height: 4.25rem;
  display: flex;
  margin: auto;
  border: 0.063rem solid #000000;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 2rem;
  line-height: 1rem;
  font-weight: 300;
}
@media (max-width: 768px) {
  .payment-day-card .option {
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1.625rem;
    line-height: 1rem;
  }
}
.payment-day-card .option.option-disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
.payment-day-card .option.selected {
  border: none;
  background-color: var(--primary-button-color);
  color: var(--primary-button-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/PaymentDayCard/styles.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,iCAAA;EACA,oBAAA;AACF;AACE;EAAA;IACE,+BAAA;EAEF;AACF;AATA;EAUI,iBAAA;AAEJ;AAAI;EAAA;IACE,iBAAA;EAGJ;AACF;AAjBA;EAkBI,uBAAA;AAEJ;AApBA;EAsBI,mBAAA;AACJ;AAvBA;EA0BI,cAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EAGA,eAAA;EACA,iBAAA;EACA,gBAAA;AAFJ;AAII;EAAA;IACE,cAAA;IACA,eAAA;IAEA,mBAAA;IACA,iBAAA;EAFJ;AACF;AAII;EACE,mBAAA;EACA,aAAA;AAFN;AAKI;EACE,YAAA;EACA,6CAAA;EACA,uCAAA;AAHN","sourcesContent":[".payment-day-card {\n  padding: 2.5rem !important;\n  padding-bottom: 3.5rem !important;\n  max-width: 34.438rem;\n\n  @media(max-width: @md-screen) {\n    padding: 1.5rem 1rem !important;\n  }\n\n  .ant-row {\n    padding-top: 2rem;\n\n    @media(max-width: @md-screen) {\n      padding-top: 1rem;\n    }\n  }\n\n  .ant-col {\n    justify-content: center;\n  }\n\n  .options-title {\n    margin-bottom: 2rem;\n  }\n\n  .option {\n    width: 4.25rem;\n    height: 4.25rem;\n    display: flex;\n    margin: auto;\n    border: .063rem solid #000000;\n    border-radius: 50%;\n    align-items: center;\n    justify-content: center;\n    transition: .4s;\n    background-color: #ffffff;\n    cursor: pointer;\n\n\n    font-size: 2rem;\n    line-height: 1rem;\n    font-weight: 300;\n    \n    @media(max-width: @md-screen) {\n      width: 3.75rem;\n      height: 3.75rem;\n      \n      font-size: 1.625rem;\n      line-height: 1rem;\n    }\n\n    &.option-disabled {\n      cursor: not-allowed;\n      opacity: .25;\n    }\n\n    &.selected {\n      border: none;\n      background-color: var(--primary-button-color);\n      color: var(--primary-button-text-color);\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
