// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-bank-text {
  font-size: 0.7rem;
}
@media (min-width: 768px) {
  .upload-bank-text {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .upload-bank-icon {
    height: 66px;
    width: 66px;
  }
}
#integrations-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;
  padding: 0.625rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/others/IntegrationTypesList/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EAAA;IACE,eAAA;EAGF;AACF;AACE;EAAA;IACE,YAAA;IACA,WAAA;EAEF;AACF;AAEA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,eAAA;EACA,SAAA;EACA,aAAA;EACA,iBAAA;AAAF","sourcesContent":[".upload-bank-text {\n  font-size: 0.7rem;\n  @media (min-width:@md-screen) {\n    font-size: 1rem;\n  }\n}\n\n.upload-bank-icon {\n  @media (min-width:@sm-screen) {\n    height: 66px;\n    width: 66px;\n  }\n}\n\n\n#integrations-container {\n  display: flex;\n  justify-content: space-between;\n  max-width: 100%;\n  flex-wrap: wrap;\n  gap: 1rem;\n  row-gap: 1rem;\n  padding: 0.625rem;\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
