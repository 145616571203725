/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Progress } from 'antd';

import './styles.less';

/**
 * @param {{
 *  percent: number,
 *  className?: string,
 *  bordered?: boolean,
 *  borderPlacement?: 'top'|'left'|'right'|'bottom',
 *  rounded?: boolean,
 *  progressColor?: 'primary'|'primary-button'|'success'|'warning'|'danger'
 *  progress: 'line'|'circle'|'dashboard'
 *  strokeWidth: number
 * }} props
 */
const GyramaisProgress = ({
  percent,
  className,
  bordered,
  borderPlacement,
  rounded = true,
  progressColor = 'primary',
  type,
  strokeWidth = 16,
  ...rest
}) => (
  <Progress
    strokeColor={`var(--${progressColor}-color)`}
    strokeWidth={strokeWidth}
    trailColor="#E8E8E8"
    type={type}
    className={`
        gyramais-progress
        ${className || ''}
        ${bordered ? `ant-progress-bordered${borderPlacement ? `-${borderPlacement}` : ''}` : ''}
        ${rounded ? '' : 'ant-progress-not-rounded'}
      `}
    showInfo={false}
    percent={percent}
    status="active"
    {...rest}
  />
);

export default GyramaisProgress;
