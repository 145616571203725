import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Information,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import {
  Row,
  Col,
  notification,
  Tooltip,
} from 'antd';
import {
  useMutation,
} from '@apollo/client';
import { formatAndNotificateError, formatDate, formatCurrency } from 'utils';
import { PrinterOutlined, CopyOutlined } from '@ant-design/icons';
import { DownPaymentInvoiceIcon } from 'assets/images';
import {
  SAVE_INVOICE,
} from './graphql';

import './styles.less';

const date = new Date(new Date().setHours(0, 0, 0, 0));

const finalAmount = (installment) => {
  if (installment?.status?.value === 'paid') {
    return installment?.paidAmount || installment?.totalAmount;
  }

  return installment?.values?.balanceAmount;
};

const InstallmentRow = ({
  installment: {
    id,
    values: { status },
    number,
    dueDate,
    invoices,
  },
  installment,
  last,
  isAbleToGetInvoice,
  next,
  detailed,
  late,
  installmentsCount,
  withOptions,
  actions = [],
  loading,
}) => {
  const [invoiceType, setInvoiceType] = useState();
  const [invoice, setInvoice] = useState(
    invoices.find((i) => !i.canceledAt
    && new Date(new Date(i.dueDate).setHours(0, 0, 0, 0))
      >= new Date(new Date().setHours(0, 0, 0, 0))),
  );
  const [saveInvoice, { loading: confirmLoading }] = useMutation(SAVE_INVOICE, {
    onCompleted: async ({ saveInvoice: { id, barcode, url } = {} }) => {
      if (invoiceType === 'print') window.open(url, '_blank');
      else if (invoiceType === 'copy') {
        navigator.clipboard.writeText(barcode);
        notification.success({ message: 'Código de Barras copiado com sucesso' });
      }
      setInvoice({ id, barcode, url });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const aux = new Date(dueDate);
  const invoiceDueDate = new Date(
    aux.getUTCFullYear(),
    aux.getUTCMonth(),
    aux.getUTCDate(), 0, 0, 0,
  );

  const isDisabled = !confirmLoading && !isAbleToGetInvoice;

  return (
    <div className={`installment-container ${next ? 'next' : ''}`}>
      <Row className="installment-row" gutter={[16, 16]}>
        {detailed ? (
          <>
            <Col xs={status.value === 'paid' ? 12 : 24} sm={12} md={12} lg={6} className="installment-row-left-side">
              <div className={`information-value-prefix ${status.value === 'paid' ? 'paid' : `${late ? 'late' : ''}`}`}>
                {number === 0 ? (
                  <Tooltip title="Boleto de entrada">
                    <img src={DownPaymentInvoiceIcon} alt="Boleto de Entrada" height={22} />
                  </Tooltip>
                ) : (number)}
              </div>

              <h4 className={`no-margin-bottom ${status.value === 'paid' ? 'success-text' : `${late ? 'danger-text' : ''}`}`}>
                {formatCurrency(finalAmount(installment) || 0)}
              </h4>
            </Col>
            <Col xs={status.value === 'paid' ? 12 : 24} sm={12} md={12} lg={6}>
              <div className="due-date-text-container">
                <h4 className={`no-margin-bottom ${status.value === 'paid' ? 'success-text' : `${late ? 'danger-text' : ''}`}`}>
                  {`${status.value === 'paid' ? 'Pago' : `${late ? 'Venceu' : 'Vence'} em`} ${status.value !== 'paid' ? formatDate(new Date(dueDate)) : ''}`}
                </h4>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Information
                size="large"
                danger={late}
                label={`${late ? 'Parcela atrasada' : 'Próxima parcela'} (${number}/${installmentsCount})`}
                value={`${dueDate ? formatDate(new Date(dueDate)) : '-'}`}
              />
            </Col>

            <Col xs={24} sm={12} md={12} lg={6}>
              <Information
                size="large"
                danger={late}
                label="Valor da Parcela"
                value={`${finalAmount(installment) || '-'}`}
                type="currency"
              />
            </Col>
          </>
        )}

        {!loading
        && withOptions
        && status.value !== 'paid'
        && (!actions.includes('debit-authorization-term') || (actions.includes('debit-authorization-term') && date > invoiceDueDate)) && (
          <Col xs={24} sm={24} md={24} lg={12} className="actions-area">
            <Row align="middle" justify="end" gutter={[16, 16]}>
              <Col span={24} align="right">
                <Button
                  loading={invoiceType === 'print' && confirmLoading}
                  disabled={isDisabled}
                  link
                  icon={<PrinterOutlined />}
                  onClick={async () => {
                    if (!invoice) {
                      setInvoiceType('print');
                      await saveInvoice({ variables: { installmentId: id } });
                    } else window.open(invoice.url, '_blank');
                  }}
                >
                  Imprimir boleto
                </Button>
                <Button
                  link
                  id={`installment-${number}-copy-code-button`}
                  loading={invoiceType === 'copy' && confirmLoading}
                  disabled={isDisabled}
                  icon={<CopyOutlined />}
                  onClick={async () => {
                    if (!invoice) {
                      setInvoiceType('copy');
                      await saveInvoice({ variables: { installmentId: id } });
                    } else {
                      navigator.clipboard.writeText(invoice.barcode);
                      notification.success({ message: 'Código de Barras copiado com sucesso' });
                    }
                  }}
                >
                  Copiar código do boleto
                </Button>
              </Col>
            </Row>

          </Col>
        )}
      </Row>
      {!last && <hr className="installments-divider" />}
    </div>
  );
};

InstallmentRow.propTypes = {
  installment: PropTypes.shape({
    id: PropTypes.string,
    invoices: PropTypes.arrayOf(PropTypes.shape()),
    next: PropTypes.bool,
    values: PropTypes.shape({
      balanceAmount: PropTypes.number,
      status: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
    number: PropTypes.number,
    totalAmount: PropTypes.number,
    dueDate: PropTypes.string,
  }),
  last: PropTypes.bool,
  next: PropTypes.bool,
  detailed: PropTypes.bool,
  withOptions: PropTypes.bool,
  late: PropTypes.bool,
  isAbleToGetInvoice: PropTypes.bool,
  installmentsCount: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default InstallmentRow;
