import ADD_SESSION from './addSession.gql';
import EMAIL_IS_VERIFIED from './emailIsVerified.gql';
import LOG_IN from './logIn.gql';
import PARTNER from './partner.gql';
import VALIDATE_SELLER from './validateSeller.gql';
import VALIDATE_SELLER_USER from './validateSellerUser.gql';
import SAVE_SELLER_USER_FROM_LOGIN from './saveSellerUserFromLogin.gql';
import REMOVE_SESSION from './removeSession.gql';
import SET_LOGGED_OUT from './setLoggedOut.gql';
import SEND_EMAIL_LOGIN_WITH_LINK from './sendEmailLoginWithLink.gql';

export {
  ADD_SESSION,
  EMAIL_IS_VERIFIED,
  LOG_IN,
  PARTNER,
  VALIDATE_SELLER,
  VALIDATE_SELLER_USER,
  SAVE_SELLER_USER_FROM_LOGIN,
  REMOVE_SESSION,
  SET_LOGGED_OUT,
  SEND_EMAIL_LOGIN_WITH_LINK,
};
