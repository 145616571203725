import React from 'react';
import { formatString } from 'utils';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

const NewOldPreview = () => {
  const {
    loan: { installmentAmount },
    roundedCurrentDiscount,
    installmentCountSelected,
    loanAmount,
    discountedAmount,
    currentInstallmentAmount,
  } = usePromoRenegotiationContext();

  let title = 'O valor das suas parcelas ficará:';
  let oldValue = installmentAmount;
  let newValue = currentInstallmentAmount;

  // quando o "desconto" é negativo mostramos então os valores das parcelas
  if (discountedAmount < loanAmount) {
    title = installmentCountSelected === 1
      ? `Você pagará apenas ${100 - roundedCurrentDiscount}% do valor total de sua dívida:`
      : 'Valor total da dívida renegociada:';

    oldValue = loanAmount;
    newValue = discountedAmount;
  }

  return (
    <>
      <p>
        <b className="secondary-text">{title}</b>
      </p>

      <div className="card-value-text-value">
        <span className="old-value">
          {formatString(oldValue, 'currency')}
        </span>

        <span className="new-value" data-cy="new-value-span">
          {formatString(newValue, 'currency')}
        </span>
      </div>
    </>
  );
};

export default NewOldPreview;
