import React, { memo } from 'react';
import {
  Row,
  Col,
  Rate,
} from 'antd';
import {
  Card,
} from 'components/gyramais';
import {
  Layout,
} from 'components/sdk';
import './styles.less';

const {
  Content,
} = Layout;

const texts = [{
  name: 'Geiza Soares Alves Barreto',
  time: '• 2 semanas atrás',
  comment: '"Realmente uma empresa sem as burocracias que impedem nós microempreendedores de conseguirmos um empréstimo nesse momento tão delicado que estamos passando"',
}, {
  name: 'Glauco Vasco Carvalho do Amaral Pinto',
  time: '• 10 semanas atrás',
  comment: '"Excelente atendimento feito pelo Gabriel (GYRA) em parceria como Market Place olist.. O Gabriel esclareceu todos os passos e o dinheiro saiu em 24 hs. Excelente alternativa para empreendedores, com um juro honesto e viável. Recomendamos!"',
}];

const ClientsComments = () => (
  <div id="clients-comments">
    <Content>
      <br />
      <br />

      <h2>
        <b>
          O que nossos clientes
          <br />
          dizem sobre nós
        </b>
      </h2>

      <br />

      <Row gutter={[16, 32]} justify="space-between">
        {texts.map(({ name, time, comment }) => (
          <Col key={name} span={24} className="clients-comments-item">
            <Card>
              <h5 className="no-margin-bottom">
                <strong className="olist-title">
                  {name}
                </strong>
              </h5>

              <Rate disabled defaultValue={5} />
              {' '}
              <span className="comment-date">{time}</span>

              <br />
              <br />

              <p className="justified-text">
                {comment}
              </p>
            </Card>
          </Col>
        ))}
      </Row>

      <br />
      <br />
    </Content>
  </div>
);

export default memo(ClientsComments);
