// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#first-integration-screen .ant-tabs-nav {
  margin-bottom: 2rem;
}
#first-integration-screen .your-accounts-container {
  display: flex;
  align-items: center;
}
#first-integration-screen .your-accounts-container .your-accounts-container-label {
  margin-left: 0.6rem;
  margin-right: 0.4rem;
}
#first-integration-screen .footer-icon {
  font-size: 1.2rem;
  margin-right: 0.6rem;
}
.video-link {
  color: white;
  align-items: center;
  font-size: 20px;
  margin-top: 10px;
}
.video-link svg {
  margin-right: 9px;
}
.video-link p {
  color: white;
  margin: auto 0 !important;
  font-weight: 500;
  text-decoration: underline;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/FirstIntegrationScreen/styles.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;AAAJ;AAFA;EAMI,aAAA;EACA,mBAAA;AADJ;AANA;EAUM,mBAAA;EACA,oBAAA;AADN;AAVA;EAgBI,iBAAA;EACA,oBAAA;AAHJ;AAOA;EACE,YAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AALF;AACA;EAMI,iBAAA;AAJJ;AAFA;EASI,YAAA;EACA,yBAAA;EACA,gBAAA;EACA,0BAAA;EACA,YAAA;AAJJ","sourcesContent":["#first-integration-screen {\n  .ant-tabs-nav {\n    margin-bottom: 2rem;\n  }\n\n  .your-accounts-container {\n    display: flex;\n    align-items: center;\n    \n    .your-accounts-container-label {\n      margin-left: .6rem;\n      margin-right: .4rem;\n    }\n  }\n\n  .footer-icon {\n    font-size: 1.2rem;\n    margin-right: .6rem;\n  }\n}\n\n.video-link {\n  color:white;\n  align-items:center;\n  font-size: 20px;  \n  margin-top: 10px;\n  svg {\n    margin-right:9px;\n  }\n  p {\n    color:white;\n    margin:auto 0 !important;\n    font-weight:500;\n    text-decoration: underline;\n    height:100%;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
