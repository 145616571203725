import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
} from 'antd';
import {
  Button,
} from 'components/sdk';
import {
  TermCard,
} from 'components';

const ProofStep = ({
  acceptedCheckbox,
  setCheckBox,
  setAcceptedCheckbox,
  loading,
  handleAccept,
  user,
}) => (
  <>
    <div className="flow-proof-civil-status-content">
      <TermCard
        alert="A seguir, você assinará um termo que comprova a veracidade do estado civil do sócio. Leia atentamente."
        user={user}
        business={user?.currentBusiness}
        address={user?.address}
        type={user?.civilStatus?.value}
      />
      <Checkbox
        checked={acceptedCheckbox}
        id="proof-of-civil-status-checkbox"
        ref={(c) => setCheckBox(c)}
        onChange={({ target: { checked } }) => setAcceptedCheckbox(checked)}
      >
        Confirmo que li, aceito e concordo com as informações descritas acima.
        Reconheço a assinatura deste termo como válida e comrpovante do
        estado civil do sócio adicionado.
      </Checkbox>
    </div>
    <div className="flow-badesc-footer">
      <Button loading={loading} onClick={handleAccept}>
        Confirmar
      </Button>
    </div>
  </>
);

ProofStep.propTypes = {
  acceptedCheckbox: PropTypes.bool,
  setCheckBox: PropTypes.func,
  setAcceptedCheckbox: PropTypes.func,
  loading: PropTypes.bool,
  handleAccept: PropTypes.func,
  user: PropTypes.shape(),
};

export default ProofStep;
