// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#integration-motivation-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#integration-motivation-modal img {
  max-width: 8.125rem;
  max-height: 11.25rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
#integration-motivation-modal .body {
  text-align: justify;
  color: black;
  font-weight: 400;
  line-height: 1.35rem;
  font-size: 1.125rem;
  padding-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/IntegrationMotivationModal/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAJA;EAMI,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AAVA;EAaI,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,oBAAA;AAAJ","sourcesContent":["#integration-motivation-modal {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  \n  img {\n    max-width: 8.125rem;\n    max-height: 11.25rem;\n    margin-top: .5rem;\n    margin-bottom: 2rem;\n  }\n\n  .body {\n    text-align: justify;\n    color: black;\n    font-weight: 400;\n    line-height: 1.35rem;\n    font-size: 1.125rem;\n    padding-bottom: 1rem;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
