// @ts-check
/* eslint-disable react/prop-types */
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { formatAndNotificateError } from 'utils';
import { LOAN_AVAILABLE_RENEGOTIATE, PROMO_RENEGOTIATE } from './graphql';
import { useSubHeaderFields } from './hooks/useSubheaderFields';
import { useLoanLoaded } from './hooks/useLoanLoaded';

export const PromoRenegotiationContext = createContext({});

/**
 * @typedef {{
 *  amount: number
 *  dueDate: string
 *  url: string
 * }} PendingInvoice
 */

/**
 * @typedef {{
 *  installmentAmount: number
 *  installmentCount: number
 *  downPaymentAmount: number
 * }} InstallmentDTO
 */

/**
 * @typedef {InstallmentDTO & {
 *  finalDiscountedAmount: number
 *  discountPercent: number
 * }} InstallmentData
 */

const PromoRenegotiationProvider = ({ children }) => {
  const [installmentCountSelected, selectInstallmentCount] = useState(1);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const history = useHistory();

  const {
    data: {
      loanAvailableForPromoRenegotiate: loan,
    } = {},
    loading,
  } = useQuery(LOAN_AVAILABLE_RENEGOTIATE, {
    onError: (error) => {
      formatAndNotificateError(error);
      history.push('/');
    },
  });

  /** @type {PendingInvoice | undefined} */
  const pendingInvoice = loan?.pendingFullyPayInvoice;

  const remainingInstallmentsCount = useMemo(
    () => loan?.installments.filter(({ values: { status: { value } } }) => (value !== 'paid'))?.length,
    [loan],
  );

  const interestRate = loan?.interestRate;

  const portfolioName = loan?.portfolioName;
  const businessOwnerFullName = loan?.business?.owner?.fullName;
  const businessCnpj = loan?.business?.cnpj;

  const {
    id: chargePolicyId,
    maxInstallmentCount = 24,
    installmentAmounts,
    noDiscount,
  } = loan?.chargePolicy || {};

  const {
    interestRateDiscount = 0,
    installmentPaymentDiscount: installmentDiscount = 0,
  } = loan?.chargePolicy?.activeDiscount || {};

  const canShowInstallment = ({ installmentAmount, discountPercent }) => {
    // nao tendo desconto e tendo uma parcela maior nem mostramos a opção
    if (discountPercent <= 0 && loan?.installmentAmount - installmentAmount < 0) {
      return false;
    }

    return true;
  };

  const discountedInterestRate = interestRate * (1 - interestRateDiscount);

  const {
    installmentPaymentRestAmount,
    cashPaymentRestAmount,
    cashPaymentRealDiscount: cashDiscount,
  } = loan || {};

  const loanAmount = installmentCountSelected === 1
    ? cashPaymentRestAmount
    : installmentPaymentRestAmount;

  /**
   * @type InstallmentData[]
   */
  const installmentOptionsData = useMemo(() => installmentAmounts?.map(
    (installmentDTO) => {
      const {
        installmentAmount,
        installmentCount,
        downPaymentAmount,
      } = installmentDTO;

      const finalDiscountedAmount = (installmentAmount * installmentCount) + downPaymentAmount;

      return ({
        ...installmentDTO,
        installmentAmount,
        installmentCount,
        finalDiscountedAmount,
        discountPercent: Math.round(
          (1 - (finalDiscountedAmount / installmentPaymentRestAmount)) * 100,
        ),
      });
    },
  ), [installmentAmounts]);

  useLoanLoaded({
    loading,
    loan,
    installmentOptionsData,
    canShowInstallment,
    onLoad: ({ defaultInstallmentCountSelected }) => {
      selectInstallmentCount(defaultInstallmentCountSelected);
    },
  });

  const currentDiscount = installmentCountSelected > 1
    ? installmentOptionsData[installmentCountSelected - 2]?.discountPercent
    : cashDiscount * 100;

  const roundedCurrentDiscount = Math.floor(currentDiscount);
  const roundedCashDiscount = Math.floor(cashDiscount * 100);
  const roundedInstallmentDiscount = Math.floor(installmentDiscount * 100);

  const currentInstallmentSelected = installmentOptionsData
    ?.find((el) => el.installmentCount === installmentCountSelected);

  const currentInstallmentAmount = currentInstallmentSelected?.installmentAmount;
  const currentInstallmentDownPayment = currentInstallmentSelected?.downPaymentAmount;

  const discountedAmount = installmentCountSelected === 1
    ? loanAmount * ((100 - currentDiscount) / 100)
    : currentInstallmentSelected.finalDiscountedAmount;

  const [promoRenegotiateLoan, { loading: isRenegotiating }] = useMutation(PROMO_RENEGOTIATE, {
    variables: {
      loanId: loan?.id,
      installmentsCount: installmentCountSelected,
    },
    onError: (error) => formatAndNotificateError(error),
    onCompleted: () => {
      if (installmentCountSelected === 1) {
        setSuccessModalVisible(true);
        return;
      }

      notification.success({
        message: 'Boleto de entrada criado com sucesso. Pague antes que sua renegociação seja cancelada!',
      });

      history.push('/?action=entrada');
    },
  });

  const subHeaderFields = useSubHeaderFields({
    invoiceDueDate: pendingInvoice?.dueDate,
    cashDiscount: roundedCashDiscount,
    installmentDiscount: roundedInstallmentDiscount,
    installmentCountSelected,
    maxInstallmentCount,
    noDiscount,
  });

  return (
    <PromoRenegotiationContext.Provider
      value={{
        loan: loan ?? {},
        ...subHeaderFields,
        loading,
        loanAmount,
        discountedInterestRate,
        pendingInvoice,
        cashDiscount,
        roundedCashDiscount,
        installmentDiscount,
        interestRateDiscount,
        chargePolicyId,
        isRenegotiating,
        installmentCountSelected,
        cashPaymentRestAmount,
        installmentOptionsData,
        promoRenegotiateLoan,
        selectInstallmentCount,
        currentDiscount,
        confirmModalVisible,
        discountedAmount,
        noDiscount,
        maxInstallmentCount,
        roundedCurrentDiscount,
        currentInstallmentAmount,
        remainingInstallmentsCount,
        currentInstallmentDownPayment,
        canShowInstallment,
        portfolioName,
        businessOwnerFullName,
        businessCnpj,
        openConfirmModal: () => setConfirmModalVisible(true),
        closeConfirmModal: () => setConfirmModalVisible(false),

        successModalVisible,
        openSuccessModal: () => setSuccessModalVisible(true),
        closeSuccessModal: () => setSuccessModalVisible(false),

      }}
    >
      {children}
    </PromoRenegotiationContext.Provider>
  );
};

export default PromoRenegotiationProvider;

/**
 * @returns {{
 *  loading: boolean
 *  isRenegotiating: boolean
 *  noDiscount: boolean
 *  chargePolicyId: string
 *  pendingInvoice: PendingInvoice
 *  loanAmount: number
 *  installmentCountSelected: number
 *  interestRateDiscount: number
 *  cashDiscount: number
 *  roundedCashDiscount: number
 *  installmentDiscount: number
 *  cashPaymentRestAmount: number
 *  maxInstallmentCount: number
 *  currentDiscount: number
 *  discountedAmount: number
 *  currentInstallmentDownPayment: number
 *  currentInstallmentAmount: number
 *  roundedCurrentDiscount: number
 *  installmentOptionsData: InstallmentData[]
 *  canShowInstallment: (args: {
 *    installmentAmount: number
 *    discountPercent: number
 *  }) => boolean
 *  installmentAmounts: InstallmentDTO[]
 *  selectInstallmentCount: (number: number) => void
 *  promoRenegotiateLoan: () => void
 *  confirmModalVisible: boolean
 *  openConfirmModal: () => void
 *  closeConfirmModal: () => void
 *  successModalVisible: boolean
 *  openSuccessModal: () => void
 *  closeSuccessModal: () => void
 * } & ReturnType<useSubHeaderFields>}
 */
export function usePromoRenegotiationContext() {
  // @ts-ignore
  return useContext(PromoRenegotiationContext);
}
