// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#gyramais-partner-footer .logo {
  width: 8.25rem;
}
#gyramais-partner-footer .ant-btn {
  background-color: #ffffff00;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/PartnerFooter/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAFA;EAKI,2BAAA;EACA,YAAA;AAAJ","sourcesContent":["#gyramais-partner-footer {\n  .logo {\n    width: 8.25rem;\n  }\n  .ant-btn {\n    background-color: #ffffff00;\n    border: none;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
