/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import { IntegrationDrawer } from 'components/drawers';
import { meVar } from 'services/graphql/cache';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import IntegrationTypesList from '../IntegrationTypesList';
import SearchBar from '../SearchBar';
import {
  CONNECTORS,
} from './graphql';

const ConnectorsList = ({
  refetchBankAccount,
  refetchIntegration,
  goToAccountScreen,
  handleSendSolicitation,
  sendSolicitationLoading,
  reintegrationVariables,
  sendSolicitation,
  businessRequestsIds,
  refetchBusinessRequests,
  loanStatus,
  filteredIntegrations,
}) => {
  const { stone: isStoneSeller } = useReactiveVar(meVar);
  const { width } = useWindowDimensions();
  const [integrationName, setIntegrationName] = useState('');
  const [integrationType, setIntegrationType] = useState('');
  const [integrationSelected, setIntegrationSelected] = useState(undefined);
  const [reintegrationId, setReintegrationId] = useState();
  const { currentContext } = useBusinessLoanContext();
  const { loadingActions, checkActions } = usePartnerActionsContext();
  const onlyByAnnualBilling = checkActions(['show-only-bi-anual-billing']);

  const {
    data: {
      connectors = [],
    } = {},
  } = useQuery(CONNECTORS, {
    variables: {
      contextId: currentContext?.id,
    },
    skip: loadingActions || onlyByAnnualBilling,
  });

  const handleSelectIntegration = (integration) => {
    setIntegrationSelected(integration);
  };

  useEffect(() => {
    if (!reintegrationVariables) {
      return;
    }

    setIntegrationType(reintegrationVariables.integrationTypeId);
    setReintegrationId(reintegrationVariables.integrationId);

  }, [reintegrationVariables]);

  const connectorsFiltered = useMemo(() => {
    if (!connectors?.[0]) {
      return [];
    }

    return connectors.filter(({
      name, type, value,
    }) => {
      if (filteredIntegrations?.length > 0) {
        return filteredIntegrations.includes(value);
      }

      if (isStoneSeller && value === 'stone') {
        return false;
      }

      if (!integrationName && !integrationType) {
        return true;
      }

      const nameExist = name?.toLowerCase().includes(integrationName.toLowerCase());
      const typeExist = !integrationType || type === integrationType;

      if (nameExist && typeExist) {
        return true;
      }

      return false;
    });
  }, [connectors, integrationName, integrationType]);

  return (
    <div>
      <IntegrationDrawer
        integrationSelected={integrationSelected?.type !== 'Banco' && integrationSelected}
        onClose={() => handleSelectIntegration()}
        reintegrationId={reintegrationId}
        goToAccountScreen={goToAccountScreen}
        handleSendSolicitation={handleSendSolicitation}
        sendSolicitationLoading={sendSolicitationLoading}
        sendSolicitation={sendSolicitation}
        handleSuccessIntegration={refetchIntegration}
        refetchBusinessRequests={refetchBusinessRequests}
        isCheckDocuments={loanStatus === 'check-documents'}
      />

      {width > 768 && (
        <SearchBar
          onChangeInput={(e) => setIntegrationName(e)}
          onChangeSelect={(e) => setIntegrationType(e)}
          connectors={connectors}
          refetch={refetchIntegration}
        />
      )}

      <IntegrationTypesList
        integrationSelected={integrationSelected}
        integrationType={filteredIntegrations ? true : integrationType}
        onSet={(e) => { handleSelectIntegration(e); }}
        refetchBankAccount={refetchBankAccount}
        connectors={connectorsFiltered}
        businessRequestsIds={businessRequestsIds}
        refetchBusinessRequests={refetchBusinessRequests}
        forceComplete={handleSendSolicitation}
      />
    </div>
  );
};

export default ConnectorsList;
