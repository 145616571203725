import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import './styles.less';

const { Step } = Steps;

const TutorialModal = ({
  steps,
  visible,
  title,
  onClose,
  customFinish,
}) => {
  const [current, setCurrent] = useState(0);

  const handleClose = () => {
    setCurrent(0);
    onClose();
  };

  return (
    <Modal
      style={{ whiteSpace: 'pre-line' }}
      title={title}
      centered
      visible={Boolean(steps) && visible}
      closable={false}
      onCancel={handleClose}
      id="tutorial-modal"
      okButton={current === steps?.length - 1 ? {
        text: steps?.[current]?.nextButtonText || 'Concluir',
        onClick: () => {
          customFinish?.();
          handleClose();
        },
      } : {
        text: steps?.[current]?.nextButtonText || 'Avançar',
        onClick: () => {
          steps?.[current]?.nextAction?.();
          setCurrent(current + 1);
        },
      }}
      backButton={current === 0 ? {
        text: 'Fechar',
        onClick: handleClose,
        color: 'secondary',
      } : {
        text: 'Voltar',
        onClick: () => setCurrent(current - 1),
        color: 'secondary',
      }}
    >
      {steps && (
        <>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="tutorial-modal-steps-content">{steps[current].content}</div>
        </>
      )}
    </Modal>
  );
};

TutorialModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  customFinish: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.shape),
};

export default memo(TutorialModal);
