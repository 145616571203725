/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Carousel,
  notification,
} from 'antd';
import {
  Drawer,
  SignatureProcess,
  SuccessMessage,
  DigitalSignTutorialModal,
  TutorialModal,
} from 'components';
import {
  Button,
} from 'components/sdk';
import './styles.less';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { formatError, formatAndNotificateError } from 'utils';
import useDrawerHelpers from 'utils/hooks/useDrawerHelpers';
import { useHistory } from 'react-router-dom';

import {
  CREATE_VERIFICATION_CODE,
  SAVE_SIGNATURE,
  VERIFY_CODE,
  SAVE_USER,
  SAVE_OPTIN,
  SETTINGS,
  CREATE_CAF_SIGNATURE,
  MARK_AS_PHYSICAL_PRINTED,
} from './graphql';
import { meVar } from '../../../services/graphql/cache';

const SignatureDrawer = ({
  onClose,
  onCompleted,
  visible,
  loan,
  refetch,
  isBadesc,
}) => {
  const carouselRef = useRef(null);
  const [jsonIpLoading, setJsonIpLoading] = useState(false);
  const [carouselStep, setCarouselStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [acceptedCheckbox, setAcceptedCheckbox] = useState(false);
  const [acceptedAvalistCheckbox, setAcceptedAvalistCheckbox] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [ethnicGroup, setEthnicGroup] = useState('');
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [tutorialModalVisible, setTutorialModalVisible] = useState(false);
  const [physicalTutorialModalVisible, setPhysicalTutorialModalVisible] = useState(false);
  const [bryModuleLoading, setBryModuleLoading] = useState(false);
  const [digitalSignature, setDigitalSignature] = useState();
  const [digitalSignatureNonce, setDigitalSignatureNonce] = useState();
  const [digitalSignatureError, setDigitalSignatureError] = useState();
  const history = useHistory();

  const { goToStepSafely } = useDrawerHelpers({ carouselRef });

  const toSign = () => {
    if (loan) {
      return loan.signatures
        ?.filter(({ user }) => user !== null)
        .find(({ user: { id } = {} }) => meVar().id === id);
    }
    return null;
  };

  const {
    data: { settings: { signatureByFaceMatch } = {} } = {},
    loading: signatureByFaceMatchLoading,
  } = useQuery(SETTINGS);

  const [createVerificationCode] = useMutation(CREATE_VERIFICATION_CODE);
  const [saveOptIn] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      setJsonIpLoading(false);
    },
    onError: (error) => {
      setJsonIpLoading(false);
      const formated = formatError(error);
      notification.error({ message: formated });
    },
  });
  const [saveSignature, { loading: signatureLoading }] = useMutation(SAVE_SIGNATURE, {
    onCompleted: () => {
      notification.destroy();

      if (signatureByFaceMatch && !loan.additive) {
        setCurrentStep(3);
      } else {
        notification.success({ message: 'Sua assinatura foi confirmada com sucesso.' });
        setCarouselStep(carouselStep + 1);
        setAlreadySigned(true);
        carouselRef?.current?.next();
      }
      setJsonIpLoading(false);
    },
    onError: (error) => {
      setJsonIpLoading(false);
      setDigitalSignature();
      setDigitalSignatureNonce();

      const formated = formatError(error);
      if (formated === 'Tempo para finalização de assinatura excedido. Tente novamente. Se o problema persistir, entre em contato com o suporte'
        || formated === 'Esse e-CPF já foi utilizado em uma assinatura deste contrato') {
        setDigitalSignatureError(formated);
      } else {
        notification.error({
          message: formated,
        });
      }
    },
  });

  const [saveUser, { loading: userLoading }] = useMutation(SAVE_USER, {
    onCompleted: () => { },
    onError: (error) => {
      notification.error({
        message: formatError(error),
      });
    },
  });

  const [
    markAsPhysicalPrinted,
  ] = useMutation(MARK_AS_PHYSICAL_PRINTED, {
    onCompleted: () => { },
    onError: (error) => {
      notification.error({
        message: formatError(error),
      });
    },
  });

  const handleIpFailure = () => {
    notification.error({
      message: 'Não conseguimos localizar seu IP. '
        + 'Por favor tente se desconectar do Wi-Fi ou tentar em outro navegador.',
      duration: 30,
    });
    setJsonIpLoading(false);
  };

  const [verifyCode, { loading: verificationLoading }] = useMutation(VERIFY_CODE, {
    onCompleted: () => {
      if (isBadesc) return setCurrentStep(2);
      if (process.env.ENVIRONMENT === 'production' && loan) {
        ReactGA.event({
          category: 'Signature',
          action: 'Signed Contract',
        });
        ReactPixel.trackCustom('Subscribe', {
          action: 'Signed Contract',
          value: loan?.totalAmount,
        });
      }

      setJsonIpLoading(true);
      fetch('https://api.ipify.org/?format=json')
        .then((response) => response.json())
        .then(({ ip }) => {
          saveSignature({
            variables: {
              ip,
              id: toSign()?.id,
              verificationCode,
            },
          });
        })
        .catch(handleIpFailure);
      return true;
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [createCAFSignatureQuery, {
    data: {
      createCAFSignature,
    } = {},
    loading: createCAFSignatureLoading,
  }] = useLazyQuery(CREATE_CAF_SIGNATURE, {
    onError: (error) => formatAndNotificateError(error),
  });

  const _onClose = () => {
    setCarouselStep(0);
    setCurrentStep(0);
    refetch();
    if (onCompleted) onCompleted();
    onClose?.();
  };

  useEffect(() => {
    goToStepSafely(carouselStep);
  }, [carouselStep]);

  useEffect(() => {
    if (currentStep === 3 && !isBadesc) {
      createCAFSignatureQuery();
    }
  }, [currentStep]);

  const getPrimaryButtonProps = () => {
    if (alreadySigned) {
      return null;
    }

    const baseProps = {
      disabled: !acceptedCheckbox || (!acceptedAvalistCheckbox && !isBadesc),
      loading: signatureLoading
        || verificationLoading
        || jsonIpLoading
        || bryModuleLoading
        || userLoading
        || signatureByFaceMatchLoading
        || createCAFSignatureLoading,
    };

    // Confirmar assinatura
    if (currentStep === 0) {
      return {
        ...baseProps,
        id: 'sign-loan-button',
        onClick: async () => {
          if (isBadesc) {
            if (!window.BryExtension) return setTutorialModalVisible(true);
            setBryModuleLoading(true);
            try {
              await window.BryExtension.isNativeModuleUpdated();
              setBryModuleLoading(false);
            } catch (error) {
              setBryModuleLoading(false);
              return window.BryExtension.promptUserToInstallNativeModule();
            }
          }

          createVerificationCode();

          setJsonIpLoading(true);
          fetch('https://api.ipify.org/?format=json')
            .then((response) => response.json())
            .then(({ ip }) => {
              saveOptIn({
                variables: {
                  ip,
                  accepted: acceptedAvalistCheckbox,
                },
              });
              setCurrentStep(currentStep + 1);
            })
            .catch(handleIpFailure);

          return true;
        },
        children: 'Assinar',
      };
    }

    // Pesquisa étnico-racial
    if (currentStep === 1 && !loan?.additive && !isBadesc) {
      return {
        ...baseProps,
        id: 'racial-search-button',
        disabled: !ethnicGroup,
        onClick: async () => {
          await saveUser({
            variables: {
              id: meVar().id,
              ethnicGroup: ethnicGroup.toString(),
            },
          });
          setCurrentStep(2);
        },
        children: 'Prosseguir',
      };
    }

    // Inserir código sms
    if ((currentStep === 2 && !loan?.additive && !isBadesc) || (currentStep === 1 && loan?.additive) || (currentStep === 1 && isBadesc)) {
      let buttonText = 'Concluir';
      if (isBadesc) {
        buttonText = 'Prosseguir';
      } else if (signatureByFaceMatch) {
        buttonText = 'Continuar';
      }
      return {
        ...baseProps,
        id: 'verificate-code-button',
        onClick: async () => {
          await verifyCode({
            variables: {
              verificationCode: verificationCode.toString(),
            },
          });
        },
        children: buttonText,
      };
    }

    // Certificado digital
    if (currentStep === 2 && isBadesc) {
      return {
        ...baseProps,
        id: 'certificate-button',
        disabled: !digitalSignature || !digitalSignatureNonce,
        onClick: async () => {
          setJsonIpLoading(true);
          fetch('https://api.ipify.org/?format=json')
            .then((response) => response.json())
            .then(({ ip }) => {
              saveSignature({
                variables: {
                  ip,
                  id: toSign()?.id,
                  verificationCode,
                  digitalSignature: digitalSignature.signature,
                  cpf: digitalSignature.cpf,
                  nonce: digitalSignatureNonce,
                },
              });
            })
            .catch(handleIpFailure);
        },
        children: 'Concluir',
      };
    }

    return null;
  };

  useEffect(() => {
    const signatures = loan?.signatures || [];

    const mySignature = signatures.find((signature) => signature?.user?.id === meVar()?.id);

    if (!mySignature) {
      return;
    }

    if (mySignature.status.value === 'signed') {
      setAlreadySigned(true);
    }

  }, [loan]);

  return (
    <Drawer
      id="signature-drawer"
      visible={visible}
      maxWidth="90.5rem"
      onClose={() => {
        if (carouselStep === 1) refetch();
        setCurrentStep(0);
        onClose();
      }}
      className="signature-drawer"
      title={carouselStep !== 1 ? 'Assinar contrato' : null}
      primaryButton={getPrimaryButtonProps()}
      extraFooterComponents={isBadesc && (
        <>
          {currentStep === 0 && (
            <Button
              className="print-button"
              onClick={() => {
                setPhysicalTutorialModalVisible(true);
              }}
            >
              Assinar fisicamente
            </Button>
          )}
        </>
      )}
    >
      <div className="drawer-body">
        <Carousel
          ref={(ref) => {
            carouselRef.current = ref;
          }}
          draggable={false}
          swipe={false}
          swipeToSlide={false}
          dots={false}
          accessibility={false}
          infinite={false}
        >
          <div className="carousel-content">
            {carouselStep === 0 && (
              <SignatureProcess
                alreadySigned={alreadySigned}
                setAlreadySigned={setAlreadySigned}
                setEthnicGroup={(e) => setEthnicGroup(e)}
                currentStep={currentStep}
                loan={loan}
                setCurrentStep={(e) => setCurrentStep(e)}
                acceptedCheckbox={acceptedCheckbox}
                setAcceptedCheckbox={(e) => setAcceptedCheckbox(e)}
                acceptedAvalistCheckbox={acceptedAvalistCheckbox}
                setAcceptedAvalistCheckbox={(e) => setAcceptedAvalistCheckbox(e)}
                verificationCode={verificationCode}
                setVerificationCode={(e) => setVerificationCode(e)}
                setDigitalSignature={(e) => setDigitalSignature(e)}
                setDigitalSignatureNonce={(e) => setDigitalSignatureNonce(e)}
                digitalSignatureError={digitalSignatureError}
                setDigitalSignatureError={(e) => setDigitalSignatureError(e)}
                signatureLoading={signatureLoading || verificationLoading || jsonIpLoading}
                next={() => {
                  setCarouselStep(carouselStep + 1);
                }}
                isBadesc={isBadesc}
                signatureByFaceMatch={signatureByFaceMatch}
                signatureByFaceMatchLoading={signatureByFaceMatchLoading}
                CAFSignatureURL={createCAFSignature}
              />
            )}
          </div>

          <div className="carousel-content">
            {carouselStep === 1 && (
              <Row>
                <Col span={24}>
                  <SuccessMessage
                    title="Você assinou o contrato."
                    centered
                    subtitle={(
                      <>
                        Esse processo
                        {' '}
                        <b>levará algum tempo</b>
                        , e todos
                        {' '}
                        <b>os sócios deverão assinar </b>
                        {' '}
                        o contrato.
                        <br />
                        <b>Você receberá a resposta por email.</b>
                      </>
                    )}
                    primaryButton={!isBadesc && ({
                      text: 'Ver lista de empréstimos',
                      onClick: () => {
                        _onClose();
                        history.push('/emprestimos');
                      },
                    })}
                    secondaryButton={{
                      text: 'Ir para Página Inicial',
                      onClick: () => {
                        onClose();
                        history.push('/');
                      },
                    }}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Carousel>
      </div>

      {isBadesc && (
        <>
          <DigitalSignTutorialModal
            visible={tutorialModalVisible}
            onClose={() => setTutorialModalVisible(false)}
          />
          <TutorialModal
            visible={physicalTutorialModalVisible}
            onClose={() => setPhysicalTutorialModalVisible(false)}
            title="O que fazer agora?"
            customFinish={onClose}
            steps={[
              {
                title: 'Instruções',
                nextButtonText: 'Estou ciente',
                nextAction: () => markAsPhysicalPrinted({
                  variables: {
                    loanId: loan.id,
                  },
                }),
                content: (
                  <>
                    <b>Algumas informações úteis sobre a assinatura física</b>
                    <ul>
                      <li>
                        Caso seja assinatura física, todos os avalistas devem assinar físicamente
                      </li>
                      <li>
                        Abaixo, estarão informações sobre como
                        prosseguir com o contrato assinado fisicamente
                      </li>
                    </ul>
                    <Button
                      block
                      onClick={() => {
                        window.open(
                          'https://api.gyramais.com.br/files/cbc6e712-dc06-4c37-8720-ee2cef5b9a9d/bbbfbfd64b7dc107aa2ef651edd6e894_badesc-tutorial.pdf',
                          '_blank',
                        );
                      }}
                      link
                    >
                      Abrir instruções
                    </Button>
                  </>
                ),
              },
              {
                title: 'Impressão do contrato',
                content: (
                  <>
                    <b>Clique no botão abaixo para imprimir o contrato e assiná-lo.</b>
                    <br />
                    <br />
                    <Button
                      block
                      onClick={() => window.open(loan.ccb.url, '_blank')}
                      link
                    >
                      Abrir contrato
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    </Drawer>
  );
};

SignatureDrawer.propTypes = {
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  refetch: PropTypes.func,
  visible: PropTypes.bool,
  loan: PropTypes.shape({
    totalAmount: PropTypes.number,
    signatures: PropTypes.array,
    additive: PropTypes.shape({
      url: PropTypes.string,
    }),
    status: PropTypes.shape(),
    bankerId: PropTypes.string,
    ccb: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  isBadesc: PropTypes.bool,
};

export default SignatureDrawer;
