/* eslint-disable no-param-reassign */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  notification,
  Steps,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import {
  formatAndNotificateError,
} from 'utils';
import { useMutation } from '@apollo/client';
import { Images } from 'assets';
import {
  TermsAndPoliciesOptIn,
  WhatsappOptIn,
} from './optIns';
import {
  SAVE_OPTIN,
} from './graphql';
import './styles.less';

const { LogoGyraWhite } = Images;
const { Step } = Steps;

const OptInModal = ({
  visible,
  onClose,
  logo,
  optIns,
  showBadescOptIn,
  portfolioName,
}) => {
  const [stepsCount, setStepsCount] = useState(0);
  const [current, setCurrent] = useState(0);
  const [confirmation, setConfirmation] = useState(false);

  const [ipLoading, setIpLoading] = useState(false);

  const [saveOptIn, { loading }] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      if (current < stepsCount - 1) {
        setCurrent(current + 1);
        setConfirmation(false);
      } else {
        setCurrent(0);
        onClose();
      }
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const handleSaveOptIn = ({ accepted, types, needConfirmation }) => {
    if (needConfirmation && !confirmation) {
      notification.error({
        message: 'Aceite o termo',
        description: 'Para aceitar o termo, clique para checar a opção na qual você concorda com o termo ',
        duration: 10,
      });
    } else {
      setIpLoading(true);
      fetch('https://api.ipify.org/?format=json')
        .then((response) => response.json())
        .then(({ ip }) => {
          saveOptIn({
            variables: {
              ip,
              accepted,
              types,
            },
          });
          setIpLoading(false);
        })
        .catch(() => { notification.error({ message: 'Não conseguimos localizar seu IP' }); });
    }
  };

  const steps = useMemo(() => {
    const {
      policyOptIn = false,
      registryOptIn = false,
      wppOptIn = false,
      badescOptIn = false,
    } = optIns?.reduce((result, { type }) => {
      if (type === 'TERMS-AND-POLICIES') {
        result.policyOptIn = true;
      }
      if (type === 'BADESC') {
        result.badescOptIn = true;
      }
      if (type === 'RECEIVABLES-REGISTRY') {
        result.registryOptIn = true;
      }
      if (type === 'WHATSAPP') {
        result.wppOptIn = true;
      }

      return result;
    }, {}) || {};

    const types = [
      'TERMS-AND-POLICIES',
      'RECEIVABLES-REGISTRY',
    ];

    if (showBadescOptIn) {
      types.push('BADESC');
    }

    const temp = [
      (!policyOptIn || !registryOptIn || (showBadescOptIn && !badescOptIn))
      && {
        title: 'Termos de Uso e da Registradora, e Política de Privacidade',
        content: (
          <TermsAndPoliciesOptIn
            confirmation={confirmation}
            setConfirmation={setConfirmation}
            showBadescOptIn={showBadescOptIn}
            portfolioName={portfolioName}
          />
        ),
        okButton: {
          text: 'Aceitar',
          onClick: () => handleSaveOptIn({
            accepted: true,
            types: [
              'TERMS-AND-POLICIES',
              'RECEIVABLES-REGISTRY',
              showBadescOptIn && 'BADESC',
            ].filter(Boolean),
            needConfirmation: true,
          }),
        },
      },
      !wppOptIn && {
        title: 'Mais agilidade para acompanhar sua proposta?',
        content: (
          <WhatsappOptIn />
        ),
        okButton: {
          text: 'Quero receber atualizações!',
          onClick: () => handleSaveOptIn({ accepted: true, types: ['WHATSAPP'] }),
          id: 'whatsapp-opt-in-modal-confirm-button',
          'data-cy': 'opt-in-modal-confirm-button',
        },
        cancelButton: {
          text: 'Não quero receber atualizações',
          onClick: () => handleSaveOptIn({ accepted: false, types: ['WHATSAPP'] }),
          id: 'whatsapp-opt-in-modal-cancel-button',
          color: 'secondary',
        },
      },
    ].filter(Boolean);

    setStepsCount(temp.length);

    return temp;
  }, [confirmation, optIns]);

  return (
    <Modal
      style={{ whiteSpace: 'pre-line' }}
      title={(
        <img src={logo || LogoGyraWhite} alt="logo" className="opt-in-logo" />
      )}
      centered
      closable={false}
      className="opt-in-modal"
      visible={visible}
      okButton={steps[current]?.okButton
        ? { ...steps[current]?.okButton, loading: ipLoading || loading }
        : undefined}
      cancelButton={steps[current]?.cancelButton
        ? { ...steps[current]?.cancelButton, disabled: ipLoading || loading }
        : undefined}
    >
      <Steps current={current}>
        {steps.map((item, index) => (
          <Step
            key={item.title}
            title={current === index ? item.title : ''}
            className={current !== index ? 'step-without-title' : ''}
          />
        ))}
      </Steps>

      <div className="steps-content">{steps[current]?.content}</div>
    </Modal>
  );
};

OptInModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  logo: PropTypes.string,
  optIns: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
  })),
};

export default memo(OptInModal);
