import SAVE_BUSINESS_USER from './saveBusinessUser.gql';
import SAVE_ADDRESS from './saveAddress.gql';
import COMPLETE_BUSINESS_USER from './completeBusinessUser.gql';
import SAVE_MARRIAGE_OR_DIVORCE_DOCS from './saveMarriageOrDivorceDocs.gql';
import SAVE_WIDOWER_DOCS from './saveWidowerDocs.gql';
import BUSINESS from './business.gql';
import GENERATE_PROOF_OF_CIVIL_STATUS from './generateProofOfCivilStatus.gql';

export {
  SAVE_BUSINESS_USER,
  SAVE_ADDRESS,
  COMPLETE_BUSINESS_USER,
  SAVE_MARRIAGE_OR_DIVORCE_DOCS,
  SAVE_WIDOWER_DOCS,
  BUSINESS,
  GENERATE_PROOF_OF_CIVIL_STATUS,
};
