// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#racialserach-form {
  display: flex;
}
#racialserach-form .gyramais-input {
  margin-top: 0.938rem;
  margin-bottom: 2.5rem;
}
#racialserach-form .racial-search-body {
  margin-top: 2.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/RacialSearchForm/styles.less"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;AAFA;EAIE,oBAAA;EACA,qBAAA;AACF;AANA;EASE,kBAAA;AAAF","sourcesContent":["#racialserach-form{\n\tdisplay: flex;\n\n\t.gyramais-input {\n\t\tmargin-top: .938rem;\n\t\tmargin-bottom: 2.5rem;\n\t}\n\n\t.racial-search-body {\n\t\tmargin-top: 2.5rem;\n\t}\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
