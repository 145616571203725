import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Input } from 'components/gyramais';
import { Button } from 'components/sdk';

const { Item } = Form;

const CnpjForm = ({
  form,
  onFinish,
  partner,
  loading,
}) => (
  <Form
    form={form}
    layout="vertical"
    onFinish={(values) => onFinish(values)}
  >
    <h2>
      Bem-vindo(a) ao
      {' '}
      <span className="secondary-text">
        Portal
        {' '}
        {partner.name}
      </span>!
    </h2>

    <p className="no-margin-bottom">
      Esse é o ambiente onde você vai poder acompanhar suas
      propostas cadastradas por meio da parceria
      {' '}
      <b className="secondary-text">
        GYRA+ &
        {' '}
        {partner.name}
      </b>
    </p>
    <br />

    <Item
      name="cnpj"
      rules={[{ required: true, message: 'Por favor insira o CNPJ.' }]}
    >
      <Input
        placeholder="Insira o CNPJ"
        type="masked"
        mask="cnpj"
      />
    </Item>
    <br />

    <Button
      block
      loading={loading}
      id="cnpj-enter-button"
      htmlType="submit"
    >
      Próximo
    </Button>
  </Form>
);

CnpjForm.propTypes = {
  form: PropTypes.shape(),
  onFinish: PropTypes.func,
  partner: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default CnpjForm;
