import CHECK_CREDENTIALS from './checkCredentials.gql';
import ABLE_TO_CREATE_NEW_LOAN from './ableToCreateNewLoan.gql';
import BUSINESS_PLATFORM_TYPES from './businessPlatformTypes.gql';
import BUSINESS_SEGMENTS from './businessSegments.gql';
import BUSINESS_SECTORS from './businessSectors.gql';
import ME from './me.gql';
import SAVE_LOAN from './saveLoan.gql';
import REASONS_FOR_LOAN from './reasonsForLoan.gql';

export {
  CHECK_CREDENTIALS,
  ABLE_TO_CREATE_NEW_LOAN,
  BUSINESS_PLATFORM_TYPES,
  BUSINESS_SECTORS,
  BUSINESS_SEGMENTS,
  ME,
  SAVE_LOAN,
  REASONS_FOR_LOAN,
};
