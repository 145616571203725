import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { createEvents } from 'ics';
import TagManager from 'react-gtm-module';
import {
  Button,
  Modal,
} from 'components/sdk';
import { downloadFile } from 'utils';
import { AppleOutlined, GoogleOutlined, WindowsOutlined } from '@ant-design/icons';
import './styles.less';

const ScheduleInstallmentsModal = ({
  visible, onClose, installmentsCount, installmentDate, installments,
}) => {
  const handleGenerateICS = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'addedInstallmentToCalendarEvent',
      },
    });

    const events = [];

    for (let i = 0; i < installments.length; i++) {
      const installmentDate = new Date(Date.parse(installments[i]?.dueDate));
      const eventMonth = installmentDate.getUTCMonth() + 1;
      const eventYear = installmentDate.getUTCFullYear();
      events.push({
        title: `Gyramais parcela (${i + 1}/${installmentsCount})`,
        start: [
          eventYear,
          eventMonth,
          installmentDate.getUTCDate(),
          9, 0,
        ],
        end: [
          eventYear,
          eventMonth,
          installmentDate.getUTCDate(),
          10, 0,
        ],
        alarms: [{
          action: 'display',
          description: `Lembrete Parcela ${i + 1} Gyra+`,
          trigger: { days: 1, before: true },
        }],
        description: 'Para pagar seu boleto, basta entrar em http://app.gyramais.com.br',
      });
    }

    const { value } = createEvents(events);

    const fileBlob = new Blob([value], {
      type: 'text/calendar',
    });

    downloadFile(fileBlob, 'Parcelas.ics');
  };

  const handleGoogleCalendar = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'addedInstallmentToCalendarEvent',
      },
    });

    const formatNumber = (number) => {
      if (number >= 10) {
        return number;
      }
      return `0${number}`;
    };

    const date = new Date(installmentDate);
    // Para testar local, adicione 3 horas na variavel date
    const year = date.getUTCFullYear();
    const month = formatNumber(date.getUTCMonth() + 1);
    const day = formatNumber(date.getUTCDate());

    const start = `${year}${month}${day}T130000Z`;
    const end = `${year}${month}${day}T140000Z`;
    const url = `https://calendar.google.com/calendar/r/eventedit?text=Lembrete+parcela+Gyra%2B&dates=${start}/${end}&trp=false&recur=RRULE:FREQ=MONTHLY;COUNT=${installmentsCount}`;
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Modal
      title="Marcar vencimento de parcelas no calendário"
      centered
      visible={visible}
      onClose={onClose}
      okButton={{
        text: 'Fechar',
        onClick: onClose,
      }}
    >
      <div className="schedule-installments-content">
        <div className="calendar-option-container">
          <Button
            link
            onClick={handleGoogleCalendar}
            data-cy="google-calendar-button"
          >
            <GoogleOutlined />
            Google Calendar
          </Button>
        </div>
        <div className="calendar-option-container">
          <Button
            link
            onClick={handleGenerateICS}
            data-cy="apple-calendar-button"
          >
            <AppleOutlined />
            Apple iCal/Calendar
          </Button>
        </div>
        <div className="calendar-option-container">
          <Button
            link
            onClick={handleGenerateICS}
            data-cy="windows-calendar-button"
          >
            <WindowsOutlined />
            Windows Calendar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ScheduleInstallmentsModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  installmentsCount: PropTypes.number,
  installmentDate: PropTypes.string,
  installments: PropTypes.arrayOf(PropTypes.shape({
    dueDate: PropTypes.string,
  })),
};

export default memo(ScheduleInstallmentsModal);
