import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.less';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Button = ({
  children, lg, sm, block, loading, disabled, color, className,
  style, link, onClick, htmlType, id, form, key, icon, text,
  onMouseEnter, onMouseLeave, href, target, 'data-cy': dataCy,
  bordered, action,
}) => {
  const iconToShow = () => {
    if (loading) {
      return <Spin indicator={antIcon} />;
    }

    if (icon) {
      return icon;
    }

    return null;
  };

  if (href || target) {
    return (
      <a
        id={id}
        href={href}
        target={target}
        className={
          `gyramais-btn${
            lg ? ' gyramais-btn-large' : ''
          }${
            block ? ' gyramais-btn-block' : ''
          }${
            loading ? ' gyramais-btn-loading' : ''
          }${
            color ? ` gyramais-btn-${color}` : ''
          }${
            link ? ' gyramais-btn-link' : ''
          }${
            bordered ? ' gyramais-btn-bordered' : ''
          }${
            icon ? ` gyramais-btn-icon ${!children ? 'only-icon' : ''}` : ''
          }${
            className ? ` ${className}` : ''
          }`
        }
        style={style}
        onClick={() => onClick?.()}
        data-cy={dataCy}
      >
        {iconToShow()}

        {children && (
          <span style={{ paddingLeft: iconToShow() ? '10px' : '' }}>
            {children}
          </span>
        )}
      </a>
    );
  }

  if (text) {
    return (
      <span
        tabIndex={0}
        role="button"
        onKeyDown={() => false}
        className={`gyramais-btn-text ${link ? 'gyramais-btn-text-link' : ''} ${className || ''}`}
        onClick={() => onClick?.()}
        data-cy={dataCy}
      >
        {children}
      </span>
    );
  }

  return (
    <button
      className={
        `gyramais-btn${
          lg ? ' gyramais-btn-large' : ''
        }${
          sm ? ' gyramais-btn-small' : ''
        }${
          block ? ' gyramais-btn-block' : ''
        }${
          loading ? ' gyramais-btn-loading' : ''
        }${
          color ? ` gyramais-btn-${color}` : ''
        }${
          link ? ' gyramais-btn-link' : ''
        }${
          bordered ? ' gyramais-btn-bordered' : ''
        }${
          icon ? ` gyramais-btn-icon ${!children ? 'only-icon' : ''}` : ''
        }${
          action ? ' gyramais-btn-action' : ''
        }${
          className ? ` ${className}` : ''
        }`
      }
      disabled={loading || disabled}
      style={style}
      onClick={() => onClick?.()}
      type={htmlType === 'submit' ? 'submit' : 'button'}
      id={id}
      key={key}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      form={form}
      data-cy={dataCy}
    >
      {iconToShow()}

      {children && (
      <span style={{ paddingLeft: iconToShow() ? '10px' : '' }}>
        {children}
      </span>
      )}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]),
  lg: PropTypes.bool,
  sm: PropTypes.bool,
  action: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.shape({}),
  link: PropTypes.bool,
  text: PropTypes.bool,
  bordered: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  htmlType: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.string,
  key: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  'data-cy': PropTypes.string,
};

export default Button;
