import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import App from 'components/App';
import client from 'services/graphql';

import 'styles/index.less';
import 'styles/sdk.less';

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);

// serviceWorker.register();
