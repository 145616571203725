// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyra-statement-card {
  padding: 1.875rem 2.5rem !important;
}
.gyra-statement-card .gyra-statement-label {
  font-size: 1.25rem;
}
.gyra-statement-card .gyra-statement-action {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .gyra-statement-card .gyra-statement-action .gyramais-btn {
    margin-top: 0.6rem;
    width: 100% !important;
  }
  .gyra-statement-card .gyra-statement-action .gyramais-btn .upload-button-label {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
}
.statements-inputs-card {
  padding: 1.875rem 2.5rem !important;
}
.statements-inputs-card .statement-input-area {
  padding: 0.4rem 0;
  border-radius: 1.2rem;
  transition: 0.3s;
}
.statements-inputs-card .statement-input-area:hover {
  background-color: #0001;
}
.statements-inputs-card .statement-input-area .statment-label {
  margin-top: 0.8rem;
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}
.hidden {
  display: hidden;
  position: absolute;
}
.form-footer {
  padding: 0 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/BusinessUploadForm/styles.less"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;AAFA;EAGI,kBAAA;AAEJ;AALA;EAMI,aAAA;EACA,yBAAA;AAEJ;AADI;EAAA;IAEI,kBAAA;IACA,sBAAA;EAGN;EANE;IAMM,aAAA;IACA,mBAAA;IACA,WAAA;IACA,uBAAA;EAGR;AACF;AAGA;EACE,mCAAA;AADF;AAAA;EAGI,iBAAA;EACA,qBAAA;EACA,gBAAA;AAAJ;AACI;EACE,uBAAA;AACN;AARA;EAUM,kBAAA;EACA,iBAAA;EACA,qBAAA;AACN;AAIA;EACE,eAAA;EACA,kBAAA;AAFF;AAKA;EACE,eAAA;AAHF","sourcesContent":[".gyra-statement-card {\n  padding: 1.875rem 2.5rem !important;\n  .gyra-statement-label {\n    font-size: 1.25rem;\n  }\n  .gyra-statement-action {\n    display: flex;\n    justify-content: flex-end;\n    @media(max-width: @md-screen) {\n      .gyramais-btn {\n        margin-top: .6rem;\n        width: 100% !important;\n\n        .upload-button-label {\n          display: flex;\n          align-items: center;\n          width: 100%;\n          justify-content: center;\n        }\n      }\n    }\n  }\n}\n\n.statements-inputs-card {\n  padding: 1.875rem 2.5rem !important;\n  .statement-input-area {\n    padding: .4rem 0;\n    border-radius: 1.2rem;\n    transition: .3s;\n    &:hover {\n      background-color: #0001;\n    }\n    .statment-label {\n      margin-top: .8rem;\n      font-size: 1.2rem;\n      margin-bottom: .6rem;\n    }\n  }\n}\n\n.hidden {\n  display: hidden;\n  position: absolute;\n}\n\n.form-footer {\n  padding: 0 1rem;\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
