/* eslint-disable react/prop-types */
import React from 'react';
import {
  Divider,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import { PessoalComemorando } from 'assets/images';
import { formatString } from 'utils';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

import './Modal.less';
/**
 * @type {React.FC}
  */
const InstallmentPaymentConfirmModal = () => {
  const {
    isRenegotiating,
    confirmModalVisible,
    currentDiscount,
    installmentCountSelected,
    currentInstallmentDownPayment,
    currentInstallmentAmount,
    closeConfirmModal,
    promoRenegotiateLoan,
    discountedAmount,
    noDiscount,
    loanAmount,
  } = usePromoRenegotiationContext();

  const title = currentDiscount > 0
    ? `Estamos quase lá! Renegocie sua dívida e ganhe ${currentDiscount}% de desconto!`
    : 'Estamos quase lá! Revise os dados e leia com atenção.';

  return (
    <Modal
      className="promo-renegotiate-modal"
      visible={confirmModalVisible}
      centered
      closable={false}
      okButton={{
        text: 'Renegociar',
        id: 'ok-button',
        loading: isRenegotiating,
        onClick: promoRenegotiateLoan,
        'data-cy': 'confirmation-renegotiate-button',
      }}
      cancelButton={{
        text: 'Cancelar',
        id: 'cancel-button',
        onClick: closeConfirmModal,
      }}
      data-cy="promo-renegotiate-modal"
      title={title}
    >
      <div className="promo-renegotiate-modal-content">
        <PessoalComemorando />

        <div className="body-text">
          <div className="summary-wrapper">
            <h4>Resumo da sua renegociação:</h4>

            <div className="summary-items">

              <div className="summary-item">
                <span>Valor da Entrada:</span>
                <b>{formatString(currentInstallmentDownPayment, 'currency')}</b>
              </div>

              <div className="summary-item">
                <span>Número de parcelas:</span>
                <b>{installmentCountSelected}</b> vezes
              </div>

              <div className="summary-item">
                <span>Valor das parcelas:</span>
                <b>{formatString(currentInstallmentAmount, 'currency')}</b>
              </div>

              <div className="summary-item">
                <span>Novo valor renegociado:</span>
                <b>{formatString(discountedAmount, 'currency')}</b>
              </div>

              {noDiscount && (
                <div className="summary-item">
                  <span>Juros adicional:</span>
                  <b>+{formatString(discountedAmount - loanAmount, 'currency')}</b>
                </div>
              )}
            </div>
          </div>

          <Divider />

          Após <b>clicar no botão abaixo</b> , você passará para
          a próxima etapa do processo
          de renegociação: <b>pagar o boleto de entrada</b>.
          Certifique-se de pagá-lo dentro do prazo estimado
          para que sua renegociação não seja cancelada.
          <br />
          <br />
          Ao <b>confirmarmos o pagamento</b>, enviaremos um novo contrato para
          o <b>responsável da empresa</b>, contendo todas
          as informações da renegociação. <b>Todas as partes</b> precisarão
          assinar o contrato para que a renegociação entre em vigor.
        </div>
      </div>
    </Modal>
  );
};

export default InstallmentPaymentConfirmModal;
