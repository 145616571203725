/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Collapse,
} from 'antd';
import { formatAndNotificateError } from 'utils';
import {
  Modal,
} from 'components/sdk';
import {
  Alert,
} from 'components/gyramais';
import {
  GET_BANK_SLIP_URL,
} from './graphql';

import './styles.less';

const { Panel } = Collapse;

/**
 * @type {React.FC<{
 *  visible: boolean
 *  onClose: () => void
 *  loan: {
 *    id: string
 *  }
 * }>}
 */
const BankSlipModal = ({
  visible,
  onClose,
  loan: {
    id,
  },
}) => {
  const [bankSlipURL, setBankSlipURL] = useState();
  const [getBankSlipUrl, { loading }] = useMutation(GET_BANK_SLIP_URL, {
    onCompleted: ({ getBankSlipUrl }) => {
      setBankSlipURL(getBankSlipUrl);
    },
    onError: (error) => formatAndNotificateError(error),
  });
  const [collapseText, setCollapseText] = useState(false);

  useEffect(() => {
    getBankSlipUrl({
      variables: {
        loanId: id,
      },
    });
  }, []);
  return (
    <Modal
      visible={visible}
      title="Encargo por Concessão de Garantia pelo Fundo de Aval do Estado"
      onClose={onClose}
      okButton={{
        text: 'Abrir boleto em uma nova aba',
        disabled: loading,
        onClick: () => {
          window.open(bankSlipURL, '_blank');
        },
      }}
      cancelButton={{
        text: 'Fechar',
        onClick: (e) => {
          e?.stopPropagation();
          onClose();
        },
      }}
      id="bank-slip-modal"
    >
      <Alert
        className="bank-slip-alert"
        message={(
          <Collapse
            ghost
            expandIconPosition="right"
            onChange={(e) => {
              setCollapseText(Boolean(e[0]));
            }}
            className="bank-slip-panel"
          >
            <Panel
              header="Por que tenho que pagar?"
              key="1"
              extra={collapseText ? 'Fechar' : 'Saiba mais'}
            >
              <div className="bank-slip-text">
                <p>
                  O FAE/SC foi criado para facilitar a concessão de crédito para empresas sem garantias reais.
                  <br />
                  Por esse uso, você empreendedor, fica responsável pelo pagamento do Encargo para Concessão de Garantia (ECG). O valor pago é exclusivamente relativo à garantia e não compõe os encargos remuneratórios da operação.
                  <br />
                  <br />
                  O ECG incide sobre o valor garantido da operação multiplicado por 0,0417% e pelo número de meses da operação, conforme fórmula abaixo:
                  <br />
                  <br />
                  ECG = VG x 0,0417% x Meses
                  <br />
                  <br />
                  Em que:
                  <br />
                  ECG = Encargo de garantia contratada pelo FAE-SC;
                  <br />
                  VG = Valor garantido pelo FAE-SC;
                  <br />
                  Meses = número de meses da operação.
                  <br />
                </p>
              </div>
            </Panel>
          </Collapse>
        )}
      />
    </Modal>
  );
};

export default BankSlipModal;
