// @ts-check
/* eslint-disable react/prop-types */
import { ClockCircleOutlined } from '@ant-design/icons';
import {
  Statistic,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import {
  Button,
} from '../../../components/sdk';
import { NewLoanButton } from '../../../components';

const { Countdown } = Statistic;
const date = new Date(new Date().setHours(0, 0, 0, 0));

/**
 * @typedef {'CopyBarcode'
 *  | 'PayDownPayment'
 *  | 'WinDiscount'
 *  | 'SpeakWithUs'
 *  | 'SignContract'
 *  | 'SendBankReceipt'
 *  | 'NewLoan'
 *  | 'OpenBlog'
 *  | 'CompleteRegister'
 *  | 'PayInvoice'
 * } HomeBtnAction
 */

/**
 * @type {React.FC<{
 *  loans: any[]
 *  pendingDownPaymentInvoice: any
 *  awaitingSignatureLoan: any
 *  invoiceDueDate: any
 *  flow: string
 *  showPromoSubHeader: boolean
 *  confirmLoading: boolean
 *  needToSign: boolean
 *  noDiscountPolicy: boolean
 *  installmentPaymentRealDiscount: number
 *  isBureau: number
 *  cashPaymentRealDiscount: number
 *  discount: number
 *  onClick: (action: HomeBtnAction) => void
 * }>}
 * @returns
 */
const HomeSubHeaderButton = ({
  loans,
  pendingDownPaymentInvoice,
  flow,
  showPromoSubHeader,
  installmentPaymentRealDiscount,
  cashPaymentRealDiscount,
  discount,
  confirmLoading,
  isBureau,
  awaitingSignatureLoan,
  invoiceDueDate,
  needToSign,
  noDiscountPolicy,
  onClick,
}) => {
  const { checkActions } = usePartnerActionsContext();

  const loanStatus = loans?.[0]?.status?.value;

  const copyInvoiceCodeBtn = (
    <Button
      loading={confirmLoading}
      color={`${showPromoSubHeader ? 'secondary' : ''}`}
      onClick={() => onClick('CopyBarcode')}
    >
      Copiar código do boleto
    </Button>
  );

  if (pendingDownPaymentInvoice && loanStatus === 'awaiting-signature-additive') {
    const overdueDownPayment = dayjs(pendingDownPaymentInvoice.dueDate).isBefore(new Date());

    // entrada já venceu, agora só precisamos esperar a rotina
    // de cancelar empréstimos rodar no dia seguinte
    // o usuário já não pode mais pagar essa entrada
    if (overdueDownPayment) {
      return null;
    }

    return (
      <div className="subheader-button-section">
        <Button
          className="button-promo"
          onClick={() => onClick('PayDownPayment')}
          data-cy="promo-payment-button"
        >
          Pagar boleto de entrada
        </Button>

        <div className="clock">
          <ClockCircleOutlined />

          <Countdown
            // @ts-ignore
            value={dayjs.utc(pendingDownPaymentInvoice.dueDate).endOf('day')}
            format="DD dia[s] HH:mm:ss"
          />
        </div>
      </div>
    );
  }

  if (showPromoSubHeader) {
    let buttonText = `Ganhar
      ${installmentPaymentRealDiscount && !cashPaymentRealDiscount ? ' até ' : ' '}
      ${discount} % de desconto`;

    if (noDiscountPolicy) {
      buttonText = 'Renegocie';
    }

    return (
      <div className="subheader-button-section">
        <Button
          className="button-promo"
          onClick={() => onClick('WinDiscount')}
          data-cy="renegotiate-page-button"
        >
          {buttonText}
        </Button>

        {copyInvoiceCodeBtn}
      </div>
    );
  }

  if (isBureau && !awaitingSignatureLoan) {
    return (
      <Button onClick={() => onClick('SpeakWithUs')}>
        Fale conosco
      </Button>
    );
  }

  if (checkActions(['only-score'])) return null;

  switch (loans?.[0]?.status?.value) {
    case 'active':
    case 'active-renegotiated':
      if (checkActions(['debit-authorization-term']) && date < invoiceDueDate) {
        return null;
      }
      return (
        <div className="subheader-button-section">
          {copyInvoiceCodeBtn}
        </div>
      );
    case 'awaiting-signature':
    case 'awaiting-signature-additive':
      if (!needToSign) break;

      return (
        <Button
          id="sub-header-sign-loan-button"
          onClick={() => onClick('SignContract')}
        >
          {`Assinar ${loans[0].status.value === 'awaiting-signature-additive'
            ? 'aditivo'
            : `${checkActions(['debit-authorization-term']) ? 'contratos' : 'contrato'}`}`}
        </Button>
      );
    case 'awaiting-re-evaluation-tm-volume':
      if (flow === 'badesc') {
        break;
      }
      return (
        <Button
          onClick={() => onClick('SendBankReceipt')}
        >
          Enviar extrato bancário
        </Button>
      );
    case 'expired':
    case 'canceled':
    case 'give-up':
      if (checkActions(['partner-analyze-credit', 'partner-is-banker']) || flow === 'olist') {
        break;
      }
      return (
        <Button
          onClick={() => onClick('NewLoan')}
        >
          Solicitar outra proposta
        </Button>
      );
    case 'verifying':
    case 'score':
    case 'registered':
    case 'final-verifying':
      if (checkActions(['partner-analyze-credit', 'partner-is-banker'])) {
        break;
      }

      if (flow === 'badesc') {
        break;
      }

      return (
        <div className="double-buttons">
          <Button
            color="secondary"
            onClick={() => onClick('OpenBlog')}
          >
            Visitar blog
          </Button>
        </div>
      );
    case 'pre-approved':
    case 'pending':
    case 'review':
      return (
        <Button
          onClick={() => onClick('CompleteRegister')}
        >
          Completar cadastro
        </Button>
      );
    case 'awaiting-payment':
      return (
        <Button
          onClick={() => onClick('PayInvoice')}
        >Pagar boleto
        </Button>
      );
    default:
      if (checkActions(['partner-analyze-credit', 'partner-is-banker']) || ['olist', 'badesc'].includes(flow)) {
        break;
      }
      return (
        <NewLoanButton
          onClick={() => onClick('NewLoan')}
        />
      );
  }

  return null;
};

export default React.memo(HomeSubHeaderButton);
