// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bank-account-optin-drawer .drawer-content {
  max-width: 42.1rem;
  align-self: center;
  justify-self: center;
  text-align: center;
}
#bank-account-optin-drawer .drawer-content svg {
  width: 30%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  #bank-account-optin-drawer .drawer-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/BankAccountOptinDrawer/styles.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EAEA,kBAAA;EACA,oBAAA;EACA,kBAAA;AADJ;AALA;EASM,UAAA;EACA,mBAAA;AADN;AAII;EAAA;IACE,kBAAA;IACA,QAAA;IACA,2BAAA;EADJ;AACF","sourcesContent":["#bank-account-optin-drawer {\n  .drawer-content {\n    max-width: 42.1rem;\n    \n    align-self: center;\n    justify-self: center;\n    text-align: center;\n\n    svg {\n      width: 30%;\n      margin-bottom: 2rem;\n    }\n    \n    @media(min-width: 768px) {\n      position: absolute;\n      top: 50%;\n      transform: translateY(-50%);\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
