import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  notification,
} from 'antd';
import {
  Drawer,
  Input,
  Information,
} from 'components/gyramais';
import { formatAndNotificateError, CPFValidator } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import {
  GET_USER_BY_CPF,
  SAVE_BUSINESS_USER,
} from './graphql';

import './styles.less';

const { Item, useForm } = Form;

const getContext = (type) => {
  const contexts = {
    socio: 'sócio(a)',
    avalista: 'avalista',
    representantelegal: 'representante legal',
  };

  const context = contexts[type];
  if (context) {
    return context;
  }

  return 'responsável';
};

const FindUserDrawer = ({
  visible,
  onClose,
  businessId,
  refetch,
  onAddNewUser,
  type,
  businessUsers,
}) => {
  const [form] = useForm();
  const [userSearched, setUserSearched] = useState(false);
  const [user, setUser] = useState();

  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: () => {
      notification.success({ message: `Novo ${getContext(type)} adicionado com sucesso` });
      refetch();
      form.resetFields();
      setUserSearched(false);
      setUser();
      onClose();
    },
    onError: (error) => {
      formatAndNotificateError(error);
    },
  });

  const [getUserByCPF, { loading }] = useMutation(GET_USER_BY_CPF, {
    onCompleted: ({
      userByCPF,
    }) => {
      setUserSearched(true);
      setUser(userByCPF);
    },
    onError: (error) => {
      setUserSearched(true);
      setUser();
      formatAndNotificateError(error);
    },
  });

  const handleClose = () => {
    form.resetFields();
    setUserSearched(false);
    setUser();
    onClose();
  };

  const handleSubmit = async () => {
    const { cpf } = form.getFieldsValue();

    const sanitizedCpf = cpf.replace(/\D/g, '');
    const businessUser = businessUsers.find(({ user }) => user?.cpf === sanitizedCpf);

    if (businessUser) {
      await saveBusinessUser({
        variables: {
          id: businessUser.id,
          typesValues: [...businessUser.types.map(({ value }) => value), type],
        },
      });

      return;
    }

    if (user) {
      await saveBusinessUser({
        variables: {
          businessId,
          cpf,
          typesValues: [type],
        },
      });

      return;
    }

    onAddNewUser(cpf);
    form.resetFields();
    setUserSearched(false);
    setUser();
  };

  const handleCpfChange = (e) => {
    const sanitizedCpf = e.target.value.replace(/\D/g, '');

    if (sanitizedCpf.length < 11) {
      setUserSearched(false);
      setUser();
    }

    if (sanitizedCpf.length === 11) {
      if (CPFValidator(sanitizedCpf)) {
        getUserByCPF({ variables: { cpf: e.target.value } });
      } else {
        notification.error({
          message: 'CPF inválido',
          description: 'Insira um CPF válido para continuar',
        });
        form.setFieldsValue({ cpf: '' });
      }
    }
  };

  return (
    <Drawer
      visible={visible}
      id="find-user-drawer"
      title={`Adicionar ${getContext(type)}`}
      onClose={handleClose}
      maxWidth="40rem"
      primaryButton={{
        loading: loading || saveLoading,
        id: 'continue-add-business-user-button',
        disabled: !userSearched,
        onClick: handleSubmit,
      }}
    >
      <div className="drawer-content">
        <Form
          layout="vertical"
          form={form}
        >
          <Row gutter={12}>
            <Col span={24}>
              <Item name="cpf" rules={[{ required: true, message: `Insira o CPF do ${getContext(type)}.` }]}>
                <Input
                  guide
                  mask="cpf"
                  placeholder="CPF"
                  onChange={handleCpfChange}
                />
              </Item>
            </Col>
          </Row>
        </Form>

        {userSearched && user && (
          <>
            <hr className="divider" />
            <h3>
              {`Deseja adicionar ${user.firstName} como ${getContext(type)}?`}
            </h3>
            <br />
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24}>
                <Information label="Nome" value={user.fullName} />
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Information label="Email" value={user.email} />
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Information label="Telefone celular" value={user.phoneNumber} />
              </Col>
            </Row>
          </>
        )}

        {userSearched && !user && (
          <>
            <hr className="divider" />
            <h3>
              Novo usuário
              <br />
              <br />
              Deseja adicioná-lo como
              {' '}
              {getContext(type)}
              ?
            </h3>
          </>
        )}
      </div>
    </Drawer>
  );
};

FindUserDrawer.propTypes = {
  businessId: PropTypes.string,
  type: PropTypes.string,
  refetch: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  businessUsers: PropTypes.arrayOf(PropTypes.shape()),
};

export default FindUserDrawer;
