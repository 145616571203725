// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyramais-footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  min-height: 1.25rem;
  background-color: var(--primary-color);
  z-index: 99 !important;
  color: var(--primary-text-color);
}
.gyramais-footer .gyramais-footer-content {
  max-width: 1598px;
  margin: auto;
}
.gyramais-footer .gyramais-footer-content .gyramais-btn-link {
  color: var(--primary-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/Footer/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,SAAA;EAEA,mBAAA;EACA,sCAAA;EAEA,sBAAA;EACA,gCAAA;AADF;AARA;EAYI,iBAAA;EACA,YAAA;AADJ;AAZA;EAgBM,gCAAA;AADN","sourcesContent":[".gyramais-footer {\n  width: 100%;\n  position: sticky;\n  bottom: 0;\n\n  min-height: 1.25rem;\n  background-color: var(--primary-color);\n  \n  z-index: 99 !important;\n  color: var(--primary-text-color);\n\n  .gyramais-footer-content {\n    max-width: @content-max-width;\n    margin: auto;\n\n    .gyramais-btn-link {\n      color: var(--primary-text-color);\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
