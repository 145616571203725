// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#clients-comments .clients-comments-item .comment-date {
  color: #959595;
  font-size: 0.875rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/sections/ClientsComments/styles.less"],"names":[],"mappings":"AAAA;EAGM,cAAA;EACA,mBAAA;AADN","sourcesContent":["#clients-comments{\n  .clients-comments-item{\n    .comment-date {\n      color: #959595;\n      font-size: 0.875rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
