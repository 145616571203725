import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
} from 'components/sdk';
import { ABLE_TO_CREATE_NEW_LOAN } from './graphql';

const NewLoanButton = ({ ...props }) => {
  const [isAbleToCreateNewLoan, setIsAbleToCreateNewLoan] = useState(false);
  const [CheckIfIsAbleToCreateNewLoan] = useMutation(ABLE_TO_CREATE_NEW_LOAN, {
    onCompleted: ({ ableToCreateNewLoan }) => {
      setIsAbleToCreateNewLoan(ableToCreateNewLoan);
    },
  });

  useEffect(() => {
    CheckIfIsAbleToCreateNewLoan();
  }, []);

  return (
    <Button
      disabled={!isAbleToCreateNewLoan}
      {...props}
    >
      Novo empréstimo
    </Button>
  );
};

NewLoanButton.propTypes = Button.propTypes;

export default NewLoanButton;
