import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import {
  getRedirectId, getReintegrationId, setGyraRedirectPath, setRedirectId,
} from '../../../../services/graphql/cache';

import {
  SAVE_INTEGRATION,
  ME,
} from '../graphql';
import { useHelpers } from './helper-hook';

const MERCADO_LIVRE_TYPE_ID = 'oQbIiRAQqJ';
const MERCADO_PAGO_TYPE_ID = 'TjRV79t4oE';

// eslint-disable-next-line import/prefer-default-export
export const useRedirectIntegration = ({
  carouselRef,
  internalIntegrationType,
  handleClose,
  handleSuccessIntegration,
  setInternalVisible,
  setInternalReintegrationId,
}) => {

  const { pathname } = useLocation();
  const { currentContext } = useBusinessLoanContext();
  const { checkActions } = usePartnerActionsContext();

  const {
    getStep,
    goToWaitingDrawerToOpen,
    clearQueryParams,
    hasPlatformSelectionStep,
    handleRemoveRedirectInfo,
  } = useHelpers({
    carouselRef,
    internalIntegrationType,
  });

  const {
    data: {
      me,
      me: {
        currentBusiness: {
          id: currentBusinessId,
        } = {},
      } = {},
    } = {},
  } = useQuery(ME);

  const [saveIntegrationRedirect, { loading: loadingRedirect }] = useMutation(SAVE_INTEGRATION, {
    onCompleted: () => {
      handleSuccessIntegration?.();
      handleRemoveRedirectInfo();
      clearQueryParams();
      setInternalVisible(true);
      setInternalReintegrationId(null);
      goToWaitingDrawerToOpen(getStep('success'));
    },
    onError: ((error) => {
      notification.error({
        message: error.message,
      });
      handleRemoveRedirectInfo();
      clearQueryParams();
      handleClose();
    }),
  });

  useEffect(() => {
    if (!loadingRedirect) {
      return;
    }

    setInternalVisible(true);
    goToWaitingDrawerToOpen(getStep('loading-redirect'));
  }, [loadingRedirect]);

  const finishRedirectIntegration = (queryParams) => {
    if (!currentBusinessId) {
      return;
    }

    if (!me) {
      return;
    }

    const redirectIdExists = Boolean(getRedirectId());
    const reintegrationIdVarExists = Boolean(getReintegrationId());

    if (!redirectIdExists && !reintegrationIdVarExists) {
      return;
    }

    const {
      legacyId,
      currentBusiness: {
        approvedAt,
        completedAt,
        loans = [],
      },
    } = me;

    const { partner } = currentContext;

    const hasIntegrationPermission = checkActions(['order-upload', 'payment-upload', 'invoice-upload']);
    const notLegacyOrLegacyApproved = !legacyId || (legacyId && approvedAt && !completedAt);
    const urlContainsCadastro = pathname.split('/').includes('cadastro');
    const hasLoans = loans.length > 0;
    const partnerWithoutIntegration = !hasIntegrationPermission;

    const hasLoansWithStatus = (statuses) => loans
      .filter(({ status }) => statuses.includes(status?.value)).length > 0;

    if (notLegacyOrLegacyApproved
      && !urlContainsCadastro
      && hasLoans
      && hasLoansWithStatus(['new', 'lead', 'bank-account'])
      && (!partner || partnerWithoutIntegration)) {
      return;
    }

    const integrationId = getReintegrationId();
    const isMercadoLivreOrPago = [
      MERCADO_LIVRE_TYPE_ID,
      MERCADO_PAGO_TYPE_ID,
    ].includes(getRedirectId());

    // deu erro na integração
    // Exmplo: usuário negou a permissão
    if (queryParams?.error) {
      handleRemoveRedirectInfo();
      clearQueryParams();

      if (hasPlatformSelectionStep) {
        setInternalVisible(true);
      }
      return;
    }

    if (!queryParams.code && isMercadoLivreOrPago) {
      handleRemoveRedirectInfo();
      clearQueryParams();

      // TODO mostrar um modal para deixar o usuário decidir se ele quer cancelar a integração
      // TODO ou se quer abirar a aba de integração novamente
      if (hasPlatformSelectionStep) {
        setInternalVisible(true);
        return;
      }

      handleClose();
      return;
    }

    const typeId = getRedirectId();
    handleRemoveRedirectInfo();
    clearQueryParams();

    saveIntegrationRedirect({
      variables: {
        id: integrationId,
        businessId: currentBusinessId,
        typeId,
        credentials: JSON.stringify(queryParams),
      },
    });
  };

  const handleIntegrationWishRedirect = (integration) => {
    setRedirectId(integration.id);
    setGyraRedirectPath(pathname);
    window.location = integration.redirect;
  };

  return {
    handleIntegrationWishRedirect,
    finishRedirectIntegration,
    currentBusinessId,
    loadingRedirect,
  };
};
