// @ts-check

/**
 * @param {Object[]} loans
 * @returns {Boolean}
 */
const needToGetToRegisterScreen = (loans) => {
  const loan = loans.find((loan) => [
    'pending',
    'review',
    'pending-bank-account',
    'pending-users',
    'pending-docs',
    'pending-renegotiated',
    'pending-business-infos',
    'check-documents',
  ].includes(loan.status?.value));
  return Boolean(loan);
};
const needToGetToPreApprovedScreen = (loans) => {
  const loan = loans.find((loan) => ['pre-approved'].includes(loan.status?.value));
  return Boolean(loan);
};

module.exports = {
  needToGetToRegisterScreen,
  needToGetToPreApprovedScreen,
};
