import CREATE_CAF_SIGNATURE from './createCAFSignature.gql';
import CREATE_VERIFICATION_CODE from './createVerificationCode.gql';
import LOAN from './loan.gql';
import SAVE_SIGNATURE from './saveSignature.gql';
import VERIFY_CODE from './verifyCode.gql';
import SAVE_USER from './saveUser.gql';
import SAVE_OPTIN from './saveOptIn.gql';
import SETTINGS from './settings.graphql';
import STARTING_SIGNATURE_BY_FACE_MATCH from './startingSignatureByFaceMatch.graphql';
import MARK_AS_PHYSICAL_PRINTED from './markAsPhysicalPrinted.gql';

export {
  CREATE_VERIFICATION_CODE,
  LOAN,
  SAVE_SIGNATURE,
  VERIFY_CODE,
  SAVE_USER,
  SAVE_OPTIN,
  SETTINGS,
  STARTING_SIGNATURE_BY_FACE_MATCH,
  CREATE_CAF_SIGNATURE,
  MARK_AS_PHYSICAL_PRINTED,
};
