// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loans-screen .loans-back-button {
  margin-bottom: 0.875rem;
}
#loans-screen .loans-back-button .back-icon {
  margin-right: 0.5rem;
}
#loans-screen .loans-loading {
  width: 100%;
  height: 15rem;
  border-radius: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/screens/LoansScreen/styles.less"],"names":[],"mappings":"AAAA;EAEI,uBAAA;AAAJ;AAFA;EAIM,oBAAA;AACN;AALA;EAQI,WAAA;EACA,aAAA;EACA,sBAAA;AAAJ","sourcesContent":["#loans-screen {\n  .loans-back-button {\n    margin-bottom: .875rem;\n    .back-icon {\n      margin-right: .5rem;\n    }\n  }\n  .loans-loading {\n    width: 100%;\n    height: 15rem;\n    border-radius: .25rem;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
