/* eslint-disable react/prop-types */
import React from 'react';
import {
  Select,
  Row,
  Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import {
  Input,
} from 'components/gyramais';

const { Option } = Select;

/**
 * @type {React.FC<{
 *  onChangeInput: (value: any) => void
 *  selectItems?: string[]
 *  onChangeSelect: (value: string) => void
 *  onlySearch?: boolean
 * }>}
 */
const SearchBar = ({
  onChangeInput,
  connectors,
  onChangeSelect,
  onlySearch = false,
}) => {
  const everyType = connectors?.reduce((types, connector) => {
    types.add(connector.type);

    return types;
  }, new Set());

  return (
    <div className="search-bar">
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col className="search-col" xs={24} sm={onlySearch ? 24 : 12} md={onlySearch ? 24 : 8} lg={onlySearch ? 24 : 6}>
          <Input
            className="input"
            suffix={<SearchOutlined />}
            placeholder="Insira o nome da plataforma"
            onChange={({ target: { value } }) => onChangeInput(value)}
          />
        </Col>

        {!onlySearch && (
          <Col className="selector" xs={24} sm={12} md={8} lg={6}>
            <Select
              className="gyramais-input select-item"
              defaultValue=""
              onChange={onChangeSelect}
            >
              <Option value="">Todas</Option>
              {[...everyType].map((type) => (
                <Option key={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
      <br />
    </div>
  );
};

export default SearchBar;
