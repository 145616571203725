import React from 'react';
import {
  Row,
  Col,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import './styles.less';

const { Option } = Select;
const ethnic = [
  'Branca',
  'Preta',
  'Parda',
  'Amarela',
  'Indígena',
  'Prefiro não declarar',
];

const RacialSearchForm = ({ setEthnicGroup }) => (
  <div id="racialserach-form">
    <div className="racial-search-body">
      <h4>
        Antes de concluir, precisamos de sua ajuda!
      </h4>
      <Row>
        <Col md={12}>
          <p>
            <b>
              Voce já foi aprovado e o seu contrato está aguardando para ser assinado,
              mas precisamos que você ajude em nossa pesquisa antes de prosseguir.
            </b>
          </p>
          <h6 className="no-margin-bottom secondary-text">
            Qual é sua raça ou grupo étnico?
          </h6>
          <Select
            className="gyramais-input"
            placeholder="Escolha uma opção"
            onChange={(value) => setEthnicGroup(value.toString())}
          >
            {ethnic.map((ethnicGroup) => (
              <Option key={ethnicGroup}>{ethnicGroup}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  </div>
);

RacialSearchForm.propTypes = {
  setEthnicGroup: PropTypes.func,
};

export default RacialSearchForm;
