// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-renegotiate-modal .promo-renegotiate-modal-content {
  font-size: 1.125rem;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
}
.promo-renegotiate-modal .promo-renegotiate-modal-content b {
  font-weight: 500;
  color: var(--primary-button-color);
}
.promo-renegotiate-modal .promo-renegotiate-modal-content .body-text {
  margin-top: 2rem;
}
.promo-renegotiate-modal .promo-renegotiate-modal-content .body-text ul {
  padding-left: 0.625rem;
}
.promo-renegotiate-modal .promo-renegotiate-modal-content .body-text .summary-item span {
  min-width: 14rem;
  display: inline-block;
}
@media (max-width: 768px) {
  .promo-renegotiate-modal .promo-renegotiate-modal-content .body-text .summary-item {
    font-size: 16px;
  }
  .promo-renegotiate-modal .promo-renegotiate-modal-content .body-text .summary-item span {
    min-width: 12.5rem;
  }
}
.promo-renegotiate-modal .promo-renegotiate-modal-content .body-text .summary-wrapper {
  text-align: center;
}
.promo-renegotiate-modal .promo-renegotiate-modal-content .body-text .summary-items {
  display: inline-block;
  text-align: initial;
}
@media (max-width: 768px) {
  .promo-renegotiate-modal .promo-renegotiate-modal-content .body-text {
    margin-bottom: 1rem;
  }
}
.promo-renegotiate-modal .cancel-button {
  background: var(--secondary-button-color) !important;
  color: var(--secondary-button-text-color) !important;
}
@media (max-width: 768px) {
  .promo-renegotiate-modal .cancel-button {
    margin-top: 1rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/PromoRenegotiationScreen/Components/Modal.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAPA;EAUM,gBAAA;EACA,kCAAA;AAAN;AAXA;EAeM,gBAAA;AADN;AAdA;EAkBQ,sBAAA;AADR;AAjBA;EAuBU,gBAAA;EACA,qBAAA;AAHV;AAMQ;EAAA;IACE,eAAA;EAHR;EAEM;IAII,kBAAA;EAHV;AACF;AA7BA;EAqCQ,kBAAA;AALR;AAhCA;EAyCQ,qBAAA;EACA,mBAAA;AANR;AASM;EAAA;IACE,mBAAA;EANN;AACF;AAzCA;EAqDI,oDAAA;EACA,oDAAA;AATJ;AAWI;EAAA;IACE,2BAAA;EARJ;AACF","sourcesContent":[".promo-renegotiate-modal {\n  .promo-renegotiate-modal-content {\n    font-size: 1.125rem;\n    line-height: 120%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-weight: 400;\n    \n    b {\n      font-weight: 500;\n      color: var(--primary-button-color)\n    }\n\n    .body-text {\n      margin-top: 2rem;\n\n      ul {\n        padding-left: .625rem;\n      }\n\n      .summary-item {\n        span {\n          min-width: 14rem;\n          display: inline-block;\n        }\n\n        @media(max-width: @md-screen) {\n          font-size: 16px;\n\n          span {\n            min-width: 12.5rem;\n          }\n        }\n      }\n\n      .summary-wrapper{\n        text-align: center;\n      }\n\n      .summary-items {\n        display: inline-block;\n        text-align: initial;\n      }\n\n      @media(max-width: @md-screen) {\n        margin-bottom: 1rem;\n      }\n\n    }\n  }\n\n  .cancel-button {\n    background: var(--secondary-button-color) !important;\n    color: var(--secondary-button-text-color) !important;\n\n    @media(max-width: @md-screen) {\n      margin-top: 1rem !important;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
