// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.domicile-bank-account-step-container .domicile-bank-account-card {
  height: 100%;
}
.domicile-bank-account-step-container .adding-account-icon {
  color: #0BB599 !important;
}
.domicile-bank-account-step-container .cancel-icon {
  color: var(--danger-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/SignatureFeatureDrawer/steps/PrepaymentBankAccount/styles.less"],"names":[],"mappings":"AAAA;EAGI,YAAA;AADJ;AAFA;EAOI,yBAAA;AAFJ;AALA;EAWI,qCAAA;AAHJ","sourcesContent":[".domicile-bank-account-step-container {\n  \n  .domicile-bank-account-card {\n    height: 100%;\n  }\n\n  .adding-account-icon {\n    color: #0BB599 !important;\n  }\n  \n  .cancel-icon {\n    color: var(--danger-color) !important;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
