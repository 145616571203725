/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal,
} from 'components/sdk';
import { PessoalComemorando } from 'assets/images';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

import './Modal.less';

const CashPaymentSuccessModal = () => {
  const {
    successModalVisible,
    roundedCashDiscount,
    pendingInvoice,
    closeSuccessModal,
  } = usePromoRenegotiationContext();

  return (
    <Modal
      className="promo-renegotiate-modal"
      visible={successModalVisible}
      centered
      closable={false}
      title={`Sucesso! Você renegociou sua dívida e ganhou ${roundedCashDiscount}% de desconto!`}
      okButton={{
        text: 'Visualizar boleto',
        href: pendingInvoice?.url,
        target: '_blank',
        onClick: closeSuccessModal,
      }}
    >
      <div className="promo-renegotiate-modal-content">
        <PessoalComemorando />
        <div className="body-text">
          Seu contrato foi
          {' '}
          <b>renegociado com sucesso!</b>
          {' '}
          Agora, seu último passo é
          {' '}
          <b>pagar o boleto</b>
          {' '}
          antes que o tempo acabe.
          Você terá
          {' '}
          <b>3 dias para pagá-lo</b>
          , por isso,
          não perca tempo e reabilite seu nome agora mesmo!
        </div>
      </div>
    </Modal>
  );
};

export default CashPaymentSuccessModal;
