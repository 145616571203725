import HowItWorks from './HowItWorks';
import RequestRules from './RequestRules';
import NeededDocs from './NeededDocs';
import AboutGyra from './AboutGyra';
import ClientsComments from './ClientsComments';
import OlistFaq from './OlistFaq';

export {
  HowItWorks,
  RequestRules,
  NeededDocs,
  AboutGyra,
  ClientsComments,
  OlistFaq,
};
