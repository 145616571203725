/* eslint-disable no-else-return */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  notification,
  Steps,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import {
  formatAndNotificateError,
} from 'utils';
import { useMutation } from '@apollo/client';
import {
  SAVE_OPTIN,
  GENERATE_PROOF_OF_CIVIL_STATUS,
} from './graphql';
import {
  OptInStep,
  ProofStep,
} from './steps';
import './styles.less';

const { Step } = Steps;

const BadescFlowModal = ({
  visible,
  logo,
  civilStatus,
  user,
  onClose,
}) => {
  const [checkbox, setCheckBox] = useState();
  const [current, setCurrent] = useState(0);
  const [ipLoading, setIpLoading] = useState(false);
  const [acceptedCheckbox, setAcceptedCheckbox] = useState(false);

  const [saveOptIn, { loading }] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      if (['solteiro', 'uniao-estavel'].includes(civilStatus)) {
        setCurrent(1);
        setAcceptedCheckbox(false);
      } else onClose();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [generateProofOfCivilStatus,
    { loading: businessUserLoading }] = useMutation(GENERATE_PROOF_OF_CIVIL_STATUS, {
    onCompleted: () => {
      onClose();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const handleAccept = () => {
    if (!acceptedCheckbox) {
      if (current === 0) {
        if (checkbox) {
          checkbox.focus();
          notification.error({ message: 'Aceite o termo de política de privacidade', duration: 10 });
        } else {
          notification.error({
            message: 'Aceite o termo de política de privacidade',
            description: 'Para aceitar o termo, desça até o final do texto e clique para checar a opção na qual você concorda com o termo ',
          });
        }
      } else if (current === 1) {
        notification.error({
          message: 'Confirme o termo de declaração',
        });
      }
    } else {
      setIpLoading(true);
      fetch('https://api.ipify.org/?format=json')
        .then((response) => response.json())
        .then(async ({ ip }) => {
          if (current === 0) {
            await saveOptIn({
              variables: { ip },
            });
          } else if (current === 1) {
            await generateProofOfCivilStatus({
              variables: {
                ip,
              },
            });
          }
          setIpLoading(false);
        })
        .catch(() => { notification.error({ message: 'Não conseguimos localizar seu IP' }); });
    }
  };

  const steps = [
    {
      title: 'Termo',
      content: (
        <OptInStep
          acceptedCheckbox={acceptedCheckbox}
          setAcceptedCheckbox={setAcceptedCheckbox}
          setCheckBox={setCheckBox}
          loading={loading || ipLoading}
          handleAccept={handleAccept}
        />
      ),
    },
    {
      title: 'Declaração',
      content: (
        <ProofStep
          acceptedCheckbox={acceptedCheckbox}
          setAcceptedCheckbox={setAcceptedCheckbox}
          setCheckBox={setCheckBox}
          loading={businessUserLoading || ipLoading}
          handleAccept={handleAccept}
          user={user}
        />
      ),
    },
  ];
  const stepsWithoutProof = [
    {
      title: 'Termo',
      content: (
        <OptInStep
          acceptedCheckbox={acceptedCheckbox}
          setAcceptedCheckbox={setAcceptedCheckbox}
          setCheckBox={setCheckBox}
          loading={loading || ipLoading}
          handleAccept={handleAccept}
        />
      ),
    },
  ];

  useEffect(() => {
    if (['solteiro', 'uniao-estavel'].includes(civilStatus)) {
      if (current === 0 && user?.optIns?.some(({ type }) => type === 'BADESC')) return setCurrent(1);
      if (current === 1 && user?.proofOfCivilStatus) return onClose();
    }

    if (current === 0 && user?.optIn) return onClose();
    return true;
  }, [current, user]);
  return (
    <Modal
      style={{ whiteSpace: 'pre-line' }}
      title={(
        <>
          <img src={logo} alt="logo" className="badesc-flow-logo" />
        </>
      )}
      centered
      closable={false}
      className="badesc-flow-modal"
      visible={visible}
      hideFooter
    >
      <Steps current={current} className="badesc-flow-steps">
        {(['solteiro', 'uniao-estavel'].includes(civilStatus) ? steps : stepsWithoutProof).map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="badesc-flow-steps-content">{(['solteiro', 'uniao-estavel'].includes(civilStatus) ? steps : stepsWithoutProof)[current].content}</div>
    </Modal>
  );
};

BadescFlowModal.propTypes = {
  visible: PropTypes.bool,
  logo: PropTypes.string,
  civilStatus: PropTypes.string,
  user: PropTypes.shape(),
  onClose: PropTypes.func,
};

export default memo(BadescFlowModal);
