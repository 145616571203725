import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
} from 'antd';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import './styles.less';

const levels = [{
  label: 'Muito pequenas',
  color: 'not',
}, {
  label: 'Pequenas',
  color: 'danger',
}, {
  label: 'Medianas',
  color: 'more-warning',
}, {
  label: 'Boas',
  color: 'warning',
}, {
  label: 'Muito Boas',
  color: 'primary',
}, {
  label: 'Excelentes',
  color: 'success',
}];

const LoanChances = ({
  business,
  setChances,
  className,
  necessaryDocumentsFlow,
}) => {
  const [progress, setProgress] = useState(0);
  const [labelIndex, setLabelIndex] = useState(0);

  const { actions, checkActions } = usePartnerActionsContext();

  useEffect(() => {
    setTimeout(() => {
      setLabelIndex(progress);
    }, 2000);
  }, [progress]);

  useEffect(() => {
    let businessProgress = 0;

    if (business) {
      const {
        name,
        legalName,
        bylaws,
        bankAccount,
        address,
        users,
        images,
        majorityPartnerSelfie,
        segment,
      } = business;

      const hasBusinessInfos = Boolean(name && legalName && address?.id);
      if (hasBusinessInfos) businessProgress += 1;

      const hasBusinessDocs = Boolean(bylaws && (address?.proofOfAddress || necessaryDocumentsFlow !== 'B'));
      if (hasBusinessDocs) businessProgress += 1;

      const hasBankAccount = Boolean(bankAccount) || checkActions(['partner-is-banker']);
      if (hasBankAccount) businessProgress += 1;

      const hasBusinessUsers = Boolean(users.length > 0
        && users.length === users.filter(({ completedAt }) => completedAt).length);
      if (hasBusinessUsers) businessProgress += 1;

      const hasExtraInfos = Boolean(
        (majorityPartnerSelfie || necessaryDocumentsFlow !== 'B')
        && (segment.value === '2' || (segment.value !== '2' && images.length > 0)),
      );
      if (hasExtraInfos) businessProgress += 1;
    }

    setProgress(businessProgress);
    setChances?.(businessProgress);
  }, [business, actions]);

  return (
    <div id="loan-chances" className={className || ''}>
      <div className="loan-chances-title-container">
        <h4 className="no-margin-bottom white-label-text">Chances de Aprovação</h4>
        <Tooltip
          title="Baseada na quantidade de informações preenchidas"
          placement="topLeft"
        >
          <InfoCircleOutlined className="icon" />
        </Tooltip>
      </div>
      <h6 className="white-label-text">{levels[labelIndex].label}</h6>
      <div className="loan-chances-pointer-container">
        {levels.map((item, index) => index !== 0 && (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`loan-chances-pointer color-${levels[progress].color} ${progress >= index ? 'active' : ''}`}
          >
            <div className="loan-chances-pointer-handler" />
          </div>
        ))}
      </div>
    </div>
  );
};

LoanChances.propTypes = {
  business: PropTypes.shape(),
  setChances: PropTypes.func,
  className: PropTypes.string,
  necessaryDocumentsFlow: PropTypes.string,
};

export default memo(LoanChances);
