import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'antd';

import './styles.less';

const ProposalCard = ({
  logo,
  name,
  datacy,
  id,
  onClick,
}) => (
  <Card
    onClick={() => onClick?.()}
    id={id}
    className="proposal-card"
    data-cy={datacy}
  >
    {logo ? <img src={logo.url} alt={name} /> : <p className="proposal-number">{name}</p>}
  </Card>
);

ProposalCard.propTypes = {
  logo: PropTypes.shape(),
  name: PropTypes.string,
  datacy: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.shape(
    {
      name: PropTypes.string,
      colors: PropTypes.arrayOf(PropTypes.string),
      parent: PropTypes.shape(
        {
          name: PropTypes.string,
          colors: PropTypes.arrayOf(PropTypes.string),
        },
      ),
    },
  ),
  onClick: PropTypes.func,
};

export default memo(ProposalCard);
