/* eslint-disable react/prop-types */
import React from 'react';
import {
  ClockCircleOutlined,
} from '@ant-design/icons';
import {
  Statistic,
} from 'antd';
import {
  Button,
} from 'components/sdk';
import {
  Card,
  Status,
  Information,
} from 'components/gyramais';

import './PromoRenegotiatedInvoice.less';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

dayjs.extend(utc);

const { Countdown } = Statistic;

/**
 * @type {React.FC}
 */
const PromoRenegotiatedInvoice = () => {
  const {
    pendingInvoice,
  } = usePromoRenegotiationContext();

  const daysDiff = dayjs(pendingInvoice?.dueDate).diff(dayjs(), 'days');

  return (
    <Card className="invoice-card" data-cy="invoice-card">
      <div className="invoice-title">
        <div className="invoice-title-container">
          <h3 className="no-margin-bottom">Renegociação</h3>
        </div>
        <Status value="pending" name="PAGAMENTO PENDENTE" />
      </div>

      <br />

      <div className="invoice-infos">
        <div className="left-side">
          <Information
            label="Valor"
            type="currency"
            size="large"
            value={pendingInvoice?.amount}
          />

          <Information
            label="Prazo"
            size="large"
            value={`${daysDiff} ${daysDiff > 1 ? 'dias' : 'dia'}`}
          />

          <Information
            label="Vencimento do boleto"
            size="large"
            value={(
              <div className="clock">
                <ClockCircleOutlined />
                <Countdown
                  value={dayjs.utc(pendingInvoice.dueDate).endOf('day')}
                  format="DD dia[s] HH:mm:ss"
                />
              </div>
            )}
          />
        </div>

        <Button
          href={pendingInvoice.url}
          target="_blank"
        >
          Visualizar boleto
        </Button>
      </div>
    </Card>
  );
};

export default PromoRenegotiatedInvoice;
