// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#promo-renegotiation-screen .loading-row {
  width: 100% !important;
}
#promo-renegotiation-screen .loading-row .ant-skeleton,
#promo-renegotiation-screen .loading-row .ant-skeleton-button {
  width: 100%;
  min-height: 21.5rem;
  border-radius: 1.25rem;
}
@media (max-width: 768px) {
  #promo-renegotiation-screen .ant-layout-content {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/PromoRenegotiationScreen/styles.less"],"names":[],"mappings":"AAAA;EAEI,sBAAA;AAAJ;AAFA;;EAKM,WAAA;EACA,mBAAA;EACA,sBAAA;AACN;AAII;EAAA;IACE,oBAAA;IACA,qBAAA;EADJ;AACF","sourcesContent":["#promo-renegotiation-screen {\n  .loading-row {\n    width: 100% !important;\n\n    .ant-skeleton, .ant-skeleton-button {\n      width: 100%;\n      min-height: 21.5rem;\n      border-radius: @border-radius;\n    }\n  }\n\n  .ant-layout-content {\n    @media(max-width: @md-screen) { \n      padding-left: 0.7rem;\n      padding-right: 0.7rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
