// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-approved-card {
  animation: up_entry 1 2s;
}
.pre-approved-card .pre-approved-progress {
  transform: scale(1.4);
  margin-top: 1rem;
  margin-bottom: 2.8rem;
}
.pre-approved-card .pre-approved-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pre-approved-card .pre-approved-progress-container .pre-approved-progress-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 8rem;
  position: absolute;
  margin-bottom: 1.4rem;
}
.pre-approved-card .pre-approved-progress-container .pre-approved-progress-content .pre-approved-progress-label {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.875rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .pre-approved-card .pre-approved-progress-container .pre-approved-progress-content .pre-approved-progress-label {
    margin-bottom: 0.3125rem;
  }
}
.pre-approved-card .pre-approved-vertical-divider {
  height: 16rem;
}
@media (max-width: 768px) {
  .pre-approved-card .pre-approved-progress {
    transform: scale(1.1);
    margin-top: 0;
    margin-bottom: 1.8rem;
  }
}
@keyframes up_entry {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/PreApprovedBadescCard/styles.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AAFA;EAII,qBAAA;EACA,gBAAA;EACA,qBAAA;AACJ;AAPA;EAUI,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAZA;EAeM,aAAA;EACA,sBAAA;EACA,kBAAA;EAEA,WAAA;EACA,kBAAA;EACA,qBAAA;AADN;AApBA;EAwBQ,mBAAA;EACA,gBAAA;EACA,qBAAA;EAEA,mBAAA;AAFR;AAIQ;EAAA;IACE,wBAAA;EADR;AACF;AA/BA;EAsCI,aAAA;AAJJ;AAOE;EAAA;IAEI,qBAAA;IACA,aAAA;IACA,qBAAA;EALJ;AACF;AASA;EACE;IACE,UAAA;IACA,2BAAA;EAPF;EASA;IACE,UAAA;EAPF;EASA;IACE,UAAA;EAPF;EASA;IACE,UAAA;IACA,wBAAA;EAPF;AACF","sourcesContent":[".pre-approved-card {\n  animation: up_entry 1 2s;\n\n  .pre-approved-progress {\n    transform: scale(1.4);\n    margin-top: 1rem;\n    margin-bottom: 2.8rem;\n  }\n\n  .pre-approved-progress-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .pre-approved-progress-content {\n      display: flex;\n      flex-direction: column;\n      text-align: center;\n\n      width: 8rem;\n      position: absolute;\n      margin-bottom: 1.4rem;\n  \n      .pre-approved-progress-label {\n        font-size: .875rem;\n        font-weight: 500;\n        line-height: .875rem;\n    \n        margin-bottom: 1rem;\n\n        @media(max-width: 768px) {\n          margin-bottom: 0.3125rem;\n        }\n      }\n    }\n  }\n\n  .pre-approved-vertical-divider {\n    height: 16rem\n  }\n\n  @media(max-width: 768px) {\n    .pre-approved-progress {\n      transform: scale(1.1);\n      margin-top: 0;\n      margin-bottom: 1.8rem;\n    }\n  }\n}\n\n@keyframes up_entry {\n  0% {\n    opacity: 0;\n    transform: translateY(2rem);\n  }\n  20% {\n    opacity: 0;\n  }\n  60% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
