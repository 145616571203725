/* eslint-disable react/prop-types */
import React from 'react';

import './styles.less';

/**
 *
 * @param {Object} props
 * @param {string} [props.message]
 * @param {boolean} props.blur
 * @returns
 */
const BlurWithMessage = ({ message, blur }) => (
  <div className={`blur-container ${blur && 'active'}`}>
    <div className="blur-content">
      <p className="no-margin-bottom">
        <b className="weight-500">
          {message}
        </b>
      </p>
    </div>
  </div>
);

export default BlurWithMessage;
