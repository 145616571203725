import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { TutorialPanel } from 'components';
import './styles.less';

const TutorialDrawer = ({ tutorial, visible, onClose }) => (

  <Drawer
    width="90%"
    placement="right"
    onClose={() => onClose()}
    visible={visible}
    id="tutorial-drawer"
  >
    <TutorialPanel tutorial={tutorial} />
  </Drawer>
);

TutorialDrawer.propTypes = {
  tutorial: PropTypes.arrayOf(Object),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TutorialDrawer;
