import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Checkbox,
  Form,
  notification,
  Tooltip,
  Divider,
  Row,
  Col,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import { UserTag } from 'components/others';
import { RetweetOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { formatAndNotificateError } from 'utils';
import {
  CREATE_PASSWORD,
  ADD_SESSION,
  RESEND_EMAIL_VERIFICATION_CODE,
  SEND_EMAIL_LOGIN_WITH_LINK,
} from './graphql';

const { Item, useForm } = Form;

const PasswordForm = ({
  email, verificationCode, tag, loginWithLink,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [form] = useForm();
  const [ipLoading, setIpLoading] = useState(false);

  const {
    'update-password-token': updatePasswordToken,
  } = queryString.parse(location.search);

  const [accepted, setAccepted] = useState(!!updatePasswordToken);

  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => {
      history.push('/', {
        params: {
          loggedInNow: true,
        },
      });
    },
  });

  const [
    resendEmailVerificationCode,
    { loading: loadingResendEmail },
  ] = useMutation(RESEND_EMAIL_VERIFICATION_CODE, {
    onCompleted: () => {
      notification.success({ message: 'E-mail enviado com sucesso!' });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [createPassword, { loading }] = useMutation(CREATE_PASSWORD, {
    onCompleted: ({ createPassword }) => {
      addSession({
        variables: {
          me: createPassword,
        },
      });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    sendEmailLoginWithLink,
    { loading: loadingLoginWithLink },
  ] = useMutation(SEND_EMAIL_LOGIN_WITH_LINK, {
    onCompleted: () => {
      notification.success({ message: 'E-mail enviado com sucesso!' });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const onFinish = async (values) => {
    await form.validateFields();

    const { password, passwordConfirmation, verificationCode } = values;
    if (password !== passwordConfirmation) {
      notification.error({ message: 'Confirmação de senha incorreta.' });
      return;
    }

    setIpLoading(true);
    try {
      const { data } = await axios.get('https://api.ipify.org/?format=json');
      if (data?.ip) {
        // eslint-disable-next-line no-param-reassign
        values.ip = data.ip;
      }
    } catch (error) {
      // ignore error
    }

    createPassword({
      variables: {
        ...values,
        email,
        updatePasswordToken,
        verificationCode,
      },
    });

    setIpLoading(false);
  };

  useEffect(() => {
    if (verificationCode) {
      form.setFieldsValue({ verificationCode });
    }
  }, [verificationCode]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      id="password-form"
    >
      <h2>
        Crie sua
        {' '}
        <span className="secondary-text">senha</span>
      </h2>
      <br />

      <Item>
        <UserTag>
          {tag}
        </UserTag>
      </Item>

      <Item name="password" rules={[{ required: true, message: 'Por favor insira sua senha.' }]}>
        <Input
          id="password"
          autoComplete="new-password"
          type="password"
          placeholder="Digite sua nova senha"
        />
      </Item>

      <Item name="passwordConfirmation" rules={[{ required: true, message: 'Por favor insira a confirmação de senha.' }]}>
        <Input
          id="passwordConfirmation"
          autoComplete="new-password"
          type="password"
          placeholder="Digite novamente"
        />
      </Item>

      {!updatePasswordToken && (
        <Row>
          <Col span={!verificationCode ? 20 : 24}>
            <Item name="verificationCode" rules={[{ required: true, message: 'Por favor insira o código.' }]}>
              <Input
                id="verificationCode"
                className={`verification-code ${verificationCode ? 'full-width' : ''}`}
                placeholder="Digite o código"
                alert="Enviamos um código de verificação para o seu e-mail"
                disabled={verificationCode}
              />
            </Item>
          </Col>

          {!verificationCode && (
            <Col span={4} align="right">
              <Tooltip title="Reenviar código para o e-mail">
                <Button
                  loading={loadingResendEmail}
                  onClick={() => resendEmailVerificationCode({ variables: { email } })}
                  icon={(<RetweetOutlined />)}
                  bordered
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      )}

      <Item style={{ marginTop: 24 }} className="password-terms-area">
        <Checkbox
          checked={accepted}
          id="password-terms"
          onChange={({ target: { checked } }) => setAccepted(checked)}
        >
          <p>
            <b>
              Li e aceito os
              {' '}
              <Button
                text
                onClick={() => window.open('https://faq.gyramais.com.br/termos-de-uso/', '_blank')}
              >
                <b className="secondary-text underline">
                  Termos e Condições do Solicitante
                </b>
              </Button>
              {' '}
              e da
              {' '}
              <Button
                text
                onClick={() => window.open('https://faq.gyramais.com.br/termos-de-consulta-registradoras/', '_blank')}
              >
                <b className="secondary-text underline">
                  Registradora
                </b>
              </Button>
              {' '}
              e as
              {' '}
              <Button
                text
                onClick={() => window.open('https://faq.gyramais.com.br/politica-de-privacidade/', '_blank')}
              >
                <b className="secondary-text underline">
                  Políticas de Privacidade
                </b>
              </Button>
              .
            </b>
          </p>
        </Checkbox>
      </Item>

      <Button
        block
        disabled={!accepted}
        loading={loading || ipLoading}
        htmlType="submit"
        id="create-password-button"
      >
        Criar senha e entrar
      </Button>

      <Divider className="password-step-divider">OU</Divider>

      <Button
        block
        color="secondary"
        loading={loadingLoginWithLink}
        onClick={async () => {
          await sendEmailLoginWithLink({ variables: { email } });
          loginWithLink();
        }}
      >
        Entrar sem digitar senha
      </Button>
    </Form>
  );
};

PasswordForm.propTypes = {
  email: PropTypes.string,
  verificationCode: PropTypes.string,
  tag: PropTypes.string,
  loginWithLink: PropTypes.func,
};

export default memo(PasswordForm);
