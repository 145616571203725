// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#email-verification-modal-body .modal-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.87rem;
}
#email-verification-modal-body .modal-body {
  padding: 0.62rem;
}
@media (max-width: 768px) {
  #email-verification-modal-body .modal-body {
    padding: 1.25rem 0rem;
    padding-bottom: 0.62rem;
  }
}
#email-verification-modal-body .email-in-alert {
  font-weight: 500;
  color: #03738C;
  padding: 0rem 0.25rem;
}
#email-verification-modal-body .alert-text {
  font-weight: 400;
}
#email-verification-modal-body .secondary-btn {
  margin-right: 1.56rem;
  color: var(--primary-color);
}
#email-verification-modal-body .footer {
  padding-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
}
#email-verification-modal-body .error-msg {
  padding-top: 0.62rem;
  padding-left: 1.87rem;
  color: red;
}
#email-verification-modal-body .styles_react-code-input__CRulA {
  width: 100%;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/EmailVerificationModal/styles.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;AAAJ;AATA;EAaI,gBAAA;AADJ;AAGI;EAAA;IACE,qBAAA;IACA,uBAAA;EAAJ;AACF;AAlBA;EAsBI,gBAAA;EACA,cAAA;EACA,qBAAA;AADJ;AAvBA;EA4BI,gBAAA;AAFJ;AA1BA;EAgCI,qBAAA;EACA,2BAAA;AAHJ;AA9BA;EAqCI,mBAAA;EACA,aAAA;EACA,yBAAA;AAJJ;AAnCA;EA2CI,oBAAA;EACA,qBAAA;EACA,UAAA;AALJ;AAxCA;EAiDI,WAAA;EACA,kBAAA;AANJ","sourcesContent":["#email-verification-modal-body {\n  .modal-title {\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: 300;\n    font-size: 1.5rem;\n    line-height: 1.75rem;\n    letter-spacing: -0.04em;\n    text-align: center;\n    margin-bottom: 1.87rem;\n  }\n  \n  .modal-body {\n    padding: 0.62rem;\n  \n    @media(max-width: @md-screen) {\n      padding: 1.25rem 0rem;\n      padding-bottom: 0.62rem;\n    }\n  }\n  \n  .email-in-alert {\n    font-weight: 500;\n    color: #03738C;\n    padding: 0rem 0.25rem;\n  }\n  \n  .alert-text {\n    font-weight: 400;\n  }\n  \n  .secondary-btn {\n    margin-right: 1.56rem;\n    color: var(--primary-color)\n  }\n  \n  .footer {\n    padding-top: 2.5rem;\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .error-msg {\n    padding-top: 0.62rem;\n    padding-left: 1.87rem;\n    color: red;\n  }\n  \n  .styles_react-code-input__CRulA {\n    width: 100%;\n    text-align: center;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
