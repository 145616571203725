/* eslint-disable max-len */
import React, { memo } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  Layout,
  Button,
} from 'components/sdk';
import { Images } from 'assets';
import {
  whatsappUrl,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
} from 'utils';
import './styles.less';

const {
  Footer: GyraFooter,
} = Layout;
const {
  WhatsappWhite,
  FacebookWhite,
  InstagramWhite,
  LinkedinWhite,
  LogoGyraWhite,
} = Images;

const Footer = () => (
  <GyraFooter id="gyramais-partner-footer" className="dark">
    <br />

    <Row gutter={[20, 50]} justify="space-between">
      <Col xs={24} sm={24} md={12}>
        <img src={LogoGyraWhite} alt="logo" className="logo" />
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Row gutter={20} justify="end">
          <Col xs={6} sm={6} md={3} lg={2}>
            <Button link onClick={() => window.open(whatsappUrl)}>
              <WhatsappWhite />
            </Button>
          </Col>
          <Col xs={6} sm={6} md={3} lg={2}>
            <Button link onClick={() => window.open(facebookUrl)}>
              <FacebookWhite />
            </Button>
          </Col>
          <Col xs={6} sm={6} md={3} lg={2}>
            <Button link onClick={() => window.open(instagramUrl)}>
              <InstagramWhite />
            </Button>
          </Col>
          <Col xs={6} sm={6} md={3} lg={2}>
            <Button link onClick={() => window.open(linkedinUrl)}>
              <LinkedinWhite />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row type="flex" style={{ marginTop: 30 }} gutter={[20, 20]}>
      <Col xs={24} sm={12} md={6}>
        <b className="white-text">Rio de Janeiro</b>
        <br />
        Rua Farme de Amoedo 76,
        <br />
        Sala 403 - Ipanema
      </Col>
      <Col xs={24} sm={12} md={6}>
        <b className="white-text">São Paulo</b>
        <br />
        Rua Arandu 281
        <br />
        Conjunto 1 - Brooklin
      </Col>
      <Col span={24} style={{ whiteSpace: 'pre-line', marginTop: 40 }}>
        {`A Gyramais.com (“Gyra+”) , com sede na Rua Farme de Amoedo 76, SL403, Ipanema - Rio de Janeiro, RJ, CEP: 22420-020, inscrita no CNPJ sob no 27.734.451/0001-09 não é uma instituição financeira. Somos um correspondente bancário e possuímos uma plataforma online que facilita o acesso de clientes a produtos e serviços ofertados por instituições financeiras credenciadas na plataforma.

Os correspondentes bancários são empresas contratadas por instituições financeiras e demais instituições autorizadas pelo Banco Central do Brasil para a prestação de serviços de atendimento aos clientes e usuários dessas instituições. A atividade de correspondente bancário é uma atividade regulada pelo Banco Central do Brasil, nos termos da Resolução nº. 3.954, de 24 de fevereiro de 2011.

Prazo de pagamento: de 3 a 24 meses. Custo Efetivo Total (CET): De 3,04% a.m. (43,18% a.a.) a 8,28% a.m. (159,81% a.a.). Exemplo: um empréstimo de R$ 20.000,00 com pagamento em 12 parcelas de R$ 2.140,05. O valor total a ser pago será de R$ 25.680,60, com juros de 2,99% a.m. e CET de 4,07% a.m.

Em caso de duvidas não deixe de entrar em contato com nosso atendimento!

©GYRA+ · TODOS OS DIREITOS RESERVADOS`}
      </Col>
    </Row>

    <br />
  </GyraFooter>
);

export default memo(Footer);
