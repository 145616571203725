import React, { useState } from 'react';
import {
  SubHeader,
  Information,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import {
  UserDrawer,
} from 'components';
import {
  Row,
  Col,
} from 'antd';
import { formatAndNotificateError, formatString, formatDate } from 'utils';
import {
  useQuery,
} from '@apollo/client';
import Card from 'components/gyramais/Card';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import {
  ME,
} from './graphql';

import './styles.less';

const { Content } = Layout;

const UserScreen = () => {
  const [visible, setVisible] = useState(false);

  const { checkActions } = usePartnerActionsContext();

  const {
    data: {
      me: {
        fullName,
        cpf,
        email,
        phoneNumber,
        additionalInfo,
        birthDate,
        currentBusiness,
      } = {},
    } = {},
    refetch,
  } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => formatAndNotificateError(error),
  });
  return (
    <Layout id="user-screen">
      <SubHeader
        secondary
        title={`${fullName?.split(' ')[0] || ''} ${fullName?.split(' ')[fullName?.split(' ').length - 1] || '-'}`}
        buttonsPlacement="right"
        buttons={(
          <>
            <Button id="edit-perfil-button" onClick={() => setVisible(true)}>Editar Perfil</Button>
          </>
        )}
      />
      <Content>
        <Card title="Sobre você">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={6}>
              <Information
                label="Nome completo"
                value={fullName}
              />
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Information
                label="Email"
                value={email}
              />
            </Col>

            <Col xs={24} md={12} lg={5}>
              <Information
                label="CPF"
                value={cpf && formatString(cpf, 'cpf')}
              />
            </Col>

            <Col xs={24} md={12} lg={5}>
              <Information
                label="Telefone com DDD"
                value={phoneNumber}
              />
            </Col>

            {birthDate && (
              <Col xs={24} md={12} lg={6}>
                <Information
                  label="Data de nascimento"
                  value={formatDate(new Date(birthDate))}
                />
              </Col>
            )}

            {additionalInfo && (
              <>
                {additionalInfo.gender && (
                  <Col xs={24} md={12} lg={6}>
                    <Information
                      label="Sexo"
                      value={additionalInfo.gender}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </Card>

        <br />

        {!checkActions(['only-score']) && (
          <Card title="Sobre seu empreendimento">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} lg={6}>
                <Information
                  label="Número de funcionários"
                  value={`${currentBusiness?.employeesNumber || '-'}`}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>

                <Information
                  label="Faturamento mensal"
                  type="currency"
                  value={`${currentBusiness?.revenueAmount || '0'}`}
                />
              </Col>

              <Col xs={24} md={12} lg={5}>
                <Information
                  label="Ramo de atuação"
                  value={`${currentBusiness?.platformType?.name || '-'}`}
                />
              </Col>

              <Col xs={24} md={12} lg={5}>
                <Information
                  label="Segmento"
                  value={`${currentBusiness?.segment?.name || '-'}`}
                />
              </Col>

              <Col xs={24}>
                <Information
                  label="Setor"
                  value={`${currentBusiness?.sector?.name || '-'}`}
                />
              </Col>

            </Row>
          </Card>
        )}

      </Content>

      <UserDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        onCompleted={() => {
          refetch();
          setVisible(false);
        }}
      />
    </Layout>
  );
};

export default UserScreen;
