// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tutorial-drawer .header {
  padding: 0 2rem !important;
  margin-bottom: 2rem;
}
#tutorial-drawer .header .close-button {
  margin-right: 0.65rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/TutorialDrawer/styles.less"],"names":[],"mappings":"AAAA;EAEI,0BAAA;EACA,mBAAA;AAAJ;AAHA;EAKM,qBAAA;AACN","sourcesContent":["#tutorial-drawer {\n  .header {\n    padding: 0 2rem !important;\n    margin-bottom: 2rem;\n    .close-button {\n      margin-right: 0.65rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
