// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyramais-status {
  width: fit-content;
  height: fit-content !important;
  display: flex;
  align-items: center;
  border-radius: calc(1.25rem / 5);
  padding: 0rem 0.875rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/Status/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,8BAAA;EAEA,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,sBAAA;EAEA,kBAAA;EACA,oBAAA;EACA,gBAAA;AADF","sourcesContent":[".gyramais-status {\n  width: fit-content;\n  height: fit-content !important;\n\n  display: flex;\n  align-items: center;\n  border-radius: calc(@border-radius / 5);\n  padding: 0rem .875rem;\n\n  font-size: .75rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
