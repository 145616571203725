import SAVE_INTEGRATION from './saveIntegration.gql';
import PLUGGY_CONNECT_TOKEN from './pluggyConnectToken.gql';
import BANK_ACCOUNTS from './bankAccounts.gql';
import PLUGGY_TYPE_ID from './pluggyTypeId.gql';

export {
  SAVE_INTEGRATION,
  PLUGGY_CONNECT_TOKEN,
  BANK_ACCOUNTS,
  PLUGGY_TYPE_ID,
};
