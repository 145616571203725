import React, { memo } from 'react';
import {
  Row,
  Col,
  Collapse,
  Image,
} from 'antd';
import {
  WhatsAppOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  Button,
  Layout,
} from 'components/sdk';
import {
  whatsappUrl,
} from 'utils';
import {
  ImgPdfFaq,
} from 'assets/images';
import './styles.less';

const {
  Content,
} = Layout;
const { Panel } = Collapse;

const OlistFaq = () => (
  <Content id="olist-faq">
    <br />
    <br />

    <h2>
      <b>
        Nós estamos aqui para
        <br />
        te ajudar!
      </b>
    </h2>

    <Row align="middle" justify="space-between" gutter={[0, 16]}>
      <Col span={18}>
        <h6>
          <br />

          Navegue pelas perguntas
          <br />
          mais frequentes para tirar todas as suas
          <br />
          dúvidas.

        </h6>
      </Col>
      <Col>
        <Button
          className="olist-button"
          onClick={() => window.open(whatsappUrl)}
        >
          <WhatsAppOutlined />
          {' '}
          Falar com nosso suporte
        </Button>
      </Col>
    </Row>
    <Row gutter={[16, 32]} justify="space-between">
      <Col xs={24} sm={24} md={24} className="olist-faq-questions">
        <Collapse bordered={false} style={{ marginBottom: 48 }} expandIconPosition="right">
          <Panel header="Quem é a GYRA+?" key="1">
            Somos a GYRA+, uma plataforma de capital de giro que tem como propósito ampliar
            os limites de cada empreendedor para a construção dos seus sonhos.
            Lutamos pela democratização do crédito para pequenas e médias empresas,
            em especial as que possuem lideranças femininas, negras e com 60 anos de
            idade ou mais.
            Estamos conectados em plataformas parceiras para consultar os dados de faturamento
            e oferecer uma análise de crédito rápida, customizada e segura, sem a burocracia
            dos grandes bancos.
          </Panel>
          <Panel
            header="O que é a parceria olist e GYRA+?"
            key="2"
          >
            Acreditamos que as parcerias só funcionam quando todos saem ganhando!
            Por isso, nos juntamos com o olist para oferecer capital de giro com
            vantagens exclusivas para os lojistas do olist store.
            Além da oferta de crédito já ser pré-aprovada com uma taxa especial,
            o pagamento das parcelas é descontado diretamente do repasse de vendas
            no financeiro do olist store, sem complicação.
          </Panel>
          <Panel
            header="MEI também pode solicitar?"
            key="3"
          >
            Sim! Nosso empréstimo é válido para MEIs de todo o Brasil.
          </Panel>
          <Panel
            header="Precisa apresentar alguma garantia?"
            key="4"
          >
            Como oferecemos um capital de giro sem garantias reais,
            solicitamos o aval do(s) sócio(s) e do respectivo cônjuge,
            quando o responsável é casado(a).
          </Panel>
          <Panel
            header="Precisa realizar algum pagamento adiantado para liberar o valor do empréstimo?"
            key="5"
          >
            Não! Você sabia que isso é uma prática ilegal?
            Se você recebeu algum contato suspeito exigindo esse tipo de pagamento,
            entre em contato com nosso atendimento.
          </Panel>
          <Panel
            header="Qual o prazo máximo de pagamento do empréstimo?"
            key="6"
          >
            É possível parcelar o empréstimo em até 24 meses.
          </Panel>
          <Panel
            header="Como faço para solicitar uma proposta?"
            key="7"
          >
            É muito simples, basta seguir as instruções:
            <ol>
              <li>
                Preencha a primeira etapa do formulário com as informações da sua empresa e do
                sócio responsável. Clique para aceitar os termos, condições e a política de
                privacidade e depois clique no botão para
                confirmar e prosseguir;

              </li>
              <li>
                Na segunda etapa, crie a sua senha de acesso para o Portal GYRA+ e insira o
                código de verificação, que foi enviado para o seu e-mail pelo olist
                (conforme imagem abaixo);

              </li>
              <li>
                Ao terminar, você será redirecionado para o Portal GYRA+, onde enviará os
                documentos para validação.

              </li>
              <li>
                Assim que o seu cadastro e a documentação enviada forem analisados por nossa
                equipe de crédito, você receberá uma proposta pré-aprovada em até 24 horas, em
                seu e-mail cadastro.

              </li>
            </ol>
            <div className="center-image">
              <Image
                alt="second_item_image"
                width={300}
                src={ImgPdfFaq}
                preview={{
                  mask: (
                    <div>
                      <EyeOutlined />
                      Visualizar
                    </div>
                  ),
                }}
              />
            </div>
          </Panel>
          <Panel
            header="É possível fazer uma simulação antes do cadastro?"
            key="8"
          >
            Não é necessário, pois o valor pré-aprovado enviado por e-mail já é um indicativo
            do quanto você poderá conseguir de empréstimo.
          </Panel>
          <Panel
            header="Quais documentos devem ser enviados?"
            key="9"
          >
            Para facilitar, tenha em mãos os seguintes documentos:
            <br />
            <ul>
              <li>Documento de identidade com foto ou CNH</li>
              <li>Comprovante de renda</li>
              <li>Comprovante de endereço</li>
              <li>Nome, telefone e e-mail do cônjuge, caso seja casado(a)</li>
              <li>Averbação do divórcio, caso seja divorciado(a)</li>
            </ul>
          </Panel>
          <Panel
            header="Recebi uma proposta pré-aprovada, o que isso significa?"
            key="10"
          >
            Significa que você possui um crédito pré-aprovado até o valor indicado pelo e-mail.
            Contudo, a proposta final será definida após a verificação da documentação enviada.
          </Panel>
          <Panel
            header="Consigo alterar o valor do empréstimo, do valor que recebi como pré-aprovado?"
            key="11"
          >
            Sim! Após o envio da documentação, nossa equipe entrará em contato para validar suas
            informações. Você pode aproveitar esse momento para pedir a revisão da sua proposta.
          </Panel>
          <Panel
            header="Como funciona a avaliação?"
            key="12"
          >
            Utilizamos o seu histórico de vendas na plataforma olist e também verificamos a
            documentação enviada no Portal GYRA+.
          </Panel>
          <Panel
            header="Por que estão me pedindo para incluir um avalista adicional?"
            key="13"
          >
            Em alguns casos, quando as informações fornecidas para análise não são suficientes ou
            quando é solicitado um valor maior que a proposta oferecida, nossa política de
            crédito exige a inclusão de novos avalistas para melhorar a avaliação de crédito
            e aumentar as chances de aprovação.
          </Panel>
          <Panel
            header="Quem posso incluir como avalista?"
            key="14"
          >
            Todos os sócios responsáveis pela empresa e seus respectivos cônjuges já entram como
            os avalistas responsáveis pelo empréstimo. No entanto, também é possível adicionar
            terceiros, lembrando que o mesmo será analisado pela nossa equipe de crédito.
          </Panel>
          <Panel
            header="As parcelas são corrigidas por algum índice monetário?"
            key="15"
          >
            Não, as parcelas são fixas e personalizadas de acordo com a sua capacidade de
            pagamento, de tal forma que não prejudique o seu fluxo de caixa.
          </Panel>
          <Panel
            header="Qual o período de carência?"
            key="16"
          >
            Oferecemos pelo menos 30 dias de carência para todos os clientes da parceria
            com o olist.
          </Panel>
          <Panel
            header="Como funciona a cobrança dos pagamentos das parcelas?"
            key="17"
          >
            O valor da parcela será descontado, em todo 5º dia útil de cada mês,
            diretamente do saldo disponível da sua conta junto ao olist. Tudo muito
            simples, sem você se preocupar com nada.
          </Panel>
          <Panel
            header="E se o saldo disponível na conta do olist não for suficiente para pagar a parcela do empréstimo?"
            key="18"
          >
            Sem problemas! Nós enviaremos um e-mail com o link para você gerar um
            boleto com o valor restante da parcela. Na parceria olist e GYRA+,
            você ainda contará com 2 dias de carência após o vencimento para efetuar o pagamento.
          </Panel>
          <Panel
            header="Meu saldo ficou negativo! E agora?"
            key="19"
          >
            Pode ficar tranquilo! O seu saldo ficará negativo temporariamente, de acordo com o
            valor pendente da parcela que não foi ainda descontado da sua conta do olist. O saldo
            será zerado em 1 dia útil, e o valor em aberto deverá ser pago por boleto bancário,
            emitido no Portal GYRA+.
          </Panel>
          <Panel
            header="Por onde consigo acompanhar a quantidade de parcelas remanescentes?"
            key="20"
          >
            Através do Portal GYRA+ (https://app.gyramais.com.br) você consegue acessar todas as
            informações do seu empréstimo, verificar as parcelas remanescentes e emitir os
            boletos de pagamento, caso necessário.
          </Panel>
          <Panel
            header="Por onde consigo acompanhar o pagamento da parcela?"
            key="21"
          >
            Dentro da plataforma olist, na aba Financeiro, na janela de repasse do dia 5 de cada
            mês. Lembrando que, caso não tenha saldo suficiente, você poderá emitir um boleto com
            a diferença do valor no Portal GYRA+ (https://app.gyramais.com.br).
          </Panel>
          <Panel
            header="Eu possuo múltiplas lojas, eu consigo solicitar o empréstimo para todas elas?"
            key="22"
          >
            Só é possível receber o empréstimo por uma das empresas que você é sócio. Não é
            possível ter empréstimo ativo em empresas que tenham os mesmos sócios em diferentes
            CNPJs.
          </Panel>
        </Collapse>
      </Col>
    </Row>

    <br />
    <br />
  </Content>
);

export default memo(OlistFaq);
