// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  #request-rules .condition-item {
    flex-direction: row;
  }
  #request-rules .condition-item span {
    margin-top: 0;
    margin-left: 0.625rem;
    width: 100%;
  }
}
#request-rules .olist-button {
  background-color: #03738C !important;
  color: white;
}
#request-rules .olist-button span {
  background-color: #03738C !important;
}
@media (max-width: 768px) {
  #request-rules .ant-row {
    row-gap: 2rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/sections/RequestRules/styles.less"],"names":[],"mappings":"AAEI;EAAA;IACE,mBAAA;EAAJ;EADE;IAGI,aAAA;IACA,qBAAA;IACA,WAAA;EACN;AACF;AATA;EAaI,oCAAA;EAIA,YAAA;AAJJ;AAbA;EAeM,oCAAA;AACN;AAIE;EAAA;IAEI,wBAAA;EAFJ;AACF","sourcesContent":["#request-rules {\n  .condition-item {\n    @media(max-width: @md-screen) {\n      flex-direction: row;\n      span {\n        margin-top: 0;\n        margin-left: .625rem;\n        width: 100%;\n      }\n    }\n  }\n\n  .olist-button {\n    background-color: #03738C !important;\n    span {\n      background-color: #03738C !important;      \n    }\n    color: white;\n  }\n\n  @media(max-width: @md-screen) {\n    .ant-row {\n      row-gap: 2rem !important;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
