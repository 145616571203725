/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Tooltip,
  Checkbox,
  notification,
} from 'antd';
import { formatAndNotificateError } from 'utils';
import {
  Status,
} from 'components/gyramais';
import {
  Progress,
  Modal,
  Button,
} from 'components/sdk';
import { useMutation, useQuery } from '@apollo/client';
import { Alert } from 'components';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  REMOVE_INTEGRATION, SELECT_BANK_ACCOUNT, REMOVE_BANK_ACCOUNT, ME,
} from './graphql';

import './styles.less';

const TYPE_INTEGRATION = 'Integration';
const TYPE_BANK_ACCOUNT = 'BankAccount';

const AccountCard = ({
  logo,
  status: {
    name,
    value,
  },
  platformName,
  account,
  id,
  code,
  flow,
  refetch,
  bypass = false,
  refetchBankAccount,
  type,
  currentAccount,
  removedAt,
  selected,
  reintegration,
  typeInfo: {
    id: typeId,
  } = {},
  onRemove,
  mobileStyle,
  createdByPluggy,
  removable = true,
  approved,
  currentBankAccountCreatedByPluggy,
  onEdit,
}) => {
  const [onButton, setOnButton] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeIntegration, { loading: removingIntegration }] = useMutation(REMOVE_INTEGRATION, {
    onCompleted: () => {
      refetch();
      setRemoveModal(false);
      onRemove?.();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [removeBankAccount, { loading: removingBankAccount }] = useMutation(REMOVE_BANK_ACCOUNT, {
    onCompleted: () => {
      refetchBankAccount();
      setRemoveModal(false);
      onRemove?.();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [selectBankAccount, { loading: selectLoading }] = useMutation(SELECT_BANK_ACCOUNT, {
    onCompleted: () => refetch(),
    onError: (error) => formatAndNotificateError(error),
  });

  const removeLoading = removingIntegration || removingBankAccount;

  const handleRemove = async (e) => {
    e?.stopPropagation();

    if (type === TYPE_BANK_ACCOUNT) {
      await removeBankAccount({ variables: { id, bypass } });
      return;
    }

    await removeIntegration({ variables: { id } });
  };

  const {
    data: {
      me: {
        currentBusiness,
        roles,
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  const isAbleToRemoveAccount = roles?.some((role) => role.name.includes(`owner-${currentBusiness.id}`));

  const credentialFields = ['establishment', 'token', 'affiliationCode', 'accountNumbers', 'user', 'email'];

  const getIntegrationCredential = () => {
    if (!account) {
      return '';
    }

    let credentialData;

    for (const fieldName of credentialFields) {
      if (account[fieldName]) {
        credentialData = account[fieldName];
      }
    }
    if (typeof credentialData === 'object') credentialData = credentialData.toString();

    if (!credentialData) {
      return '';
    }

    return `${credentialData.substring(0, 15)}${credentialData.substring(15) ? '...' : ''}`;
  };

  return (
    <>
      <Card
        id={`${type}-${id}`}
        data-cy={`${type}-${platformName}`}
        className={`
          gyramais-account-card
          ${(selected && !bypass) ? 'selected-account-card' : ''}
          ${type === 'BankAccount' ? 'bank-account' : 'integration'}
          ${mobileStyle && 'mobile-style'}
          ${approved === false && 'editable-card'}
        `}
        onClick={() => {
          if (!onButton) {
            if (code !== '401' && flow === 'iugu') {
              notification.error({
                message: 'Você não pode selecionar um banco que não seja a IUGU como banco principal',
              });
            } else if (type === TYPE_BANK_ACCOUNT && !selected && !bypass && (createdByPluggy || !currentBankAccountCreatedByPluggy)) {
              selectBankAccount({ variables: { bankAccountId: id } });
            }
          }
        }}
      >
        <div className={`card-loading-container ${selectLoading ? 'on-loading' : ''}`}>
          <h5>Alterando conta principal ...</h5>
          <Progress
            percent={100}
          />
        </div>

        <Row className={`account-card-content ${selectLoading ? 'on-loading' : ''}`} align="middle" gutter={[12, 16]}>
          <Col className="logo" xs={24} sm={24} md={24} lg={mobileStyle ? 24 : 8}>
            {type !== TYPE_INTEGRATION && !bypass && (createdByPluggy || !currentBankAccountCreatedByPluggy) && (
              <div className="checkbox-container">
                <Checkbox checked={selected} />
              </div>
            )}
            <span className="logo-wrapper">
              {logo ? <img src={logo} className="card-logo" alt="logo" /> : platformName}
            </span>
            {!mobileStyle && (
              <Status value={value} name={name} />
            )}
          </Col>

          <Col className="data-col" xs={24} sm={24} md={24} lg={mobileStyle ? 24 : 8}>
            {type !== TYPE_BANK_ACCOUNT && getIntegrationCredential() && (
              <h6 className="no-margin-bottom">
                Conta:
                {' '}
                <span className="secondary-text">
                  {getIntegrationCredential()}
                </span>
              </h6>
            )}

            {type === TYPE_BANK_ACCOUNT && (
              <>
                <h6>
                  Agência:
                  {' '}
                  <span className="secondary-text">
                    {account?.branchCode}
                  </span>
                </h6>

                <h6 className="no-margin-bottom">
                  {(currentAccount === true ? 'Conta Corrente ' : 'Conta Poupança ')}
                  {' '}
                  <span className="secondary-text">
                    {account?.accountNumber}
                  </span>
                </h6>
              </>
            )}
          </Col>

          <Col xs={24} sm={24} md={24} lg={mobileStyle ? 24 : 8}>
            <Row className="actions-wrapper" align="middle" justify="end" gutter={[0, 12]}>
              {type === TYPE_INTEGRATION && removedAt && (
                <Col xs={24} sm={24} md={24} lg={mobileStyle ? 24 : 12}>
                  <Button
                    className="re-integrate action-button"
                    id="re-integrate"
                    onClick={() => {
                      reintegration({ id, typeId });
                    }}
                    block
                  >
                    Reintegrar
                  </Button>
                </Col>
              )}
              <Col md={24} lg={6} className="buttons-col">
                {(approved === false)
                && (
                <Button
                  className="action-button"
                  data-cy="onboard-bank-account-button"
                  onClick={() => onEdit({ bankAccount: account })}
                >
                  Revisar
                </Button>
                )}

                {(approved === true)
                && (
                  <CheckCircleOutlined className="approved-analysis-icon" />
                )}
                {removable
                && (
                  <Button
                    className="action-button"
                    data-cy="delete-account-button"
                    link
                    disabled={!isAbleToRemoveAccount}
                    onMouseEnter={() => setOnButton(true)}
                    onMouseLeave={() => setOnButton(false)}
                    onClick={() => {
                      setRemoveModal(true);
                    }}
                    block
                  >

                    {isAbleToRemoveAccount ? (<DeleteOutlined className="upload-card-icon" />) : (
                      <Tooltip
                        placement="bottomRight"
                        title={(
                          <span>
                            Apenas o usuário principal pode remover a conta.
                          </span>
                      )}
                      >
                        <DeleteOutlined />
                      </Tooltip>
                    )}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Modal
        visible={removeModal}
        closable={false}
        centered
        title={type === TYPE_BANK_ACCOUNT
          ? 'Ei! Tem certeza que deseja remover essa conta bancária?'
          : 'Ei! Tem certeza que deseja remover a sua conta?'}
        okButton={{
          id: type === TYPE_BANK_ACCOUNT ? 'removeBankAccount' : 'removeIntegration',
          text: 'Remover',
          onClick: handleRemove,
          loading: removeLoading,
          'data-cy': `remove-integration-button-${(type !== TYPE_BANK_ACCOUNT) && 'removeIntegration'}`,
        }}
        cancelButton={{
          text: 'Cancelar',
          onClick: (e) => {
            e?.stopPropagation();
            setRemoveModal(false);
          },
          disabled: removeLoading,
        }}
      >
        <Alert message="Ao confirmar a exclusão, a ação não poderá ser revertida." />
      </Modal>
    </>
  );
};

AccountCard.propTypes = {
  logo: PropTypes.string,
  status: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  platformName: PropTypes.string,
  account: PropTypes.shape({
    code: PropTypes.string,
    branchCode: PropTypes.string,
    accountNumber: PropTypes.string,
    bank: PropTypes.shape(),
  }),
  id: PropTypes.string,
  code: PropTypes.string,
  flow: PropTypes.string,
  bypass: PropTypes.bool,
  refetch: PropTypes.func,
  refetchBankAccount: PropTypes.func,
  type: PropTypes.oneOf([
    TYPE_BANK_ACCOUNT,
    TYPE_INTEGRATION,
  ]),
  currentAccount: PropTypes.bool,
  removedAt: PropTypes.bool,
  selected: PropTypes.bool,
  reintegration: PropTypes.func,
  typeInfo: PropTypes.shape({
    id: PropTypes.string,
  }),
  onRemove: PropTypes.func,
  mobileStyle: PropTypes.bool,
  createdByPluggy: PropTypes.bool,
  removable: PropTypes.bool,
  approved: PropTypes.bool,
  currentBankAccountCreatedByPluggy: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default AccountCard;
