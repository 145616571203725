import React from 'react';
import PropTypes from 'prop-types';

const ArrowComponent = ({
  currentSlide, slideCount, Icon, imagesNumber, direction, ...props
}) => {
  if (imagesNumber < 2) {
    return (<></>);
  }

  return (
    <button
      {...props}
      className={
        `slick-${direction} slick-arrow ${currentSlide === slideCount - 1 ? 'slick-disabled' : ''}`
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount + 1}
      type="button"
    >
      <Icon />
    </button>
  );
};

ArrowComponent.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  Icon: PropTypes.element,
  imagesNumber: PropTypes.number,
  direction: PropTypes.string,
};

export default ArrowComponent;
