import ME from './me.gql';
import SAVE_ME from './saveMe.gql';
import BUSINESS_PLATFORM_TYPES from './businessPlatformTypes.gql';
import BUSINESS_SEGMENTS from './businessSegments.gql';
import BUSINESS_SECTORS from './businessSectors.gql';
import CREATE_DELETE_VERIFICATION_CODE from './createDeleteVerificationCode.gql';
import LOANS from './loans.gql';

export {
  ME,
  SAVE_ME,
  BUSINESS_PLATFORM_TYPES,
  BUSINESS_SECTORS,
  BUSINESS_SEGMENTS,
  CREATE_DELETE_VERIFICATION_CODE,
  LOANS,
};
