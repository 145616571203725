import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  notification,
  Row,
} from 'antd';
import {
  Alert,
} from 'components/gyramais';
import {
  Modal,
} from 'components/sdk';
import ReactCodeInput from 'react-verification-code-input';
import { useMutation } from '@apollo/client';
import { formatError } from 'utils';
import {
  USER_DELETE,
} from './graphql';

import './styles.less';

function DeleteVerificationModal({
  visible,
  onCancel,
  handleSendCode,
  verificationCodeSent,
}) {
  const [verificationCode, setVerificationCode] = useState('');

  const [userDelete, { loading: deleteLoading }] = useMutation(USER_DELETE, {
    onCompleted: (() => {
      localStorage.removeItem('me');
      window.location.href = '/login?deleted=1';
    }),
    onError: ((error) => {
      notification.error({
        message: 'Sua conta não pôde ser deletada.',
        description: formatError(error),
      });
    }),
  });

  const handleDeleteUser = async () => {
    userDelete({
      variables: {
        verificationCode,
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      centered
      width={550}
      title="Você deseja deletar sua conta?"
      okButton={{
        id: 'btn-delete-user',
        text: 'Deletar conta',
        loading: deleteLoading,
        onClick: handleDeleteUser,
      }}
      cancelButton={{
        text: 'Reenviar código',
        disabled: verificationCodeSent,
        onClick: handleSendCode,
      }}
      onClose={onCancel}
      closable
    >
      <Row
        id="sms-verification-modal-body"
        className="modal-body"
      >
        <Col xs={24}>
          <Alert
            message={(
              <h6>
                <b>
                  Enviamos um código de 6 dígitos
                  {' '}
                  <span className="secondary-text">para o seu celular</span>
                  {' '}
                  . Ao deletar sua conta, você
                  {' '}
                  <span className="secondary-text">perderá o total acesso</span>
                  {' '}
                  à plataforma GYRA+, e iremos deletar
                  {' '}
                  <span className="secondary-text">PERMANENTEMENTE </span>
                  {' '}
                  todos os seus dados pessoais.
                </b>
              </h6>
            )}
          />
        </Col>

        <Col xs={24}>
          <ReactCodeInput
            type="number"
            inputMode="tel"
            id="verification-code-input"
            fields={6}
            onChange={async (code) => {
              setVerificationCode(code);
            }}
            autoFocus
          />
        </Col>
      </Row>
    </Modal>
  );
}

DeleteVerificationModal.propTypes = {
  visible: PropTypes.bool,
  verificationCodeSent: PropTypes.bool,
  onCancel: PropTypes.func,
  handleSendCode: PropTypes.func,
};

export default DeleteVerificationModal;
