import React from 'react';
import PropTypes from 'prop-types';
import {
  Steps, Row, Col,
} from 'antd';
import {
  LoanData,
  RacialSearchForm,
  SignatureForm,
  DigitalSignatureForm,
} from 'components';
import './styles.less';

const { Step } = Steps;

const SignatureProcess = ({
  next,
  currentStep,
  setAcceptedCheckbox,
  acceptedCheckbox,
  acceptedAvalistCheckbox,
  setAcceptedAvalistCheckbox,
  signatureLoading,
  verificationCode,
  setVerificationCode,
  setEthnicGroup,
  setDigitalSignature,
  setDigitalSignatureNonce,
  alreadySigned,
  loan,
  isBadesc,
  digitalSignatureError,
  setDigitalSignatureError,
  signatureByFaceMatch,
  signatureByFaceMatchLoading,
  CAFSignatureURL,
}) => {
  const racialStep = loan?.additive
    ? []
    : [{
      title: 'Pesquisa Étnico-Racial',
      content: (
        <RacialSearchForm
          next={next}
          setEthnicGroup={(e) => setEthnicGroup(e)}
        />
      ),
    }];

  const handleSignatureSteps = () => {
    const signatureSteps = [{
      title: 'Inserir código',
      content: (
        <SignatureForm
          next={next}
          verificationCode={verificationCode}
          setVerificationCode={(e) => setVerificationCode(e)}
          signatureLoading={signatureLoading}
        />
      ),
    }];

    if (
      !signatureByFaceMatchLoading
      && signatureByFaceMatch
      && !loan.additive
    ) {
      signatureSteps.push({
        title: 'Confirmar identidade',
        content: (
          <iframe
            className="face-match-iframe"
            title="face-match"
            src={CAFSignatureURL}
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
            width={549}
          />
        ),
      });
    }

    return signatureSteps;
  };

  const steps = [
    {
      title: 'Confirmar assinatura',
      content: (
        <LoanData
          acceptedCheckbox={acceptedCheckbox}
          setAcceptedCheckbox={setAcceptedCheckbox}
          acceptedAvalistCheckbox={acceptedAvalistCheckbox}
          setAcceptedAvalistCheckbox={setAcceptedAvalistCheckbox}
          alreadySigned={alreadySigned}
          loan={loan}
        />
      ),
    },
    ...racialStep,
    ...handleSignatureSteps(),
  ];

  const badescSteps = [
    {
      title: 'Confirmar assinatura',
      content: (
        <LoanData
          acceptedCheckbox={acceptedCheckbox}
          setAcceptedCheckbox={setAcceptedCheckbox}
          acceptedAvalistCheckbox={acceptedAvalistCheckbox}
          setAcceptedAvalistCheckbox={setAcceptedAvalistCheckbox}
          alreadySigned={alreadySigned}
          loan={loan}
        />
      ),
    },
    {
      title: 'Inserir código',
      content: (
        <SignatureForm
          next={next}
          verificationCode={verificationCode}
          setVerificationCode={(e) => setVerificationCode(e)}
          signatureLoading={signatureLoading}
          isBadesc={isBadesc}
        />
      ),
    },
    {
      title: 'Assinatura digital',
      content: (
        <DigitalSignatureForm
          loan={loan}
          setDigitalSignature={(e) => setDigitalSignature(e)}
          setDigitalSignatureNonce={(e) => setDigitalSignatureNonce(e)}
          digitalSignatureError={digitalSignatureError}
          setDigitalSignatureError={(e) => setDigitalSignatureError(e)}
        />
      ),
    },
  ];

  return (
    <div className="signature-process">
      <Row>
        <Col xs={24} sm={24} md={24}>
          <Steps current={currentStep}>
            {(isBadesc ? badescSteps : steps).map(({ title }) => (
              <Step key={title} title={title} />
            ))}
          </Steps>
        </Col>
      </Row>

      {(isBadesc ? badescSteps : steps)[currentStep].content && (
        <div className="steps-content">
          {(isBadesc ? badescSteps : steps)[currentStep].content}
        </div>
      )}
    </div>
  );
};

SignatureProcess.propTypes = {
  next: PropTypes.func,
  currentStep: PropTypes.number,
  acceptedCheckbox: PropTypes.bool,
  setAcceptedCheckbox: PropTypes.func,
  acceptedAvalistCheckbox: PropTypes.bool,
  setAcceptedAvalistCheckbox: PropTypes.func,
  verificationCode: PropTypes.string,
  setVerificationCode: PropTypes.func,
  setEthnicGroup: PropTypes.func,
  signatureLoading: PropTypes.bool,
  alreadySigned: PropTypes.bool,
  loan: PropTypes.shape(),
  isBadesc: PropTypes.bool,
  setDigitalSignature: PropTypes.func,
  setDigitalSignatureNonce: PropTypes.func,
  digitalSignatureError: PropTypes.string,
  setDigitalSignatureError: PropTypes.func,
  signatureByFaceMatchLoading: PropTypes.bool,
  signatureByFaceMatch: PropTypes.bool,
};
export default SignatureProcess;
