import CREATE_PASSWORD from './createPassword.gql';
import ADD_SESSION from './addSession.gql';
import RESEND_EMAIL_VERIFICATION_CODE from './resendEmailVerificationCode.gql';
import SEND_EMAIL_LOGIN_WITH_LINK from './sendEmailLoginWithLink.gql';

export {
  CREATE_PASSWORD,
  ADD_SESSION,
  RESEND_EMAIL_VERIFICATION_CODE,
  SEND_EMAIL_LOGIN_WITH_LINK,
};
