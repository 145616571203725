import LOANS from './loans.gql';
import LOAN_UPDATE from './loanUpdate.gql';
import ME from './me.gql';

export {
  ME,
  LOANS,
  LOAN_UPDATE,

};
