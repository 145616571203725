// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caf-step-container .caf-step-iframe {
  width: 100%;
  height: 38rem;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/SignatureFeatureDrawer/steps/CAF/styles.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,aAAA;EAEA,YAAA;AADJ","sourcesContent":[".caf-step-container {\n  .caf-step-iframe {\n    width: 100%;\n    height: 38rem;\n\n    border: none;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
