// @ts-check
import React from 'react';
import {
  Col,
  Collapse,
  Row,
  Table,
  Tooltip,
} from 'antd';
import {
  Card,
} from 'components/gyramais';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatString } from 'utils';
import BlurWithMessage from '../BlurWithMessage';

import './styles.less';

/**
 *
 * @param {{
 *  integrationType: any
 *  usedValue: number,
 *  place: string,
 *  dueDate: string,
 *  type: string,
 *  key: string
 * }} param0
 * @returns
 */
const ReceivableItem = ({
  place,
  dueDate,
  type,
  integrationType,
  key,
  usedValue,
}) => {
  const date = new Date(dueDate);

  return (
    <Row className="receivable-item" align="middle" gutter={[16, 8]} key={key}>
      <Col xs={0} sm={0} md={4} lg={2}>
        <img className="receivable-item-icon" src={integrationType.icon.url} alt={integrationType.name} />
      </Col>

      {/* @ts-ignore */}
      <Col xs={0} sm={0} md={1} lg={1} align="middle">
        <div className="vr" />
      </Col>

      <Col xs={10} sm={10} md={15} lg={17}>
        <p className="no-margin-bottom">
          <strong>
            {integrationType?.name?.toUpperCase()}
          </strong>
        </p>

        <h5 className="no-margin-bottom">
          <strong className="success-text">
            {formatString(usedValue, 'currency')}
          </strong>
        </h5>

        {place && (
          <p className="no-margin-bottom">
            <b>
              {place}
            </b>
          </p>
        )}
      </Col>

      {/* @ts-ignore */}
      <Col xs={14} sm={14} md={4} lg={4} align="right">
        <p className="no-margin-bottom small-text">
          <b>
            Liquidação em {`${date.getDate() + 1}/${date.getMonth() + 1}/${date.getFullYear()}`}
          </b>
        </p>

        <p className="no-margin-bottom small-text">
          <b>
            {type}
          </b>
        </p>
      </Col>
    </Row>
  );
};

/**
 *
 * @param {Object} props
 * @param {boolean} props.blur
 * @param {{
 *   id: string,
 *   amount: number,
 *   date: string,
 *   cardBrand: string,
 *   integrationTypeId: string,
 * }[]} props.affectedReceivables
 * @param {Array<any>} props.integrations
 * @param {number} props.percentageUsedOfLastReceivable
 * @returns
 */
const ReceivableList = ({
  blur,
  affectedReceivables,
  percentageUsedOfLastReceivable,
}) => (
  <Card className="receivable-list-card">
    <BlurWithMessage blur={blur} />

    <Collapse
      bordered={false}
      expandIconPosition="right"
      destroyInactivePanel
      className="receivable-list-card-collapse"
      defaultActiveKey={['receivable-list']}
    >
      <Collapse.Panel
        key="receivable-list"
        header={(
          <div className="receivable-list-card-header">
            <Tooltip title="Extrato referente a quais recebíveis serão antecipados de acordo com o valor e adquirentes selecionadas.">
              <QuestionCircleOutlined />
            </Tooltip>

            <h5 className="no-margin-bottom">
              <b>
                Unidades de Recebíveis impactadas
              </b>
            </h5>
          </div>
        )}
      >
        <br />
        <Table
          columns={[
            {
              key: ':)',
              render: (receivable) => {
                const isLast = affectedReceivables[affectedReceivables.length - 1] === receivable;
                return (
                  <ReceivableItem
                    key={`${receivable.cardBrand}-${receivable.date}`}
                    integrationType={receivable.integrationType}
                    usedValue={
                      isLast
                        ? receivable.amount * percentageUsedOfLastReceivable
                        : receivable.amount
                    }
                    place=""
                    dueDate={receivable.date}
                    type={receivable.cardBrand}
                  />
                );
              },
            },
          ]}
          dataSource={affectedReceivables}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
        />

      </Collapse.Panel>
    </Collapse>
  </Card>
);

export default ReceivableList;
