/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'components/sdk';
import { Gif23 } from 'assets/images';
import { formatCurrency } from 'utils';

import './styles.less';

/**
 * @typedef {{
 *    cet: number
 *    iofAmount: number
 *    annualCET: number
 *    interestRate: number
 *    installmentsCount: number
 *    finalInstallmentsCount: number
 *    installmentAmount: number
 *    amount: number
 *    finalAmount: number
 *    totalAmount: number
 *    gyraMaisFeeAmount: number
 *    partnerFeeAmount: number
 *    businessFeeAmount: number
 *  }} PreApprovedOption
 *
 * @type {React.FC<{
 *  visible: boolean
 *  buttonDisabled: boolean
 *  amount: number
 *  selectedDay: number
 *  secondaryButton?: {
 *    onClick: Function
 *  }
 *  primaryButton?: {
 *    onClick: Function
 *    loading: boolean
 *  }
 *  selectedOption?: PreApprovedOption
 *  flow?: string
 * }>}
 */
const ConfirmLoanConfigModal = ({
  visible,
  buttonDisabled,
  amount,
  selectedDay,
  onClose,
  secondaryButton,
  selectedOption,
  primaryButton,
  flow,
  creditLine,
}) => {
  const handleRenderModalText = () => {
    const creditAmount = formatCurrency(amount);

    const finalCreditAmountByInstallment = formatCurrency(selectedOption?.installmentAmount * (amount / selectedOption?.finalAmount));

    let badescText = '';

    let installmentMessage = (
      <>
        {'O valor total será parcelado em '}
      </>
    );

    if (flow === 'badesc') {
      installmentMessage = 'parcelado em ';
      if (creditLine === 'FUNGETUR SC MAIS RENDA EMPRESARIAL') {
        badescText = (
          <>
            {'. Em caso de atraso superior a 60 dias no pagamento será acrescentada uma taxa de '}
            <b className="secondary-text">
              {`${selectedOption?.interestRate}% a.a. + SELIC`}
            </b>
          </>
        );
      } else {
        badescText = (
          <>
            {'(Valor da parcela + IOF). Em caso de atraso superior a 60 dias no pagamento será acrescentada uma taxa de '}
            <b className="secondary-text">
              {`${selectedOption?.interestRate}% a.a. + SELIC`}
            </b>
          </>
        );
      }
    }

    return (
      <>
        {'Você receberá um crédito de '}
        <b className="secondary-text">{`${creditAmount}. `}</b>
        {installmentMessage}
        <b className="secondary-text">
          {`${selectedOption?.finalInstallmentsCount} vezes`}
        </b>
        {' de '}
        <b className="secondary-text">
          {`${finalCreditAmountByInstallment}`}
        </b>
        {badescText}
        {'. Seus boletos vencerão todo dia '}
        <b className="secondary-text">{selectedDay}</b>
        . Caso não esteja satisfeito, podemos voltar e reconfigurar seu crédito!
      </>
    );
  };
  return (
    <Modal
      visible={visible}
      title="Atenção: Você confirma a configuração de crédito escolhida?"
      centered
      destroyOnClose
      onClose={onClose}
      okButton={{
        id: 'confirm-option-button',
        text: 'Confirmar crédito',
        onClick: primaryButton?.onClick,
        loading: primaryButton?.loading,
      }}
      cancelButton={{
        text: 'Reconfigurar crédito',
        onClick: secondaryButton?.onClick,
        disabled: buttonDisabled,
      }}
    >
      <div className="confirm-option-account-body">
        <img
          src={Gif23}
          className="confirm-modal-image"
          alt="balão de confirmação"
        />

        <h5 className="no-margin-bottom">
          <b>
            {handleRenderModalText()}
          </b>
        </h5>

      </div>
    </Modal>
  );
};

export default ConfirmLoanConfigModal;
