import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Alert,
} from 'antd';
import {
  useQuery,
  useSubscription,
} from '@apollo/client';
import { formatAndNotificateError } from 'utils';
import {
  CREATE_CAF_SIGNATURE,
  FACE_MATCH_CREATED,
} from './graphql';
import { useSignatureContext } from '../..';

import './styles.less';

const CAF = () => {
  const {
    setStepVariables,
    setStepHandle,
    onFinishStep,
  } = useSignatureContext();

  const {
    data: { createCAFSignature } = {},
  } = useQuery(CREATE_CAF_SIGNATURE, {
    onError: (error) => formatAndNotificateError(error),
  });

  useSubscription(FACE_MATCH_CREATED, {
    onSubscriptionData: () => {
      setStepVariables({ done: true });
      onFinishStep();
    },
  });

  useEffect(() => setStepHandle(undefined), []);

  return (
    <Row gutter={[24, 24]} className="caf-step-container">
      <Col span={24}>
        <Alert
          showIcon
          message="Ao concluir o processo de confirmação de identidade, aguarde um momento enquanto processamos suas informações."
          type="warning"
        />
      </Col>

      <Col span={24}>
        <iframe
          className="caf-step-iframe"
          title="face-match"
          src={createCAFSignature}
          allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
        />
      </Col>
    </Row>
  );
};

CAF.propTypes = {};

export default {
  id: 'caf-step',
  title: 'Confirmar identidade',
  component: <CAF />,
};
