// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#find-user-drawer .drawer-content .divider {
  margin-bottom: 1.5rem;
}
#find-user-drawer .drawer-content .gyramais-information {
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/FindUserDrawer/styles.less"],"names":[],"mappings":"AAAA;EAGM,qBAAA;AADN;AAFA;EAOM,mBAAA;AAFN","sourcesContent":["#find-user-drawer {\n  .drawer-content {\n    .divider {\n      margin-bottom: 1.5rem;\n    }\n\n    .gyramais-information  {\n      margin-bottom: 1rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
