import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
  Radio,
} from 'antd';
import {
  Information,
  Card,
} from 'components';
import {
  formatString,
  formatDate,
} from 'utils';
import {
  ReceivableImg,
  ShareholderImg,
} from 'assets/images';
import {
  ReceivableGif,
  ShareholderGif,
} from 'assets/gifs';

import './styles.less';

const props = {
  'receivables-guarantee': {
    image: ReceivableImg,
    selectedImage: ReceivableGif,
    title: 'Empréstimo com recebíveis',
  },
  'shareholder-guarantee': {
    image: ShareholderImg,
    selectedImage: ShareholderGif,
    title: 'Empréstimo sem recebíveis',
  },
};

const LoanTypeCard = ({
  recommended,
  danger,
  value,
  selected,
  notSelected,
  onClick,
  values: {
    finalAmount = 10000,
    finalInstallmentsCount = 10,
    installmentAmount = 1000,
    firstPaymentDate = new Date(),
  } = {},
}) => (
  <Card
    className={`loan-type-card ${selected ? 'loan-type-card-selected' : ''} ${notSelected ? 'loan-type-card-not-selected' : ''}`}
    onClick={onClick}
  >
    <Row align="middle" justify="center" gutter={[0, 16]}>
      {recommended && (
        <Col span={12}>
          <span className="loan-type-card-tag">
            RECOMENDADO
          </span>
        </Col>
      )}

      <Col span={recommended ? 12 : 24} align="right">
        <Radio value={value} />
      </Col>

      <Col xs={0} sm={0} md={24} align="middle">
        <div className="loan-type-card-image-container">
          <img
            className={`loan-type-card-image ${selected ? 'loan-type-card-image-selected' : ''}`}
            src={selected ? props[value].selectedImage : props[value].image}
            alt="imagem"
          />
        </div>
      </Col>

      <Col span={24}>
        <h5>
          <b>
            {props[value].title}
          </b>
        </h5>
      </Col>

      <Col span={14}>
        <Information
          danger={danger}
          label="Valor"
          value={formatString(finalAmount, 'currency')}
        />
      </Col>

      <Col span={10}>
        <Information
          danger={danger}
          label="Prazo"
          value={`${finalInstallmentsCount} meses`}
        />
      </Col>

      <Divider className="loan-type-card-divider desktop-only" />

      <Col xs={0} sm={0} md={14}>
        <Information
          label={`Próxima parcela (1/${finalInstallmentsCount})`}
          value={formatDate(firstPaymentDate)}
        />
      </Col>

      <Col xs={0} sm={0} md={10}>
        <Information
          danger={danger}
          label="Valor das parcelas"
          value={formatString(installmentAmount, 'currency')}
        />
      </Col>
    </Row>
  </Card>
);

LoanTypeCard.propTypes = {
  recommended: PropTypes.bool,
  danger: PropTypes.bool,
  value: PropTypes.oneOf(['receivables-guarantee', 'shareholder-guarantee']).isRequired,
  selected: PropTypes.bool,
  notSelected: PropTypes.bool,
  onClick: PropTypes.func,
  values: PropTypes.shape({
    finalAmount: PropTypes.number,
    finalInstallmentsCount: PropTypes.number,
    installmentAmount: PropTypes.number,
    firstPaymentDate: PropTypes.string,
  }),
};
export default LoanTypeCard;
