import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { setWhiteLabelData, meVar } from '../../services/graphql/cache';
import {
  ME,
  CHANGE_BUSINESS,
  GET_TOOLBOX_SETUP,
  RUN_TOOLBOX_TO_BUSINESS,
} from './graphql';

const BusinessLoanContext = createContext();

const BusinessLoanProvider = ({ children }) => {
  const [isBusinessRegistrationCompleted, setIsBusinessRegistrationCompleted] = useState(true);
  const [currentContextIds, setCurrentContextIds] = useState(JSON.parse(localStorage.getItem('currentContextIds') ?? '{}'));
  const [themeAlreadyApplied, setThemeAlreadyApplied] = useState(!!localStorage.getItem('whiteLabelData'));

  const [ip, setIp] = useState();
  const [changeBusiness] = useMutation(CHANGE_BUSINESS);

  const history = useHistory();
  const { pathname } = useLocation();

  const {
    data: {
      me: {
        id,
        allContexts = [],
      } = {},
      me,
    } = {},
    loading: contextLoading,
    refetch: contextRefetch,
  } = useQuery(ME, {
    skip: !meVar(),
    fetchPolicy: 'network-only',
  });

  const {
    data: {
      getToolboxToBusinessLocation = '{}',
    } = {},
    loading: toolboxLocationLoading,
    refetch: refetchToolboxLocation,
  } = useQuery(RUN_TOOLBOX_TO_BUSINESS, {
    variables: {
      businessId: currentContextIds.businessId,
      partnerId: currentContextIds.partnerId,
    },
    skip: !currentContextIds.businessId,
  });

  const {
    data: {
      getToolboxSetup = '[]',
    } = {},
    loading: toolboxSetupLoading,
  } = useQuery(GET_TOOLBOX_SETUP, {
    variables: {
      businessId: currentContextIds.businessId,
    },
    skip: !currentContextIds.businessId,
  });

  const currentContext = useMemo(
    () => allContexts.find(({ id }) => id === currentContextIds?.id),
    [currentContextIds, allContexts],
  ) ?? {};

  useEffect(() => {
    if (currentContext.id) {
      const { partner } = currentContext;

      setWhiteLabelData({
        colors: partner?.appColors,
        darkLogoUrl: partner?.darkLogo?.url,
        lightLogoUrl: partner?.lightLogo?.url,
      });
    }

    setThemeAlreadyApplied(true);
  }, [currentContext]);

  const allBusinesses = useMemo(
    () => allContexts.reduce((businesses, curr) => {
      const { business } = curr;

      const businessAlreadyAdded = businesses.find(({ id }) => id === business.id);

      if (businessAlreadyAdded) {
        return businesses;
      }

      businesses.push(business);

      return businesses;
    }, []),
    [allContexts],
  ) ?? [];

  const changeBusinessAndLoan = async (context) => {
    if (context?.businessId !== currentContextIds?.businessId) {
      await changeBusiness({
        variables: {
          id: context.businessId,
        },
        refetchQueries: [ME],
      });
    }
    localStorage.setItem('currentContextIds', JSON.stringify(context));

    setCurrentContextIds(context);

    const urlPaths = pathname.split('/');
    const isPrepaymentScreen = urlPaths.includes('antecipacao');

    if (!isPrepaymentScreen) {
      history.push('/');
    }
  };

  const handleRedirect = () => {
    if (!me) return;

    const urlPaths = pathname.split('/');

    const isProfileScreen = urlPaths.includes('perfil');
    const isPrepaymentScreen = urlPaths.includes('antecipacao');
    const isProposalScreen = urlPaths.includes('propostas');

    if (isProfileScreen || isPrepaymentScreen || isProposalScreen) {
      return;
    }

    const { loan, partner } = currentContext;

    if (!urlPaths.includes('propostas') && !contextLoading && !loan) {
      if (!currentContext.id) {
        history.push('/propostas');
        return;
      }

      if (!loan) {
        history.push('/antecipacao');
      }

      return;
    }

    const {
      legacyId,
      currentBusiness,
      roles,
    } = me;

    const {
      approvedAt,
      completedAt,
    } = currentBusiness;

    const ownerRole = roles.find((role) => role.name.includes(`owner-${currentBusiness.id}`));

    if (urlPaths.includes('cadastro') && !ownerRole) {
      notification.warning({
        message: 'Ops! Você não tem permissão para isso',
        description: 'Apenas a conta principal da empresa (o email que solicitou a proposta) tem permissão para completar o cadastro. Entre em contato com a pessoa em questão!',
        duration: 10,
        placement: 'topRight',
      });
      history.push('/');

    } else if (urlPaths.includes('pre-aprovado') && loan.status.value !== 'pre-approved') {
      history.push('/');

    } else if (
      loan.status.value === 'pre-approved'
      && ownerRole
      && !['/cadastro/pre-aprovado/liberar', '/cadastro/integracoes', '/propostas'].includes(pathname)
    ) {
      history.push('/cadastro/pre-aprovado');
    }

    const hasIntegrationPermission = partner?.actions
      .find((a) => ['order-upload', 'payment-upload', 'invoice-upload'].includes(a.value));

    // parceiros com landing customizada precisam
    // passar pela tela de primeira integração
    const showFirstIntegrationScreen = partner?.slug || !hasIntegrationPermission;

    if ((!legacyId || (legacyId && approvedAt && !completedAt))
      && !urlPaths.includes('cadastro')
      && !urlPaths.includes('plataformas-cadastradas')
      && !urlPaths.includes('perfil')
      && !urlPaths.includes('propostas')
      && !urlPaths.includes('antecipacao')
      && ['new', 'lead', 'bank-account', 'integrating'].includes(loan.status.value)
      && ownerRole
      && showFirstIntegrationScreen) {
      const queryParams = window.location.search;
      history.push(`/cadastro/integracoes${queryParams}`);
    }

    const statusNeedsRedirect = [
      'review',
      'pending',
      'integrated',
      'verifying',
      'score',
      'final-verifying',
      'awaiting-partner',
      'awaiting-re-evaluation-direct-data',
      'check-documents',
    ].includes(loan.status.value);

    const parentStatusNeedsRedirect = loan.status?.parent === 'pending';
    const isReviewing = loan.status?.parent?.value === 'review';
    const haveDirectDataProblem = loan.status?.value === 'awaiting-re-evaluation-direct-data';

    const loanNeedsRedirect = statusNeedsRedirect
      || parentStatusNeedsRedirect
      || isReviewing;

    if (loanNeedsRedirect
      && !urlPaths.includes('cadastro')
      && !urlPaths.includes('perfil')
      && !!roles.find((role) => role.name.includes(`owner-${currentBusiness.id}`))
      && ownerRole) {
      if (haveDirectDataProblem) {
        history.push('/cadastro/pendencias');
      } else if (!window.location.pathname.includes('plataformas-cadastradas') && !window.location.pathname.includes('propostas')) {
        history.push('/cadastro/status');
      }
    } else if (
      partner
      && ownerRole
      && loan.status.value === 'pending' && !urlPaths.includes('cadastro') && !urlPaths.includes('perfil')
    ) {
      history.push('/cadastro/empresa');
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [currentContext, pathname, me]);
  useEffect(() => {
    fetch('https://api.ipify.org/?format=json')
      .then((response) => response.json())
      .then(({ ip: clientIp }) => setIp(clientIp))
      .catch(() => {
      });
  }, []);

  const toolboxSetup = JSON.parse(getToolboxSetup);

  const toolboxLoading = !toolboxSetup[0]
    || toolboxLocationLoading
    || toolboxSetupLoading
    || contextLoading
    || !id;

  return (
    <BusinessLoanContext.Provider
      value={{
        me,
        meLoading: contextLoading,
        changeBusinessAndLoan,
        currentContext,
        allContexts,
        allBusinesses,
        contextLoading: !!(contextLoading || !id),
        contextRefetch,
        toolboxLocation: JSON.parse(getToolboxToBusinessLocation),
        refetchToolboxLocation,
        toolboxSetup,
        toolboxLoading,
        businessId: currentContextIds.businessId,
        partnerId: currentContextIds.partnerId,
        ip,
        isBusinessRegistrationCompleted,
        setIsBusinessRegistrationCompleted,
        themeAlreadyApplied,
      }}
    >
      {children}
    </BusinessLoanContext.Provider>
  );
};

export const useBusinessLoanContext = () => useContext(BusinessLoanContext);

export default BusinessLoanProvider;
