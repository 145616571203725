// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bankslip-code-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.bankslip-code-container #bankslip-copy-number {
  align-self: flex-end;
}
.bank-slip-alert span[role=img] {
  margin-top: 1rem;
  margin-bottom: auto;
}
.bank-slip-alert .bank-slip-panel {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.bank-slip-alert .bank-slip-panel .bank-slip-text {
  max-height: 15rem;
  overflow: auto;
}
.bank-slip-alert .bank-slip-panel .bank-slip-text p {
  padding-right: 1rem;
}
.bank-slip-alert .bank-slip-panel .ant-collapse-header {
  font-weight: 500;
}
.bank-slip-alert .bank-slip-panel .ant-collapse-extra {
  font-weight: bolder;
}
.bank-slip-alert .bank-slip-panel ::-webkit-scrollbar {
  width: 0.5rem;
}
.bank-slip-alert .bank-slip-panel ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}
.bank-slip-alert .bank-slip-panel ::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--primary-button-color);
}
.bank-slip-alert .bank-slip-panel ::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/BankSlipModal/styles.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;AAAF;AAHA;EAKI,oBAAA;AACJ;AAEA;EAEI,gBAAA;EACA,mBAAA;AADJ;AAFA;EAIE,UAAU;EACV,WAAW;EACX,oBAAoB;AACtB;AAPA;EAOM,iBAAA;EACA,cAAA;AAGN;AAXA;EAUQ,mBAAA;AAIR;AAdA;EAcM,gBAAA;AAGN;AAjBA;EAiBM,mBAAA;AAGN;AApBA;EAoBM,aAAA;AAGN;AAvBA;EAyBM,gBAAA;EACA,mBAAA;AACN;AA3BA;EA+BM,mBAAA;EACA,uCAAA;AADN;AA/BA;EAqCM,gCAAA;AAHN","sourcesContent":["\n.bankslip-code-container{\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  #bankslip-copy-number {\n    align-self: flex-end;\n  }\n}\n.bank-slip-alert{\n  span[role=img]{\n    margin-top: 1rem;\n    margin-bottom: auto;\n  }\n  .bank-slip-panel{\n    .bank-slip-text{\n      max-height: 15rem;\n      overflow: auto;\n      p {\n        padding-right: 1rem;\n      }\n    } \n    .ant-collapse-header{\n      font-weight: 500;\n    }\n    .ant-collapse-extra{\n      font-weight: bolder;\n    }\n    ::-webkit-scrollbar {\n      width: 0.5rem;\n    }\n    \n    /* Track */\n    ::-webkit-scrollbar-track {\n      background: #fff;\n      border-radius: 20px;\n    }\n    \n    /* Handle */\n    ::-webkit-scrollbar-thumb {\n      border-radius: 20px;\n      background: var(--primary-button-color);\n    }\n    \n    /* Handle on hover */\n    ::-webkit-scrollbar-thumb:hover {\n      background: var(--primary-color);\n    }\n  }\n}\n  \n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
