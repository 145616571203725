// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  line-height: 0rem;
}
.proposal-card-add .ant-card-body {
  margin-top: 0.625rem !important;
}
.proposal-card {
  display: flex;
  width: 8rem;
  height: 8rem;
  box-shadow: none !important;
  border: 0.063rem solid #C9D3CC;
  cursor: pointer;
  padding: 0.5rem !important;
  align-items: center;
  justify-content: center;
}
.proposal-card .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.proposal-card .ant-card-body p {
  text-align: center;
}
.proposal-card .ant-card-body img {
  max-width: 90%;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 452px) {
  .proposal-card {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/ProposalCard/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,+BAAA;AAAF;AAGA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;EACA,8BAAA;EACA,eAAA;EACA,0BAAA;EAEA,mBAAA;EACA,uBAAA;AAFF;AARA;EAcI,aAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;AAbA;EAmBM,kBAAA;AAHN;AAhBA;EAuBM,cAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAJN;AAQE;EAAA;IACI,WAAA;EALJ;AACF","sourcesContent":["p {\n  line-height: 0rem;\n}\n\n.proposal-card-add .ant-card-body {\n  margin-top: 0.625rem !important;\n}\n\n.proposal-card {\n  display: flex;\n  width: 8rem;\n  height: 8rem;\n  box-shadow: none !important;\n  border: .063rem solid #C9D3CC;\n  cursor: pointer;\n  padding: 0.5rem !important;\n  \n  align-items: center;\n  justify-content: center;\n  \n  .ant-card-body {\n    \n    display: flex;\n    align-items: center;\n    justify-content: center;\n    \n    p{\n      text-align: center;\n    }\n\n    img {\n      max-width: 90%;\n      height: 100%;\n      width: 100%;\n      object-fit: contain;\n    }\n  }\n\n  @media(max-width: 452px) {\n      width: 100%;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
