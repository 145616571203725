import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  notification,
  Select,
} from 'antd';
import {
  Input,
  Alert,
} from 'components/gyramais';
import { formatAndNotificateError, CPFValidator, formatDate } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import {
  GET_USER_BY_CPF,
} from './graphql';

import './styles.less';

const { Item } = Form;
const { Option } = Select;
const genders = [
  { id: 1, name: 'Masculino', value: 'MASCULINO' },
  { id: 2, name: 'Feminino', value: 'FEMININO' },
];

const CivilPartnerForm = ({
  form,
  businessUser: selectedBusinessUser,
  flow,
}) => {
  const [userSearched, setUserSearched] = useState(false);
  const [user, setUser] = useState();

  const [getUserByCPF, { loading: userLoading }] = useMutation(GET_USER_BY_CPF, {
    onCompleted: ({
      userByCPF,
    }) => {
      setUserSearched(true);
      setUser(userByCPF);
      form.setFieldsValue({
        ...userByCPF,
        birthDate: userByCPF?.birthDate
          && formatDate(new Date(userByCPF.birthDate)),
        gender: userByCPF?.additionalInfo?.gender,
      });
    },
    onError: (error) => {
      setUserSearched(true);
      setUser();
      formatAndNotificateError(error);
    },
  });

  useEffect(() => {
    form.resetFields();
    const civilPartner = selectedBusinessUser?.user?.civilPartner
      && selectedBusinessUser.user.civilPartner;

    setUser(civilPartner);
    setUserSearched(true);

    if (civilPartner) {
      form.setFieldsValue({
        ...civilPartner,
        birthDate: civilPartner?.birthDate
          && formatDate(new Date(civilPartner.birthDate)),
        gender: civilPartner?.additionalInfo?.gender,
      });
    }
  }, [selectedBusinessUser]);

  const handleCpfChange = (e) => {
    if (e.target.value.replace(/\D/g, '').length < 11) {
      setUserSearched(false);
      setUser();
      return;
    }

    if (e.target.value.replace(/\D/g, '').length === 11) {
      if (CPFValidator(e.target.value.replace(/\D/g, ''))) {
        getUserByCPF({
          variables: {
            cpf: e.target.value,
          },
        });

      } else {
        notification.error({
          message: 'CPF inválido',
          description: 'Insira um CPF válido para continuar',
        });

        form.setFieldsValue({ cpf: '' });
      }
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        id="civil-partner-form"
      >
        <Row gutter={12}>
          <Col xs={24} md={12}>
            <Item style={{ display: 'none' }} name="id" />
            <Item
              name="cpf"
              rules={[{ required: true, message: 'Por favor, insira o CPF do cônjuge' }]}
            >
              <Input
                guide
                mask="cpf"
                placeholder="CPF do cônjuge"
                onChange={handleCpfChange}
              />
            </Item>
          </Col>

          <Col xs={24} md={12}>
            <Item name="fullName" rules={[{ required: true, message: 'Por favor, insira o nome completo do cônjuge' }]}>
              <Input
                placeholder="Nome completo do cônjuge"
                loading={userLoading}
                disabled={!userSearched || Boolean(userSearched && user)}
              />
            </Item>
          </Col>

          <Col xs={24} md={12}>
            <Item name="email" rules={[{ required: true, message: 'Por favor, insira o endereço de email do cônjuge' }]}>
              <Input
                placeholder="Email do cônjuge"
                loading={userLoading}
                disabled={!userSearched || Boolean(userSearched && user)}
              />
            </Item>
          </Col>

          <Col xs={24} md={6}>
            <Item name="phoneNumber" rules={[{ required: true, message: 'Por favor, insira o numero do cônjuge' }]}>
              <Input
                guide
                loading={userLoading}
                disabled={!userSearched || Boolean(userSearched && user)}
                placeholder="Celular com DDD do cônjuge"
                mask="phone"
              />
            </Item>
          </Col>

          <Col xs={24} sm={24} lg={6}>
            <Item name="birthDate" rules={[{ required: true, message: 'Selecione a data de nascimento' }]}>
              <Input
                type="masked"
                guide
                loading={userLoading}
                disabled={!userSearched
                    || Boolean(userSearched && user && user?.birthDate)}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder="Data de nascimento"
              />
            </Item>
          </Col>

          {flow === 'badesc' && (
          <>

            <Col xs={24} sm={24} lg={12}>
              <Item name="gender" rules={[{ required: true, message: 'Selecione o sexo' }]}>
                <Select
                  className="gyramais-input"
                  disabled={!userSearched
                    || Boolean(userSearched && user && user?.additionalInfo?.gender)}
                  placeholder="Selecione o sexo"
                >
                  {genders?.map(({ id, name, value }) => (
                    <Option key={id} value={value}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </>
          )}
        </Row>
      </Form>

      <Alert
        message="O cônjuge será avalista do empréstimo"
      />
    </>
  );
};

CivilPartnerForm.propTypes = {
  form: PropTypes.shape(),
  businessUser: PropTypes.shape(),
  flow: PropTypes.string,
};

export default CivilPartnerForm;
