import React from 'react';
import PropTypes from 'prop-types';
import { Card, Alert } from 'components/gyramais';
import { formatString } from 'utils';

import './styles.less';

const TermCard = ({
  user: {
    fullName,
    cpf,
  } = {},
  business: {
    legalName,
    name,
  },
  alert,
  type = 'solteiro',
  address,
}) => (
  <>
    {Boolean(alert) && (
      <Alert
        className="terms-card-alert"
        message={alert}
      />
    )}
    <Card id="terms-card">
      <div className="terms-card-title" align="middle">
        {type === 'solteiro' && 'TERMO DE DECLARAÇÃO DE ESTADO CIVIL'}
        {type === 'uniao-estavel' && 'TERMO DE DECLARAÇÃO DE UNIÃO ESTÁVEL'}
      </div>
      <div className="terms-card-body">
        Declaro para os devidos fins e efeitos legais que
        <b className="highlight-text">{fullName}</b>
        , sócio(a) da empresa
        <b className="highlight-text">{name || legalName}</b>
        , inscrito(a) no CPF sob o número
        <b className="highlight-text">{(cpf && formatString(cpf, 'cpf')) || '-'}</b>
        , residente e domicilado(a)
        à
        <b className="highlight-text">{address?.street}</b>
        , nº
        <b className="highlight-text">{address?.number}</b>
        -
        <b className="highlight-text">{address?.district}</b>
        , CEP
        <b className="highlight-text">{(address?.postalCode && formatString(address.postalCode, 'cep')) || '-'}</b>
        , na cidade de
        <b className="highlight-text">{address?.city}</b>
        -
        <b className="highlight-text">{address?.state}</b>
        {type === 'solteiro' && (
          <span>
            , permanece no estado civil de
            <b className="highlight-text">Solteiro</b>
            no momento em que assino este termo, livre de relações
            de vida em comum ou união estável com outra pessoa
            nas condições previstas no artivo 1.723 do Código Civil.
          </span>
        )}
        {type === 'uniao-estavel' && (
          <span>
            , convive em
            <b className="highlight-text">União Estável</b>
            , de natureza familiar, pública e duradoura,
            com o objetivo de constituição da família, nos termos dos artivos
            1.723 e seguintes do Código Civil.
          </span>
        )}
      </div>
    </Card>
  </>
);

TermCard.propTypes = {
  user: PropTypes.shape(),
  address: PropTypes.shape(),
  business: PropTypes.shape(),
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  type: PropTypes.string,
};

export default TermCard;
