import React, { memo } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  Layout,
} from 'components/sdk';
import './styles.less';

const {
  Content,
} = Layout;

const NeededDocs = () => (
  <Content id="needed-docs" className="dark">
    <Row justify="space-between">
      <Col>
        <h2>
          <b>
            Quais documentos vou precisar?
          </b>
        </h2>

        <h6>
          Tenha em mãos os documentos necessários para sua avaliação
        </h6>
      </Col>
      <Col style={{ whiteSpace: 'pre-line' }}>
        <ul className="user-conditions">
          <li>Documento de identidade ou CNH</li>
          <li>Comprovante de renda</li>
          <li>Comprovante de endereço</li>
          <li>Nome, telefone, CPF e e-mail do(a) cônjugue ou averbação do divórcio</li>
        </ul>
      </Col>
    </Row>
  </Content>
);

export default memo(NeededDocs);
