// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#terms-card {
  padding: 1.4rem 2.2rem;
  margin-bottom: 1.4rem;
}
#terms-card .terms-card-alert {
  margin-bottom: 0.5rem;
}
#terms-card .terms-card-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.4rem;
}
#terms-card .terms-card-body {
  font-size: 1rem;
  font-weight: 400;
  text-align: justify;
}
#terms-card .terms-card-body > span {
  font-weight: 400;
}
#terms-card .terms-card-body .highlight-text {
  font-weight: bold;
  margin: 0 0.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/TermCard/styles.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,qBAAA;AACF;AAHA;EAKI,qBAAA;AACJ;AANA;EAQI,eAAA;EACA,iBAAA;EACA,qBAAA;AACJ;AAXA;EAaI,eAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAhBA;EAiBM,gBAAA;AAEN;AAnBA;EAoBM,iBAAA;EACA,gBAAA;AAEN","sourcesContent":["#terms-card {\n  padding: 1.4rem 2.2rem;\n  margin-bottom: 1.4rem;\n\n  .terms-card-alert {\n    margin-bottom: .5rem;\n  }\n  .terms-card-title {\n    font-size: 1rem;\n    font-weight: bold;\n    margin-bottom: 1.4rem;\n  }\n  .terms-card-body {\n    font-size: 1rem;\n    font-weight: 400;\n    text-align: justify;\n    > span {\n      font-weight: 400;\n    }\n    .highlight-text {\n      font-weight: bold;\n      margin: 0 0.3rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
