import React, { memo, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import Routes from 'routes';
import { Sider, HuggyChat } from 'components';
import ConfirmModalProvider from 'contexts/ConfirmModalContext/confirm-modal-context';
import PartnerActionsProvider from 'contexts/PartnerActionsContext';
import { notification } from 'antd';
import BusinessLoanProvider from 'contexts/BusinessLoanContext';

import { Layout } from './sdk';

const App = () => {
  useEffect(() => {
    notification.config({
      placement: 'topRight',
      duration: 10,
    });
  }, []);

  return (
    <Router>
      <BusinessLoanProvider>
        <PartnerActionsProvider>
          <ConfirmModalProvider>
            <HuggyChat />
            <ScrollMemory />

            <Layout>
              <Sider />
              <Routes />
            </Layout>

          </ConfirmModalProvider>
        </PartnerActionsProvider>
      </BusinessLoanProvider>

    </Router>
  );
};

export default memo(App);
