import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const PlatformCard = ({
  logo,
  iconComponent: IconComponent,
  name,
  id,
  onClick,
  description,
  className,
}) => {
  const handleLogo = () => {
    if (IconComponent) {
      return IconComponent;
    }

    if (logo) {
      return (<img src={logo.url} alt={name} />);
    }

    return name;
  };
  return (
    <button
      onClick={() => onClick?.()}
      id={id}
      className={`platform-card ${className}`}
      alt={`Botão para plataforma ${name}`}
      type="button"
    >
      {handleLogo()}
      {description && (
        <span>
          {description}
        </span>
      )}
    </button>
  );
};

PlatformCard.propTypes = {
  logo: PropTypes.shape(),
  iconComponent: PropTypes.shape(),
  name: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default memo(PlatformCard);
