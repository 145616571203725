import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';

const useURL = () => {
  const history = useHistory();
  const { search } = useLocation();

  /**
   * @type {{
   *   action: 'assinar' | 'entrada' | ''
   * }}
   */
  const { action } = qs.parse(search);

  return {
    action,
    removeQueryParam: (name) => {
      const queryParams = new URLSearchParams(search);

      if (!queryParams.has(name)) {
        return;
      }

      queryParams.delete(name);

      history.replace({
        search: queryParams.toString(),
      });
    },
  };
};

export {
  // eslint-disable-next-line import/prefer-default-export
  useURL,
};
