import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
} from 'components/gyramais';
import {
  Modal,
  Layout,
} from 'components/sdk';
import { ExclamationCircleOutlined } from '@ant-design/icons';

function StoneIntegrationModal({ visible, onSubmit, handleClose }) {
  const [countdown, setCountdown] = useState(5 * 60);

  useEffect(() => {
    if (countdown !== 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown]);

  const countdownAsDate = () => {
    const date = new Date(0);
    date.setSeconds(countdown);

    // 1970-01-01T00:00:01.000Z => 00:01
    return date.toISOString().split('.')[0].split('T00:')[1];
  };

  return (
    <Modal
      visible={visible}
      centered
      width={550}
      okButton={{
        text: 'Conexão autorizada',
        onClick: onSubmit,
      }}
      cancelButton={{
        text: 'Não recebi o email',
        onClick: handleClose,
      }}
      title="Acesse seu email e autorize a conexão"
      closable={false}
    >
      <Layout
        id="email-verification-modal-body"
        className="modal-body"
      >
        <Alert
          className="upload-card-alert"
          message={(
            <div className="alert-text">
              Na sua caixa de entrada, abra o email da Stone,
              autorize o acesso, volte aqui e clique no botão abaixo.
              Você só poderá conectar sua conta na Gyra+ após confirmar sua identidade.
            </div>
          )}
          type="info"
          showIcon
          icon={<ExclamationCircleOutlined className="bank-alert-icon" />}
        />

        <div className="timer">
          A autorização expira em:
          <span>{countdownAsDate()}</span>
        </div>
      </Layout>
    </Modal>
  );
}

StoneIntegrationModal.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default StoneIntegrationModal;
