import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { meVar } from 'services/graphql/cache';

const useValidateLoggedInUser = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const me = meVar();

  useEffect(() => {
    if (!me?.id) {
      return;
    }

    const { userId } = queryString.parse(search);

    if (!userId) {
      return;
    }

    const searchWithoutUser = search
      .replace(`&userId=${userId}&`, '')
      .replace(`&userId=${userId}`, '')
      .replace(`userId=${userId}&`, '')
      .replace(`userId=${userId}`, '');

    if (userId === me.id) {
      history.push(`${pathname}${searchWithoutUser}`);
      return;
    }

    localStorage.clear();
    document.location.href = `/login?redirect=${pathname}${searchWithoutUser}`;
  }, [me, search]);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  useValidateLoggedInUser,
};
