// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-user-card {
  margin-bottom: 1rem !important;
  transition: 0.4s;
}
.business-user-card .buttons-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.business-user-card .buttons-col .gyramais-btn {
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .business-user-card .buttons-col {
    width: 100%;
    justify-content: space-between;
    padding-top: 1rem;
  }
  .business-user-card .buttons-col .gyramais-btn {
    margin-left: 0;
  }
  .business-user-card .buttons-col .gyramais-btn-link {
    width: 2rem;
  }
}
.business-user-card .add-type-icon {
  color: var(--primary-button-color);
  margin-left: 0.6rem;
  cursor: pointer;
  font-size: 2rem;
  transition: 1s;
}
.business-user-card .add-type-icon:hover {
  transform: rotateZ(270deg);
}
.business-user-card.incomplete {
  animation: warning 1.5s infinite;
}
.business-user-card .conjuge-card-icon {
  font-size: 3rem;
  color: var(--primary-button-color);
  margin-top: 0.6rem;
}
.business-user-card .remove-card-icon {
  color: var(--link-button-color);
  font-size: 1.6rem;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/BusinessUserCard/styles.less"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,gBAAA;AACF;AAHA;EAKI,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AARA;EAUM,iBAAA;AACN;AAEI;EAAA;IACE,WAAA;IACA,8BAAA;IACA,iBAAA;EACJ;EAJE;IAMI,cAAA;EACN;EAPE;IAUI,WAAA;EAAN;AACF;AAxBA;EA6BI,kCAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,cAAA;AAFJ;AAII;EACE,0BAAA;AAFN;AAME;EACE,gCAAA;AAJJ;AArCA;EA6CI,eAAA;EACA,kCAAA;EACA,kBAAA;AALJ;AA1CA;EAmDI,+BAAA;EACA,iBAAA;EACA,eAAA;AANJ","sourcesContent":[".business-user-card {\n  margin-bottom: 1rem !important;\n  transition: .4s;\n\n  .buttons-col {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n\n    .gyramais-btn {\n      margin-left: 1rem;\n    }\n\n    @media(max-width: @md-screen) {\n      width: 100%;\n      justify-content: space-between;\n      padding-top: 1rem;\n\n      .gyramais-btn {\n        margin-left: 0;\n      }\n\n      .gyramais-btn-link {\n        width: 2rem;\n      }\n    }    \n  }\n\n  .add-type-icon {\n    color: var(--primary-button-color);\n    margin-left: .6rem;\n    cursor: pointer;\n    font-size: 2rem;\n    transition: 1s;\n\n    &:hover {\n      transform: rotateZ(270deg);\n    }\n  }\n\n  &.incomplete {\n    animation: warning 1.5s infinite;\n  }\n\n  .conjuge-card-icon {\n    font-size: 3rem;\n    color: var(--primary-button-color);\n    margin-top: .6rem;\n  }\n\n  .remove-card-icon {\n    color: var(--link-button-color);\n    font-size: 1.6rem;\n    cursor: pointer;\n  }\n}\n\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
