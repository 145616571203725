const downloadFile = (content, name) => {
  const url = window.URL.createObjectURL(content);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

module.exports = {
  downloadFile,
};
