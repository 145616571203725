import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import {
  LoanChances,
} from 'components';
import './styles.less';

const LoanChancesAlertModal = ({
  visible,
  onClose,
  onClickContinue,
  onClickIncrease,
  business,
  necessaryDocumentsFlow,
}) => (
  <>
    <Modal
      visible={visible}
      onClose={onClose}
      closable={false}
      title="Que tal aumentar suas chances de aprovação?"
      centered
      okButton={{
        id: 'increase-chances-button',
        text: 'Aumentar chances',
        onClick: onClickIncrease,
      }}
      cancelButton={{
        text: 'Continuar assim mesmo',
        onClick: onClickContinue,
      }}
    >
      <div className="chances-alert-modal">
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <p className="no-margin-bottom">
              <b>
                Falta muito pouco para você alcançar o nível máximo de chances de aprovação!
                Que tal aproveitar essa segunda chance pra completar seu cadastro
                e não encontrar complicações na sua análise?
              </b>
            </p>
          </Col>
          <Col span={24}>
            <LoanChances
              business={business}
              className="chances-in-alert-modal"
              necessaryDocumentsFlow={necessaryDocumentsFlow}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  </>
);

LoanChancesAlertModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onClickContinue: PropTypes.func,
  onClickIncrease: PropTypes.func,
  business: PropTypes.shape(),
  necessaryDocumentsFlow: PropTypes.string,
};

export default LoanChancesAlertModal;
