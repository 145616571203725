/* eslint-disable react/no-array-index-key */
// @ts-check
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Select,
} from 'antd';

import { formatString } from 'utils';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

const { Option } = Select;

/**
 * @type {React.FC}
 */
const PromoRenegotiationCard = () => {
  const {
    loan: {
      installmentAmount: originalInstallmentAmount,
    },
    installmentOptionsData,
    cashPaymentRestAmount,
    interestRateDiscount,
    cashDiscount,
    selectInstallmentCount,
    installmentCountSelected,
    roundedCashDiscount,
    discountedInterestRate,
    remainingInstallmentsCount,
    canShowInstallment,
  } = usePromoRenegotiationContext();

  const showInterestRateDisclaimer = Boolean(interestRateDiscount && discountedInterestRate);

  const handleInstallmentOptions = () => (
    installmentOptionsData?.map(({ installmentAmount, installmentCount, discountPercent }) => {
      const canShow = canShowInstallment({
        installmentAmount,
        discountPercent,
      });

      if (!canShow) {
        return null;
      }

      let backgroundTextToShow = (
        <>
          {`${discountPercent}% `}
          <span className="mobile discount-one-line">de desconto</span>
        </>
      );

      if (discountPercent <= 0) {
        const discountedAmount = Math.round(originalInstallmentAmount - installmentAmount);
        backgroundTextToShow = (
          <>
            {`- R$ ${discountedAmount} `}
            <span className="mobile">por parcela</span>
            <span className="per-invoice-two-lines">
              <div>por</div>
              <div>parcela</div>
            </span>
          </>
        );
      }

      return (
        <Option
          className="promotion-gyra-select-option"
          value={installmentCount}
          title={`${installmentCount} parcelas`}
          key={installmentAmount + installmentCount}
        >
          {installmentCount} x de
          <span className="select-value">
            {formatString(installmentAmount, 'currency')}
            {showInterestRateDisclaimer ? '*' : ''}
          </span>

          <div className="background-text">
            {backgroundTextToShow}
          </div>
        </Option>
      );
    })
  );

  if (!originalInstallmentAmount || !remainingInstallmentsCount) {
    return null;
  }

  return (
    <>
      <Select
        id="select-promo"
        className="gyramais-input select-item promotion-gyra-select"
        value={installmentCountSelected}
        onChange={selectInstallmentCount}
        data-cy="select-promo-select"
      >
        {cashDiscount && (
        <Option
          className="promotion-gyra-select-option"
          key={1}
          value={1}
          title="a vista"
        >
          1x de
          <span className="select-value">
            {formatString(cashPaymentRestAmount * (1 - cashDiscount), 'currency')}
          </span>
          {' '}
          <div className="background-text">
            🔥  {roundedCashDiscount}%
            {' '}
            <span className="mobile discount-one-line">de desconto</span>
          </div>
        </Option>
        )}
        {handleInstallmentOptions()}
      </Select>
    </>
  );
};

export default React.memo(PromoRenegotiationCard);
