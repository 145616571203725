/* eslint-disable react/prop-types */
import React from 'react';
import {
  Col,
  Row,
} from 'antd';
import { Card } from 'components';

import './styles.less';

/**
 * @type {React.FC<{
 *  paymentDays: number[]
 *  onDaySelected: Function
 *  selectedDay: number
 *  flow?: string
 * }>}
 */
const PaymentDayCard = ({
  paymentDays,
  onDaySelected,
  selectedDay,
  flow,
  hasPaymentDateAction,
}) => (
  <Card
    title={(flow === 'badesc' || hasPaymentDateAction) ? 'O dia do pagamento será em:' : 'Selecionar dia para pagamento'}
    className="payment-day-card"
  >
    <Row
      gutter={[
        {
          xs: 40,
          md: 92,
        },
        {
          xs: 38,
          md: 46,
        },
      ]}
    >
      {paymentDays.map((paymentDate) => (
        <Col xs={8} key={`${paymentDate}-k`}>
          <div
            id={`day-${paymentDate}-option`}
            className={`option ${selectedDay === paymentDate ? 'selected' : ''} ${(flow === 'badesc' || hasPaymentDateAction) && selectedDay !== paymentDate ? 'option-disabled' : ''}`}
            onClick={() => (flow !== 'badesc' && !hasPaymentDateAction) && onDaySelected?.(paymentDate)}
            role="button"
            tabIndex="-1"
            onKeyPress={() => false}
          >
            {paymentDate}
          </div>
        </Col>
      ))}
    </Row>
  </Card>
);

export default PaymentDayCard;
