/* eslint-disable react/prop-types */

import React from 'react';
import { Slider } from 'components/sdk';
import {
  Card,
  Information,
} from 'components/gyramais';
import {
  Row,
  Col,
} from 'antd';
import { formatCurrency } from 'utils';
import BlurWithMessage from '../BlurWithMessage';

import './styles.less';

let debounce;

/**
 *
 * @param {{
 *  minValue: number,
 *  maxValue: number,
 *  value: number,
 *  onChange: (value: number) => void,
 *  monthFee: number,
 *  effectiveInterestRate: number
 * }} props
* @returns {React.FC}
*/
const PrepaymentSliderCard = ({
  minValue,
  maxValue,
  value,
  onChange,
  monthFee,
  effectiveInterestRate,
}) => (
  <Card className={`prepayment-slider-card card ${maxValue === 0 && 'select-some-integration'}`}>
    <BlurWithMessage
      blur={maxValue === 0}
      message="Selecione uma opção acima ou conecte mais contas
      para liberar a configuração da sua antecipação."
    />

    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h6 className="no-margin-bottom">
          <b className="weight-500">
            Defina o valor a ser antecipado
          </b>
        </h6>
      </Col>

      <Col span={24}>
        <Slider
          tooltipVisible={false}
          min={minValue}
          max={maxValue}
          value={value}
          onChange={(e) => {
            clearTimeout(debounce);

            debounce = setTimeout(() => {
              onChange(e);
            }, 5);
          }}
          step={0.1}
          showValues
          valuesFormatter={(e) => formatCurrency(e)}
          input={{ type: 'currency' }}
        />
      </Col>

      <Col span={24}>
        <hr />
      </Col>

      <Col xs={12} sm={12} md={8}>
        <Information
          label="Taxa ao mês"
          value={`${monthFee.toFixed(2)}%`}
        />
      </Col>

      <Col xs={12} sm={12} md={8}>
        <Information
          label="Taxa efetiva"
          value={`${(effectiveInterestRate * 100).toFixed(2)}%`}
        />
      </Col>
    </Row>
  </Card>
);

export default PrepaymentSliderCard;
