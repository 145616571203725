import { Loading3QuartersOutlined } from '@ant-design/icons';
import React from 'react';

import './styles.less';

const SpinIndicator = () => (
  <div className="spin-indicator">
    <Loading3QuartersOutlined spin />
    <h3>Aguarde!</h3>
    <p>Estamos atualizando os valores</p>
  </div>
);

export default SpinIndicator;
