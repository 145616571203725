/* eslint-disable max-len */
import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SubHeader,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import { SignatureFeatureDrawer } from 'components';
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription,
} from '@apollo/client';
import ConnectorDrawer from 'components/drawers/ConnectorDrawer';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { formatAndNotificateError } from 'utils';
import { Spin } from 'antd';
import {
  SET_NEXT_MODULE_FROM_USER_INTERACTION,
  SET_PREVIOUS_MODULE_FROM_USER_INTERACTION,
  PREPAYMENT_OPPORTUNITIES,
  REQUEST_PREPAYMENT,
  SIGN_PREPAYMENT,
  FACE_MATCH_UPDATED,
  TOOLBOX_LOCATION_UPDATED,
  PRE_PAYMENTS,
  REGISTER_CLIENTE_RECEIVABLES,
} from './graphql';
import './styles.less';
import PrepaymentSliderCard from './components/PrepaymentSliderCard';
import PrepaymentValueCard from './components/PrepaymentValueCard';
import ReceivableList from './components/ReceivableList';
import PrepaymentHistory from './components/PrepaymentHistory';
import IntegrationList from './components/IntegrationList';
import SpinIndicator from './components/SpinIndicator';

const { Content } = Layout;

const PrePaymentScreen = () => {
  const [prepaymentAmount, setPrepaymentAmount] = useState(0);
  const [searchedFromPrepayments, setSearchedFromPrepayments] = useState(false);

  const [platformDrawerVisible, setPlatformDrawerVisible] = useState(false);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);

  const [signatureEnabled, setSignatureEnabled] = useState(false);

  const {
    businessId,
    partnerId,
    toolboxSetup,
    toolboxLocation,
    toolboxLoading,
    refetchToolboxLocation,
    ip,
  } = useBusinessLoanContext();

  const integrationsToConnect = toolboxSetup?.find(({ interaction }) => interaction === 'user-connect-integrations')?.config?.selectedIntegrationValues ?? [];
  const acquirerNames = toolboxSetup?.find(({ interaction }) => interaction === 'user-connect-integrations')?.config?.acquirerNames ?? [];
  let acquirerNamesString = acquirerNames?.join(', ');
  acquirerNamesString = acquirerNamesString?.replace(/,(?=[^,]*$)/, ' ou ');
  const startConfigs = toolboxSetup?.find(({ interaction }) => interaction === 'start')?.config ?? {};
  const useRegister = toolboxSetup?.find(({ interaction }) => interaction === 'user-connect-integrations')?.config?.useRegistrator ?? false;

  const {
    data: {
      prepayments = [],
    } = {},
    refetch: refetchPrepayments,
  } = useQuery(PRE_PAYMENTS, {
    variables: { businessId, notStatuses: ['awaiting-signature', 'expired', 'canceled'] },
  });

  const [setNextModule] = useMutation(SET_NEXT_MODULE_FROM_USER_INTERACTION, {
    variables: { businessId, partnerId },
    onCompleted: () => {
      refetchToolboxLocation();
    },
  });
  const [setPreviousModule] = useMutation(SET_PREVIOUS_MODULE_FROM_USER_INTERACTION, {
    variables: { businessId, partnerId },
    onCompleted: () => setSignatureEnabled(false),
  });
  const [requestPrepayment, { loading: requestLoading }] = useMutation(REQUEST_PREPAYMENT, {
    onCompleted: async () => {
      setNextModule();
    },
    onError: (error) => formatAndNotificateError(error),
  });
  const [signPrepayment] = useMutation(SIGN_PREPAYMENT, {
    onCompleted: () => {
      setSignatureEnabled(false);
      refetchToolboxLocation();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    getPrepaymentOpportunities,
    {
      data: {
        prepaymentOpportunities = [],
      } = {},
      loading: prepaymentOpportunitiesLoading,
    },
  ] = useLazyQuery(PREPAYMENT_OPPORTUNITIES);

  const [
    registerClientReceivables,
    { loading: registerClientReceivablesLoading },
  ] = useMutation(REGISTER_CLIENTE_RECEIVABLES, {
    onCompleted: () => {
      setSearchedFromPrepayments(true);
      refetchToolboxLocation();
    },
  });

  useSubscription(TOOLBOX_LOCATION_UPDATED, { onSubscriptionData: () => refetchToolboxLocation() });
  useSubscription(FACE_MATCH_UPDATED, { onSubscriptionData: () => refetchToolboxLocation() });

  useEffect(() => {
    if (toolboxLocation?.interaction) {
      const { interaction } = toolboxLocation;

      switch (interaction) {
        case 'user-connect-integrations':
          if (useRegister && !prepayments[0]) {
            registerClientReceivables({
              variables: {
                acquirersCnpj: toolboxLocation.config.acquirersCnpj,
              },
            });
          } else {
            setPlatformDrawerVisible(true);
          }
          break;
        case 'user-choose-prepayment-offer':
          getPrepaymentOpportunities();
          break;
        case 'user-sign-prepayment':
          setSignatureEnabled(true);
          break;
        default:
          break;
      }
    }
  }, [toolboxLocation?.interaction]);

  const {
    /**
     * @type {Object.<string, {
     *  receivables: Receivable[]
     *  presentValue: number
     * }>}
     */
    receivablesDataByIntegrationTypeId,
  } = useMemo(() => {
    const receivablesDataByIntegrationTypeId = {};

    prepaymentOpportunities.forEach(({ integrationType, receivables }) => {
      receivablesDataByIntegrationTypeId[integrationType.id] = {};
      receivablesDataByIntegrationTypeId[integrationType.id].receivables = receivables;
      receivablesDataByIntegrationTypeId[integrationType.id].presentValue = 0;

      receivables.forEach(({ presentValue }) => {
        receivablesDataByIntegrationTypeId[integrationType.id].presentValue += presentValue;
      });
    });

    const keys = Object.keys(receivablesDataByIntegrationTypeId);
    if (keys[0]) {
      setSelectedIntegrations(Object.keys(receivablesDataByIntegrationTypeId));
    }
    return { receivablesDataByIntegrationTypeId };
  }, [prepaymentOpportunities]);

  const {
    selectedReceivableUnits,
  } = useMemo(() => {
    const selectedReceivableUnits = prepaymentOpportunities.filter(
      ({ integrationType }) => selectedIntegrations.includes(integrationType.id),
    );

    const _selectedReceivableUnits = selectedReceivableUnits.reduce(
      (acc, { integrationType, receivables }) => {
        const newReceivables = [
          ...acc,
          ...receivables.map((receivable) => ({
            ...receivable,
            integrationType,
          })),
        ];
        return newReceivables;
      }, [],
    );

    return {
      selectedReceivableUnits: _selectedReceivableUnits,
    };
  }, [prepaymentOpportunities, selectedIntegrations]);

  const selectedIntegrationAmount = useMemo(() => {
    const _integrations = prepaymentOpportunities.filter(
      ({ integrationType }) => integrationType && selectedIntegrations.includes(integrationType.id),
    );

    const integrationsWithPrePayments = Object.keys(receivablesDataByIntegrationTypeId);
    const _integrationsWithPrePayments = _integrations.filter(
      ({ integrationType }) => integrationsWithPrePayments.includes(integrationType?.id),
    );

    const newMaxAmount = _integrationsWithPrePayments.reduce(
      (acc, { summary }) => acc + summary.totalPresentValue, 0,
    );

    setPrepaymentAmount(newMaxAmount);
    return newMaxAmount;
  }, [selectedIntegrations]);

  const affectedReceivables = useMemo(() => {
    const obj = {
      receivables: [],
      totalCurrentAmount: 0,
    };

    selectedReceivableUnits.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate - bDate;
    }).forEach((receivable) => {
      if (obj.totalCurrentAmount <= prepaymentAmount) {
        obj.receivables.push(receivable);
        obj.totalCurrentAmount += receivable.presentValue;
      }
    });

    return obj.receivables;
  }, [prepaymentAmount, selectedReceivableUnits]);

  const {
    totalReceivableValue,
    totalReceivablePresentValue,
    percentageUsedOfLastReceivable,
  } = useMemo(() => {
    let totalValue = 0;
    let totalPresentValue = 0;
    let percentageUsedOfLastReceivable = 0;

    affectedReceivables.forEach((receivable) => {
      if (totalPresentValue + receivable.presentValue >= prepaymentAmount) {
        // Caso o valor passe, não vai ser adicionado por completo e sim o valor que falta

        const missingValue = prepaymentAmount - totalPresentValue;
        const missingPercentage = missingValue / receivable.presentValue;

        totalPresentValue += missingValue;
        totalValue += receivable.amount * missingPercentage;
        percentageUsedOfLastReceivable = missingPercentage;
      } else {
        totalValue += receivable.amount;
        totalPresentValue += receivable.presentValue;
      }
    });

    return {
      totalReceivableValue: totalValue,
      totalReceivablePresentValue: totalPresentValue,
      percentageUsedOfLastReceivable,
    };
  }, [affectedReceivables]);

  const cet = useMemo(() => {
    const percentage = (totalReceivableValue / totalReceivablePresentValue) - 1;

    if (Number.isNaN(percentage)) return 0;

    return percentage;
  }, [totalReceivableValue, totalReceivablePresentValue]);

  const pageLoading = (prepaymentOpportunitiesLoading
    || registerClientReceivablesLoading
    || toolboxLoading);

  return (
    <Layout id="prepayments-screen">
      <Spin
        spinning={
          pageLoading
          || (toolboxLocation?.interaction === 'user-connect-integrations' && !prepayments[0])
        }
        indicator={<SpinIndicator />}
        className="prepayment-spin"
      >
        <ConnectorDrawer
          visible={platformDrawerVisible}
          onClose={() => { setPlatformDrawerVisible(false); }}
          filteredIntegrations={integrationsToConnect}
        />
        <SubHeader
          title="Antecipação de recebíveis"
          subtitle={
            'Mais capital de giro para sua empresa! \r'
            + 'Antecipe suas vendas e mantenha o equilíbrio financeiro do seu negócio.'
          }
          secondary
          buttons={
            useRegister || toolboxLoading ? null : (
              <>
                <Button
                  id="connect-more-accounts-button"
                  onClick={() => setPlatformDrawerVisible(true)}
                >
                  Conectar mais contas
                </Button>
              </>
            )
          }
          buttonsPlacement="right"
        />

        <Content>
          <div className="content-wrapper">
            <IntegrationList
              receivableUnits={prepaymentOpportunities}
              setPlatformDrawerVisible={setPlatformDrawerVisible}
              handleUserInteraction={setNextModule}
              setSelectedIntegrations={setSelectedIntegrations}
              selectedIntegrations={selectedIntegrations}
              pageLoading={pageLoading}
              receivablesDataByIntegrationTypeId={receivablesDataByIntegrationTypeId}
              registerClientReceivables={registerClientReceivables}
            />

            {
              (searchedFromPrepayments
                || toolboxLocation?.interaction === 'user-choose-prepayment-offer')
              && !prepaymentOpportunities[0]
              && !pageLoading
              && (
                <div className="no-integrations-message">
                  <p className="title">
                    Não encontramos vendas para antecipar
                  </p>
                  <p className="subtitle">
                    No momento não encontramos vendas para antecipar na {acquirerNamesString} que totalizem pelo menos {' '} {
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(startConfigs.minPrepaymentValue)
                    }. Tente novamente mais tarde.

                  </p>
                </div>
              )
            }

            {toolboxLocation?.interaction === 'user-choose-prepayment-offer'
              && prepaymentOpportunities[0]
              && !pageLoading
              && (
                <>
                  <div className="prepayment-value-wrapper">
                    <PrepaymentSliderCard
                      minValue={startConfigs.minPrepaymentValue}
                      maxValue={selectedIntegrationAmount}
                      value={prepaymentAmount}
                      onChange={(e) => {
                        setPrepaymentAmount(e);
                      }}
                      monthFee={startConfigs.baseFee}
                      effectiveInterestRate={cet}
                    />

                    <PrepaymentValueCard
                      prepaymentValue={prepaymentAmount}
                      totalFee={totalReceivableValue - totalReceivablePresentValue}
                      loading={requestLoading}
                      onNeedPrepayment={() => {
                        requestPrepayment({
                          variables: {
                            businessId,
                            partnerId,
                            amount: prepaymentAmount,
                            integrationTypesIds: selectedIntegrations,
                          },
                        });
                      }}
                    />
                  </div>

                  <div className="receivable-list-wrapper">
                    <ReceivableList
                      blur={pageLoading || prepaymentAmount === 0}
                      affectedReceivables={affectedReceivables}
                      percentageUsedOfLastReceivable={percentageUsedOfLastReceivable}
                    />
                  </div>
                </>
              )}

            <div className="receivable-history-wrapper">
              <PrepaymentHistory
                blur={pageLoading}
                prepayments={prepayments}
              />
            </div>
          </div>
        </Content>

        <SignatureFeatureDrawer
          enabled={signatureEnabled}
          title={toolboxLocation?.config?.title}
          stepsIds={toolboxLocation?.config?.stepsIds}
          onFinish={({ prepaymentId }) => {
            signPrepayment({
              variables: {
                ip,
                prepaymentId,
              },
            });
          }}
          onCancel={setPreviousModule}
          onBackToPrepayments={() => {
            refetchPrepayments();
            setSelectedIntegrations([]);
          }}
        />
      </Spin>
    </Layout>
  );
};

export default memo(PrePaymentScreen);
