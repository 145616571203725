import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  notification,
} from 'antd';
import {
  Input,
  Alert,
} from 'components/gyramais';
import {
  Modal,
  Layout,
} from 'components/sdk';
import queryString from 'query-string';
import ReactCodeInput from 'react-verification-code-input';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { RESEND_SIGN_UP_EMAIL, UPDATE_EMAIL, VALIDATE_VERIFICATION_CODE } from './graphql';

import './styles.less';

const { Item, useForm } = Form;

function EmailVerificationModal({
  visible,
  passwordToken,
  onCodeVerified,
}) {
  const [form] = useForm();
  const history = useHistory();
  const [isUpdatingEmail, setUpdatingEmail] = useState(false);
  const [countdown, setCountdown] = useState(5 * 60);
  const [_visible, setVisible] = useState(false);
  const [errorMsg, setErroMsg] = useState('');
  const [resendSigUpEmail, { loading: resendingEmail }] = useMutation(RESEND_SIGN_UP_EMAIL);
  const [updateEmail, { loading: updatingEmail }] = useMutation(UPDATE_EMAIL);
  const [
    validateVerificationCode,
    { loading: validatingCode },
  ] = useMutation(VALIDATE_VERIFICATION_CODE);
  const { email: sentEmail } = queryString.parse(useLocation().search);
  const [email, setEmail] = useState(sentEmail);

  const handleCodeInput = async (verificationCode) => {
    if (validatingCode) {
      return;
    }

    try {
      await validateVerificationCode({
        variables: {
          passwordToken,
          verificationCode,
        },
      });
    } catch (err) {
      setErroMsg(err.message);
      return;
    }

    notification.success({
      message: 'Código verificado com sucesso!',
      description: 'Agora você pode escolher uma senha para sua conta!',
    });

    // adiciona o verification-code na url para permitir um reload
    // sem abrir novamente o modal
    history.replace(`${window.location.pathname}${window.location.search}&verification-code=${verificationCode}`);

    onCodeVerified(verificationCode);
    setVisible(false);
  };

  const handleResendCode = async () => {
    await resendSigUpEmail({
      variables: {
        passwordToken,
      },
    });

    notification.success({
      message: 'Código de verificação reenviado!',
      description: 'Dentro de alguns segundos, verifique se você recebeu o código na caixa de entrada do seu email. Caso o erro persista, entre em contato através do nosso número do WhatsApp.',
    });
  };

  const handleUpdateEmail = async () => {
    await form.validateFields();
    const { newEmail } = form.getFieldsValue();

    await updateEmail({
      variables: {
        passwordToken,
        newEmail,
      },
    });

    notification.success({
      message: 'Sucesso!',
      description: `Seu email foi alterado para ${newEmail} e reenviamos o seu código!`,
    });

    setEmail(newEmail);
    setUpdatingEmail(false);
  };

  useEffect(() => {
    if (countdown !== 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown]);

  const countdownAsDate = () => {
    const date = new Date(0);
    date.setSeconds(countdown);

    // 1970-01-01T00:00:01.000Z => 00:01
    return date.toISOString().split('.')[0].split('T00:')[1];
  };

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const codeBody = (
    <>
      <Alert
        className="upload-card-alert"
        message={(
          <>
            Por motivos de segurança, enviamos para a caixa de entrada do email
            {' '}
            <b>{email}</b>
            {' '}
            um código de
            {' '}
            <b>4 dígitos</b>
            . Insira o código abaixo para confirmarmos seu endereço.
          </>
          )}
      />

      <br />

      <div align="center">
        <h4>
          O código expira em:
          {' '}
          <span className="secondary-text">{countdownAsDate()}</span>
        </h4>
      </div>

      <ReactCodeInput
        type="number"
        inputMode="tel"
        fields={4}
        onChange={async (code) => {
          if (code.length === 4) {
            await handleCodeInput(code);
          }
        }}
        autoFocus
      />

      <div className="error-msg">
        {errorMsg}
      </div>
    </>
  );

  const updateEmailBody = (
    <>
      <Alert
        className="upload-card-alert"
        message="Tenha certeza de inserir um email que você tenha acesso. Caso contrário, você não será capaz de receber o token para a validação do endereço."
      />

      <Form
        style={{ marginTop: '20px' }}
        layout="vertical"
        form={form}
      >
        <Item
          label="Email"
          name="newEmail"
          rules={[
            { required: true, message: 'Por favor preencha o email' },
            { type: 'email', message: 'Email inválido' },
          ]}
        >
          <Input placeholder="E-mail" />
        </Item>
      </Form>
    </>
  );

  return (
    <Modal
      visible={_visible}
      centered
      width={550}
      title="Verifique seu endereço de email"
      onClose={() => setVisible(false)}
      okButton={isUpdatingEmail ? {
        text: 'Alterar e reenviar',
        loading: updatingEmail,
        onClick: handleUpdateEmail,
      } : {
        text: 'Reenviar código',
        loading: resendingEmail,
        onClick: handleResendCode,
      }}
      cancelButton={isUpdatingEmail ? {
        text: 'Voltar',
        onClick: () => setUpdatingEmail(false),
      } : {
        text: 'Alterar email',
        onClick: () => setUpdatingEmail(true),
      }}
    >
      <Layout
        id="email-verification-modal-body"
        className="modal-body"
      >
        {isUpdatingEmail ? updateEmailBody : codeBody}
      </Layout>
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  visible: PropTypes.bool,
  passwordToken: PropTypes.string,
  onCodeVerified: PropTypes.func,
};

export default EmailVerificationModal;
