import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  Drawer,
  UploadCard,
} from 'components';
import { formatAndNotificateError } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import {
  SAVE_BUSINESS,
} from './graphql';

const {
  useForm,
  Item,
} = Form;

const IncreaseChancesDrawer = ({
  visible,
  onClose,
  business,
  refetch,
}) => {
  const [form] = useForm();

  const [saveBusiness, { loading }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      refetch();
      onClose();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  useEffect(() => {
    if (business) {
      form.setFieldsValue({
        majorityPartnerSelfieFileId: business?.majorityPartnerSelfie?.id,
        imagesFilesIds: business?.images?.length > 0 ? business.images.map(({ id }) => id) : [],
      });
    }
  }, [business]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title="Completar cadastro"
      primaryButton={{
        id: 'complete-increase-chances-button',
        children: 'Concluir cadastro',
        loading,
        onClick: async () => {
          const variables = {
            id: business.id,
            imagesFilesIds: form.getFieldsValue().imagesFilesIds,
            majorityPartnerSelfieFileId: form.getFieldsValue().majorityPartnerSelfieFileId,
          };

          await saveBusiness({
            variables,
          });
        },
      }}
    >
      <Form
        form={form}
      >
        <Row>
          {business?.segment?.value !== '2' && (
          <Col span={24}>
            <Item name="imagesFilesIds">
              <UploadCard
                id="images-upload"
                accept="image/png,image/jpeg"
                actualFile={business?.images}
                multiples
                refetch={refetch}
                label="Fotos da fachada, do interior e do estoque do seu negócio (opcional)"
                onCompleted={async ({ id }) => {
                  const ids = form.getFieldValue('imagesFilesIds');
                  ids.push(id);

                  await form.setFieldsValue({ imagesFilesIds: ids });
                }}
                onDelete={async ({ id }) => {
                  const ids = form.getFieldValue('imagesFilesIds');
                  const newArrayOfIds = [];
                  ids.map((index) => index !== id && newArrayOfIds.push(index));

                  await form.setFieldsValue({ imagesFilesIds: newArrayOfIds });
                }}
              />
            </Item>
          </Col>
          )}

          <Col span={24}>
            <Item name="majorityPartnerSelfieFileId">
              <UploadCard
                id="selfie-major-upload"
                accept="image/png,image/jpeg,application/pdf"
                actualFile={business?.majorityPartnerSelfie}
                label="Selfie do sócio majoritário com documento de identidade (opcional)"
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ majorityPartnerSelfieFileId: id });
                }}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

IncreaseChancesDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  business: PropTypes.shape(),
  refetch: PropTypes.func,
};

export default IncreaseChancesDrawer;
