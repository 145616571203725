import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
  Form,
  notification,
  Col,
} from 'antd';
import {
  Drawer,
  CustomStep,
} from 'components/gyramais';
import {
  AddressForm,
  NewPartnerForm,
  CivilPartnerForm,
  SuccessMessage,
  DocumentsForm,
} from 'components';
import { formatAndNotificateError } from 'utils';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  SAVE_BUSINESS_USER,
  SAVE_ADDRESS,
  COMPLETE_BUSINESS_USER,
  SAVE_MARRIAGE_OR_DIVORCE_DOCS,
  BUSINESS,
  GENERATE_PROOF_OF_CIVIL_STATUS,
} from './graphql';

import './styles.less';

const { Step } = Steps;
const { useForm } = Form;
let carouselRef;

const getContext = (type) => {
  const contexts = {
    socio: 'sócio(a)',
    avalista: 'avalista',
    representantelegal: 'representante legal',
  };

  const context = contexts[type];
  if (context) {
    return context;
  }

  return 'responsável';
};

const BusinessUserDrawer = ({
  visible,
  onClose,
  businessId,
  onAddNewUser,
  refetch,
  businessUser: selectedBusinessUser,
  setSelectedBusinessUser,
  flow,
  me,
  type,
  necessaryDocumentsFlow,
  setBusinessUsersRevised,
  businessUsersRevised,
  isCreation,
  loanStatus,
}) => {
  const [form] = useForm();
  const [current, setCurrent] = useState(0);
  const [step, setStep] = useState(0);
  const [civilStatus, setCivilStatus] = useState();
  const [couple, setCouple] = useState(false);
  const [address, setAddress] = useState(false);
  const { requestConfirmation } = useConfirmModalContext();

  const {
    data: {
      business = {},
    } = {},
  } = useQuery(BUSINESS, {
    variables: { id: businessId },
    skip: !businessId,
  });

  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: ({ saveBusinessUser = {} }) => {
      setSelectedBusinessUser(saveBusinessUser);
      if ((couple && step === 3) || (!couple && step === 2)) {
        return;
      }

      setStep(1);
      refetch();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [generateProofOfCivilStatus] = useMutation(GENERATE_PROOF_OF_CIVIL_STATUS, {
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    saveMarriageOrDivorceDocs, { loading: docsLoading },
  ] = useMutation(SAVE_MARRIAGE_OR_DIVORCE_DOCS, {
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    saveBusinessUserPartner, { loading: savePartnerLoading },
  ] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: async () => {
      setStep(2);
      refetch();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [completeBusinessUser, { loading: completeLoading }] = useMutation(COMPLETE_BUSINESS_USER, {
    onCompleted: () => {
      setCurrent(1);
      refetch();
      form.resetFields();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveAddress, { loading: addressLoading }] = useMutation(SAVE_ADDRESS, {
    onCompleted: async () => {
      if (couple) setStep(3);
      else setStep(2);
      refetch();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const isLoading = saveLoading
    || addressLoading
    || completeLoading
    || savePartnerLoading
    || docsLoading;

  const steps = [
    {
      title: `Dados do(a) ${getContext(type)}`,
      content: (
        <NewPartnerForm
          businessUser={selectedBusinessUser}
          form={form}
          flow={flow}
          setCivilStatus={setCivilStatus}
          civilStatus={civilStatus}
        />
      ),
    }, {
      title: 'Endereço',
      content: (
        <AddressForm
          current
          flow={flow}
          postalCodePlaceholder="CEP"
          form={form}
          address={selectedBusinessUser?.user?.address || {}}
        />
      ),
    }, {
      title: 'Documentos',
      content: (
        <DocumentsForm
          form={form}
          civilStatus={civilStatus}
          businessUser={selectedBusinessUser}
          business={business}
          address={selectedBusinessUser?.user?.address}
          flow={flow}
          me={me}
          necessaryDocumentsFlow={necessaryDocumentsFlow}
          lemitValidated={selectedBusinessUser?.user?.address?.lemitValidated}
          setBusinessUsersRevised={setBusinessUsersRevised}
          businessUsersRevised={businessUsersRevised}
          isCreation={isCreation}
          loanStatus={loanStatus}
        />
      ),
    },
  ];

  const marriedSteps = [
    {
      title: `Dados do(a) ${getContext(type)}`,
      content: (
        <NewPartnerForm
          businessUser={selectedBusinessUser}
          form={form}
          flow={flow}
          setCivilStatus={setCivilStatus}
          civilStatus={civilStatus}
        />
      ),
    }, {
      title: 'Cônjuge',
      content: (
        <CivilPartnerForm
          form={form}
          businessUser={selectedBusinessUser}
          flow={flow}
        />
      ),
    }, {
      title: 'Endereço',
      content: (
        <AddressForm
          current
          flow={flow}
          form={form}
          postalCodePlaceholder="CEP"
          address={selectedBusinessUser?.user?.address || {}}
        />
      ),
    }, {
      title: 'Documentos',
      content: (
        <DocumentsForm
          form={form}
          civilStatus={civilStatus}
          flow={flow}
          business={business}
          address={address}
          businessUser={selectedBusinessUser}
          me={me}
          necessaryDocumentsFlow={necessaryDocumentsFlow}
          lemitValidated={selectedBusinessUser?.user?.address?.lemitValidated}
          isCreation={isCreation}
          loanStatus={loanStatus}
        />
      ),
    },
  ];

  const handleClose = () => {
    form.resetFields();
    setCurrent(0);
    setStep(0);
    setCivilStatus();
    onClose();
  };

  useEffect(() => {
    if (carouselRef) {
      setTimeout(() => {
        carouselRef.goTo(current);
      }, 200);
    }
  }, [current]);
  useEffect(() => {
    setCouple(['casado', 'uniao-estavel'].includes(civilStatus));
  }, [civilStatus]);

  useEffect(() => {
    if (!!selectedBusinessUser?.id && !isCreation && loanStatus === 'check-documents') {
      if (couple) setStep(3);
      else setStep(2);
    }
  }, [isCreation, selectedBusinessUser, couple]);

  const handleSubmit = async () => {
    if (step === 0) {
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!selectedBusinessUser?.id && values.email !== values.confirmEmail) {
        notification.error({
          message: 'Os emails não coincidem.',
        });
        return;
      }

      values.confirmEmail = undefined;
      const currentTypes = values?.typesValues || ['avalista'];

      await saveBusinessUser({
        variables: {
          ...values,
          id: selectedBusinessUser?.id && selectedBusinessUser.id,
          typesValues: [...new Set(currentTypes)],
          businessId,
        },
      });
    } else if ((step === 1 && !couple) || (step === 2 && couple)) {
      await form.validateFields();
      const values = form.getFieldsValue();

      const postalCodeLength = values.postalCode.replace(/[_-\s]/g, '').length;

      if (postalCodeLength !== 8) {
        notification.error({ message: 'Preencha o CEP com 8 dígitoss' });
        return;
      }

      if (flow === 'badesc') {
        setAddress(values);
      }

      await saveAddress({
        variables: {
          ...values,
          id: selectedBusinessUser?.user?.address?.id,
          userId: selectedBusinessUser?.user?.id && selectedBusinessUser.user.id,
        },
      });
    } else if ((step === 2 && !couple) || (step === 3 && couple)) {
      await form.validateFields();
      const values = form.getFieldsValue();

      await saveBusinessUser({
        variables: {
          id: selectedBusinessUser?.id && selectedBusinessUser.id,
          idCardFileId: values.idCardFileId,
          proofOfAddressFileId: values.proofOfAddressFileId,
          proofOfIncomeFileId: values.proofOfIncomeFileId,
          proofOfIncomeReceiptFileId: values.proofOfIncomeReceiptFileId,
        },
      });

      if (values.withProof) {
        fetch('https://api.ipify.org/?format=json')
          .then((response) => response.json())
          .then(async ({ ip }) => {
            generateProofOfCivilStatus({
              variables: {
                ip,
              },
            });
          })
          .catch(() => { notification.error({ message: 'Não conseguimos localizar seu IP' }); });
      }
      if (['casado', 'divorciado', 'uniao-estavel'].includes(civilStatus)) {
        const variables = {
          id: selectedBusinessUser?.user?.id && selectedBusinessUser.user.id,
          proofOfMariageOrDivorceFileId: values.proofOfMariageOrDivorceFileId,
        };
        if (['casado', 'uniao-estavel'].includes(civilStatus)) {
          variables.idCardFileId = values.partnerIdCardFileId;
        }
        await saveMarriageOrDivorceDocs({
          variables,
        });
      }

      await completeBusinessUser({
        variables: {
          id: selectedBusinessUser?.id && selectedBusinessUser.id,
        },
      });
    } else if (step === 1 && couple) {
      await form.validateFields();

      const {
        id, fullName, email, cpf, phoneNumber, birthDate, gender,
      } = form.getFieldsValue();

      if (couple) {
        await saveBusinessUserPartner({
          variables: {
            id: selectedBusinessUser?.id && selectedBusinessUser.id,
            civilPartner: {
              fullName,
              email,
              cpf,
              phoneNumber,
              birthDate,
              gender,
              id: id && id,
            },
          },
        });
      }
    }
  };

  const carouselStep = [
    {
      content: (
        <div className="carousel-content" key={current}>
          <>
            <Steps
              className="steps"
              current={step}
              direction="horizontal"
            >
              {couple
                ? marriedSteps.map(({ title }) => (<Step key={title} title={title} />))
                : steps.map(({ title }) => (<Step key={title} title={title} />))}
            </Steps>

            <div className="steps-content">
              {couple ? marriedSteps[step].content : steps[step].content}
            </div>
          </>
        </div>
      ),
    },
    {
      content: (
        <div className="carousel-content" key={current}>
          <Col xs={24}>
            <SuccessMessage
              title={`Novo(a) ${getContext(type)} adicionado`}
              centered
              withoutPadding
              subtitle={(
                <>
                  Por favor, aguarde validarmos as informações do(a)
                  {' '}
                  {getContext(type)}
                  {' '}
                  adicionado.
                  {' '}
                  <b className="secondary-text">Se algo estiver faltando</b>
                  , pode deixar que
                  {' '}
                  <b className="secondary-text">entraremos em contato</b>
                  {' '}
                  com você! Mas,
                  {' '}
                  <b className="secondary-text">se tudo estiver ok</b>
                  , iremos notificá-lo e
                  {' '}
                  <b className="secondary-text">enviar o contrato para assinatura</b>
                  {' '}
                  por email e SMS logo, logo.
                </>
              )}
              primaryButton={{
                id: 'back-to-list-btn',
                text: 'Continuar e prosseguir',
                onClick: handleClose,
              }}
              secondaryButton={{
                text: `Adicionar outro(a) ${getContext(type)}`,
                onClick: () => {
                  onAddNewUser();
                  form.resetFields();
                  setCurrent(0);
                  setStep(0);
                  setCivilStatus();
                },
              }}
            />
          </Col>
        </div>
      ),
    },
  ];

  return (
    <Drawer
      visible={visible}
      className={`business-user-drawer ${visible ? 'active' : ''}`}
      maxWidth="71.5rem"
      onClose={() => {
        if (!selectedBusinessUser.user) {
          requestConfirmation({
            onOk: () => {
              handleClose();
            },
            onCancel: () => { },
          });
        } else {
          handleClose();
        }
      }}
      hideFooter={current === 1}
      title={current !== 1 && `${selectedBusinessUser?.id ? 'Editar' : 'Adicionar'} ${getContext(type)}`}
      backButton={step > 0 && {
        disabled: isLoading,
        onClick: () => setStep(step - 1),
      }}
      primaryButton={{
        id: 'continue-business-user-button',
        loading: isLoading,
        onClick: handleSubmit,
      }}
    >
      <div className="drawer-body">
        <CustomStep steps={carouselStep} current={current} />
      </div>
    </Drawer>
  );
};

BusinessUserDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAddNewUser: PropTypes.func,
  refetch: PropTypes.func,
  businessId: PropTypes.string,
  flow: PropTypes.string,
  type: PropTypes.string,
  businessUser: PropTypes.shape(),
  setSelectedBusinessUser: PropTypes.func,
  me: PropTypes.bool,
  necessaryDocumentsFlow: PropTypes.string,
  isCreation: PropTypes.bool,
  setBusinessUsersRevised: PropTypes.func,
  businessUsersRevised: PropTypes.shape(),
  loanStatus: PropTypes.string,
};

export default BusinessUserDrawer;
