// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badesc-flow-modal .badesc-flow-steps {
  margin: 1rem 0;
}
.badesc-flow-modal .badesc-flow-steps-content .flow-opt-in-content {
  height: 30rem;
  overflow-y: auto;
  padding-right: 0.4rem;
}
@media (max-width: 768px) {
  .badesc-flow-modal .badesc-flow-steps-content .flow-opt-in-content {
    height: 24rem;
  }
}
.badesc-flow-modal .badesc-flow-logo {
  max-width: 6rem;
}
.badesc-flow-modal .flow-badesc-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/BadescFlowModal/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAFA;EAMM,aAAA;EACA,gBAAA;EACA,qBAAA;AADN;AAEM;EAAA;IACE,aAAA;EACN;AACF;AAZA;EAeI,eAAA;AAAJ;AAfA;EAkBI,gBAAA;EACA,aAAA;EACA,yBAAA;AAAJ","sourcesContent":[".badesc-flow-modal {\n  .badesc-flow-steps {\n    margin: 1rem 0;\n  }\n  .badesc-flow-steps-content {\n    .flow-opt-in-content {\n      height: 30rem;\n      overflow-y: auto;\n      padding-right: .4rem;\n      @media(max-width: @md-screen) {\n        height: 24rem;\n      }\n    }\n  }\n  .badesc-flow-logo {\n    max-width: 6rem;\n  }\n  .flow-badesc-footer {\n    margin-top: 2rem;\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
