/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Row,
  Col,
  Divider,
  Tooltip,
} from 'antd';
import {
  formatString,
  interestRateByPortfolio,
} from 'utils';
import {
  Card,
  Information,
} from 'components/gyramais';
import {
  Progress,
  Slider,
} from 'components/sdk';
import {
  useQuery,
} from '@apollo/client';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  SETTINGS,
} from './graphql';

import './styles.less';

/**
 *
 * @param {{
 *  loan: any
 *  onChangeValues?: void
 *  disabled?: boolean
 *  type: 'danger' | 'warning' | 'success'
 *  className?: string
 *  maxInstallmentsCount?: number
 * }} props
 * @returns {React.FC}
 */
const PreApprovedCard = ({
  loan,
  onChangeValues,
  className,
  disabled = false,
  type = 'danger',
  maxInstallmentsCount,
}) => {
  const { width } = useWindowDimensions();
  const { currentContext } = useBusinessLoanContext();

  const [selectedOption, setSelectedOption] = useState();

  const [installments, setInstallments] = useState(0);
  const [amount, setAmount] = useState(0);
  const [paymentDay, setPaymentDay] = useState(0);
  const [retentionFee, setRetentionFee] = useState(0);
  const [installmentAmount, setInstallmentAmount] = useState(0);

  const paymentDaysOptions = useMemo(() => [1, 5, 10, 15, 20]);
  const flow = currentContext?.partner?.flow;

  useEffect(() => {
    if (loan && disabled) {
      const selectedPreApprovedOption = loan
        .preApprovedOptions
        ?.find(({ finalInstallmentsCount }) => loan.finalInstallmentsCount === finalInstallmentsCount);
      const selectedPreApprovedOptionIndex = loan.preApprovedOptions?.indexOf(selectedPreApprovedOption);

      setInstallments(selectedPreApprovedOptionIndex);
      setAmount(loan.finalAmount);

      const date = loan.firstPaymentDate && new Date(loan.firstPaymentDate);
      if (date) {
        date.setHours(date.getHours() + 3);

        const paymentDayIndex = paymentDaysOptions.indexOf(date.getDate());
        setPaymentDay(paymentDayIndex);
      }
    }

    if (loan?.type?.value === 'receivables-guarantee') {
      setRetentionFee(loan.receivableRetentionFee);
    }
  }, [loan?.id]);

  const {
    data: {
      settings: {
        preApprovedLimit = 100000,
        chatbotLowerLimit = 15000,
      } = {},
    } = {},
    loading: settingsLoading,
  } = useQuery(SETTINGS);

  const preApprovedOptions = useMemo(() => {
    if (!loan) {
      return [];
    }

    const { preApprovedOptions } = loan;
    if (!preApprovedOptions) {
      return [];
    }

    return preApprovedOptions.filter(({ totalAmount, finalInstallmentsCount }) => (!maxInstallmentsCount && (totalAmount >= chatbotLowerLimit))
      || (maxInstallmentsCount && (totalAmount >= chatbotLowerLimit) && (finalInstallmentsCount <= maxInstallmentsCount)));
  }, [loan, chatbotLowerLimit]);

  const installmentsOptions = useMemo(() => {
    if (preApprovedOptions.length === 0 && loan) {
      return [loan.finalInstallmentsCount];
    }

    if (preApprovedOptions.length === 0) {
      return [];
    }

    const temp = preApprovedOptions.map(({ finalInstallmentsCount }) => finalInstallmentsCount);
    if (!disabled) {
      setInstallments(temp.length - 1);
      setSelectedOption(preApprovedOptions[temp.length - 1]);
    }

    return temp;
  }, [preApprovedOptions, loan]);

  const partnerHasPaymentDay = useMemo(() => {
    if (!loan) {
      return false;
    }

    const { partner } = loan;
    if (!partner) {
      return false;
    }

    const partnerPaymentDate = partner.actions?.some(({ value }) => value === 'payment-date') && partner.paymentDate;
    if (!partnerPaymentDate) {
      return false;
    }

    setPaymentDay(paymentDaysOptions.indexOf(partnerPaymentDate));
    return true;
  }, [loan]);

  const loading = useMemo(() => settingsLoading, [settingsLoading]);

  const handleAmountChanged = useCallback((currentAmount) => {
    if (currentAmount > preApprovedLimit) {
      return setAmount(preApprovedLimit);
    }

    return setAmount(currentAmount);
  }, [loan]);

  useEffect(() => {
    if (selectedOption && !disabled) {
      setAmount(selectedOption.finalAmount);
      setInstallmentAmount(selectedOption?.installmentAmount);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (amount > selectedOption?.finalAmount) {
      return setInstallmentAmount();
    }

    return setInstallmentAmount(selectedOption?.installmentAmount * (amount / selectedOption?.finalAmount));
  }, [amount]);

  useEffect(() => onChangeValues?.({
    amount,
    installments: preApprovedOptions?.[installments]?.installmentsCount,
    installmentAmount,
    paymentDay: paymentDaysOptions[paymentDay],
  }), [amount, installments, installmentAmount, paymentDay]);

  const preApprovedProgress = useMemo(() => {
    const percentage = {
      danger: 10,
      warning: 50,
      success: 100,
    };

    return (
      <div className="pre-approved-progress-container">
        <Progress
          className="pre-approved-progress"
          type="circle"
          percent={percentage[type]}
          progressColor={type}
          strokeWidth={9}
        />

        {/* eslint-disable-next-line react/no-unknown-property */}
        <div className="pre-approved-progress-content" align="middle">
          <p className="no-margin-bottom pre-approved-progress-label secondary-text">
            Valor
            <br />
            disponível
          </p>

          <h5>
            <strong>
              {(formatString(loan?.amount, 'currency'))?.split('.')[0]} mil
            </strong>
          </h5>
        </div>
      </div>
    );
  }, [type, loan]);

  return (
    <Card className={`pre-approved-card ${className || ''}`}>
      <Row gutter={[56, 16]} align="middle">
        <Col xs={24} sm={24} md={11}>
          {!disabled && (
            // eslint-disable-next-line react/no-unknown-property
            <div align="middle">
              <h4>
                <b>
                  Sua proposta pré-aprovada!
                </b>
              </h4>
              <br />
            </div>
          )}

          {(!disabled || (disabled && width > 768)) && (
            <>
              {preApprovedProgress}
            </>
          )}

          {!disabled ? (
            // eslint-disable-next-line react/no-unknown-property
            <div align="middle">
              <p className="no-margin-bottom">
                <strong>
                  Total pré-aprovado
                </strong>
              </p>

              <h3>
                <strong className={`${type}-text`}>
                  {formatString(selectedOption?.finalAmount, 'currency')}
                </strong>
              </h3>
            </div>
          ) : (
            <Row align="middle" gutter={[16, 0]}>
              <Col xs={disabled ? 12 : 0} sm={disabled ? 12 : 0} md={0}>
                {preApprovedProgress}
              </Col>
              <Col xs={disabled ? 12 : 24} sm={disabled ? 12 : 24} md={24}>
                <Row gutter={width > 768 ? [32, 24] : 8}>
                  <Col xs={24} sm={24} md={12}>
                    <Information
                      loading={loading}
                      label="Total pré-aprovado"
                      value={formatString(loan?.finalAmount, 'currency')}
                      type=""
                    />
                  </Col>
                  {loan?.type?.value === 'receivables-guarantee' && (
                    <Col xs={24} sm={24} md={8}>
                      <Information
                        loading={loading}
                        label="Recebíveis"
                        value={`${loan?.receivableRetentionFee?.toFixed(2)}%`}
                      />
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={12}>
                    <Information
                      loading={loading}
                      label="Taxa"
                      value={flow === 'badesc' ? (
                        <div>
                          <Tooltip title="Variação diária da taxa Selic">
                            Selic* + {interestRateByPortfolio[loan?.portfolioName]} a.a.
                          </Tooltip>
                        </div>
                      ) : `${loan?.interestRate?.toFixed(2)}%` || '-'}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Information
                      loading={loading}
                      label="Parcelas"
                      value={`${loan?.finalInstallmentsCount || '-'}x de ${formatString(loan?.installmentAmount, 'currency')}`}
                    />
                  </Col>
                  {loan?.type?.value !== 'receivables-guarantee' && (
                    <Col xs={24} sm={24} md={12}>
                      <Information
                        loading={loading}
                        label="Vencimento"
                        value={`Todo dia ${paymentDaysOptions[paymentDay]}`}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </Col>

        <Col xs={24} sm={24} md={2} align="middle">
          <Divider type={width > 768 && 'vertical'} className={width > 768 ? 'pre-approved-vertical-divider' : ''} />
        </Col>

        <Col xs={24} sm={24} md={11}>
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <h5>
                <b>
                  Prazo
                </b>
              </h5>

              <Slider
                tooltipVisible={false}
                min={0}
                max={installmentsOptions.length - 1}
                marks={installmentsOptions}
                value={installments}
                onChange={(e) => {
                  setInstallments(e);

                  setAmount(chatbotLowerLimit);
                  setSelectedOption(preApprovedOptions[e]);
                }}
                loading={loading}
                disabled={disabled}
              />
            </Col>

            <Col span={24}>
              <h5>
                <b>
                  Valor solicitado
                </b>
              </h5>

              <Slider
                tooltipVisible={false}
                min={chatbotLowerLimit}
                max={preApprovedLimit}
                value={amount}
                onChange={handleAmountChanged}
                step={1000}
                showValues
                valuesFormatter={(e) => {
                  if (width <= 768) {
                    return `${(formatString(e, 'currency'))?.split('.')[0]} mil`;
                  }
                  return formatString(e, 'currency');
                }}
                type={amount > selectedOption?.finalAmount && 'danger'}
                tooltip={{
                  message: 'Ops! Parece que sua solicitação passou do limite pré-aprovado. Para reavaliar suas condições comprove seu faturamento',
                  visible: amount > selectedOption?.finalAmount,
                }}
                input={{ type: 'currency' }}
                loading={loading}
                disabled={disabled}
              />
            </Col>

            <Col span={24}>
              <h5>
                <b>
                  Dia de vencimento
                </b>
              </h5>

              <Slider
                tooltipVisible={false}
                min={0}
                max={paymentDaysOptions.length - 1}
                marks={paymentDaysOptions}
                value={paymentDay}
                onChange={setPaymentDay}
                disabled={partnerHasPaymentDay || disabled}
              />
            </Col>

            {loan?.type?.value === 'receivables-guarantee' && (
              <Col span={24}>
                <h5>
                  <b>
                    % de uso dos recebíveis
                  </b>
                </h5>

                <Slider
                  tooltipVisible={false}
                  min={5}
                  max={100}
                  step={1}
                  value={retentionFee}
                  showValues
                  valuesFormatter={(e) => `${e}%`}
                  disabled
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default PreApprovedCard;
