const whatsappUrl = `https://wa.me/55${process.env.GYRAMAIS_WHATSAPP}`;
const facebookUrl = 'https://www.facebook.com/gyramais/';
const instagramUrl = 'https://www.instagram.com/gyramais/';
const linkedinUrl = 'https://www.linkedin.com/company/gyramais/';

export {
  whatsappUrl,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
};
