import React, { useState, useEffect } from 'react';
import {
  notification,
} from 'antd';
import {
  PlayCircleOutlined,
} from '@ant-design/icons';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  SubHeader,
  AccountCard,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import {
  formatAndNotificateError,
} from 'utils';
import {
  IntegrationVideoMotivationModal,
} from 'components';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { PluggyConnect } from 'react-pluggy-connect';
import ConnectorDrawer from 'components/drawers/ConnectorDrawer';
import {
  BANK_ACCOUNTS,
  INTEGRATIONS,
  ME,
  PLUGGY_CONNECT_TOKEN,
  SAVE_INTEGRATION,
} from './graphql';
import { isVideoMotivationalSeen } from '../../services/graphql/cache';

const { Content } = Layout;

const IntegrationScreen = () => {
  const history = useHistory();

  const { loadingActions, checkActions } = usePartnerActionsContext();

  const [platformDrawerVisible, setPlatformDrawerVisible] = useState(false);
  const [reintegrationInfo, setReintegrationInfo] = useState({});
  const [integrationSelected, setIntegrationSelected] = useState();
  const [internalReintegrationId, setInternalReintegrationId] = useState();
  const [typeId, setTypeId] = useState();
  const [accessToken, setAccessToken] = useState();
  const validationModalSeen = useReactiveVar(isVideoMotivationalSeen);

  const {
    data: {
      me: {
        currentBusiness: {
          id: businessId,
          bankAccount,
        } = {},
      } = {},
    } = {},
    refetch,
  } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: {
      integrations = [],
    } = {},
    refetch: refetchIntegration,
  } = useQuery(INTEGRATIONS, {
    variables: { businessId },
    skip: !businessId,
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: {
      bankAccounts = [],
    } = {},
    refetch: refetchBankAccount,
  } = useQuery(BANK_ACCOUNTS, {
    variables: { businessId },
    skip: !businessId,
    fetchPolicy: 'cache-and-network',
  });

  const [pluggyConnectToken] = useMutation(PLUGGY_CONNECT_TOKEN, {
    onCompleted: ({ pluggyConnectToken }) => {
      setAccessToken(pluggyConnectToken);
    },
    onError: ({ message } = {}) => formatAndNotificateError({
      message: message.includes('400') ? message : 'Ocorreu um erro interno! Por favor tente novamente mais tarde!',
    }),
  });
  const [saveIntegration] = useMutation(SAVE_INTEGRATION, {
    onCompleted: () => {
      notification.success({
        message: 'Conta cadastrada com sucesso.',
        duration: 0,
      });
    },
    onError: (error) => {
      setAccessToken();
      formatAndNotificateError(error);
    },
    refetchQueries: [{ query: INTEGRATIONS, variables: { businessId } }],
  });

  const handlePlatformDrawerClose = (e) => {
    setPlatformDrawerVisible(false);
    setReintegrationInfo({});
    if (e && e.id) {
      pluggyConnectToken();
      setTypeId(e.id);
    }
  };

  const handleReintegration = ({ id, typeId }) => {
    setIntegrationSelected({ id: typeId });
    setInternalReintegrationId(id);
  };

  const closeMotivationModal = () => {
    isVideoMotivationalSeen(true);
    localStorage.setItem('motivationalModalSeen', true);
  };

  useEffect(() => {
    isVideoMotivationalSeen(true);
  }, []);

  useEffect(() => {
    if (!loadingActions) {
      if (checkActions(['partner-analyze-credit'])) {
        history.goBack();
      }
    }

    return true;
  }, [loadingActions]);

  let extraButtons;
  if (!checkActions(['only-score']) && !loadingActions) {
    extraButtons = (
      <Button
        onClick={() => {
          isVideoMotivationalSeen(!validationModalSeen);
        }}
        icon={<PlayCircleOutlined className="white-label-text" />}
        color="white"
        link
      >
        <span className="white-label-text underline">Assista nosso vídeo explicativo sobre Plataformas!</span>
      </Button>
    );
  }

  return (
    <Layout id="integration-screen" className="dashboard-layout">
      <ConnectorDrawer
        visible={platformDrawerVisible}
        onClose={handlePlatformDrawerClose}
        refetchIntegration={refetchIntegration}
        refetchBankAccount={refetchBankAccount}
        reintegrationInfo={reintegrationInfo}
        reintegrationId={internalReintegrationId}
        integrationSelected={integrationSelected}
      />

      <SubHeader
        title="Contas"
        secondary
        buttons={(
          <>
            <Button
              id="add-integration-button"
              onClick={() => setPlatformDrawerVisible(true)}
              className="button-add"
            >
              + Conta
            </Button>
          </>
          )}
        buttonsPlacement="right"
        extraButtons={extraButtons}
        extraButtonsPlacement="bottom"
      />

      {accessToken && (
        <PluggyConnect
          connectToken={accessToken}
          onClose={() => {
            setAccessToken();
          }}
          onSuccess={({ item }) => saveIntegration({
            variables: {
              businessId,
              typeId,
              credentials: JSON.stringify(item),
            },
          })}
          onError={({ data: { item = {} } = {} }) => {
            const { status, executionStatus } = item;

            if (['UPDATING', 'UPDATED'].includes(status)
                    || (status === 'OUTDATED' && ['WAITING_USER_INPUT', 'USER_AUTHORIZATION_PENDING'].includes(executionStatus))) {
              return saveIntegration({
                variables: {
                  businessId,
                  typeId,
                  credentials: JSON.stringify(item),
                },
              });
            }

            return true;
          }}
          countries={['BR']}
          language="pt"
        />
      )}

      <Content>
        {[...integrations, ...bankAccounts].sort((a, b) => a - b).map((account) => {
          // eslint-disable-next-line no-underscore-dangle
          const type = account?.__typename;
          const { currentAccount } = account;
          const credentials = type === 'Integration' && JSON.parse(account?.credentials);
          switch (type) {
            case 'Integration':
              return (
                <AccountCard
                  key={account?.id}
                  status={{ name: `${account?.removedAt ? 'REINTEGRAR' : 'CONECTADA'}`, value: `${account?.removedAt ? 'review' : 'active'}` }}
                  logo={account?.type?.icon?.url}
                  platformName={account?.type?.name}
                  account={credentials}
                  id={account?.id}
                  refetch={refetchIntegration}
                  type={type}
                  typeInfo={account?.type}
                  reintegration={handleReintegration}
                  removedAt={Boolean(account?.removedAt)}
                />
              );
            case 'BankAccount':
              return (
                <AccountCard
                  key={account?.id}
                  status={{ name: 'CONECTADA', value: 'active' }}
                  logo={account?.bank?.logo?.url}
                  platformName={account?.bank?.name}
                  account={{
                    branchCode: account?.branchCode,
                    accountNumber: account?.accountNumber,
                  }}
                  id={account?.id}
                  refetch={refetch}
                  refetchBankAccount={refetchBankAccount}
                  selected={account?.id === bankAccount?.id}
                  type={type}
                  currentAccount={currentAccount}
                />
              );
            default: return null;
          }
        })}
      </Content>
      <IntegrationVideoMotivationModal
        onClose={closeMotivationModal}
        visible={!validationModalSeen}
      />

    </Layout>
  );
};

export default IntegrationScreen;
