import LOANS from './loans.gql';
import LOAN_AVAILABLE_FOR_PROMO_RENEGOTIATE from './loanAvailableForPromoRenegotiate.gql';
import LOAN_UPDATED from './loanUpdated.gql';
import SAVE_INVOICE from './saveInvoice.gql';
import ME from './me.gql';

export {
  LOANS,
  LOAN_AVAILABLE_FOR_PROMO_RENEGOTIATE,
  LOAN_UPDATED,
  SAVE_INVOICE,
  ME,
};
