import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
} from 'antd';
import {
  Card,
  Information,
  Status,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import {
  ConfirmRemoveBusinessUserModal,
} from 'components';
import { formatCPF, formatString } from 'utils';
import { CrownOutlined, DeleteOutlined } from '@ant-design/icons';

import './styles.less';

const BusinessUserCard = ({
  ids = [],
  loanStatus,
  businessUser: {
    id,
    isTrustable,
    types = [],
    user: {
      fullName,
      cpf,
      email,
      phoneNumber,
      civilPartner,
      civilStatus,
      address,
      proofOfIncome,
    } = {},
    isOwner,
    completedAt,
  } = {},
  refetch,
  onClickEdit,
  section,
  forceOpenToEdit,
}) => {
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);

  /**
   * true = Operador do core analisa e aprova
   * false = Operador do core analisa e rejeita
   * undefined = Não houve nenhuma analise no documento
   *
   * Quando Operador do core rejeita um dos documentos, volta pro cliente analisar novamente
   */
  const statusCompleted = completedAt
    && address?.analysis?.approved === true
    && proofOfIncome?.analysis?.approved === true
    && !forceOpenToEdit;

  const statusRevised = !statusCompleted && (address?.analysis?.approved !== false)
    && (proofOfIncome?.analysis?.approved !== false);

  const statusRevisedConditions = statusRevised ? 'Revisado' : 'Revisar';

  return (
    <Card
      className={`
        business-user-card
        ${(loanStatus === 'check-documents' && (statusCompleted || statusRevised)) || (loanStatus !== 'check-documents' && completedAt) ? '' : 'incomplete'}
      `}
    >
      <Row>
        <Col md={24} lg={18}>
          <Row gutter={[12, 12]} align="middle">
            <Col xs={{ order: 10 }} sm={{ order: 10 }} md={{ order: 0 }}>
              {loanStatus === 'check-documents' ? (
                <Status
                  name={statusCompleted ? 'Completo' : statusRevisedConditions}
                  value={statusCompleted || statusRevised ? 'completed' : 'pending'}
                />
              ) : (
                <Status
                  name={completedAt ? 'Completo' : 'Incompleto'}
                  value={completedAt ? 'completed' : 'pending'}
                />
              )}
            </Col>

            <Col>
              <h4 className="no-margin-bottom">
                {fullName || '-'}
                {' '}
                {isOwner && (
                  <CrownOutlined className="secondary-text" />
                )}
              </h4>
            </Col>
          </Row>

          <br />

          <Row gutter={32}>
            <Col>
              <Information label="CPF" value={formatCPF(cpf) || '-'} />
            </Col>

            <Col>
              <Information label="Email" value={email || '-'} />
            </Col>

            <Col>
              <Information
                label="Telefone"
                value={formatString(phoneNumber, 'phone') || '-'}
              />
            </Col>
          </Row>
        </Col>

        <Col md={24} lg={6} className="buttons-col">
          {!statusCompleted && (
            <Button
              onClick={onClickEdit}
              id={`edit-${fullName?.toLowerCase().split(' ').join('-') || '-'}`}
              data-cy={`edit-${email || '-'}`}
            >
              {loanStatus === 'check-documents' && !statusRevised
                ? 'Revisar'
                : 'Editar'}
            </Button>
          )}
          {!isTrustable && loanStatus !== 'check-documents' && (
            <Button
              link
              id={`remove-${fullName?.toLowerCase().split(' ').join('-') || '-'}`}
              onClick={() => setRemoveModalVisible(true)}
            >
              <DeleteOutlined className="remove-card-icon" />
            </Button>
          )}
        </Col>
      </Row>
      {section.type === 'avalista'
        && ['casado', 'uniao-estavel'].includes(civilStatus?.value)
        && !ids.includes(civilPartner?.id)
        && (
          <>
            <Divider />
            <Row gutter={32} align="middle">
              <Col span={24}>
                <h4 className="no-margin-bottom">Cônjuge</h4>
                <br />
              </Col>

              <Col>
                <Information
                  label="Nome"
                  value={civilPartner?.fullName || '-'}
                />
              </Col>

              <Col>
                <Information
                  label="CPF"
                  value={formatCPF(civilPartner?.cpf) || '-'}
                />
              </Col>

              <Col>
                <Information label="Email" value={civilPartner?.email || '-'} />
              </Col>

              <Col>
                <Information
                  label="Telefone"
                  value={civilPartner?.phoneNumber || '-'}
                />
              </Col>
            </Row>
          </>
        )}

      <ConfirmRemoveBusinessUserModal
        visible={isRemoveModalVisible}
        onDelete={refetch}
        onClose={() => setRemoveModalVisible(false)}
        businessUser={{
          fullName,
          id,
          types,
        }}
        section={section}
      />
    </Card>
  );
};

BusinessUserCard.propTypes = {
  businessUser: PropTypes.shape(),
  refetch: PropTypes.func,
  onClickEdit: PropTypes.func,
  section: PropTypes.shape(),
  ids: PropTypes.arrayOf(PropTypes.string),
  loanStatus: PropTypes.string,
  forceOpenToEdit: PropTypes.bool,
};

export default BusinessUserCard;
