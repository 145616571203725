// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyramais-progress .ant-progress-outer {
  display: inline !important;
}
.gyramais-progress .ant-progress-outer .ant-progress-inner {
  display: flex !important;
}
.gyramais-progress.ant-progress-not-rounded .ant-progress-inner {
  border-radius: 0 !important;
}
.gyramais-progress.ant-progress-not-rounded .ant-progress-inner .ant-progress-bg {
  border-radius: 0 !important;
}
.gyramais-progress.ant-progress-bordered .ant-progress-inner {
  border: 0.063rem solid #C9D3CC;
}
.gyramais-progress.ant-progress-bordered-top .ant-progress-inner {
  border-top: 0.063rem solid #C9D3CC;
}
.gyramais-progress.ant-progress-bordered-bottom .ant-progress-inner {
  border-bottom: 0.063rem solid #C9D3CC;
}
.gyramais-progress.ant-progress-bordered-left .ant-progress-inner {
  border-left: 0.063rem solid #C9D3CC;
}
.gyramais-progress.ant-progress-bordered-right .ant-progress-inner {
  border-right: 0.063rem solid #C9D3CC;
}
`, "",{"version":3,"sources":["webpack://./src/components/sdk/Progress/styles.less"],"names":[],"mappings":"AAAA;EAEI,0BAAA;AAAJ;AAFA;EAKM,wBAAA;AAAN;AAIE;EAEI,2BAAA;AAHN;AACE;EAKM,2BAAA;AAHR;AAQE;EAEI,8BAAA;AAPN;AAUE;EAEI,kCAAA;AATN;AAYE;EAEI,qCAAA;AAXN;AAcE;EAEI,mCAAA;AAbN;AAgBE;EAEI,oCAAA;AAfN","sourcesContent":[".gyramais-progress {\n  .ant-progress-outer {\n    display: inline !important;\n\n    .ant-progress-inner {\n      display: flex !important;\n    }\n  }\n\n  &.ant-progress-not-rounded {\n    .ant-progress-inner {\n      border-radius: 0 !important;\n      \n      .ant-progress-bg {\n        border-radius: 0 !important;\n      }\n    }\n  }\n\n  &.ant-progress-bordered {\n    .ant-progress-inner {\n      border: .063rem solid #C9D3CC;\n    }\n  }\n  &.ant-progress-bordered-top {\n    .ant-progress-inner {\n      border-top: .063rem solid #C9D3CC;\n    }\n  }\n  &.ant-progress-bordered-bottom {\n    .ant-progress-inner {\n      border-bottom: .063rem solid #C9D3CC;\n    }\n  }\n  &.ant-progress-bordered-left {\n    .ant-progress-inner {\n      border-left: .063rem solid #C9D3CC;\n    }\n  }\n  &.ant-progress-bordered-right {\n    .ant-progress-inner {\n      border-right: .063rem solid #C9D3CC;\n    }\n  }\n\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
