import React, { useState, memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  Skeleton,
} from 'antd';
import {
  LeftOutlined,
} from '@ant-design/icons';
import {
  SubHeader,
  NewLoanButton,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import {
  LoanCard,
  NewLoanDrawer,
  SignatureDrawer,
} from 'components';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { useQuery, useSubscription } from '@apollo/client';
import {
  ME,
  LOANS,
  LOAN_UPDATE,
} from './graphql';
import './styles.less';
import { useURL } from '../../utils/hooks/useURL';

const { Content } = Layout;

const LoansScreen = () => {
  const { checkActions } = usePartnerActionsContext();
  const { currentContext } = useBusinessLoanContext();

  const history = useHistory();
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const [newLoanDrawerVisible, setNewLoanDrawerVisible] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);
  const [loan, setLoan] = useState();
  const { removeQueryParam, action } = useURL();

  const flow = currentContext?.partner?.flow;

  const {
    data: {
      me: {
        currentBusiness: {
          id: businessId,
        } = {},
      } = {},
    } = {},
    loading: meLoading,
  } = useQuery(ME);

  const {
    data: {
      loans = [],
    } = {},
    refetch,
    loading,
  } = useQuery(LOANS, {
    variables: { businessId, partnerId: currentContext?.partner?.id },
    skip: !businessId,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ loans }) => {
      if (id && action === 'assinar') {
        const [loan] = loans.filter((loan) => loan.id === id);
        setSignatureVisible(true);
        setLoan(loan);
        removeQueryParam('action');
      } else {
        const signatureStatuses = ['awaiting-signature', 'awaiting-signature-additive'];
        const signatureLoan = loans.find((loan) => signatureStatuses.includes(loan.status.value));
        if (signatureLoan) {
          setLoan(signatureLoan);
        } else {
          setSignatureVisible(false);
        }
      }
    },
  });

  useSubscription(LOAN_UPDATE, {
    variables: { ids: loans.map(({ id }) => id) },
    onSubscriptionData: () => refetch(),
  });

  return (
    <Layout id="loans-screen">
      <SubHeader
        title="Empréstimos"
        secondary
        buttons={(
          <>
            {!meLoading && flow !== 'stone' && flow !== 'olist' && !checkActions(['partner-analyze-credit', 'partner-is-banker']) && (
              <NewLoanButton
                id="new-loan-button"
                onClick={() => setNewLoanDrawerVisible(true)}
              />
            )}
          </>
        )}
        buttonsPlacement="right"
      />
      <Content>
        <div className="content-wrapper">
          {loading ? (
            <Skeleton.Button
              active
              shape="square"
              className="loans-loading"
            />
          ) : (
            <>
              {id && (
              <Button
                link
                onClick={() => history.push('/emprestimos')}
                className="loans-back-button"
              >
                <LeftOutlined className="back-icon" />
                Ver todos os empréstimos
              </Button>
              )}
              <>
                {loans.filter(({ id: loanId }) => (id ? loanId === id : true))
                  .map((loan, index) => (
                    <LoanCard
                      flow={flow}
                      index={index}
                      key={loan.id}
                      loan={loan}
                      detailed
                      refetch={refetch}
                      onClickSign={() => {
                        setSignatureVisible(true);
                        setLoan(loan);
                      }}
                    />
                  ))}
              </>
            </>
          )}
        </div>
      </Content>

      <NewLoanDrawer
        bypass={loans.length === 0}
        visible={newLoanDrawerVisible}
        onClose={() => setNewLoanDrawerVisible(false)}
      />

      {loans.find((loan) => ['awaiting-signature', 'awaiting-signature-additive', 'signed'].includes(loan.status.value)) && (
        <SignatureDrawer
          flow={flow}
          loan={loan}
          refetch={refetch}
          businessId={businessId}
          visible={signatureVisible}
          onClose={() => {
            setSignatureVisible(false);
          }}
        />
      )}
    </Layout>
  );
};

export default memo(LoansScreen);
