import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Divider,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import {
  formatString,
} from 'utils';
import {
  AddressForm,
} from 'components';

const { Item } = Form;

const BusinessForm = ({
  business: {
    id,
    legalName,
    name,
    cnpj,
    phoneNumber,
  } = {},
  address,
  form,
  flow,
}) => {
  useEffect(() => {
    if (!id) {
      return;
    }
    form.setFieldsValue({
      id,
      legalName,
      name,
      cnpj: cnpj ? formatString(cnpj, 'cnpj') : '',
      phoneNumber: phoneNumber ? formatString(phoneNumber, 'phone') : '',
    });

  }, [id]);

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
          <Item
            name="cnpj"
            rules={[{ required: true, message: 'Por favor preencha o cnpj.' }]}
          >
            <Input
              type="masked"
              disabled
              className="ant-input"
              guide
              mask="cnpj"
              placeholder="CNPJ da sua empresa"
            />
          </Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={6}>
          <Item
            name="name"
            rules={[{ required: true, message: 'Por favor preencha o nome fantasia.' }]}
          >
            <Input placeholder="Nome fantasia" />
          </Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={10}>
          <Item
            name="legalName"
            rules={[{ required: true, message: 'Por favor preencha a razão social.' }]}
          >
            <Input placeholder="Razão Social" />
          </Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
          <Item name="phoneNumber" rules={[{ required: true, message: 'Por favor preencha o contato' }]}>
            <Input
              type="masked"
              guide
              placeholder="Contato"
              mask="phone"
            />
          </Item>
        </Col>

        <Divider className="divider-without-margin" />

        <AddressForm
          address={address}
          form={form}
          flow={flow}
          onlyColumns
          fieldProps={{
            postalCode: {
              fieldOrder: 1,
              lg: 7,
              md: 12,
              xs: 24,
            },
            street: {
              fieldOrder: 2,
              lg: 11,
              md: 12,
              xs: 24,
            },
            district: {
              fieldOrder: 3,
              lg: 6,
              md: 12,
              xs: 24,
            },
            number: {
              fieldOrder: 4,
              lg: 5,
              md: 12,
              xs: 24,
            },
            complement: {
              fieldOrder: 5,
              lg: 7,
              md: 12,
              xs: 24,
            },
            state: {
              fieldOrder: 6,
              lg: 6,
              md: 12,
              xs: 24,
            },
            city: {
              fieldOrder: 7,
              lg: 6,
              md: 12,
              xs: 24,
            },
          }}
          business
        />
      </Row>
    </Form>
  );
};

BusinessForm.propTypes = {
  business: PropTypes.shape(),
  address: PropTypes.shape(),
  form: PropTypes.shape(),
  flow: PropTypes.string,
};

export default BusinessForm;
