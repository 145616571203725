import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Popover,
} from 'antd';
import {
  Button,
} from 'components/sdk';

const ContractButton = ({
  statusValue,
  flow,
  debitAuthorization,
  authorizationTerm,
  ccb,
  bankerId,
  bankerValue,
  additive,
  partner,
}) => {
  const hasDebitAuthorizationAction = partner?.actions?.some((action) => action.value === 'debit-authorization-term');
  if (['awaiting-signature', 'signed', 'active'].includes(statusValue) && (['ideal'].includes(flow) || hasDebitAuthorizationAction)) {
    const handleTerm = () => {
      if (flow === 'ideal') {
        return window.open(authorizationTerm.url);
      }
      return window.open(debitAuthorization.url);
    };
    return (
      <Popover
        placement="topRight"
        content={(
          <Row gutter={[10, 10]} justify="center">
            <Col span={23}>
              <Button
                block
                onClick={() => {
                  if (statusValue === 'awaiting-signature' && bankerValue === 'money-plus') window.open(`${process.env.MONEYPLUS_URI}&code=${bankerId}`);
                  else window.open(ccb.url);
                }}
              >
                CCB
              </Button>
            </Col>
            <Col span={23}>
              <Button
                block
                onClick={handleTerm}
              >
                Termo de Autorização
              </Button>
            </Col>
          </Row>
        )}
        trigger="click"
      >
        <Button>Ver contratos</Button>
      </Popover>
    );
  }

  return (
    <Button
      onClick={async () => {
        if (additive) window.open(additive.url);
        if (!additive) {
          if (statusValue === 'awaiting-signature' && flow !== 'badesc' && bankerValue === 'money-plus') {
            window.open(`${process.env.MONEYPLUS_URI}&code=${bankerId}`);
          } else window.open(ccb.url);
        }
      }}
    >
      {`Ver ${(['awaiting-signature', 'signed', 'active', 'lawsuit']).includes(statusValue) ? 'contrato' : 'aditivo'}`}
    </Button>
  );
};

ContractButton.propTypes = {
  additive: PropTypes.shape(),
  ccb: PropTypes.shape(),
  debitAuthorization: PropTypes.shape(),
  authorizationTerm: PropTypes.shape(),
  flow: PropTypes.string,
  bankerId: PropTypes.string,
  bankerValue: PropTypes.string,
  statusValue: PropTypes.string,
  partner: PropTypes.shape(),
};

export default ContractButton;
