// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-account-card {
  padding: 2rem 2.5rem !important;
}
.bank-account-card .bank-account-card-container {
  height: 100%;
}
.bank-account-card .bank-account-card-container .bank-account-card-logo {
  max-height: 2rem;
}
@media (max-width: 768px) {
  .bank-account-card {
    padding: 1rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/BankAccountCard/styles.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;AAFA;EAII,YAAA;AACJ;AALA;EAOM,gBAAA;AACN;AAGE;EAAA;IACE,wBAAA;EAAF;AACF","sourcesContent":[".bank-account-card {\n  padding: 2rem 2.5rem !important;\n\n  .bank-account-card-container {\n    height: 100%;\n\n    .bank-account-card-logo {\n      max-height: 2rem;\n    }\n  }\n\n  @media(max-width: @md-screen) {\n    padding: 1rem !important\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
