// @ts-check
/* eslint-disable react/prop-types */
import { ClockCircleOutlined } from '@ant-design/icons';
import { Card, Divider } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import React, { useMemo } from 'react';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';

import './HighlightedDiscountCard.less';

/**
 * @type {React.FC<{
 *  deadline: Date
 * }>}
 */
const HighlightedDiscountCard = ({ deadline }) => {
  const {
    loan: { installmentAmount },
    noDiscount,
    roundedCurrentDiscount,
    installmentCountSelected,
    maxInstallmentCount,
    currentInstallmentAmount,
  } = usePromoRenegotiationContext();

  const content = useMemo(() => {
    if (noDiscount) {
      return (
        <>
          Parcele em até
          <br />
          <b>{maxInstallmentCount}x</b>
        </>
      );
    }

    if (roundedCurrentDiscount < 0) {
      return (
        <>
          <b>R${Math.round(installmentAmount - currentInstallmentAmount)}</b>
          <br />
          à menos na parcela
        </>
      );
    }

    return (
      <>
        <b>{roundedCurrentDiscount}%</b>
        <br />
        de desconto
        {installmentCountSelected === 1 ? ' à vista' : ''}
      </>
    );
  }, [
    noDiscount,
    roundedCurrentDiscount,
    installmentCountSelected,
    currentInstallmentAmount,
  ]);

  return (
    <Card className="discount-card">
      <div className="discount-card-value">
        <div className="discount-value" data-cy="discount-value-div">
          {content}
        </div>
      </div>

      {!noDiscount ? (
        <>
          <Divider type="vertical" />

          <div className="clock">
            Sua oferta expira em:
            <div className="promotion-card__clock">
              <ClockCircleOutlined />
              <Countdown
                // @ts-ignore
                value={deadline}
                format="HH:mm:ss"
              />
            </div>
          </div>
        </>
      ) : <div />}
    </Card>
  );
};

export default HighlightedDiscountCard;
