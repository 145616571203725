// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-loan-drawer .loading-redirect-screen {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 12.5rem;
}
@media (max-width: 768px) {
  .new-loan-drawer .loading-redirect-screen {
    padding: 0 2rem;
  }
}
.new-loan-drawer .loading-redirect-screen p {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/NewLoanDrawer/styles.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EAAA;IACA,eAAA;EACF;AACF;AAbA;EAeM,YAAA;AACN","sourcesContent":[".new-loan-drawer {\n  .loading-redirect-screen{\n    width: 100%;\n    height: 70%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 0 12.5rem;\n\n    @media (max-width: @md-screen) {\n    padding: 0 2rem;\n    }\n\n    p {\n      opacity: 0.5;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
