// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pre-approved-screen .improve-loan-card {
  opacity: 0;
  transform: translateX(-10rem);
  pointer-events: none;
  transition: 0.4s;
}
#pre-approved-screen .improve-loan-card .improve-loan-card-divider {
  border-color: var(--primary-button-text-color);
  opacity: 0.6;
  height: 3rem;
}
#pre-approved-screen .improve-loan-card.improve-loan-card-visible {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
}
#pre-approved-screen .pre-approved-screen-footer-installment {
  display: flex;
  align-items: center;
}
#pre-approved-screen .pre-approved-screen-footer-installment .icon-container {
  margin-right: 1rem;
}
@media (max-width: 768px) {
  #pre-approved-screen .pre-approved-screen-footer-installment .icon-container {
    display: none;
  }
}
@media (max-width: 768px) {
  #pre-approved-screen .pre-approved-screen-footer-installment .text-container {
    margin-top: 0.6rem;
  }
}
.pre-approved-question-modal .question-modal-icon {
  font-size: 10rem;
  margin-bottom: 2.625rem;
  color: var(--warning-color);
}
.badesc-pre-approved-content {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .badesc-pre-approved-content {
    display: inline;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/PreApprovedScreen/styles.less"],"names":[],"mappings":"AAAA;EAEI,UAAA;EACA,6BAAA;EACA,oBAAA;EAEA,gBAAA;AADJ;AALA;EASM,8CAAA;EACA,YAAA;EACA,YAAA;AADN;AAII;EACE,UAAA;EACA,wBAAA;EACA,mBAAA;AAFN;AAfA;EAsBI,aAAA;EACA,mBAAA;AAJJ;AAnBA;EA0BM,kBAAA;AAJN;AAMM;EAAA;IACE,aAAA;EAHN;AACF;AAQM;EAAA;IACE,kBAAA;EALN;AACF;AAUA;EAEI,gBAAA;EACA,uBAAA;EACA,2BAAA;AATJ;AAaA;EACE,aAAA;EACA,uBAAA;AAXF;AAaE;EAAA;IACE,eAAA;EAVF;AACF","sourcesContent":["#pre-approved-screen {\n  .improve-loan-card {\n    opacity: 0;\n    transform: translateX(-10rem);\n    pointer-events: none;\n\n    transition: .4s;\n\n    .improve-loan-card-divider {\n      border-color: var(--primary-button-text-color);\n      opacity: .6;\n      height: 3rem;\n    }\n\n    &.improve-loan-card-visible {\n      opacity: 1;\n      transform: translateX(0);\n      pointer-events: all;\n    }\n  }\n\n  .pre-approved-screen-footer-installment {\n    display: flex;\n    align-items: center;\n\n    .icon-container {\n      margin-right: 1rem;\n\n      @media(max-width: 768px) {\n        display: none;\n      }\n    }\n\n    .text-container {\n      \n      @media(max-width: 768px) {\n        margin-top: .6rem;\n      }\n    }\n  }\n}\n\n.pre-approved-question-modal {\n  .question-modal-icon {\n    font-size: 10rem;\n    margin-bottom: 2.625rem;\n    color: var(--warning-color);\n  }\n}\n\n.badesc-pre-approved-content {\n  display: flex;\n  justify-content: center;\n\n  @media (max-width: 768px) {\n    display: inline;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
