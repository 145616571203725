/* eslint-disable react/prop-types */
import { UserOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { memo } from 'react';

import './styles.less';

/**
 * @type {React.FC<{
 *  children: string
 * }>}
 */
const UserTag = ({
  children,
}) => (
  <div className="user-tag">
    <UserOutlined />
    {' '}

    <Tooltip
      title={children}
    >
      <span className="user-tag-content">
        {children}
      </span>
    </Tooltip>
  </div>
);

export default memo(UserTag);
