import React from 'react';
import {
  Card,
  Collapse,
  Empty,
} from 'antd';
import { Status, Table } from 'components';
import { formatString } from 'utils';
import dayjs from 'dayjs';
import './styles.less';
import BlurWithMessage from '../BlurWithMessage';

const PrepaymentHistory = ({ prepayments, blur }) => {

  const tableColumns = [
    {
      title: 'ADQUIRENTE(S)',
      dataIndex: 'integrationTypes',
      key: 'integrationTypes',
      render: (integrationTypes) => `${integrationTypes.map(({ name }) => name)}` || '-',
    },
    {
      title: 'DATA DA ANTECIPAÇAO',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY') || '-',
    },
    {
      title: 'TAXA',
      dataIndex: 'effectiveFee',
      key: 'effectiveFee',
      render: (effectiveFee) => effectiveFee || '-',
    },
    {
      title: 'VALOR ANTECIPADO',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (totalAmount) => formatString(totalAmount, 'currency') ?? '-',
    },
    {
      title: 'VALOR RECEBIDO',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => formatString(amount, 'currency') || '-',
    },
    {
      title: 'STATUS',
      width: '1rem',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div className="receivable-history-status">
          <Status
            value={status.value}
            name={status.name}
          />
        </div>
      ),
    },
  ];

  return (
    <Card className="receivable-history-card">
      <BlurWithMessage blur={blur} />
      <Collapse
        bordered={false}
        expandIconPosition="right"
        destroyInactivePanel
        className="receivable-history-card-collapse"
        defaultActiveKey={['receivable-history']}
      >
        <Collapse.Panel
          key="receivable-history"
          header={(
            <h5 className="receivable-history-title">
              <b>
                Histórico de Antecipação dos Recebíveis
              </b>
            </h5>
          )}
        >
          <br />
          <div className="receivable-history-table">
            {prepayments[0] ? (
              <Table
                columns={tableColumns}
                dataSource={prepayments}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                }}
              />
            ) : (
              <Empty description={(
                <b>
                  Nenhum histórico de Antecipação de
                  <br />
                  Recebíveis encontrado.
                </b>
              )}
              />
            )}
          </div>
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
};

export default PrepaymentHistory;
