import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Select,
  notification,
} from 'antd';
import { formatDate, formatAndNotificateError, formatError } from 'utils';
import {
  Drawer,
  Input,
  Alert,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import {
  MergeModal,
} from 'components';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteVerificationModal } from 'components/modals';
import { DeleteOutlined } from '@ant-design/icons';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import {
  ME,
  BUSINESS_PLATFORM_TYPES,
  BUSINESS_SECTORS,
  BUSINESS_SEGMENTS,
  SAVE_ME,
  CREATE_DELETE_VERIFICATION_CODE,
  LOANS,
} from './graphql';

const { Item, useForm } = Form;
const { Option } = Select;

const genders = [
  { id: 1, name: 'Masculino', value: 'MASCULINO' },
  { id: 2, name: 'Feminino', value: 'FEMININO' },
];

const UserDrawer = ({
  onCompleted,
  onClose,
  visible,
  alert,
}) => {
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [formDefaultValues, setFormDefaultValues] = useState({});
  const [phoneNumberVerification, setPhoneNumberVerification] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(localStorage.getItem('verificationCodeSent'));
  const { checkActions, loadingActions } = usePartnerActionsContext();

  const { currentContext } = useBusinessLoanContext();

  const [createDeleteVerificationCode] = useMutation(CREATE_DELETE_VERIFICATION_CODE, {
    onCompleted: () => {
      setVerificationCodeSent(true);
      localStorage.setItem('verificationCodeSent', true);
    },
  });

  const [form] = useForm();
  const setFormFields = () => {
    form.setFieldsValue(formDefaultValues);
    if (alert) form.validateFields();
  };

  const {
    data: {
      me: {
        roles = [],
        phoneNumberVerified,
        email,
        cpf,
        currentBusiness: {
          id: businessId,
        } = {},
      } = {},
    } = {},
    refetch,
  } = useQuery(ME, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({
      me: {
        fullName,
        cpf,
        email,
        phoneNumber,
        currentBusiness,
        verificationCode: code,
        additionalInfo,
        birthDate,
      } = {},
    }) => {
      setPhoneNumberVerification(phoneNumber);
      setVerificationCode(code);
      setFormDefaultValues({
        fullName,
        cpf,
        email,
        phoneNumber,
        ...additionalInfo,
        birthDate: birthDate && formatDate(new Date(birthDate)),
        employeesNumber: currentBusiness?.employeesNumber,
        revenueAmount: currentBusiness?.revenueAmount,
        platformType: currentBusiness?.platformType?.value,
        sector: currentBusiness?.sector?.value,
        segment: currentBusiness?.segment?.value,
      });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const {
    data: {
      businessPlatformTypes,
    } = {},
  } = useQuery(BUSINESS_PLATFORM_TYPES, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSegments,
    } = {},
  } = useQuery(BUSINESS_SEGMENTS, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSectors,
    } = {},
  } = useQuery(BUSINESS_SECTORS, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      loans = [],
    } = {},
  } = useQuery(LOANS, {
    skip: !businessId,
    fetchPolicy: 'cache-and-network',
    variables: { businessId },
  });

  const [saveMe, { loading }] = useMutation(SAVE_ME, {
    onCompleted: () => {
      refetch();
      notification.success({ message: 'Informações alteradas com sucesso!' });
      onCompleted();
    },
    onError: (error) => {
      const formated = formatError(error);

      if ((!cpf && formated.toLowerCase() === 'já existe uma pessoa cadastrada com esse cpf.')
        || (!email && formated.toLowerCase() === 'já existe conta cadastrada com esse e-mail.')) {
        return setMergeModalVisible(true);
      }

      return formatAndNotificateError(error);
    },
  });

  const isInvalid = ![
    'new',
    'pending',
    'integrated',
    'integrating',
    'verifying',
    'score',
    'final-verifying',
  ].includes(loans[0]?.status?.value);

  const handleSendCode = async () => {
    await createDeleteVerificationCode();
  };

  useEffect(() => {
    setFormFields();
  }, [formDefaultValues]);

  useEffect(() => {
    if (verificationCodeSent === true) {
      setTimeout(() => {
        setVerificationCodeSent(false);
        localStorage.removeItem('verificationCodeSent');
      }, 60000);
    }
  }, [verificationCodeSent]);

  const { partner } = currentContext;

  if (loadingActions) {
    return <></>;
  }

  if (location.pathname.includes('/antecipacao')) {
    return null;
  }

  return (
    <>
      <Drawer
        className="user-drawer"
        visible={visible}
        closable={window.location.href.includes('perfil')}
        onClose={() => {
          if (alert) form.validateFields();
          else onClose();
        }}
        title={window.location.href.includes('perfil')
          ? 'Editar perfil'
          : 'Bem-vindo(a)!'}
        primaryButton={{
          id: 'save-edited-user',
          loading,
          htmlType: 'submit',
          children: 'Salvar perfil',
          onClick: async () => {
            await form.validateFields();
            const values = form.getFieldsValue();
            await saveMe({
              variables: {
                ...values,
                employeesNumber: Number(values.employeesNumber),
                revenueAmount: Number(values.revenueAmount),
              },
            });
          },
        }}
      >
        {alert && (
          <Alert message={alert} />
        )}

        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} lg={14}>
              <Item name="fullName" rules={[{ required: true, message: 'Preencha o nome completo' }]}>
                <Input
                  disabled={!!formDefaultValues?.fullName}
                  placeholder="Nome completo"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={10}>
              <Item name="cpf" rules={[{ required: true, message: 'Preencha o CPF' }]}>
                <Input
                  disabled={!!formDefaultValues?.cpf}
                  type="masked"
                  guide
                  mask="cpf"
                  placeholder="CPF"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <Item name="email" rules={[{ required: true, message: 'Preencha o email' }]}>
                <Input placeholder="Email" />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={6}>
              <Item name="phoneNumber" rules={[{ required: true, message: 'Preencha o número' }]}>
                <Input
                  type="masked"
                  disabled={formDefaultValues?.phoneNumber
                    ? !!(phoneNumberVerified || isInvalid)
                    : false}
                  guide
                  mask="phone"
                  placeholder="Telefone com DDD"
                  alert="Seu telefone não poderá ser alterado enquanto houver um empréstimo ativo. Entre em contato com nosso suporte em caso de urgência"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={6}>
              <Item name="birthDate" rules={[{ required: true, message: 'Selecione a data de nascimento' }]}>
                <Input
                  type="masked"
                  guide
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="Data de nascimento"
                />
              </Item>
            </Col>

            {partner?.flow === 'badesc' && (
              <>
                <Col xs={24} sm={24} lg={12}>
                  <Item name="gender" rules={[{ required: true, message: 'Selecione o sexo' }]}>
                    <Select
                      className="gyramais-input"
                      placeholder="Selecione o sexo"
                    >
                      {genders?.map(({ id, name, value }) => (
                        <Option key={id} value={value}>{name}</Option>
                      ))}
                    </Select>
                  </Item>
                </Col>
              </>
            )}
          </Row>

          <hr />

          {!checkActions(['only-score']) && (
            <>
              <Row gutter={12}>
                <Col xs={24} sm={24} lg={6}>
                  <Item
                    name="employeesNumber"
                    rules={[
                      {
                        required: true,
                        type: 'integer',
                        message: 'Preencha o número de funcionários corretamente.',
                      },
                      {
                        type: 'integer',
                        max: 9999,
                        message: 'O máximo é de 9999 funcionários.',
                      },
                    ]}
                  >
                    <Input
                      disabled={!roles.find(({ name }) => name === `owner-${businessId}`)}
                      type="integer"
                      size="large"
                      placeholder="Nº de funcionários"
                    />
                  </Item>
                </Col>

                <Col xs={24} sm={24} lg={6}>
                  <Item name="revenueAmount" rules={[{ required: true, message: 'Preencha a fatura mensal' }]}>
                    <Input
                      id="revenueAmount"
                      disabled={!roles.find(({ name }) => name === `owner-${businessId}`)}
                      placeholder="Faturamento Mensal"
                      type="currency"
                    />
                  </Item>
                </Col>

                <Col xs={24} sm={24} lg={12}>
                  <Item name="segment" rules={[{ required: true, message: 'Selecione o segmento' }]}>
                    <Select
                      disabled={!roles.find(({ name }) => name === `owner-${businessId}`)}
                      className="gyramais-input"
                      placeholder="Selecione o segmento"
                    >
                      {businessSegments?.map(({ id, name, value }) => (
                        <Option key={id} value={value}>{name}</Option>
                      ))}
                    </Select>
                  </Item>
                </Col>

                <Col xs={24} sm={24} lg={12}>
                  <Item name="platformType" rules={[{ required: true, message: 'Selecione o ramo de atuação' }]}>
                    <Select
                      disabled={!roles.find(({ name }) => name === `owner-${businessId}`)}
                      className="gyramais-input"
                      placeholder="Selecione o ramo de atuação"
                    >
                      {businessPlatformTypes?.map(({ id, name, value }) => (
                        <Option key={id} value={value}>{name}</Option>
                      ))}
                    </Select>
                  </Item>
                </Col>

                <Col xs={24} sm={24} lg={12}>
                  <Item name="sector" rules={[{ required: true, message: 'Selecione o setor' }]}>
                    <Select
                      disabled={!roles.find(({ name }) => name === `owner-${businessId}`)}
                      className="gyramais-input"
                      placeholder="Selecione o setor"
                    >
                      {businessSectors?.map(({ id, name, value }) => (
                        <Option key={id} value={value}>{name}</Option>
                      ))}
                    </Select>
                  </Item>
                </Col>
              </Row>
              <hr />
            </>
          )}

          <Button
            id="btn-delete-account"
            link
            icon={<DeleteOutlined />}
            onClick={() => {
              handleSendCode();
              setModal(true);
            }}
          >
            Deletar conta
          </Button>

          <DeleteVerificationModal
            phoneNumberVerification={phoneNumberVerification}
            verificationCode={verificationCode}
            verificationCodeSent={verificationCodeSent}
            onCancel={() => setModal(false)}
            visible={modal}
            handleSendCode={() => handleSendCode()}
          />
        </Form>
      </Drawer>

      <MergeModal
        cpf={!cpf ? form.getFieldValue('cpf') : undefined}
        email={!email ? form.getFieldValue('email') : undefined}
        visible={mergeModalVisible}
        onClose={() => setMergeModalVisible(false)}
      />
    </>
  );
};

UserDrawer.propTypes = {
  onCompleted: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  alert: PropTypes.string,
};

export default UserDrawer;
