import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { Images } from 'assets';

import {
  DoubleRightOutlined,
} from '@ant-design/icons';

import './styles.less';

const {
  LoanInfosCircleSVG,
} = Images;

const LoanInfoCard = ({
  icon,
  title,
  value,
  improved,
  decreased,
}) => {
  const handleIcon = () => {
    if (improved) {
      return <DoubleRightOutlined className="point-up" />;
    }
    if (decreased) {
      return <DoubleRightOutlined className="point-down" />;
    }

    return null;
  };

  return (
    <Card className="loan-infos-card">
      <div className="loan-infos-card-content">
        <div className="loan-infos-card-top">
          <div className="loan-infos-card-icon">
            {icon}
          </div>
          <h3 className="loan-infos-card-title">
            {title}
          </h3>
        </div>
        <div className="loan-infos-card-bottom">
          <div className="loan-infos-card-value">
            {value}
            {handleIcon()}
          </div>
        </div>
        <LoanInfosCircleSVG className="loan-infos-card-image" />
      </div>
    </Card>
  );
};

LoanInfoCard.propTypes = {
  icon: PropTypes.shape(),
  title: PropTypes.string,
  value: PropTypes.string,
  improved: PropTypes.bool,
  decreased: PropTypes.bool,
};

export default memo(LoanInfoCard);
