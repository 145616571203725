// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tutorial-footer {
  width: 100%;
}
.tutorial-modal-steps-content {
  padding: 2rem 0;
  font-size: 1.2rem;
}
.tutorial-modal-steps-content .other-option-text {
  margin-top: 1.6rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/TutorialModal/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACA;EACE,eAAA;EACA,iBAAA;AACF;AAHA;EAII,6BAAA;AAEJ","sourcesContent":[".custom-tutorial-footer {\n  width: 100%;\n}\n.tutorial-modal-steps-content {\n  padding: 2rem 0;\n  font-size: 1.2rem;\n  .other-option-text {\n    margin-top: 1.6rem !important;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
