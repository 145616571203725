// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signature-form {
  display: flex;
}
#signature-form .ant-spin-container {
  width: 100%;
}
#signature-form .ant-spin-container .signature-form-body {
  margin-top: 2.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/SignatureForm/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAII,WAAA;AACJ;AALA;EAOM,kBAAA;AACN","sourcesContent":["#signature-form{\n  display: flex;\n\n  .ant-spin-container {\n    width: 100%;\n\n    .signature-form-body {\n      margin-top: 2.5rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
