export const isColorDark = (_hexColor) => {
  const hexColor = _hexColor.replace('#', '');

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq <= 128);
};

export const isBrightColor = (hexColor) => !isColorDark(hexColor);
export const getContrastYIQ = (hexColor) => ((isColorDark(hexColor)) ? '#ffffff' : '#2b2b2b');

export const changeColors = (colors = {}) => {
  const {
    primary = '#003930',
    primaryButton = '#0BB599',
    secondaryButton = '#B5E8E0',
    linkButton = '#000000',
  } = colors;

  if (primary) {
    document.documentElement.style.setProperty('--primary-color', primary);
    document.documentElement.style.setProperty('--primary-text-color', getContrastYIQ(primary));
  }

  if (primaryButton) {
    document.documentElement.style.setProperty('--primary-button-color', primaryButton);
    document.documentElement.style.setProperty('--primary-button-text-color', primaryButton.toUpperCase() === '#0BB599' ? '#003930' : getContrastYIQ(primaryButton));
  }

  if (secondaryButton) {
    document.documentElement.style.setProperty('--secondary-button-color', secondaryButton);
    document.documentElement.style.setProperty('--secondary-button-text-color', getContrastYIQ(secondaryButton));
  }

  if (linkButton) {
    document.documentElement.style.setProperty('--link-button-color', linkButton);
    document.documentElement.style.setProperty('--avatar-text-color', getContrastYIQ(linkButton));
  }
};

export const isHeaderDark = () => {
  const hexColor = document.documentElement.style.getPropertyValue('--primary-color');
  return isColorDark(hexColor.replace('#', ''));
};
