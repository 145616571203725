// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#powered-area {
  color: #000000;
  font-size: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.02rem;
  display: flex;
  flex-direction: column;
}
#powered-area .powered-icon {
  width: 3rem;
}
@media (max-width: 768px) {
  #powered-area {
    font-size: 0.7rem;
    transform: scale(0.8);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/PoweredBy/styles.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAPA;EASI,WAAA;AACJ;AAEE;EAAA;IACE,iBAAA;IACA,qBAAA;EACF;AACF","sourcesContent":["#powered-area {\n  color: #000000;\n  font-size: 0.5rem;\n  font-weight: 500;\n  letter-spacing: 0.02rem;\n  display: flex;\n  flex-direction: column;\n  \n  .powered-icon {\n    width: 3rem;\n  }\n\n  @media(max-width: @md-screen) {\n    font-size: .7rem;\n    transform: scale(.8);\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
