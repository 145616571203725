import React, { useMemo, useState } from 'react';
import {
  Row, Col, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  GCortado,
  LogoGyraWhite,
  LogoGyraDark,
} from 'assets/images';

import './styles.less';
import { isBrightColor } from 'utils/colors';
import { useQuery } from '@apollo/client';

import { useLocation } from 'react-router-dom';
import { getWhiteLabelData, setWhiteLabelData } from 'services/graphql/cache';
import {
  PARTNER,
} from './graphql';

const LogInScreenWrapper = ({ children }) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const [localWhiteLabelData, setLocalWhiteLabelData] = useState();

  const { loading } = useQuery(PARTNER, {
    skip: !id,
    variables: { id },
    onCompleted: (response) => {
      if (!response) {
        return;
      }
      const {
        appColors,
        darkLogo,
        lightLogo,
      } = response?.partner || {};

      setLocalWhiteLabelData(
        setWhiteLabelData({
          colors: appColors,
          darkLogoUrl: darkLogo?.url,
          lightLogoUrl: lightLogo?.url,
        }),
      );
    },
  });

  const whiteLabelData = useMemo(() => {
    if (localWhiteLabelData) {
      return localWhiteLabelData;
    }

    return getWhiteLabelData();
  }, [localWhiteLabelData]);

  const primaryColor = whiteLabelData?.colors?.primary;
  const partnerLogoUrl = whiteLabelData?.logoUrl;

  const logoComponent = (
    <div className="left-side-wrapper">
      <div className="logo-container">
        {primaryColor && isBrightColor(primaryColor)
          ? <img src={LogoGyraDark} alt="logo gyra+" className="gyra-logo" />
          : <img src={LogoGyraWhite} alt="logo gyra+" className="gyra-logo" />}

      </div>

      {partnerLogoUrl && (
        <div className="partner-logo-wrapper">
          <img src={partnerLogoUrl} alt="logo-parceiro" className="partner-logo" />
        </div>
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="center-spinner">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Row id="split-component">
        <Col md={24} lg={12} className="left-side">
          <Row className="left-side-row">
            <Col className="left-side-col">
              {logoComponent}
              {!partnerLogoUrl && (
                <img src={GCortado} alt="gzao cortado" className="gyra-logo-background" />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={24} lg={12} className="right-side">
          {children}
          <br />
          <div className="copyright">
            {new Date().getFullYear()}
            {' '}
            © GYRA+ - Todos os direitos reservados.
          </div>
        </Col>
      </Row>
    </>
  );
};

LogInScreenWrapper.propTypes = {
  children: PropTypes.element,
};

export default LogInScreenWrapper;
