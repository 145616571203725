import React, { memo } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  Button,
  Layout,
} from 'components/sdk';
import { Check } from 'assets/images';
import './styles.less';

const {
  Content,
} = Layout;

const RequestRules = () => (
  <Content id="request-rules" className="soft">
    <br />
    <br />

    <Row gutter={24} justify="space-between">
      <Col xs={24} sm={24} md={10} lg={8}>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <h2>
              <b>
                Parceria com crédito pré-aprovado
              </b>
            </h2>

            <h6>
              Na parceria GYRA+ e
              {' '}
              <b>olist store</b>
              {' '}
              o crédito já
              está pré-aprovado, com taxa e vantagens
              especiais. Veja os requisitos:
            </h6>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Ser lojista
                    {' '}
                    <b>olist store</b>
                    {' '}
                    com operação excelente
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Ter alto potencial de crescimento
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Não possuir restrições ou estar negativado
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Possuir CNPJ com pelo menos 12 meses de atividade
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={10} lg={8}>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <h2>
              <b>
                Tenha seus documentos em mãos
              </b>
            </h2>

            <h6>
              Com documentos em mãos o processo será
              muito mais rápido. Veja o que é necessário:
            </h6>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Documento de identidade ou CNH
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">Comprovante de renda</p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Comprovante de endereço
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="condition-item">
            <Row justify="start">
              <Col span={4}>
                <Check className="check-icon" />
              </Col>
              <Col span={18}>
                <Row>
                  <p className="no-margin-bottom">
                    Nome, telefone, CPF e e-mail do(a) cônjugue ou
                    averbação do divórcio
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row justify="center">
          <Col xs={24} sm={24} md={8}>
            <br />

            <Button
              className="olist-button"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              block
            >
              Quero um empréstimo
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>

    <br />
    <br />
  </Content>
);

export default memo(RequestRules);
