// @ts-check
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Statistic,
} from 'antd';
import {
  ClockCircleOutlined,
} from '@ant-design/icons';

import { useQuery } from '@apollo/client';
import { formatString } from '../../../utils';
import {
  Card,
} from '../../../components/gyramais';
import {
  Button,
} from '../../../components/sdk';
import { usePromoRenegotiationContext } from '../PromoRenegotiationContext';
import HighlightedDiscountCard from './HighlightedDiscountCard';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {
  DOWN_PAYMENT_DUE_DAYS,
} from '../graphql';

import './PromoRenegotiationCard.less';
import { useDeadline } from '../hooks/useDeadline';
import SelectPromo from './SelectPromo';
import NewOldPreview from './NewOldPreview';

const { Countdown } = Statistic;

/**
 * @type {React.FC}
 */
const PromoRenegotiationCard = () => {
  const { width } = useWindowDimensions();

  const {
    loan: {
      isBureau,
      lawsuitDate,
      portfolio,
    },
    installmentCountSelected,
    isRenegotiating,
    installmentDiscount,
    interestRateDiscount,
    currentInstallmentDownPayment,
    openConfirmModal,
    promoRenegotiateLoan,
    discountedInterestRate,
    noDiscount,
    portfolioName,
    businessOwnerFullName,
    businessCnpj,
  } = usePromoRenegotiationContext();

  const {
    data: {
      settings: {
        // @ts-ignore
        downPaymentDueDays,
      } = {},
    } = {},
  } = useQuery(DOWN_PAYMENT_DUE_DAYS);

  const deadline = useDeadline();

  const textButton = () => {
    if (portfolioName === '7' || lawsuitDate) {
      return 'Renegociar';
    } if (installmentCountSelected > 1) {
      return 'Continuar';
    }

    return 'Gerar boleto';
  };

  const primaryButton = (
    <Button
      loading={isRenegotiating}
      // @ts-ignore
      primary
      onClick={() => {
        if (
          (portfolioName === '7' || lawsuitDate)
          && !portfolio?.setting?.renegotiatedLawsuitedLoanOnApp
        ) {
          window.open(`https://wa.me/5521990551737?text=Meu%20nome%20%C3%A9%20${businessOwnerFullName}%20e%20desejo%20renegociar%20minha%20d%C3%ADvida%20referente%20ao%20CNPJ%20${formatString(businessCnpj, 'cnpj')}`);
        } else if (installmentCountSelected > 1) {
          openConfirmModal();
        } else {
          promoRenegotiateLoan();
        }
      }}
      data-cy="renegotiate-card-button"
    >
      {textButton()}
    </Button>
  );

  const showInterestRateDisclaimer = Boolean(interestRateDiscount && discountedInterestRate);

  const showDownPaymentDisclaimer = (
    <div className="down-payment-disclaimer">
      <p className="no-margin-bottom">
        <b>
          Você pagará um boleto de <b className="secondary-text">{formatString(currentInstallmentDownPayment, 'currency')}</b> de entrada e mais:
        </b>
      </p>
    </div>
  );

  const invoiceDueDays = installmentCountSelected === 1
    ? 3
    : downPaymentDueDays;

  const dueDaysPlural = invoiceDueDays > 1
    ? 's'
    : '';

  const explanationText = (
    <p className="no-margin-bottom">
      <b>
        Clique no botão abaixo, pague seu boleto em até {invoiceDueDays} dia{dueDaysPlural}
        {isBureau && (
          <>
            {' e '}
            <b className="secondary-text">
              reabilite seu nome
            </b>
          </>
        )}
        .
      </b>
    </p>
  );

  return (
    <div className="renegotiation-card" data-cy="renegotiation-div">
      <HighlightedDiscountCard
        deadline={deadline}
      />

      {/* @ts-ignore */}
      <Card className="text-card">
        {width > 768 && (
          <>
            {explanationText}
            <br />
          </>
        )}

        {(installmentDiscount || noDiscount) && (
          <>
            {installmentCountSelected > 1 && (
              <div className="down-payment">
                {showDownPaymentDisclaimer}
              </div>
            )}
            <div className="installment-discount-block">
              <div className="select-installment-count">
                <SelectPromo />
              </div>

            </div>

            {showInterestRateDisclaimer && (
              <b className="gyramais-disclaimer">
                * valores já utilizando sua nova taxa de juros de
                {' '}
                {Number(discountedInterestRate).toFixed(2)}%
              </b>
            )}
          </>
        )}

        <div className="card-value">
          <div className="card-value-text mobile">

            <NewOldPreview />

            {width <= 768 && (
              <>
                <br />
                {explanationText}
              </>
            )}

            {primaryButton}
          </div>

          <div className="card-value-buttons desktop">
            {!noDiscount ? (
              <>
                Sua oferta expira em:
                <div className="promotion-card__clock">
                  <ClockCircleOutlined />
                  <Countdown
                    value={deadline}
                    format="HH:mm:ss"
                  />
                </div>
              </>
            ) : <div />}
            {primaryButton}
          </div>

        </div>
      </Card>
    </div>
  );
};

export default React.memo(PromoRenegotiationCard);
