/* eslint-disable react/prop-types */
import React from 'react';
import {
  Form,
  notification,
} from 'antd';
import { useMutation } from '@apollo/client';
import { formatAndNotificateError } from 'utils';
import {
  Button,
} from 'components/sdk';
import { UserTag } from 'components/others';
import PropTypes from 'prop-types';
import { RESET_PASSWORD } from './graphql';

/**
 * @type {React.FC<{
 *  email: string
 * }>}
 * @returns
 */
const LostPasswordForm = ({ tag, email }) => {
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => notification.success({ message: 'O e-mail foi enviado com sucesso.' }),
    onError: (error) => formatAndNotificateError(error),
  });

  const onFinish = async () => {
    await resetPassword({
      variables: {
        email,
      },
    });
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
    >
      <h2 className="justified-text">
        Esqueceu sua
        {' '}
        <span className="secondary-text">senha?</span>
      </h2>

      <UserTag>
        {tag}
      </UserTag>

      <br />

      <p className="justified-text">
        <b>
          Clique no botão abaixo para redefinir sua senha.
          Você irá receber um link por e-mail,
          o qual te redirecionará para criá-la novamente.
        </b>
      </p>

      <br />

      <Button
        block
        loading={loading}
        htmlType="submit"
        className="button-lost-password"
      >
        Redefinir senha
      </Button>

    </Form>
  );
};

LostPasswordForm.propTypes = {
  email: PropTypes.string,
  tag: PropTypes.string,
};

export default LostPasswordForm;
