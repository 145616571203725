import LOANS from './loans.gql';
import LOAN_UPDATE from './loanUpdate.gql';
import ME from './me.gql';
import SET_NEXT_MODULE_FROM_USER_INTERACTION from './setNextModuleFromUserInteraction.gql';
import SET_PREVIOUS_MODULE_FROM_USER_INTERACTION from './setPreviousModuleFromUserInteraction.gql';
import PREPAYMENT_OPPORTUNITIES from './prepaymentOpportunities.gql';
import INTEGRATION_UPDATED from './integrationUpdated.gql';
import REQUEST_PREPAYMENT from './requestPrepayment.gql';
import SIGN_PREPAYMENT from './signPrepayment.gql';
import FACE_MATCH_UPDATED from './faceMatchUpdated.gql';
import REGISTER_CLIENTE_RECEIVABLES from './registerClientReceivables.gql';
import GET_LAST_REGISTER_REQUEST_STATUS from './getLastRegisterRequestStatus.gql';
import REGISTER_REQUEST_STATUS_UPDATED from './registerRequestStatusUpdated.gql';
import PRE_PAYMENTS from './prepayments.gql';
import TOOLBOX_LOCATION_UPDATED from './toolboxLocationUpdated.gql';
import SETTINGS from './settings.gql';

export {
  ME,
  LOANS,
  LOAN_UPDATE,
  SET_NEXT_MODULE_FROM_USER_INTERACTION,
  SET_PREVIOUS_MODULE_FROM_USER_INTERACTION,
  PREPAYMENT_OPPORTUNITIES,
  INTEGRATION_UPDATED,
  REQUEST_PREPAYMENT,
  SIGN_PREPAYMENT,
  FACE_MATCH_UPDATED,
  REGISTER_CLIENTE_RECEIVABLES,
  GET_LAST_REGISTER_REQUEST_STATUS,
  REGISTER_REQUEST_STATUS_UPDATED,
  PRE_PAYMENTS,
  TOOLBOX_LOCATION_UPDATED,
  SETTINGS,
};
