import INTEGRATIONS from './integrations.gql';
import COMPLETE_INTEGRATION from './completeIntegration.gql';
import ME from './me.gql';
import BANK_ACCOUNTS from './bankAccounts.gql';
import IMPROVE_LOAN_WITH_INTEGRATION from './improveLoanWithIntegration.gql';
import SETTINGS from './settings.gql';

export {
  INTEGRATIONS,
  COMPLETE_INTEGRATION,
  ME,
  BANK_ACCOUNTS,
  IMPROVE_LOAN_WITH_INTEGRATION,
  SETTINGS,
};
