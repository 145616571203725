import BANKS from './banks.gql';
import SAVE_BANK_ACCOUNT from './saveBankAccount.gql';
import ME from './me.gql';

export default BANKS;
export {
  BANKS,
  ME,
  SAVE_BANK_ACCOUNT,
};
