// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-area {
  width: 100%;
}
@media (max-width: 768px) {
  .bank-row {
    justify-content: space-around;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/BanksList/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAGE;EAAA;IACE,6BAAA;EAAF;AACF","sourcesContent":[".search-area {\n  width: 100%;\n}\n\n.bank-row {\n  @media(max-width: @md-screen) {\n    justify-content: space-around;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
