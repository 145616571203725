import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Radio,
} from 'antd';
import { Card } from 'components';
import { maskString } from 'utils';

import './styles.less';

const BankAccountCard = ({
  className,
  bankAccount,
  selected,
  onClick,
}) => (
  <Card
    className={`bank-account-card ${className || ''}`}
    selected={selected}
    onClick={onClick}
  >
    <Row align="middle" className="bank-account-card-container">
      <Col xs={2} sm={2} md={2} lg={2}>
        <Radio checked={selected} />
      </Col>

      <Col xs={10} sm={10} md={10} lg={10}>
        {bankAccount.bank.logo ? (
          <img
            className="bank-account-card-logo"
            src={bankAccount.bank.logo.url}
            alt={bankAccount.bank.name}
          />
        ) : (
          <h5 className="no-margin-bottom">
            {bankAccount.bank.name}
          </h5>
        )}
      </Col>

      <Col xs={12} sm={12} md={12} lg={12} align="right">
        <h6>
          <b>
            Agência:
            {' '}
            <b className="secondary-text">
              {maskString(bankAccount.branchCode)}
            </b>
          </b>
        </h6>
        <h6 className="no-margin-bottom">
          <b>
            Conta
            {' '}
            {bankAccount.currentAccount ? 'corrente' : 'poupança'}
            :
            {' '}
            <b className="secondary-text">
              {maskString(bankAccount.accountNumber)}
            </b>
          </b>
        </h6>
      </Col>
    </Row>
  </Card>
);

BankAccountCard.propTypes = {
  className: PropTypes.string,
  bankAccount: PropTypes.shape(),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BankAccountCard;
