import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {
  LeftOutlined,
  CrownFilled,
  SmileFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { Card } from 'components/gyramais';
import { Button } from 'components/sdk';

const BusinessUserForm = ({
  selectedType,
  onSelectType,
  onClickBack,
  loading,
}) => (
  <>
    <Button
      link
      onClick={onClickBack}
      icon={(<LeftOutlined />)}
    >
      Voltar
    </Button>
    <br />

    <h2>
      Qual é o seu
      {' '}
      <span className="secondary-text">
        vínculo
      </span>
      {' '}
      com a empresa?
    </h2>
    <br />

    <Row gutter={[0, 12]}>
      <Col span={24}>
        <Card
          className={`login-activation-type-card ${selectedType === 'associate' ? 'selected' : ''}`}
          onClick={() => onSelectType('associate')}
        >
          <Row gutter={20}>
            <Col span={4}>
              <h2 className="no-margin-bottom">
                {selectedType === 'associate' && loading ? (
                  <LoadingOutlined className="secondary-text" />
                ) : (
                  <CrownFilled className="secondary-text" />
                )}
              </h2>
            </Col>
            <Col span={20}>
              <p className="no-margin-bottom">
                <b>
                  Sou sócio da empresa e quero seguir com a proposta
                </b>
              </p>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          className={`login-activation-type-card ${selectedType === 'owner' ? 'selected' : ''}`}
          onClick={() => onSelectType('owner')}
        >
          <Row gutter={20}>
            <Col span={4}>
              <h2 className="seconcary-text no-margin-bottom">
                {selectedType === 'owner' && loading ? (
                  <LoadingOutlined className="secondary-text" />
                ) : (
                  <SmileFilled className="secondary-text" />
                )}
              </h2>
            </Col>
            <Col span={20}>
              <p className="no-margin-bottom">
                <b>
                  Sou solicitante pela empresa e quero seguir com a proposta
                </b>
              </p>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  </>
);

BusinessUserForm.propTypes = {
  selectedType: PropTypes.string,
  onSelectType: PropTypes.func,
  onClickBack: PropTypes.func,
  loading: PropTypes.bool,
};

export default BusinessUserForm;
