import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Select,
  Divider,
  Alert,
} from 'antd';
import MaskedTextInput from 'react-text-mask';
import { Button } from 'components/sdk';
import { useMutation, useQuery } from '@apollo/client';
import { formatAndNotificateError } from 'utils';
import { SAVE_BANK_ACCOUNT, BANKS, ME } from './graphql';

const { Item } = Form;
const { Option } = Select;

const PrepaymentBankAccountForm = ({
  bank: {
    id,
  } = {},
  onClose,
  onSaveSuccess,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const {
    data: {
      banks = [],
    } = {},
  } = useQuery(BANKS, {
    variables: {
      limit: 9999,
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const {
    data: {
      me: {
        currentBusiness,
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  const [saveBankAccount] = useMutation(SAVE_BANK_ACCOUNT, {
    onCompleted: (data) => {
      setLoading(false);
      onSaveSuccess?.(data);
      onClose?.();
    },
    onError: (error) => {
      setLoading(false);
      formatAndNotificateError(error);
    },
  });

  const handleSaveBankAccount = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      const values = form.getFieldsValue();
      const bankAccountData = {
        bankId: values.bankId,
        currentAccount: values.currentAccount === 'corrente',
        branchCode: values.branchCode.replace(/\D/g, ''),
        accountNumber: values.accountNumber.replace(/\D/g, ''),
        businessId: currentBusiness?.id,
      };

      await saveBankAccount({ variables: bankAccountData });

    } catch (error) {
      setLoading(false);
      formatAndNotificateError(error);
    }
  };

  return (
    <div className="prepayment-bank-account-form">
      <Divider />

      <Alert
        type="warning"
        showIcon
        message={(
          <span>
            Importante: Trabalhamos <strong>APENAS</strong> com contas PJ (Pessoa Jurídica).
            <strong> NÃO</strong> trabalhamos com PF (Pessoa Física).
          </span>
        )}
      />

      <br />

      <Form form={form}>
        <Row gutter={12}>
          <Col xs={24} md={8}>
            <Item name="bankId" rules={[{ required: true, message: 'Por favor selecione a instituição bancária' }]}>
              <Select
                disabled={Boolean(id)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children
                  .toLowerCase().indexOf(input.toLowerCase()) >= 0}
                className="gyramais-input"
                dropdownClassName="gyramais-input-options"
                placeholder="Instituição Bancária"
                value={id}
              >
                {banks.map(({ id, code, name }) => (
                  <Option key={id} value={id}>
                    {`${code} - ${name}`}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>

          <Col xs={24} md={8}>
            <Item name="currentAccount" rules={[{ required: true, message: 'Por favor selecione o tipo de conta.' }]}>
              <Select
                className="gyramais-input"
                placeholder="Tipo de Conta"
              >
                <Option key="corrente" value="corrente">Conta corrente</Option>
                <Option key="poupanca" value="poupanca">Conta poupança</Option>
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={24} md={8}>
            <Item
              name="branchCode"
              rules={[
                { required: true, message: 'Por favor preencha a agência.' },
                () => ({
                  validator(rule, value) {
                    const digits = value.replace(/\D/g, '');
                    if (digits.length === 4) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('A agência deve ter 4 dígitos.'));
                  },
                }),
              ]}
            >
              <MaskedTextInput
                className="ant-input gyramais-input"
                guide
                mask={[/\d/, /\d/, /\d/, /\d/]}
                placeholder="Agência (sem dígito)"
              />
            </Item>
          </Col>

          <Col xs={24} md={8}>
            <Item
              name="accountNumber"
              onBlur={(args) => {
                const digits = args.target.value.replace(/\D/g, '');
                if (digits) {
                  form.setFieldsValue({
                    accountNumber: `${'0'.repeat(10 - (digits.length - 2))}${digits.substring(0, digits.length - 1)}-${digits.substring(digits.length - 1)}`,
                  });
                }
              }}
              rules={[
                { required: true, message: 'Por favor preencha a conta.' },
                () => ({
                  validator(rule, value) {
                    if (!value || value.replace(/\D/g, '').length === 12) {
                      return Promise.resolve();
                    }
                    const emailsDontMatch = new Error('Preencha todos os dígitos');
                    return Promise.reject(emailsDontMatch);
                  },
                }),
              ]}
            >
              <MaskedTextInput
                className="ant-input gyramais-input"
                guide
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
                placeholder="Conta (com dígito)"
              />
            </Item>
          </Col>

          <Col>
            <div className="button-container">
              <Button className="save-button" onClick={handleSaveBankAccount} disabled={loading}>
                Adicionar conta
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

PrepaymentBankAccountForm.propTypes = {
  bank: PropTypes.shape(),
  onClose: PropTypes.func,
  onSaveSuccess: PropTypes.func,
};

export default PrepaymentBankAccountForm;
