import React, { memo, useEffect, useRef } from 'react';

const HuggyChat = () => {
  const ref = useRef();

  if (process.env.ENVIROMENT !== 'production') {
    return null;
  }
  const uuid = () => {
    if (localStorage.getItem('partner') === 'olist') return 'd14621fc-80a2-458f-b882-db87f6fe93d0';
    if (localStorage.getItem('partner') === 'stone') return '1615ea87-e741-445d-9089-7fa4b035e99f';
    if (window.location.pathname === '/olist') return '11dca9fc-e37c-42f4-b227-67feb00a0b82';
    return 'cff07a49-038d-4779-aadc-ccb5739bf7f2';
  };
  useEffect(() => {
    const handleLoad = (() => {
      if (window.Huggy && window.Huggy.init) {
        window.Huggy.init({
          defaultcountry: '+55',
          uuid: uuid(),
          widget_id: '16290',
          company: '19888',
          contextID: '1e1988312980c964d57bfb1af0c1fa07',
        });
      } else window.Huggy = null;
    });
    window.addEventListener('load', handleLoad);
  }, []);
  return <div ref={ref} />;
};

export default memo(HuggyChat);
