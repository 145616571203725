// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#how-it-works .how-it-works-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#how-it-works .how-it-works-item .how-it-work-step {
  width: 2.375rem;
  height: 2.375rem;
  border: 0.188rem solid #d2f9ed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #d2f9ed;
  margin-bottom: 2rem;
}
#how-it-works .how-it-works-item .how-it-work-image {
  width: 3.75rem;
  height: 3.75rem;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  #how-it-works .how-it-works-item .how-it-work-image {
    width: 20%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/sections/HowItWorks/styles.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AALA;EAQM,eAAA;EACA,gBAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAAN;AAjBA;EAqBM,cAAA;EACA,eAAA;EACA,mBAAA;AADN;AAII;EAAA;IAEI,UAAA;EAFN;AACF","sourcesContent":["#how-it-works {\n  .how-it-works-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    .how-it-work-step {\n      width: 2.375rem;\n      height: 2.375rem;\n      border: .188rem solid #d2f9ed;\n      border-radius: 50%;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      font-size: 1.25rem;\n      color: #d2f9ed;\n      margin-bottom: 2rem;\n    }\n\n    .how-it-work-image {\n      width: 3.75rem;\n      height: 3.75rem;\n      margin-bottom: 2rem;\n    }\n    \n    @media(max-width: @md-screen) {\n      .how-it-work-image {\n        width: 20%;\n      }\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
