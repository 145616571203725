import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  notification,
  message,
} from 'antd';
import {
  Input,
  Drawer,
} from 'components/gyramais';
import { useMutation } from '@apollo/client';
import { formatError } from 'utils';
import {
  SAVE_USER_WITH_DATATRUST,
} from './graphql';

const { Item, useForm } = Form;

const EditUserDrawer = ({
  visible,
  handleClose,
  user,
  user: {
    id,
    email,
    phoneNumber,
    fullName,
  } = {},
}) => {
  const [form] = useForm();

  const [saveUserWithDatatrust, { loading }] = useMutation(SAVE_USER_WITH_DATATRUST, {
    onCompleted: () => {
      notification.success({ message: 'Usuário editado com sucesso!' });
    },
    onError: (error) => message.error(formatError(error)),
  });

  useEffect(() => {
    form.setFieldsValue({ email, phoneNumber });
  }, [user]);

  const handleSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    await saveUserWithDatatrust({ variables: { id, ...values } });
  };
  return (
    <Drawer
      visible={visible}
      id="edit-user-drawer"
      title={<div>Editar usuário <span className="secondary-text">{fullName}</span></div>}
      onClose={() => {
        handleClose();
        form.setFieldsValue({ email, phoneNumber });
      }}
      maxWidth="40rem"
      primaryButton={{
        id: 'edit-user-drawer-button',
        children: 'Confirmar Edições',
        loading,
        disabled: loading,
        onClick: handleSubmit,
      }}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} lg={12}>
            <Item name="email" rules={[{ required: true, message: 'Preencha o email' }]}>
              <Input
                placeholder="Email"
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Item name="phoneNumber" rules={[{ required: true, message: 'Preencha o celular' }]}>
              <Input
                placeholder="Celular"
                mask="phone"
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

EditUserDrawer.propTypes = {
  handleClose: PropTypes.func,
  visible: PropTypes.bool,
  user: {
    id: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    fullName: PropTypes.string,
  },

};

export default EditUserDrawer;
