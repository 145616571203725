import Bank from './bank-account.png';
import BigShoesTeamWork from './BigShoesTeamWork.png';
import BtnPlus from './btn-plus.png';
import Check from './check.svg';
import Close from './close.svg';
import FacebookWhite from './gyra-facebook-white.svg';
import HowItWork1 from './como-funciona-1.svg';
import HowItWork2 from './como-funciona-2.svg';
import HowItWork3 from './como-funciona-3.svg';
import HowItWork4 from './como-funciona-4.svg';
import HowItWork5 from './como-funciona-5.svg';
import InstagramWhite from './gyra-instagram-white.svg';
import Juros from './juros.svg';
import LinkedinWhite from './gyra-linkedin-white.svg';
import Logo from './logo.svg';
import LogoGyraG from './logo-gyra-g.svg';
import LogoGyraMaisOriginal from './logo-gyramais-original.svg';
import NotebookAndMobileMock from './notebookandmobile.png';
import LogoReduzida from './logo-reduzida.svg';
import Parcelas from './parcelas.svg';
import LogoOlist from './logo_olist store.png';
import WhatsappWhite from './gyra-whatsapp-white.svg';
import GyraWhite from './gyra-white.svg';
import GyraGrey from './gyra-grey.svg';
import ImgPdfFaq from './img-pdf-faq.jpg';
import Gif12 from './12.gif';
import Gif25 from './25.gif';
import CheckboxGif from './22.gif';
import Gif23 from './23.gif';
import Gif02 from './02.gif';
import Success from './success.gif';
import SendingLetter from './177-envelope-mail-send-outline.gif';
import GyraLogo from './gyra-logo.svg';
import FinishIntegration from './finish-integration.svg';
import DocumentExampleRG from './rg_example.png';
import DocumentExampleCNH from './cnh_example.png';
import DocumentExampleIRPF1 from './irpf_example1.jpg';
import DocumentExampleIRPF2 from './irpf_example2.jpg';
import DocumentExampleIRPF3 from './irpf_example3.jpg';
import DocumentExampleIRPF4 from './irpf_example4.jpg';
import DocumentExampleIRPF5 from './irpf_example5.jpg';
import DocumentExampleIRPF6 from './irpf_example6.jpg';
import DocumentExampleIRPF7 from './irpf_example7.jpg';
import DocumentExampleIRPF8 from './irpf_example8.jpg';
import DocumentExampleIRPF9 from './irpf_example9.jpg';
import Ticket from './ticket.svg';
import LoanInfosCircleSVG from './circles.svg';
import LogoGyraWhite from './gyra-white.png';
import LogoGyraDark from './gyra-black.png';
import PessoalComemorando from './pessoal_comemorando.svg';
import GCortado from './crop-g.png';
import Dollar from './dolar.svg';
import DownPaymentInvoiceIcon from './down-payment-invoice-icon.png';
import BankAccountOptin from './bank-account-optin.svg';
import ReceivableImg from './receivable-img.png';
import ShareholderImg from './shareholder-img.png';

export {
  Bank,
  BigShoesTeamWork,
  BtnPlus,
  Check,
  Close,
  FacebookWhite,
  HowItWork1,
  HowItWork2,
  HowItWork3,
  HowItWork4,
  HowItWork5,
  InstagramWhite,
  Juros,
  LinkedinWhite,
  Logo,
  LogoGyraG,
  LogoGyraMaisOriginal,
  NotebookAndMobileMock,
  Parcelas,
  LogoOlist,
  WhatsappWhite,
  GyraWhite,
  GyraGrey,
  ImgPdfFaq,
  Gif12,
  Success,
  Gif25,
  Gif02,
  Gif23,
  SendingLetter,
  GyraLogo,
  FinishIntegration,
  LogoReduzida,
  DocumentExampleRG,
  DocumentExampleCNH,
  DocumentExampleIRPF1,
  DocumentExampleIRPF2,
  DocumentExampleIRPF3,
  DocumentExampleIRPF4,
  DocumentExampleIRPF5,
  DocumentExampleIRPF6,
  DocumentExampleIRPF7,
  DocumentExampleIRPF8,
  DocumentExampleIRPF9,
  Ticket,
  LoanInfosCircleSVG,
  LogoGyraWhite,
  LogoGyraDark,
  PessoalComemorando,
  GCortado,
  Dollar,
  DownPaymentInvoiceIcon,
  CheckboxGif,
  BankAccountOptin,
  ReceivableImg,
  ShareholderImg,
};
