import Layout from './Layout';
import Progress from './Progress';
import Modal from './Modal';
import Button from './Button';
import Slider from './Slider';
import Avatar from './Avatar';

export {
  Layout,
  Progress,
  Modal,
  Button,
  Slider,
  Avatar,
};
