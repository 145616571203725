/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal } from 'antd';

import {
  Button,
} from 'components/sdk';

import './styles.less';

/**
 * @typedef {{
 *    id?: string
 *    text: string
 *    onClick?: () => void
 *    visible?: boolean
 *    loading?: boolean
 *    disabled?: boolean
 *    block?: boolean
 *    color?: string
 *    htmlType?: string
 *    href?: string
 *    target?: string
 *    data-cy?: string
 *  }} ModalButtonProps
 *
 * @param {{
 *  children: import('react').ReactNode[]
 *  className?: string
 *  title?: string | import('react').ReactNode[]
 *  visible?: boolean
 *  centered?: boolean
 *  hideFooter?: boolean
 *  closable?: boolean
 *  destroyOnClose?: boolean
 *  width?: number | string
 *  onClose?: () => void
 *  okButton?: ModalButtonProps
 *  cancelButton?: ModalButtonProps
 *  data-cy?: string
 * }} props
 */
const GyramaisModal = ({
  children,
  hideFooter,
  okButton,
  cancelButton,
  className,
  onClose,
  'data-cy': dataCy,
  ...rest
}) => {
  const isOkButtonVisible = okButton !== undefined
    || (okButton && okButton.visible !== true);

  const isCancelButtonVisible = cancelButton !== undefined
    || (cancelButton && cancelButton.visible !== true);

  const shouldRenderFooter = !hideFooter && (isOkButtonVisible || isCancelButtonVisible);

  return (
    <Modal
      className={`gyramais-modal ${className || ''}`}
      onCancel={onClose}
      data-cy={dataCy}
      footer={shouldRenderFooter ? (
        <>
          {isCancelButtonVisible && (
          <Button
            id={cancelButton?.id}
            link={!cancelButton?.color}
            color={cancelButton?.color}
            onClick={cancelButton?.onClick}
            loading={cancelButton?.loading}
            disabled={cancelButton?.disabled}
            className={cancelButton?.className}
            block={cancelButton?.block}
            htmlType={cancelButton?.htmlType}
            href={cancelButton?.href}
            target={cancelButton?.target}
            data-cy={cancelButton?.['data-cy']}
          >
            {cancelButton?.text || '< Voltar'}
          </Button>
          )}

          {isOkButtonVisible && (
          <Button
            id={okButton?.id}
            color={okButton?.color || 'primary'}
            onClick={okButton?.onClick}
            loading={okButton?.loading}
            disabled={okButton?.disabled}
            className={okButton?.className}
            block={okButton?.block}
            htmlType={okButton?.htmlType}
            href={okButton?.href}
            target={okButton?.target}
            data-cy={okButton?.['data-cy']}
          >
            {okButton?.text || 'Confirmar e prosseguir'}
          </Button>
          )}
        </>
      ) : false}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default GyramaisModal;
