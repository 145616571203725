import React, { useState, useEffect, useMemo } from 'react';
import {
  SubHeader,
} from 'components/gyramais';
import {
  Table,
  Tabs,
  Skeleton,
  notification,
} from 'antd';
import {
  Button,
  Layout,
} from 'components/sdk';
import { EditUserDrawer } from 'components';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { formatString, formatError } from 'utils';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  ME,
  SEND_SIGNATURE,
} from './graphql';

import './styles.less';

const { Content } = Layout;
const { TabPane } = Tabs;

const BusinessUsersManagementScreen = () => {
  const { currentContext } = useBusinessLoanContext();

  const [activeTab, setActiveTab] = useState('1');
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [verificationCodeSent, setVerificationCodeSent] = useState(localStorage.getItem('verificationCodeSent'));

  const {
    data: {
      me: {
        currentBusiness: {
          loans = [],
        } = {},
      } = {},
    } = {},
    loading,
  } = useQuery(ME);
  const loan = useMemo(() => loans
    .find(({ id }) => id === currentContext?.loan?.id), [loans, currentContext?.loan]);

  const [sendSignature, { loadingSendSignature }] = useMutation(SEND_SIGNATURE, {
    onCompleted: () => {
      notification.success({ message: 'Assinatura enviada com sucesso!' });
      setVerificationCodeSent(true);
      localStorage.setItem('verificationCodeSent', true);
    },
    onError: (error) => notification.error({ message: formatError(error) }),
  });

  const filteredBusinessUsers = [{
    label: 'Sócios',
    type: 'socio',
    signatures: loan?.signatures,
    users: loan?.signatures
      ?.filter(({ types }) => types.includes('Sócio')),
  }, {
    label: 'Avalistas',
    type: 'avalista',
    signatures: loan?.signatures,
    users: loan?.signatures
      ?.filter(({ types }) => types.includes('Avalista')),
  }, {
    label: 'Representantes Legais',
    type: 'representantelegal',
    signatures: loan?.signatures,
    users: loan?.signatures
      ?.filter(({ types }) => types.includes('Representante Legal')),
  }];

  useEffect(() => {
    if (verificationCodeSent === true) {
      setTimeout(() => {
        setVerificationCodeSent(false);
        localStorage.removeItem('verificationCodeSent');
      }, 60000);
    }
  }, [verificationCodeSent]);

  return (
    <Layout id="edit-business-user-screen">
      <SubHeader
        secondary
        title="Responsáveis"
      />
      <Content>
        { loading ? (
          <>
            <Skeleton.Button
              className="actions-refer-loading"
              shape="square"
              size="large"
              active
            />
          </>
        )
          : (
            <Tabs
              className="business-user-types"
              activeKey={activeTab}
              onChange={(e) => setActiveTab(`${e}`)}
            >
              {
            filteredBusinessUsers?.map((businessUser, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TabPane tab={(<h5 className="no-margin-bottom">{`${businessUser.label} (${businessUser?.users?.length})`}</h5>)} key={index + 1}>
                <Table
                  pagination={false}
                  className="refer-table"
                  dataSource={businessUser?.users?.map(({ user }) => user)}
                  scroll={{ x: 'max-content' }}
                  columns={[{
                    title: 'Nome',
                    key: 'name',
                    dataIndex: 'fullName',
                    render: (text) => (
                      <span className="edit-business-users-info-displayer">{text}</span>
                    ),
                  }, {
                    title: 'Email',
                    key: 'email',
                    dataIndex: 'email',
                    render: (text) => (
                      <span className="edit-business-users-info-displayer">{text}</span>
                    ),
                  }, {
                    title: 'CPF',
                    key: 'cpf',
                    dataIndex: 'cpf',
                    render: (text) => (
                      <span className="edit-business-users-info-displayer">{formatString(text, 'cpf')}</span>
                    ),
                  }, {
                    title: 'Celular',
                    key: 'phoneNumber',
                    dataIndex: 'phoneNumber',
                    render: (text) => (
                      <span className="edit-business-users-info-displayer">{text}</span>
                    ),
                  }, {
                    key: 'sendRemember',
                    render: (record) => {
                      const userSignature = businessUser?.signatures?.find(
                        (signature) => signature?.user?.id === record?.id,
                      );
                      return (
                        <Button
                          link
                          loading={loadingSendSignature}
                          disabled={Boolean(userSignature?.signedAt) || verificationCodeSent}
                          id="send-reminder-button"
                          onClick={() => {
                            sendSignature({ variables: { id: userSignature?.id } });
                          }}
                        >
                          <MailOutlined /> Enviar lembrete
                        </Button>
                      );
                    },
                  }, {
                    key: 'editUser',
                    render: (record) => {
                      const userSignature = businessUser?.signatures?.find(
                        (signature) => signature?.user?.id === record?.id,
                      );
                      return (
                        <Button
                          link
                          loading={loadingSendSignature}
                          disabled={Boolean(userSignature?.signedAt)}
                          id="edit-user-button"
                          onClick={() => {
                            setSelectedUser(record);
                            setVisible(true);
                          }}
                        >
                          <EditOutlined /> Editar
                        </Button>
                      );
                    },
                  }]}
                />
              </TabPane>
            ))
          }
            </Tabs>
          )}
      </Content>
      <EditUserDrawer
        visible={visible}
        handleClose={() => setVisible(false)}
        user={selectedUser}
      />
    </Layout>
  );
};

export default BusinessUsersManagementScreen;
