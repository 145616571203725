import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Skeleton,
  Tooltip,
} from 'antd';
import {
  Input,
} from 'components/gyramais';

import './styles.less';
import dayjs from 'dayjs';

const Information = ({
  label,
  value,
  type,
  danger,
  valuePrefix,
  loading,
  size = 'small',
  valueSuffix,
  originalValue,
  maxLen,
  editable,
  onChange,
}) => {
  const valueFieldRef = useRef(null);
  const [isValueTruncated, setValueTruncated] = useState(false);

  useEffect(() => {
    if (value && !loading && valueFieldRef?.current?.clientWidth !== 0) {
      // checa se o css text-overflow: ellipsis esta sendo aplicado
      // ou seja se o "..." aparece
      setValueTruncated(valueFieldRef?.current?.scrollWidth > valueFieldRef?.current?.clientWidth);
    }
  }, [value, loading]);

  const valueFormatted = (value, completeValue) => {
    if (!value) {
      return '-';
    }
    switch (type) {
      case 'currency':
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
          .format(value);

      case 'percentage':
        return `${parseFloat(value).toFixed(2)}%`.replace('.', ',');

      case 'date':
        return dayjs(value).format('DD/MM/YYYY');

      default:
        if (!completeValue && maxLen && value.length > maxLen) {
          return `${value.substring(0, maxLen)}...`;
        }

        return value;
    }
  };

  return (
    <div className={`gyramais-information ${danger ? 'danger' : ''}`}>
      {label && (
        <div className="information-title-container">
          <h6 className="no-margin-bottom">
            <b className="secondary-text">
              {label}
            </b>
          </h6>
        </div>
      )}

      {loading ? (
        <div className="value-area">
          <Skeleton.Button
            active
            className="information-skeleton"
            shape="square"
            size="large"
          />
        </div>
      ) : (
        <div className="value-area">
          <Tooltip title={valueFormatted(value, true)} trigger={(isValueTruncated || (maxLen && value.length > maxLen)) ? 'hover' : ''}>
            <div className="information-value">
              {!editable ? (
                <>
                  {size === 'large' ? (
                    <h2 className="no-margin-bottom information-value">
                      {valuePrefix}
                      <div className="value-wrapper" ref={valueFieldRef}>
                        {originalValue && (
                          <span className="original-promo-item">{valueFormatted(originalValue)}</span>
                        )}

                        {valueFormatted(value)}
                      </div>
                      {valueSuffix}
                    </h2>
                  ) : (
                    <h4 className="no-margin-bottom information-value">
                      {valuePrefix}
                      <div className="value-wrapper" ref={valueFieldRef}>
                        {originalValue && (
                          <span className="original-promo-item">{valueFormatted(originalValue)}</span>
                        )}

                        {valueFormatted(value)}
                      </div>
                      {valueSuffix}
                    </h4>
                  )}
                </>
              ) : (
                <Input
                  className="information-input"
                  value={value}
                  onChange={onChange}
                />
              )}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

Information.propTypes = {
  valuePrefix: PropTypes.shape(),
  valueSuffix: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.bool,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  originalValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  type: PropTypes.oneOf([
    'currency',
    'percentage',
    'date',
    null,
  ]),
  danger: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf([
    'small',
    'large',
  ]),
  maxLen: PropTypes.number,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Information;
