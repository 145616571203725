// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-user-drawer .steps {
  margin-bottom: 2rem;
}
.business-user-drawer .statement-area {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.business-user-drawer .statement-area .statement-area-title {
  margin-bottom: 0.25rem !important;
}
.business-user-drawer .statement-area .statement-area-subtitle {
  margin-bottom: 0.75rem !important;
}
.business-user-drawer .statement-area .uploaded-file-icon {
  cursor: pointer;
  width: 12rem;
  height: 8rem;
  border: 0.063rem solid var(--primary-color);
  font-size: 4rem !important;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/BusinessUserDrawer/styles.less"],"names":[],"mappings":"AAAA;EAkBI,mBAAA;AAhBJ;AAFA;EAsBI,gBAAA;EAkBA,qBAAA;AAlCJ;AANA;EAwBM,iCAAA;AAfN;AATA;EA2BM,iCAAA;AAfN;AAZA;EA8BM,eAAA;EACA,YAAA;EACA,YAAA;EACA,2CAAA;EACA,0BAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAfN","sourcesContent":[".business-user-drawer {\n  // // corrige scroll para o limbo\n  // .ant-carousel,\n  // .slick-slider.slick-initialized,\n  // .slick-list,\n  // .slick-track,\n  // .drawer-body {\n  //   height: 100%;\n  // }\n\n  // // corrige scroll para o limbo\n  // .slick-slide.slick-active.slick-current {\n  //   height: 100%;\n  //   overflow: auto;\n  // }\n\n\n  .steps{\n    margin-bottom: 2rem;\n  }\n  \n  .statement-area {\n    margin-top: 1rem;\n    .statement-area-title {\n      margin-bottom: .25rem !important;\n    }\n    .statement-area-subtitle {\n      margin-bottom:  .75rem !important;\n    }\n    .uploaded-file-icon {\n      cursor: pointer;\n      width: 12rem;\n      height: 8rem;\n      border: .063rem solid var(--primary-color);\n      font-size: 4rem !important;\n      background-color: #f0f0f0;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n    margin-bottom: 1.5rem;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
