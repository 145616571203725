// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#needed-docs .user-conditions {
  padding-inline-start: 0.875rem;
  margin-top: 0.625rem;
}
#needed-docs .user-conditions li {
  margin-bottom: 1rem;
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/components/sections/NeededDocs/styles.less"],"names":[],"mappings":"AAAA;EAEI,8BAAA;EACA,oBAAA;AAAJ;AAHA;EAKM,mBAAA;EACA,gBAAA;AACN","sourcesContent":["#needed-docs {\n  .user-conditions {\n    padding-inline-start: .875rem;\n    margin-top: .625rem;\n    li {\n      margin-bottom: 1rem;\n      font-weight: 300;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
