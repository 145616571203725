// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.platform-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 0.063rem solid #C9D3CC;
  background-color: white;
  padding: 0 !important;
  white-space: pre-line;
  width: 9.313rem;
  height: 9.313rem;
  border-radius: 20px;
  cursor: pointer;
}
.platform-card img,
.platform-card svg {
  height: auto;
  max-width: 5.875rem;
  max-height: 4rem;
}
@media (max-width: 712px) {
  .platform-card img,
  .platform-card svg {
    width: 100%;
  }
}
@media (max-width: 712px) {
  .platform-card {
    width: 7rem;
    height: 7rem;
    padding: 1rem !important;
  }
}
@media (max-width: 400px) {
  .platform-card {
    width: 6rem;
    height: 6rem;
    padding: 1rem !important;
  }
}
.platform-card span {
  margin-top: 1rem;
}
@media (max-width: 712px) {
  .platform-card span {
    font-size: 0.7rem;
  }
}
.platform-card.requested {
  animation: warning 2s infinite;
}
.jutso-das-sombras {
  border: none;
  height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/PlatformCard/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,8BAAA;EACA,uBAAA;EACA,qBAAA;EACA,qBAAA;EAEA,eAAA;EACA,gBAAA;EACA,mBAAA;EAEA,eAAA;AAFF;AAbA;;EAkBI,YAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAGI;EAAA;;IACE,WAAA;EACJ;AACF;AAEE;EAAA;IACE,WAAA;IACA,YAAA;IACA,wBAAA;EACF;AACF;AACE;EAAA;IACE,WAAA;IACA,YAAA;IACA,wBAAA;EAEF;AACF;AAvCA;EAwCI,gBAAA;AAEJ;AAAI;EAAA;IACC,iBAAA;EAGH;AACF;AAAE;EACE,8BAAA;AAEJ;AAEA;EACE,YAAA;EACA,SAAA;AAAF","sourcesContent":[".platform-card {\n  display: flex;\n\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  border: .063rem solid #C9D3CC;\n  background-color: white;\n  padding: 0 !important;\n  white-space: pre-line;\n\n  width: 9.313rem;\n  height: 9.313rem;\n  border-radius: 20px;\n\n  cursor: pointer;\n\n  img, svg {\n    height: auto;\n    max-width: 5.875rem;\n    max-height: 4rem;\n\n    @media (max-width: 712px) {\n      width: 100%;\n    } \n  }\n  \n  @media (max-width: 712px) {\n    width: 7rem;\n    height: 7rem;\n    padding: 1rem !important;\n  }\n  \n  @media (max-width: 400px) {\n    width: 6rem;\n    height: 6rem;\n    padding: 1rem !important;\n  }\n  \n  span{\n    margin-top: 1rem;\n\n    @media (max-width: 712px) {\n     font-size: 0.7rem;\n    } \n  }\n\n  &.requested {\n    animation: warning 2s infinite;\n  }\n}\n\n.jutso-das-sombras {\n  border: none;\n  height: 0;\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
