// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyramais-alert {
  margin-bottom: 1rem;
  border-radius: 1.25rem;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
  padding: 1rem 1.25rem;
  text-align: justify;
  color: #000000;
}
@media (max-width: 768px) {
  .gyramais-alert {
    text-align: left;
  }
}
.gyramais-alert .anticon-exclamation-circle,
.gyramais-alert .ant-alert-icon {
  width: 0.75rem;
  height: 100%;
  font-size: 1rem;
  margin-right: 1rem;
  color: var(--primary-button-color);
}
.gyramais-alert .ant-alert-message {
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
}
.gyramais-alert.color-red {
  background-color: var(--danger-light-color);
}
.gyramais-alert.color-red .anticon-exclamation-circle {
  color: var(--danger-color);
}
.gyramais-alert.color-green {
  background-color: var(--success-light-color);
}
.gyramais-alert.color-green .anticon-exclamation-circle {
  color: var(--success-color);
}
@media (max-width: 768px) {
  .gyramais-alert .ant-alert-content {
    padding-left: 0.313rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/gyramais/Alert/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;EACA,yBAAA;EACA,qBAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EAAA;IACE,gBAAA;EAEF;AACF;AAbA;;EAcI,cAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,kCAAA;AAGJ;AArBA;EAsBI,eAAA;EACA,gBAAA;EACA,cAAA;AAEJ;AACE;EACE,2CAAA;AACJ;AAFE;EAII,0BAAA;AACN;AAGE;EACE,4CAAA;AADJ;AAAE;EAII,2BAAA;AADN;AAKE;EAAA;IAEI,sBAAA;EAHJ;AACF","sourcesContent":[".gyramais-alert {\n  margin-bottom: 1rem;\n  border-radius: @border-radius;\n  background-color: #EFEFEF;\n  border-color: #EFEFEF;\n  padding: 1rem 1.25rem;\n  text-align: justify;\n  color: #000000;\n\n  @media(max-width: @md-screen) {\n    text-align: left;\n  }\n\n  .anticon-exclamation-circle, .ant-alert-icon {\n    width: 0.75rem;\n    height: 100%;\n    font-size: 1rem;\n    margin-right: 1rem;\n    color: var(--primary-button-color);\n  }\n\n  .ant-alert-message {\n    font-size: 1rem;\n    font-weight: 400;\n    color: #000000;\n  }\n\n  &.color-red {\n    background-color: var(--danger-light-color);\n\n    .anticon-exclamation-circle {\n      color: var(--danger-color);\n    }\n  }\n  \n  &.color-green {\n    background-color: var(--success-light-color);\n\n    .anticon-exclamation-circle {\n      color: var(--success-color);\n    }\n  }\n\n  @media(max-width: @md-screen) {\n    .ant-alert-content {\n      padding-left: .313rem;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
