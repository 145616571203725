import React from 'react';
import {
  Tooltip,
} from 'antd';
import {
  Layout,
  Button,
} from 'components/sdk';
import { useHistory } from 'react-router-dom';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  SuccessMessage,
} from 'components';

import { Gif23, Gif12 } from 'assets/images';
import {
  GET_DIRECT_DATA_PROBLEMS,
  SAVE_LOAN_EVENT,
  ME,
} from './graphql';

const { Content } = Layout;

const DirectDataProblemScreen = () => {
  const history = useHistory();
  const auxDate = new Date();
  const errors = {
    cnpjRegularity: 'Certificado de Regularidade do FGTS – CRF',
    stateDebitCertificate: 'Certidão Negativa de Débitos Estaduais',
    federalDebitCertificate: 'Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União',
    cnpjLaborDebt: 'Certidão Negativa de Débitos Trabalhistas',
  };

  const {
    data: { getDirectDataProblems = [] } = {},
    loading: directDataProblemsLoading,
  } = useQuery(GET_DIRECT_DATA_PROBLEMS);

  const {
    data: {
      me: {
        currentBusiness: {
          additionalInfo,
          loans,
        } = {},
      } = {},
    } = {},
    loading: meLoading,
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });
  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT,
    {
      onCompleted: () => {
        history.push('/cadastro/status');
      },
    });

  return (
    <Layout id="request-screen">
      <Content className="request-content">
        <SuccessMessage
          image={Gif23}
          beforeTitle="Não foi possível realizar a sua solicitação nesse momento"
          title="Verifique junto aos órgãos estaduais ou federais possíveis pendências referentes as certidões abaixo:"
          subtitle={
            saveLoanLoading || meLoading || directDataProblemsLoading
              ? <img src={Gif12} alt="Loading" />
              : getDirectDataProblems.map((directDataProblem) => (
                <ul key={directDataProblem}>
                  {errors[directDataProblem]}
                </ul>
              ))
          }
          anotherDivider
          extraOutsideFooter
          extra={(
            <>
              <h3 className="badesc-text">
                Após regularização, clique abaixo para realizar uma nova validação e dar seguimento.
                <br />
                <span>Atenção: Essa ação só pode ser realizada uma vez a cada 24hrs.</span>
              </h3>
              <Tooltip title={
              saveLoanLoading || meLoading || directDataProblemsLoading
                    || (new Date(additionalInfo?.lastTimeIntegrated)
                        > new Date(
                          auxDate.getFullYear(),
                          auxDate.getMonth(),
                          auxDate.getDate() - 1,
                          auxDate.getHours(),
                          auxDate.getMinutes(),
                        )
                    )
                ? 'Você só poderá clicar uma vez a cada 24hrs'
                : ''
            }
              >
                <div>
                  <Button
                    block
                    disabled={
                      saveLoanLoading || meLoading || directDataProblemsLoading
                      || (new Date(additionalInfo?.lastTimeIntegrated)
                          > new Date(
                            auxDate.getFullYear(),
                            auxDate.getMonth(),
                            auxDate.getDate() - 1,
                            auxDate.getHours(),
                            auxDate.getMinutes(),
                          )
                      )
                    }
                    onClick={() => {
                      saveLoanEvent({
                        variables: {
                          loanId: loans.find((loan) => loan.status.value === 'awaiting-re-evaluation-direct-data').id,
                          statusValue: 'integrated',
                        },
                      });
                    }}
                  >
                    Estou regularizado e quero continuar
                  </Button>
                </div>
              </Tooltip>
            </>
        )}
        />
      </Content>
    </Layout>
  );
};

export default DirectDataProblemScreen;
