import React, { useState, useEffect } from 'react';
import {
  Form, Row, Col, Checkbox, notification,
} from 'antd';
import {
  CloseOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Input, Alert } from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import { StoneIntegrationModal } from 'components';
import PropTypes from 'prop-types';
import TutorialPanel from '../../others/TutorialPanel';

import './styles.less';

const { Item } = Form;

const IntegrationsForm = ({
  integrationType: {
    icon,
    value,
    requiredFields,
    tutorial,
  } = {},
  onClose,
  loading,
  onSubmit,
  form,
}) => {
  const [hasPasswordField, setHasPasswordField] = useState(false);
  const [stoneModalVisible, setStoneModalVisible] = useState(false);

  const onFinish = async (values) => {
    await form.validateFields();

    if (requiredFields?.find((i) => i.value === 'authorization') && !values.authorization) {
      notification.error({ message: 'Autorize a GYRA+', duration: 10 });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    delete values.authorization;

    onSubmit({
      credentials: JSON.stringify(values),
    });
    setStoneModalVisible(false);
  };

  useEffect(() => {
    const hasPassword = requiredFields?.find(({ value }) => (value === 'password'));
    setHasPasswordField(!!hasPassword);
  }, [requiredFields]);

  return (
    <div id="add-integration-form">
      <div className="add-integration-form-body">

        <div className="add-integration-form-left">
          <Row className="header">
            <Col>
              <Button
                id="close-integration-drawer"
                link
                onClick={onClose}
                disabled={loading}
              >
                <CloseOutlined className="close-button" />
                Fechar
              </Button>
            </Col>
          </Row>

          <div className="add-integration-form-left-title">
            <h2 className="no-margin-bottom">Adicionar conta</h2>
            {' '}
            {icon ? <img src={icon?.url} alt={`Icone ${value}`} /> : (
              <h2>
                {value}
              </h2>
            )}
          </div>

          {!hasPasswordField && (
            <Alert
              message="Nós não armazenamos ou temos acesso aos dados de login da sua conta"
              icon={(<SafetyOutlined />)}
            />
          )}

          <Form form={form} layout="vertical">
            <Row className="add-integration-form-left-body" gutter={12}>
              {requiredFields?.map((requiredField) => {
                const baseRule = requiredField.rules || requiredField;
                const rule = requiredField.rules
                  ? {
                    ...baseRule,
                    transform: (v) => parseInt(v, 10).toString(),
                  } : {
                    required: true, message: requiredField.requredMessage || 'Por favor preencha esse campo',
                  };
                if (['checkbox'].includes(requiredField.type)) {
                  return (
                    <Col lg={24} md={24} key={requiredField.value}>
                      <Item
                        name={requiredField.value}
                        type={requiredField.type}
                        rules={[rule]}
                        valuePropName="checked"
                      >
                        <Checkbox>
                          <p className="no-margin-bottom">
                            <b>
                              {requiredField.label}
                            </b>
                          </p>
                        </Checkbox>
                      </Item>
                    </Col>
                  );
                }
                return (
                  <Col lg={12} md={24} key={requiredField.value}>
                    <Item
                      name={requiredField.value}
                      type={requiredField.type}
                      rules={[rule]}
                    >
                      <Input
                        disabled={loading}
                        type={requiredField.type}
                        placeholder={requiredField.placeholder}
                      />
                    </Item>
                  </Col>
                );
              })}
            </Row>
          </Form>
        </div>

        <div className="add-integration-form-right">
          <TutorialPanel tutorial={tutorial} />
        </div>
      </div>

      <div className="add-integration-form-footer">
        <Button
          loading={loading}
          id="connect-integration-button"
          onClick={() => (value === 'stone'
            ? setStoneModalVisible(true) : onFinish(form.getFieldsValue()))}
        >
          Confirmar e prosseguir

        </Button>
      </div>
      <StoneIntegrationModal
        visible={stoneModalVisible}
        handleClose={() => setStoneModalVisible(false)}
        onSubmit={() => onFinish(form.getFieldsValue())}
      />
      <div className="add-tutorial-same-drawer">
        <TutorialPanel tutorial={tutorial} />
      </div>
    </div>
  );
};

IntegrationsForm.propTypes = {
  integrationType: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  form: PropTypes.shape(),
  loading: PropTypes.bool,
  reintegrationInfo: PropTypes.shape({
    id: PropTypes.string,
    typeId: PropTypes.string,
  }),
};

export default IntegrationsForm;
