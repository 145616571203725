import React from 'react';
import IntegrationScreen from '../IntegrationScreen';
import { getGyraRedirectPath } from '../../services/graphql/cache';

const PreIntegrationScreen = () => {

  if (getGyraRedirectPath() && getGyraRedirectPath() !== window.location.pathname) {
    window.location.href = `${getGyraRedirectPath()}${window.location.search}`;
    return null;
  }

  return <IntegrationScreen />;
};

export default PreIntegrationScreen;
