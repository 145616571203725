import React, {
  createContext, useContext, useEffect,
} from 'react';

import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { changeColors } from 'utils/colors';
import { getWhiteLabelData, setWhiteLabelData } from '../../services/graphql/cache';

const PartnerActionsContext = createContext();

const PartnerActionsProvider = ({ children }) => {
  const { currentContext, contextLoading } = useBusinessLoanContext();

  useEffect(() => {
    const { partner } = currentContext;

    // if (partner?.appColors) {
    //   const data = setWhiteLabelData({
    //     colors: partner?.appColors,
    //     darkLogoUrl: partner?.darkLogo?.url,
    //     lightLogoUrl: partner?.lightLogo?.url,
    //   });

    //   if (data?.colors) {
    //     changeColors(data?.colors);
    //   }
    // } else {
    //   localStorage.removeItem('whiteLabelData');
    // }

    if (partner?.flow) {
      localStorage.setItem('partner', partner?.flow);
    } else {
      localStorage.removeItem('partner');
    }

  }, [currentContext?.partner?.id]);

  const whiteLabelData = getWhiteLabelData();
  if (whiteLabelData?.colors) {
    changeColors(whiteLabelData?.colors);
  }

  const actions = currentContext?.partner?.actions || [];

  const checkActions = (actionsToFind = []) => {
    const actionsValue = actions.map(({ value }) => value);
    return actionsValue.some((action) => actionsToFind.includes(action));
  };

  return (
    <PartnerActionsContext.Provider
      value={{
        actions,
        checkActions,
        loadingActions: contextLoading,
      }}
    >
      {children}
    </PartnerActionsContext.Provider>
  );
};

/**
 * @typedef {{
 *  id: string
 *  value: string
 * }} PartnerBusinessAction
 *
 * @callback checkActionsCallback
 * @param {string[]} actionsToFind - Ação a ser verificada
 * @returns {boolean} - Resposta da verificação
 *
 * @returns {{
 *  loadingActions: boolean
 *  actions: PartnerBusinessAction[]
 *  checkActions: checkActionsCallback
 *  refetchActions: void
 * }}
 */
export const usePartnerActionsContext = () => useContext(PartnerActionsContext);

export default PartnerActionsProvider;
