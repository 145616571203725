import React, { memo } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  Layout,
} from 'components/sdk';
import {
  HowItWork1,
  HowItWork2,
  HowItWork3,
  HowItWork4,
  HowItWork5,
} from 'assets/images';
import './styles.less';

const {
  Content,
} = Layout;

const texts = [{
  title: 'Preencha o cadastro',
  description: 'Essas informações são usadas para personalizar a proposta de crédito de acordo com o perfil do seu negócio',
  Image: HowItWork1,
}, {
  title: 'Envie os documentos',
  description: 'Os documentos são usados para validar dados e dar mais segurança no processo',
  Image: HowItWork2,
}, {
  title: 'Aguarde a proposta',
  description: 'Verificamos suas informações e respondemos com sua proposta em 24h',
  Image: HowItWork3,
}, {
  title: 'Crédito na conta!',
  description: 'Ao aceitar a proposta, o dinheiro é liberado no mesmo dia',
  Image: HowItWork4,
}, {
  title: 'Desconto no repasse',
  description: 'O desconto das parcelas será feito do repasse de vendas no financeiro do olist store, sem complicação',
  Image: HowItWork5,
}];

const HowItWorks = () => (
  <Content id="how-it-works" className="dark">
    <br />
    <br />

    <h2>
      <b className="white-text">
        Solicite o crédito e receba o dinheiro em 24h
      </b>
    </h2>

    <br />

    <Row gutter={[16, 32]} justify="space-between">
      {texts.map(({ title, description, Image }, index) => (
        <Col key={title} xs={24} sm={12} md={4} className="how-it-works-item">
          <div className="how-it-work-step">{index + 1}</div>
          <Image className="how-it-work-image" alt={`como funciona ${index + 1}`} />
          <h5>
            <b className="olist-white-title">
              {title}
            </b>
          </h5>

          <p className="white-text">
            {description}
          </p>
        </Col>
      ))}
    </Row>

    <br />
    <br />
  </Content>
);

export default memo(HowItWorks);
