// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-carousel .carousel-image-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 3rem;
}
.image-carousel img {
  display: inline-block;
  margin: 0;
  max-width: 100%;
  max-height: 70vh;
}
.image-carousel .slick-list,
.image-carousel .slick-track {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.image-carousel .slick-prev,
.image-carousel .slick-next {
  font-size: 2em;
  z-index: 1;
  color: #000000;
}
.image-carousel .slick-prev:hover,
.image-carousel .slick-next:hover,
.image-carousel .slick-prev:focus,
.image-carousel .slick-next:focus {
  color: #000000;
}
.image-carousel .slick-prev::before,
.image-carousel .slick-next::before {
  content: '';
}
.image-carousel .slick-prev {
  left: 0;
}
.image-carousel .slick-next {
  right: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/others/ImagesCarousel/styles.less"],"names":[],"mappings":"AAAA;EAEI,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;AAAJ;AANA;EAUI,qBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAZA;;EAiBI,wBAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAlBA;;EAuBI,cAAA;EACA,UAAA;EACA,cAAA;AADJ;AAGI;;;;EACE,cAAA;AAEN;AACI;;EACE,WAAA;AAEN;AAlCA;EAqCI,OAAA;AAAJ;AArCA;EAyCI,aAAA;AADJ","sourcesContent":[".image-carousel {\n  .carousel-image-container {\n    display: flex !important;\n    align-items: center;\n    justify-content: center;\n    margin: 0;\n    padding: 0 3rem;\n  }\n\n  img {\n    display: inline-block;\n    margin: 0;\n    max-width: 100%;\n    max-height: 70vh;\n  }\n\n  .slick-list, .slick-track{\n    display: flex !important;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .slick-prev, .slick-next {\n    font-size: 2em;\n    z-index: 1;\n    color: #000000;\n  \n    &:hover, &:focus {\n      color: #000000;\n    }\n  \n    &::before {\n      content: '';\n    }\n  }\n\n  .slick-prev {\n    left: 0;\n  }\n  \n  .slick-next {\n    right: 0.5rem;\n  }\n}\n\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
