/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { memo } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  Layout,
  Button,
} from 'components/sdk';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';

import './styles.less';

const {
  Footer,
} = Layout;

/**
 * @typedef {{
 *  id?: string
 *  text: string
 *  onClick?: () => void
 *  visible?: boolean
 *  loading?: boolean
 *  disabled?: boolean
 *  block?: boolean
 *  color?: string
 *  htmlType?: string
 *  href?: string
 *  target?: string
 *  data-cy?: string
 * }} ModalButtonProps
 *
 * @param {{
 *  leftContent: React.FC
 *  middleContent: React.FC
 *  rightContent: React.FC
 *  okButton?: ModalButtonProps
 *  cancelButton?: ModalButtonProps
 *  cancelButtonPosition?: 'left' | 'right'
 *  loading?: boolean
 * }} props
 * @returns {React.FC}
 */
const GyraFooter = ({
  leftContent,
  middleContent,
  okButton,
  cancelButton,
  cancelButtonPosition = 'left',
  loading,
}) => {
  const { width } = useWindowDimensions();

  const isCancelButtonVisible = (cancelButton !== undefined && cancelButton !== false)
    || (cancelButton && cancelButton.visible !== true);

  return (
    <Footer className="gyramais-footer">
      <Row className="gyramais-footer-content" align="middle" gutter={[16, 24]}>
        <Col xs={leftContent ? 24 : 0} sm={leftContent ? 24 : 0} md={12} lg={7}>
          {leftContent}
          {width > 768 && !leftContent && isCancelButtonVisible && cancelButtonPosition === 'left' && (
            <Button
              id={cancelButton?.id}
              link={!cancelButton?.color}
              color={cancelButton?.color}
              onClick={cancelButton?.onClick}
              loading={cancelButton?.loading}
              disabled={cancelButton?.disabled}
              className={cancelButton?.className}
              block={cancelButton?.block}
              htmlType={cancelButton?.htmlType}
              href={cancelButton?.href}
              target={cancelButton?.target}
              data-cy={cancelButton?.['data-cy']}
            >
              {cancelButton?.text || '< Voltar'}
            </Button>
          )}
        </Col>

        <Col xs={middleContent ? 24 : 0} sm={middleContent ? 24 : 0} md={middleContent ? 12 : 0} lg={middleContent ? 8 : 0}>
          {middleContent}
        </Col>

        <Col xs={24} sm={24} md={24} lg={middleContent ? 9 : 17}>
          {!loading && (
            <Row align="middle" justify={width <= 768 ? 'space-between' : 'end'} gutter={[16]}>
              {((width <= 768 && !leftContent) || cancelButtonPosition === 'right' || (cancelButtonPosition === 'left' && leftContent)) && isCancelButtonVisible && (
                <Col>
                  <Button
                    id={cancelButton?.id}
                    link={!cancelButton?.color}
                    color={cancelButton?.color}
                    onClick={cancelButton?.onClick}
                    loading={cancelButton?.loading}
                    disabled={cancelButton?.disabled}
                    className={cancelButton?.className}
                    block={cancelButton?.block}
                    htmlType={cancelButton?.htmlType}
                    href={cancelButton?.href}
                    target={cancelButton?.target}
                    data-cy={cancelButton?.['data-cy']}
                  >
                    {cancelButton?.text || '< Voltar'}
                  </Button>
                </Col>
              )}

              <Col span={!isCancelButtonVisible && 24} align="right">
                <Button
                  id={okButton?.id}
                  color={okButton?.color || 'primary'}
                  onClick={okButton?.onClick}
                  loading={okButton?.loading}
                  disabled={okButton?.disabled}
                  className={okButton?.className}
                  block={okButton?.block || (width <= 768 && !isCancelButtonVisible)}
                  htmlType={okButton?.htmlType}
                  href={okButton?.href}
                  target={okButton?.target}
                  data-cy={okButton?.['data-cy']}
                >
                  {okButton?.text || 'Confirmar e prosseguir'}
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Footer>
  );
};

export default memo(GyraFooter);
