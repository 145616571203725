/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  UploadCard,
} from 'components';

const { Item } = Form;

const businessFile = {
  '204-6': 'Estatuto social',
  '205-6': 'Estatuto social',
  '206-2': 'Contrato social',
  '213-5': 'Certificado da condição do MEI ou Registro de requisição EI ou Declaração de incorporação da EIRELLI',
  '223-2': 'Contrato social',
  '224-0': 'Contrato social',
  '230-5': 'Declaração de incorporação EIRELI',
  '231-5': 'Declaração de incorporação EIRELI',
  '401-4': 'Requisição de registro EI',
};

const BadescBusinessFileStep = ({
  files: {
    proofOfAddress,
    bylaws,
    revenueStatement,
    majorityPartnerSelfie,
  } = {},
  additionalFiles: {
    naturalDisasterDocument,
    regularityDocument,
    raisPerson,
    cndScEnvironmentalCrimes,
  } = {},
  form,
  type,
  address,
  hideNotAnalyzedFiles,
  businessDocumentsRevised,
  setBusinessDocumentsRevised,
  isRegularityDocumentOptional,
  isPronampeEmergencial,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      bylawsFileId: bylaws?.id,
      proofOfAddressFileId: proofOfAddress?.id,
      majorityPartnerSelfieFileId: majorityPartnerSelfie?.id,
    });
  }, [proofOfAddress, bylaws, revenueStatement]);

  return (
    <>
      <Form
        className="form-footer"
        form={form}
        layout="vertical"
      >
        <Row gutter={[0, 20]}>
          {(bylaws?.analysis?.approved === false || !hideNotAnalyzedFiles) && (
            <Col span={24} className="bylaws-upload">
              <Item name="bylawsFileId" rules={[{ required: true, message: 'Por favor, insira o documento contratual' }]}>
                <UploadCard
                  id="bylaws-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={bylaws}
                  alert={(businessFile[type] === 'Contrato social' || businessFile[type] === 'Estatuto social')
                    && 'O documento precisa estar completo, com assinaturas e carimbos da junta comercial.'}
                  label={`${businessFile[type] || 'Documento contratual'} da empresa`}
                  actionButtonText={bylaws?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={bylaws?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ bylawsFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {(!hideNotAnalyzedFiles || address?.analysis?.approved === false) && (
            <Col span={24} className="proofOfAddressFileId">
              <Item name="proofOfAddressFileId" rules={[{ required: true, message: 'Por favor, insira o comprovante de endereço' }]}>
                <UploadCard
                  id="proof-of-address-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={proofOfAddress}
                  alert="Escolha uma das opções: Conta de luz, água, gás, telefone ou internet, com no máximo 90 dias, ou contrato de locação do imóvel. Enviar conta aberta. Não aceitamos somente a capa."
                  label="Comprovante de endereço"
                  actionButtonText={address?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={address?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ proofOfAddressFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {
            (!hideNotAnalyzedFiles || regularityDocument?.analysis?.approved === false)
            && isPronampeEmergencial
            && (
              <Col span={24} className="regularityDocumentFileId">
                <Item name="regularityDocumentFileId" rules={[{ required: true, message: 'Por favor, insira o documento' }]}>
                  <UploadCard
                    id="regularity-document-upload"
                    accept="image/png,image/jpeg,application/pdf"
                    actualFile={regularityDocument}
                    alert="Comprovação de regularidade da situação da empresa perante os órgãos ambientais: Licença de Operação ou Declaração de Dispensa de Licenciamento Ambiental, emitida por órgão competente na esfera estadual ou municipal (estão dispensadas do envio desta comprovação as empresas cujas atividades econômicas, identificadas através dos CNAEs principal e secundários no cartão CNPJ, constem da listagem das atividades consideradas de baixo risco ambiental e que, consequentemente, estão sumariamente dispensadas do licenciamento ambiental pelo IMA/SC);"
                    label={`Comprovante de regularidade ambiental ${isRegularityDocumentOptional ? '(Opcional)' : ''}`}
                    actionButtonText={regularityDocument?.analysis?.approved === false && 'Revisar'}
                    incompleteAnimation={regularityDocument?.analysis?.approved === false}
                    onCompleted={async ({ id }) => {
                      await form.setFieldsValue({ regularityDocumentFileId: id });
                      setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                    }}
                  />
                </Item>
              </Col>
            )
          }

          {(!hideNotAnalyzedFiles || raisPerson?.analysis?.approved === false) && (
            <Col span={24} className="raisPersonFileId">
              <Item name="raisPersonFileId" rules={[{ required: true, message: 'Por favor, insira o documento' }]}>
                <UploadCard
                  id="rais-document-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={raisPerson}
                  alert="Comprovação de que a empresa está em dia com a entrega da Relação Anual de Informações Sociais - RAIS"
                  label="RAIS e-social"
                  actionButtonText={raisPerson?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={raisPerson?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ raisPersonFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {(!hideNotAnalyzedFiles || cndScEnvironmentalCrimes?.analysis?.approved === false) && (
            <Col span={24} className="cndScEnvironmentalCrimesFileId">
              <Item name="cndScEnvironmentalCrimesFileId" rules={[{ required: true, message: 'Por favor, insira o documento' }]}>
                <UploadCard
                  id="rais-document-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={cndScEnvironmentalCrimes}
                  alert={(
                    <div className="ant-alert-message">
                      Certidão negativa de débito com Instituto de Meio Ambiente de Santa Catarina, pode ser gerado no link: {' '}
                      <a href="https://gaia.ima.sc.gov.br/web/certidoes" target="_blank" rel="noreferrer">https://gaia.ima.sc.gov.br/web/certidoes</a>
                    </div>
                  )}
                  label="CND - Crimes Ambientais SC"
                  actionButtonText={cndScEnvironmentalCrimes?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={cndScEnvironmentalCrimes?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ cndScEnvironmentalCrimesFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {
            (!hideNotAnalyzedFiles || naturalDisasterDocument?.analysis?.approved === false)
            && isPronampeEmergencial
            && (
              <Col span={24} className="naturalDisasterDocumentFileId">
                <Item name="naturalDisasterDocumentFileId" rules={[{ required: true, message: 'Por favor, insira o documento' }]}>
                  <UploadCard
                    id="natural-disaster-document-upload"
                    accept="image/png,image/jpeg,application/pdf"
                    actualFile={naturalDisasterDocument}
                    alert="Documento expedido pela Defesa Civil Municipal ou Estadual, ou órgão equivalente nos casos de indisponibilidade (por exemplo: Prefeitura Municipal, Corpo de Bombeiros ou órgãos públicos afins), atestando ter sido diretamente atingida pelos fenômenos climáticos"
                    label="Documento de desastre natural (Opcional)"
                    actionButtonText={naturalDisasterDocument?.analysis?.approved === false && 'Revisar'}
                    incompleteAnimation={naturalDisasterDocument?.analysis?.approved === false}
                    onCompleted={async ({ id }) => {
                      await form.setFieldsValue({ naturalDisasterDocumentFileId: id });
                      setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                    }}
                  />
                </Item>
              </Col>
            )
          }
        </Row>
      </Form>
    </>
  );
};

export default BadescBusinessFileStep;
