import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Drawer, Row,
} from 'antd';
import ConnectorsList from 'components/others/ConnectorsList';
import { Button } from 'components/sdk';

import { CloseOutlined } from '@ant-design/icons';

const ConnectorDrawer = ({
  visible,
  onClose,
  refetchBankAccount,
  refetchIntegration,
  reintegrationId,
  integrationSelected,
  filteredIntegrations,
}) => (
  <Drawer
    id="integration-drawer"
    width="90%"
    destroyOnClose
    onClose={onClose}
    visible={visible}
    closable={false}
    contentWrapperStyle={{
      maxWidth: '80.5rem',
    }}
  >
    <>
      <Row className="header">
        <Col>
          <Button
            link
            onClick={onClose}
          >
            <CloseOutlined className="close-button" />
            Fechar
          </Button>
        </Col>
      </Row>

      <div className="list-container">
        <div className="add-integration-form-left-title">
          <h2 className="no-margin-bottom">Adicionar uma nova conta</h2>
        </div>
        <ConnectorsList
          filteredIntegrations={filteredIntegrations}
          refetchIntegration={refetchIntegration}
          refetchBankAccount={refetchBankAccount}
          reintegrationVariables={reintegrationId && {
            integrationId: reintegrationId,
            integrationTypeId: integrationSelected?.id,
          }}
        />
      </div>
    </>
  </Drawer>
);
ConnectorDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  refetchBankAccount: PropTypes.func,
  refetchIntegration: PropTypes.func,
  reintegrationId: PropTypes.string,
  filteredIntegrations: PropTypes.arrayOf(PropTypes.string),
  integrationSelected: PropTypes.shape({ id: PropTypes.string }),
};

export default ConnectorDrawer;
