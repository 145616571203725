import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { useQuery, useMutation } from '@apollo/client';
import {
  CONNECTORS, SAVE_BUSINESS_REQUEST,
} from './graphql';

import './styles.less';

const AcquirerIntegrationModal = ({
  visible,
  onClose,
  onSkip,
}) => {
  const { currentContext } = useBusinessLoanContext();

  const [selectedAcquirers, setSelectedAcquirers] = useState([]);

  const {
    data: {
      connectors = [],
    } = {},
  } = useQuery(CONNECTORS, {
    variables: {
      contextId: currentContext?.id,
    },
    fetchPolicy: 'cache-first',
  });
  const acquirers = useMemo(() => connectors.filter(({ type }) => type === 'Meio de pagamento'), [connectors]);

  const [saveBusinessRequest, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_REQUEST, {
    onCompleted: () => onSkip?.(),
  });

  return (
    <Modal
      title="Nos ajude a entender seu negócio! Quais maquininhas você usa?"
      centered
      visible={visible}
      closable={false}
      className="acquirer-integration-modal"
      okButton={selectedAcquirers.length > 0 ? {
        text: 'Conectar agora',
        onClick: () => onClose?.(),
      } : undefined}
      cancelButton={selectedAcquirers.length > 0 ? {
        text: 'Conectar mais tarde',
        loading: saveLoading,
        onClick: () => saveBusinessRequest({
          variables: {
            type: 'add-integration',
            businessId: currentContext?.business?.id,
            specificsIds: selectedAcquirers,
          },
        }),
      } : {
        id: 'acquirer-full-skip-button',
        text: 'Não utilizo maquininha',
        onClick: () => onSkip?.(),
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <h3>
            <strong>
              Selecione as maquininhas que você utiliza
            </strong>
          </h3>
          <Row gutter={[16, 16]}>
            {acquirers.map(({
              id,
              name,
              icon,
              description,
            }) => (
              <Col key={id} xs={6} sm={4} md={4}>
                <div
                  className={`acquirer-card ${selectedAcquirers.includes(id) ? 'selected' : ''}`}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => false}
                  onClick={() => {
                    if (selectedAcquirers.includes(id)) {
                      setSelectedAcquirers(selectedAcquirers.filter((i) => i !== id));
                    } else {
                      setSelectedAcquirers([...selectedAcquirers, id]);
                    }
                  }}
                >
                  <div className="acquirer-card-content">
                    {icon ? <img src={icon.url} alt={name} /> : name}

                    <span className="acquirer-card-description">
                      {description}
                    </span>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

AcquirerIntegrationModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSkip: PropTypes.func,
};

export default AcquirerIntegrationModal;
