import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Tooltip,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import './styles.less';

const GyramaisAvatar = ({
  id,
  alt,
  size,
  icon,
  src,
  tooltip,
  className,
  ...rest
}) => (
  <Tooltip title={tooltip}>
    <Avatar
      id={id}
      className={`gyramais-avatar ${src ? 'gyramais-avatar-with-image' : ''} ${!src && icon ? 'gyramais-avatar-with-icon' : ''} ${className || ''}`}
      alt={alt}
      size={size}
      icon={icon || <UserOutlined />}
      src={src}
      {...rest}
    />
  </Tooltip>
);

GyramaisAvatar.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  src: PropTypes.shape(),
};

export default GyramaisAvatar;
