import React, {
  memo,
  useState,
  useEffect,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Row,
  Steps,
  Col,
  Spin,
  Checkbox,
  notification,
  Form,
} from 'antd';
import {
  PolicyOrTermsModal,
  HowItWorks,
  RequestRules,
  AboutGyra,
  PartnerFooter,
  ClientsComments,
  OlistFaq,
} from 'components';
import {
  Input,
  Alert,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import ReactGA from 'react-ga';
import qs from 'query-string';
import { Images } from 'assets';
import { formatAndNotificateError, maskString } from 'utils';
import { useQuery, useMutation } from '@apollo/client';
import {
  PARTNER_LEAD,
  SAVE_SELLER,
  ADD_SESSION,
  PARTNER,
  CHECK_IF_USER_EXISTS,
  GET_USER_INFO_BY_OLIST_CODE,
} from './graphql';
import './styles.less';

const {
  Header,
  Content,
} = Layout;
const {
  useForm,
  Item,
} = Form;
const { Step } = Steps;
const {
  LogoGyraG,
  BigShoesTeamWork,
  LogoOlist,
  LogoGyraWhite,
  LogoGyraDark,
} = Images;

const PartnerLandingScreen = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const {
    id,
    code,
  } = qs.parse(search);
  const [form] = useForm();
  const [partnerLeadSource, setPartnerLeadSource] = useState(null);
  const [validation, setValidation] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [account, setAccount] = useState();
  const [current, setCurrent] = useState(0);
  const [infoModal, setInfoModal] = useState({ visible: false, type: 'terms' });

  const {
    loading,
  } = useQuery(PARTNER_LEAD, {
    skip: !id || pathname === '/olist',
    variables: { code: id },
    onCompleted: ({ partnerLead: { business } = {} }) => {
      if (business) history.push('/');
    },
    onError: (error) => {
      formatAndNotificateError(error);
      history.push('/');
    },
  });
  const {
    loading: partnerLoading,
  } = useQuery(PARTNER, {
    skip: pathname !== '/olist',
    variables: { cnpj: pathname === '/olist' && '18552346000168' },
    onError: (error) => {
      formatAndNotificateError(error);
      history.push('/');
    },
  });
  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => {
      history.push('/');
    },
  });
  const [checkIfUserExists] = useMutation(CHECK_IF_USER_EXISTS, {
    onCompleted: async ({ checkIfUserExists }) => {
      setValidation(true);
      setUserExists(checkIfUserExists);
    },
    onError: (error) => {
      setValidation(false);
      setUserExists(false);
      form.setFieldsValue({ cpf: '' });
      formatAndNotificateError(error);
    },
  });
  const [saveSeller, { loading: sellerLoading }] = useMutation(SAVE_SELLER, {
    onCompleted: ({ saveSeller }) => {
      if (process.env.ENVIRONMENT === 'production') {
        ReactGA.event({
          category: 'SignUpOlist',
          action: 'Olist Seller SignUp Success',
        });
      }
      addSession({
        variables: {
          me: saveSeller,
        },
      });
    },
    onError: (error) => {
      form.setFieldsValue({ password: '', confirmPassword: '' });
      setCurrent(0);
      formatAndNotificateError(error);
    },
  });

  const [
    getUserInfoByOlistCode,
    { loading: olistLoading },
  ] = useMutation(GET_USER_INFO_BY_OLIST_CODE, {
    onCompleted: ({ getUserInfoByOlistCode }) => {
      if (getUserInfoByOlistCode) {
        const { email, firstName } = getUserInfoByOlistCode;

        history.push(`/login?code=${code}&email=${email}&firstName=${firstName}`);
      }
    },
  });

  useEffect(() => {
    const stringSearch = JSON.stringify(qs.parse(search));
    const {
      utm_source: type, utm_medium: medium, utm_campaign: campaign, utm_content: content,
    } = JSON.parse(stringSearch);
    const variables = {
      type,
      medium,
      campaign,
      content,
    };
    if (variables) {
      setPartnerLeadSource(variables);
    }
  }, []);

  const steps = [
    {
      title: 'Faça seu cadastro',
      content: (
        <Form
          form={form}
          layout="vertical"
          onFinish={async (variables) => {
            const {
              terms, email, confirmationEmail,
            } = variables;

            if (!userExists) {
              if (!terms) return notification.error({ message: 'Aceite os termos e a política de privacidade' });
              if (email !== confirmationEmail) return notification.error({ message: 'Os emails não coincidem' });
            }
            setAccount({
              ...variables,
              email: userExists || variables.email,
            });
            setCurrent(1);
            return true;
          }}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24}>
              <Item name="cpf" label="* CPF do sócio" rules={[{ required: true, message: 'Preencha o CPF do sócio' }]}>
                <Input
                  placeholder="CPF"
                  type="masked"
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  onChange={async ({ target }) => {
                    const cpf = target.value.replace(/[^0-9]/g, '');
                    if (cpf.length === 11) await checkIfUserExists({ variables: { cpf } });
                    else {
                      setValidation(false);
                      setUserExists(false);
                    }
                  }}
                />
              </Item>
            </Col>
            {userExists ? (
              <>
                <Col span={24}>
                  <Alert
                    message={`Encontramos sua conta ${userExists && maskString(userExists.split('@')[0], true)}@${maskString(userExists.split('@')[1])}!`}
                    description="Coloque o CNPJ que deseja utilizar, depois confirme e prossiga para concluir sua solicitação"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} sm={24} md={12}>
                  <Item name="email" label="* Email do sócio" rules={[{ required: true, message: 'Preencha o email do sócio' }]}>
                    <Input placeholder="Email" disabled={!(validation && !userExists)} />
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Item name="confirmationEmail" label="* Confirmação de email do sócio" rules={[{ required: true, message: 'Preencha o email do sócio' }]}>
                    <Input placeholder="Confirmação de email" disabled={!(validation && !userExists)} />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item name="fullName" label="* Nome completo do sócio" rules={[{ required: true, message: 'Preencha o nome completo do sócio' }]}>
                    <Input placeholder="Nome Completo" disabled={!(validation && !userExists)} />
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Item name="phoneNumber" label="* Telefone celular do sócio" rules={[{ required: true, message: 'Preencha o telefone celular do sócio' }]}>
                    <Input
                      placeholder="Telefone celular"
                      type="masked"
                      mask="phone"
                      disabled={!(validation && !userExists)}
                    />
                  </Item>
                </Col>
              </>
            )}
            <Col span={24}>
              <Item name="cnpj" label="* CNPJ da empresa cadastrada no olist" rules={[{ required: true, message: 'Preencha o CNPJ da empresa' }]}>
                <Input
                  placeholder="CNPJ"
                  type="masked"
                  mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  disabled={!(validation || userExists)}
                />
              </Item>
            </Col>
            {!userExists && (
              <Col span={24}>
                <Item name="terms" className="password-terms-area" valuePropName="checked">
                  <Checkbox
                    id="password-terms"
                  >
                    <p>
                      Li e aceito os
                      {' '}
                      <Button
                        text
                        onClick={() => setInfoModal({ visible: true, type: 'terms' })}
                      >
                        <b className="olist-title underline">
                          Termos e Condições do Solicitante
                        </b>
                      </Button>
                      {' '}
                      e as
                      {' '}
                      <Button text onClick={() => setInfoModal({ visible: true, type: 'policy' })}>
                        <b className="olist-title underline">
                          Políticas de Privacidade
                        </b>
                      </Button>
                      .
                    </p>
                  </Checkbox>
                </Item>
              </Col>
            )}
          </Row>
          <Button htmlType="submit" color="olist" block loading={sellerLoading}>Confirmar e prosseguir</Button>
        </Form>
      ),
    },
    {
      title: userExists ? 'Autenticação' : 'Criação de senha',
      content: (
        <Form
          form={form}
          layout="vertical"
          onFinish={async ({ password, confirmPassword, code: formCode }) => {
            if (!userExists && password !== confirmPassword) notification.error({ message: 'As senhas não coincidem' });
            else {
              await saveSeller({
                variables: {
                  ...account,
                  password,
                  code: id || code || formCode,
                  partnerLeadSource,
                },
              });
            }
          }}
        >
          <Item name="password" label={userExists ? `* Senha de acesso da conta ${maskString(userExists.split('@')[0], true)}@${maskString(userExists.split('@')[1])}` : '* Crie sua senha'} rules={[{ required: true, message: false }]}>
            <Input placeholder="Senha" type="password" />
          </Item>
          {!userExists && (
            <Item name="confirmPassword" label="* Confirme sua senha" rules={[{ required: true, message: false }]}>
              <Input placeholder="Confirmação da senha" type="password" />
            </Item>
          )}
          {!id && !code && (
            <Item name="code" label="* Código de verificação de 5 dígitos" rules={[{ required: true, message: '', len: 5 }]}>
              <Input
                placeholder="Código de verificação"
                alert="O seu código de verificação se encontra no email enviado pela Olist, junto com o valor do seu empréstimo pré-aprovado."
                maxLength={5}
              />
            </Item>
          )}
          <Button htmlType="submit" color="olist" block loading={sellerLoading}>
            Enviar
          </Button>
        </Form>
      ),
    },
  ];
  useEffect(() => {
    document.title = 'GYRA+ | Olist';
    if ((!id && pathname !== '/olist') || (pathname === '/olist' && id)) history.push('/');
    if (code) {
      form.setFieldsValue({ code });
    }
  }, []);

  useEffect(() => {
    if (code) {
      getUserInfoByOlistCode({ variables: { code } });
    }
  }, [code]);
  return (
    <>
      <Spin
        indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
        spinning={loading || partnerLoading || olistLoading}
        style={{ minHeight: '100vh', backgroundColor: 'white', position: 'fixed' }}
        id="partner-landing-screen"
      >
        <Layout id="olist">
          <Header className="partner-landing-header">
            <img src={LogoGyraWhite} alt="logo" className="logo" />
          </Header>

          <Content>
            <br />
            <br />

            <Row gutter={[0, 32]} justify="space-between">
              <Col xs={24} sm={24} md={12}>
                <Row justify="center">
                  <Col xs={24} sm={24} md={18}>
                    <h2>
                      <b>
                        Precisando de apoio para crescer?
                      </b>
                    </h2>

                    <h6>
                      <b>
                        Com a GYRA+, lojistas
                        {' '}
                        <strong>olist store</strong>
                        {' '}
                        têm capital de giro para
                        acelerar ainda mais o crescimento do negócio. Solicite
                        uma proposta
                      </b>
                    </h6>

                    <br />
                    <br />

                    <Row gutter={30} justify="space-around">
                      <Col className="gyra-logo-partner" xs={24} sm={10} align="middle">
                        <img height={30} src={LogoGyraDark} alt="logo" />
                      </Col>
                      <Col xs={24} sm={10} align="middle">
                        {pathname === '/olist' && (
                          <img height={30} src={LogoOlist} alt="logo parceiro" />
                        )}
                      </Col>
                    </Row>
                    <img src={BigShoesTeamWork} width="100%" alt="teamwork" />
                  </Col>

                </Row>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Row justify="center">
                  <Col className="form-col" xs={24} sm={24} md={18}>
                    <h2>
                      <b className="olist-title">
                        Vamos desenvolver o melhor plano para você.
                      </b>
                    </h2>
                    <h6>
                      <b>
                        Suas informações são importantes para oferecermos
                        crédito de acordo com a sua necessidade e perfil
                      </b>
                    </h6>
                    <br />
                    <Steps current={current}>
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content}</div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <br />
            <br />
          </Content>

          <HowItWorks />
          <ClientsComments />
          <RequestRules />
          <AboutGyra />
          <OlistFaq />
          <PartnerFooter />
        </Layout>
      </Spin>
      <PolicyOrTermsModal visible={infoModal.visible} type={infoModal.type} onClose={() => setInfoModal({ visible: false, type: 'terms' })} />
    </>
  );
};

export default memo(PartnerLandingScreen);
