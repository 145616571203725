import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Drawer, Row, Col, Carousel, Form,
} from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import { Images } from 'assets';
import { Alert } from 'components/gyramais';
import { Progress, Button } from 'components/sdk';
import {
  IntegrationTypesList,
  IntegrationsForm,
  SuccessMessage,
} from 'components';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  formatAndNotificateError,
} from 'utils';
import useWordSlider from 'utils/hooks/useWordSlider';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import {
  SAVE_INTEGRATION,
  INTEGRATIONS_TYPES,
  CONNECTORS,
} from './graphql';
import {
  setReintegrationId,
} from '../../../services/graphql/cache';
import { useHelpers } from './hooks/helper-hook';
import { useRedirectIntegration } from './hooks/redirect-integration-hook';

import './styles.less';

const { useForm } = Form;
const { Gif12 } = Images;

const IntegrationDrawer = ({
  visible,
  onClose,
  integrationSelected,
  reintegrationId,
  goToAccountScreen,
  sendSolicitation,
  handleSendSolicitation,
  sendSolicitationLoading,
  handleSuccessIntegration,
  refetchBusinessRequests,
  isCheckDocuments,
}) => {
  const carouselRef = useRef(null);
  const [internalVisible, setInternalVisible] = useState(visible);
  const [internalIntegrationType, setInternalIntegrationType] = useState();
  const [internalReintegrationId, setInternalReintegrationId] = useState(reintegrationId);
  const [activeSlider, setActiveSlider] = useState(false);
  const [form] = useForm();
  const { search } = useLocation();
  const { checkActions } = usePartnerActionsContext();
  const params = queryString.parse(search);

  const {
    getStep,
    goToWaitingDrawerToOpen,
    hasPlatformSelectionStep,
  } = useHelpers({
    carouselRef,
    internalIntegrationType,
  });

  const handleClose = (e) => {
    if (sendSolicitationLoading) return;
    onClose?.(e);
    setInternalVisible(false);

    // no timeout para nao mostrar o step de sucesso enquanto
    // a animação de fechar do drawer acontece
    setTimeout(() => setInternalIntegrationType(null), 200);

    if (internalIntegrationType) {
      form.resetFields();
    }
  };

  const {
    handleIntegrationWishRedirect,
    currentBusinessId,
    loadingRedirect,
    finishRedirectIntegration,
  } = useRedirectIntegration({
    carouselRef,
    internalIntegrationType,
    handleClose,
    setInternalVisible,
    setInternalReintegrationId,
    handleSuccessIntegration: () => {
      refetchBusinessRequests?.();
      handleSuccessIntegration?.();
      goToWaitingDrawerToOpen(getStep('success') + 1);
    },
  });

  const { data: { connectors = [] } = {} } = useQuery(CONNECTORS);

  const { data: { integrationsTypes } = {} } = useQuery(INTEGRATIONS_TYPES, {
    fetchPolicy: 'no-cache',
  });

  const [saveIntegration, { loading }] = useMutation(SAVE_INTEGRATION, {
    onCompleted: () => {
      setTimeout(() => {
        handleSuccessIntegration?.();
        setInternalReintegrationId(null);
        refetchBusinessRequests?.();
        setActiveSlider(false);
        goToWaitingDrawerToOpen(getStep('success'));
      }, 600);

    },
    onError: ((error) => {
      setTimeout(() => {
        formatAndNotificateError(error);
        form.resetFields();

        setActiveSlider(false);
        carouselRef?.current?.goTo(getStep('integration-form'));
      }, 600);
    }),
  });

  const successText = {
    withLoan: 'Durante todo o processo de análise de crédito poderemos acessar essas '
    + 'informações para lhe oferecer as melhores condições em sua proposta. '
    + `Caso altere os seus dados diretamente na plataforma ${internalIntegrationType?.name} `
    + 'será necessária reintegrar sua conta conosco.',

    onlyScore: 'Durante todo o processo de análise de crédito poderemos acessar essas informações. '
    + `Caso altere os seus dados diretamente na plataforma ${internalIntegrationType?.name} `
    + 'será necessária reintegrar sua conta conosco.',
  };

  useEffect(() => {
    if (!internalIntegrationType) {
      return;
    }

    if (!integrationsTypes) {
      return;
    }

    const integration = integrationsTypes.find(({ id }) => id === internalIntegrationType.id);

    // eslint-disable-next-line no-underscore-dangle
    const isSelectedIntegrationIncomplete = !internalIntegrationType.__typename;

    if (isSelectedIntegrationIncomplete) {
      setInternalIntegrationType(integration);
    }

    const redirectURL = integration.redirect;

    if (redirectURL) {
      if (internalReintegrationId) {
        setReintegrationId(internalReintegrationId);
      }

      handleIntegrationWishRedirect(integration);
      return;
    }

    setInternalVisible(true);
    goToWaitingDrawerToOpen(getStep('integration-form'));
  }, [internalIntegrationType, integrationsTypes]);

  useEffect(() => {
    if (integrationSelected) {
      setInternalIntegrationType(integrationSelected);
    }
  }, [integrationSelected]);

  useEffect(() => {
    if (reintegrationId) {
      setInternalReintegrationId(reintegrationId);
    }
  }, [reintegrationId]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (!hasPlatformSelectionStep) {
      return;
    }

    if (internalVisible) {
      return;
    }

    setInternalVisible(true);
  }, [visible]);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      finishRedirectIntegration(params);
    }
  }, [params]);

  const handleIntegrationFormSubmit = ({ credentials }) => {
    carouselRef?.current?.goTo(getStep('integration-loading'));
    setActiveSlider(true);

    saveIntegration({
      variables: {
        id: internalReintegrationId || reintegrationId,
        businessId: currentBusinessId,
        typeId: internalIntegrationType?.id,
        credentials,
      },
    });
  };

  return (
    <Drawer
      id="integration-drawer"
      width="90%"
      destroyOnClose
      onClose={handleClose}
      visible={internalVisible}
      closable={false}
      contentWrapperStyle={{
        maxWidth: '80.5rem',
      }}
    >
      <Carousel
        ref={(ref) => {
          carouselRef.current = ref;
        }}
        draggable={false}
        swipe={false}
        swipeToSlide={false}
        accessibility={false}
      >
        {/* -------------- SELETOR DE PLATAFORMAS ------------ */}
        { hasPlatformSelectionStep && (
          <div className="carousel-content">
            {/* preciso sumir com o primeiro step para
             corrigir o height do segundo step que fica gigante */}
            {!internalIntegrationType && (
              <>
                <Row className="header">
                  <Col>
                    <Button
                      link
                      onClick={handleClose}
                    >
                      <CloseOutlined className="close-button" />
                      Fechar
                    </Button>
                  </Col>
                </Row>

                <div className="list-container">
                  <div className="add-integration-form-left-title">
                    <h2 className="no-margin-bottom">Adicionar uma nova conta</h2>
                  </div>
                  <IntegrationTypesList
                    onSet={(e) => {
                      if (e.value !== 'pluggy') setInternalIntegrationType(e);
                      else handleClose(e);
                    }}
                    connectors={connectors}
                  />
                </div>
              </>
            )}
          </div>
        )}

        {/* -------------- LOADING DE REDIRECT ------------ */}
        <div className="carousel-content">
          {loadingRedirect && (
            <div className="loading-redirect-screen">
              <h2>Conectando plataforma...</h2>
              <p>Este processo pode levar algum tempo.</p>

              <Progress
                percent={100}
              />

            </div>
          )}
        </div>

        {/* -------------- FORM DE INTEGRACAO ------------ */}
        { internalIntegrationType && (
          <div className="carousel-content">
            <IntegrationsForm
              onClose={handleClose}
              form={form}
              reintegrationInfo={{
                ...internalIntegrationType,
                id: internalReintegrationId,
              }}
              integrationType={internalIntegrationType}
              loading={loading}
              saveIntegration={saveIntegration}
              onSubmit={handleIntegrationFormSubmit}
              currentBusinessId={currentBusinessId}
            />
          </div>
        )}

        {/* -------------- LOADING DO SAVE ------------ */}
        <div className="carousel-content">
          <div className="loading-integration-screen">
            <img src={Gif12} className="loading-integration-image" alt="Loading" />

            <h3>
              {
                useWordSlider({
                  active: activeSlider,
                  words: [
                    'Validando suas credenciais...',
                    'Conectando conta à GYRA+...',
                    'Acionando segurança de dados...',
                    'Solicitando acesso ao faturamento...',
                  ],
                  ms: 3000,
                })
              }
            </h3>
          </div>
        </div>

        {/* -------------- SUCESSO ------------ */}
        <div className="carousel-content success-integration-content">
          <Row className="header">
            <Col>
              <Button
                link
                onClick={handleClose}
              >
                <CloseOutlined className="close-button" />
                Fechar
              </Button>
            </Col>
          </Row>
          <Row className="success-integration-message-container">
            <Col span={24}>

              <SuccessMessage
                beforeTitle="Conta adicionada com sucesso!"
                subtitle={(
                  <>
                    <div align="middle">
                      <Alert
                        showIcon={false}
                        className="gyramais-integration-alert"
                        message={
                          checkActions(['only-score'])
                            ? successText.onlyScore
                            : successText.withLoan
                        }
                      />
                    </div>
                    Quer aumentar suas chances de aprovação? Clique abaixo e cadastre mais contas.
                  </>
                )}
                primaryButton={{
                  text: 'Cadastrar mais contas',
                  onClick: handleClose,
                }}
                secondaryButton={isCheckDocuments === false && {
                  id: 'close-integration-drawer',
                  text: sendSolicitation ? 'Finalizar e Solicitar Empréstimo' : 'Ir para lista de contas',
                  onClick: sendSolicitation
                    ? () => {
                      handleClose();
                      handleSendSolicitation();
                    }
                    : () => {
                      carouselRef?.current?.goTo(getStep('platform-selection'));
                      handleClose();
                      goToAccountScreen('2');
                    },
                }}
              />
            </Col>
          </Row>
          <div />
        </div>
      </Carousel>
    </Drawer>
  );
};
IntegrationDrawer.propTypes = {
  onClose: PropTypes.func,
  goToAccountScreen: PropTypes.func,
  visible: PropTypes.bool,
  reintegrationId: PropTypes.string,
  integrationSelected: PropTypes.shape({}),
  sendSolicitation: PropTypes.bool,
  handleSendSolicitation: PropTypes.func,
  handleSuccessIntegration: PropTypes.func,
  sendSolicitationLoading: PropTypes.bool,
  isCheckDocuments: PropTypes.bool,
  refetchBusinessRequests: PropTypes.func,
};

export default IntegrationDrawer;
