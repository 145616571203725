/* eslint-disable react/prop-types */

import React from 'react';
import { Button } from 'components/sdk';
import { Card } from 'components/gyramais';
import {
  Row,
  Col,
} from 'antd';
import { formatString } from 'utils';
import BlurWithMessage from '../BlurWithMessage';

import './styles.less';

/**
 *
 * @param {{
 *   prepaymentValue: number,
 *   totalFee: number,
 *   onNeedPrepayment: () => void,
 *   loading: boolean,
 * }} props
* @returns {React.FC}
*/
const PrepaymentValueCard = ({
  prepaymentValue,
  totalFee,
  onNeedPrepayment,
  loading,
}) => (
  <Card className="prepayment-value-card">
    <BlurWithMessage blur={prepaymentValue === 0} />

    <Row className="prepayment-value-card-container" justify="space-between">
      <Col span={24}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <h6 className="no-margin-bottom">
              <b className="weight-500">
                O valor total da operação é de
              </b>
            </h6>
          </Col>

          <Col span={24}>
            <h2 className="no-margin-bottom">
              <b className="weight-500 secondary-text">
                {formatString(prepaymentValue + totalFee, 'currency')}
              </b>
            </h2>

            <p className="no-margin-bottom prepayment-value-card-hint">
              <i>
                sendo
                {' '}
                <strong>
                  {formatString(totalFee, 'currency')}
                </strong>
                {' '}
                referentes à taxa de antecipação.
              </i>
            </p>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="prepayment-value-card-footer">
        <Button
          block
          loading={loading}
          onClick={onNeedPrepayment}
        >
          Quero antecipar meus recebíveis
        </Button>
      </Col>
    </Row>
  </Card>
);

export default PrepaymentValueCard;
