// @ts-check

import { useMemo } from 'react';

/**
 * @param {{
 *  loans: {
 *    status: {
 *      value: string
 *      title: string
 *      parent: {
 *        value: string
 *        title: string
 *      }
 *    }
 *  }[]
 *  pendingDownPaymentInvoice: any
 *  showPromoSubHeader: boolean
 *  lateInstallments: any[]
 *  awaitingSignatureLoan: boolean
 *  needToSign: boolean
 *  alreadySigned: boolean
 *  noDiscountPolicy: boolean
 *  isBureau: boolean
 *  flow: string
 * actions: any
 *  activeStatuses: string[]
 *  lateInstallment: any
 * }} args
 * @returns
 */
const useTitle = (args) => {
  const {
    loans,
    pendingDownPaymentInvoice,
    showPromoSubHeader,
    lateInstallments,
    awaitingSignatureLoan,
    needToSign,
    alreadySigned,
    isBureau,
    flow,
    actions = [],
    activeStatuses,
    lateInstallment,
    noDiscountPolicy,
  } = args;

  return useMemo(() => {
    const activeLoan = loans?.[0];
    const activeStatus = activeLoan?.status;
    const partnerIsBanker = actions.find((action) => action.value === 'partner-is-banker');

    if (pendingDownPaymentInvoice) {
      return 'Sua renegociação está quase completa! Pague o boleto de entrada para liberar a assinatura.';
    }

    if (showPromoSubHeader && !noDiscountPolicy) {
      return `Você possui ${lateInstallments.length} parcela${lateInstallments.length === 1 ? '' : 's'} em atraso`;
    }

    if (awaitingSignatureLoan && !needToSign && !alreadySigned) {
      return 'Estamos aguardando todas as partes assinarem o contrato.';
    }

    if (awaitingSignatureLoan && alreadySigned) {
      return 'Você já fez a sua parte! Agora basta aguardar o restante das assinaturas.';
    }

    if (awaitingSignatureLoan) {
      return activeStatus?.title;
    }

    if (isBureau) {
      return 'Seu nome foi enviado para o SERASA';
    }

    if (flow === 'badesc') {
      if (activeStatus?.parent?.value === 'awaiting-re-evaluation') {
        return 'ATENÇÃO';
      }
      if (activeStatus?.value === 'signed') {
        return 'Contrato assinado com sucesso!';
      }
    }

    if (partnerIsBanker && activeStatus?.value === 'completed') {
      return 'É ISSO AI';
    }

    if (loans.length === 0) {
      return 'Por enquanto não há novidades por aqui.';
    }

    if (activeStatuses.includes(activeStatus?.value) && lateInstallment) {
      return `Você possui ${lateInstallments.length} parcela${lateInstallments.length === 1 ? '' : 's'} em atraso`;
    }

    if (activeStatus?.title) {
      return activeStatus?.title;
    }

    if (activeStatus?.parent?.title) {
      return activeStatus?.parent?.title;
    }

    return '-';
  }, [args]);
};

export default useTitle;
