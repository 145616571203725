/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/gyramais';
import {
  formatCurrency,
} from 'utils';

import './styles.less';
// selectedOption?.badescInstallmentAmount
const BadescInterestAlertCard = ({
  selectedOption: {
    finalAmount,
    interestRate,
    badescInstallmentAmount,
  } = {},
  amount,
}) => (
  <Card id="badesc-interest-alert-card">
    <div className="badesc-interest-alert-card-title">
      Atrasando a parcela em 60+ dias...
    </div>
    <div className="badesc-interest-alert-card-body">
      Será acrescentada uma taxa de juros de
      {' '}
      <b>
        {interestRate}
        % a.a. + SELIC
      </b>
      {' '}
      e o valor final da primeira parcela será de aproximadamente
      {' '}
      <b>
        { formatCurrency((badescInstallmentAmount * amount) / finalAmount) }
      </b>
      .
      <br />
      <br />
      Os valores representam apenas uma simulação com o intuito de subsidiar a decisão
    </div>
  </Card>
);

BadescInterestAlertCard.propTypes = {
  selectedOption: PropTypes.shape(),
  amount: PropTypes.number,
};

export default BadescInterestAlertCard;
