import LOAN_AVAILABLE_RENEGOTIATE from './loanAvailableForPromoRenegotiate.gql';
import PROMO_RENEGOTIATE from './promoRenegotiateLoan.gql';
import SAVE_PROMO_RENEGOTIATE from './savePromoRenegotiationFields.gql';
import DOWN_PAYMENT_DUE_DAYS from './downPaymentDueDays.gql';

export {
  LOAN_AVAILABLE_RENEGOTIATE,
  PROMO_RENEGOTIATE,
  SAVE_PROMO_RENEGOTIATE,
  DOWN_PAYMENT_DUE_DAYS,
};
