import CHANGE_BUSINESS from './changeBusiness.gql';
import RUN_TOOLBOX_TO_BUSINESS from './runToolboxToBusiness.gql';
import GET_TOOLBOX_SETUP from './getToolboxSetup.gql';
import ME from './me.gql';

export {
  ME,
  CHANGE_BUSINESS,
  RUN_TOOLBOX_TO_BUSINESS,
  GET_TOOLBOX_SETUP,
};
