/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  formatString,
  formatAndNotificateError,
} from 'utils';
import {
  Divider,
  Col,
  Row,
  Radio,
} from 'antd';
import {
  ApiOutlined,
  DollarCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  SubHeader,
  PreApprovedCard,
  Card,
  Footer,
  // Alert,
  LoanTypeCard,
} from 'components';
import {
  Layout,
  Button,
  Modal,
} from 'components/sdk';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import {
  ME,
  SAVE_LOAN_EVENT,
  SAVE_LOAN,
  SAVE_REQUESTED_VALUES,
} from './graphql';

import './styles.less';
import BadescPreApproved from './components/BadesPreApproved';

const { Content } = Layout;

const PreApprovedScreen = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { currentContext } = useBusinessLoanContext();

  const [loanType, setLoanType] = useState();
  const [{
    amount,
    installmentAmount,
    installments,
    paymentDay,
  }, setValues] = useState({});
  const [questionModalVisible, setQuestionModalVisible] = useState(false);

  const {
    data: {
      me: {
        firstName,
        currentBusiness: {
          integrations = [],
          bankAccounts = [],
          loans = [],
        } = {},
      } = {},
    } = {},
    loading: meLoading,
  } = useQuery(ME);

  const [saveLoan, { loading: saveLoading }] = useMutation(SAVE_LOAN, {
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: () => history.push('/cadastro/status'),
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveRequestedValues, { loading: requestedLoading }] = useMutation(SAVE_REQUESTED_VALUES, {
    onCompleted: () => history.push('/cadastro/integracoes'),
    onError: (error) => formatAndNotificateError(error),
  });

  const loan = useMemo(() => loans.find(({ id }) => id === currentContext?.loan?.id), [loans, currentContext?.loan]);
  const state = useMemo(() => loan && (!loan?.type?.value ? 'type' : 'pre-approved'), [loan]);
  const possibleTypesValues = useMemo(() => loan?.possibleTypes.map(({ value }) => value) || [], [loan]);
  const typeIsPossible = useCallback((typeValue) => possibleTypesValues.includes(typeValue), [possibleTypesValues]);

  const integrationsCount = useMemo(() => {
    const count = integrations.length + bankAccounts.length;
    const partnerCountInIntegrations = count === 0 && currentContext?.partner?.name !== 'GYRAMAIS';

    return count + (partnerCountInIntegrations ? 1 : 0);
  }, [integrations, bankAccounts, currentContext?.partner]);

  const integrationsLevel = useMemo(() => {
    const temp = {
      0: {
        type: 'danger',
        integrationCardLabel: 'Precisamos que você conecte pelo menos uma das suas contas para confirmar o seu faturamento',
        integrationCardButton: 'Liberar empréstimo',
      },
      1: {
        type: 'danger',
        integrationCardLabel: 'Quer aumentar o valor do seu pré-aprovado? Comprove mais faturamento',
        integrationCardButton: 'Aumentar valor pré-aprovado',
      },
      2: {
        type: 'warning',
        integrationCardLabel: 'Comprove mais faturamento para desbloquear o valor total disponível',
        integrationCardButton: 'Aumentar valor pré-aprovado',
      },
      3: {
        type: 'success',
        integrationCardLabel: 'Comprove mais faturamento e melhore sua proposta pré-aprovada',
        integrationCardButton: 'Comprovar mais faturamento',
      },
    };

    return temp[integrationsCount] || {
      type: 'success',
      integrationCardLabel: 'Comprove mais faturamento e melhore sua proposta pré-aprovada',
      integrationCardButton: 'Comprovar mais faturamento',
    };
  }, [integrationsCount]);
  const loading = useMemo(() => saveLoading || saveLoanLoading, [saveLoading, saveLoanLoading]);

  const handleConfirmModalSubmit = async () => {
    await saveLoan({
      variables: {
        id: loan.id,
        paymentDay: Number(paymentDay),
      },
    });

    await saveLoanEvent({
      variables: {
        loanId: loan.id,
        amount,
        statusValue: 'pending',
        installmentsCount: installments,
        installmentAmount,
      },
    });
  };

  const handleButtonText = () => {
    if (state === 'type') {
      return 'Confirmar e prosseguir';
    }

    if (integrationsCount === 0) {
      return 'Comprove seu faturamento';
    }

    if (installmentAmount) {
      return 'Finalizar ajustes';
    }

    return 'Negocie por WhatsApp';
  };

  useEffect(() => {
    const currentLoan = currentContext.loan;

    if (currentLoan?.status?.value !== 'pre-approved') {
      history.push('/');
    }
  }, [currentContext?.loan]);

  if (currentContext?.loan?.partner.flow === 'badesc') {
    return (
      <BadescPreApproved />
    );
  }
  return (
    <Layout id="pre-approved-screen">
      <SubHeader
        title={state === 'type'
          ? `Olá, ${firstName}! Escolha uma opção de${width > 768 ? '\n' : ' '}crédito antes de prosseguir.`
          : `Olá, ${firstName}! Monte o seu crédito de${width > 768 ? '\n' : ' '}acordo com a necessidade do seu negócio.`}
        subtitle={state === 'type' && 'Você poderá escolher entre um empréstimo com trava de recebíveis, ou um empréstimo padrão.'
          + ' Clicando no banner informativo abaixo, você poderá entender como os recebíveis funcionam.'}
      />
      <Content>
        <Layout>
          {state === 'type' && (
            <div>
              <Row align="middle" justify="center" gutter={16}>
                <Col xs={24} sm={24} md={16}>
                  {/* Código comentado pois vamos implementar já já */}
                  {/* {typeIsPossible('receivables-guarantee') && (
                    <Alert
                      message={(
                        <>
                          Não lembra como funciona o sistema de trava de recebíveis?
                          {' '}
                          <Button
                            text
                            link
                            onClick={() => alert('teste')}
                          >
                            Clique aqui
                          </Button>
                          {' '}
                          e refresque a sua memória!
                        </>
                      )}
                    />
                  )} */}

                  <Radio.Group value={loanType} onChange={({ target: { value } = {} }) => setLoanType(value)}>
                    <Row align="middle" justify={loan?.possibleTypes?.length === 1 ? 'center' : 'space-between'} gutter={[0, 16]}>
                      {typeIsPossible('receivables-guarantee') && (
                        <Col xs={24} sm={24} md={10}>
                          <LoanTypeCard
                            recommended
                            value="receivables-guarantee"
                            onClick={() => (loanType !== 'receivables-guarantee' ? setLoanType('receivables-guarantee') : setLoanType())}
                            selected={loanType === 'receivables-guarantee'}
                            notSelected={loanType && loanType !== 'receivables-guarantee'}
                            values={loan?.preApprovedOptions?.[loan.preApprovedOptions.length - 1]}
                          />
                        </Col>
                      )}

                      {typeIsPossible('shareholder-guarantee') && (
                        <Col xs={24} sm={24} md={10}>
                          <LoanTypeCard
                            value="shareholder-guarantee"
                            onClick={() => (loanType !== 'shareholder-guarantee' ? setLoanType('shareholder-guarantee') : setLoanType())}
                            selected={loanType === 'shareholder-guarantee'}
                            notSelected={loanType && loanType !== 'shareholder-guarantee'}
                            values={(!typeIsPossible('receivables-guarantee') && loan?.preApprovedOptions?.[loan.preApprovedOptions.length - 1])
                              || loan?.preApprovedOptions?.find(({ finalInstallmentsCount }) => finalInstallmentsCount === 9)
                              || loan?.preApprovedOptions?.[loan.preApprovedOptions.length - 2]}
                            danger={typeIsPossible('receivables-guarantee')}
                          />
                        </Col>
                      )}
                    </Row>
                  </Radio.Group>
                </Col>
              </Row>

            </div>
          )}

          {state === 'pre-approved' && (
            <div>
              <PreApprovedCard
                loan={loan}
                type={integrationsLevel.type}
                onChangeValues={setValues}
                maxInstallmentsCount={loan?.type?.value === 'shareholder-guarantee' && possibleTypesValues.length > 1
                  && (loan?.preApprovedOptions?.find(({ finalInstallmentsCount }) => finalInstallmentsCount === 9).finalInstallmentsCount
                    || loan?.preApprovedOptions?.[loan.preApprovedOptions.length - 2].finalInstallmentsCount)}
              />

              <br />

              <Card className={`improve-loan-card ${installmentAmount ? 'improve-loan-card-visible' : ''}`} type="secondary" size="small">
                <Row align="middle" justify="space-between" gutter={[0, 24]}>
                  <Col xs={24} sm={24} md={13} lg={13} xl={17}>
                    <h4 className="no-margin-bottom primary-button-text">
                      {integrationsLevel.integrationCardLabel}
                    </h4>
                  </Col>
                  <Col xs={0} sm={0} md={1} lg={1} xl={1} align="middle">
                    <Divider type="vertical" className="improve-loan-card-divider" />
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={6}>
                    <Button
                      block
                      color="secondary"
                      disabled={loading}
                      onClick={() => {
                        if (integrationsCount === 0) {
                          return history.push('/cadastro/pre-aprovado/liberar');
                        }

                        localStorage.setItem('integrationCount', integrationsCount - (integrationsCount === 0 && currentContext?.partner ? 1 : 0));
                        return history.push('/cadastro/integracoes');
                      }}
                    >
                      {integrationsLevel.integrationCardButton}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </div>
          )}
        </Layout>
      </Content>

      <Footer
        loading={meLoading}
        leftContent={state === 'pre-approved' && (
          <div>
            <h4 className="no-margin-bottom">
              <strong className="secondary-text">
                VALOR ESTIMADO DA PARCELA*
              </strong>
            </h4>

            <div className="pre-approved-screen-footer-installment">
              <h1 className="no-margin-bottom secondary-text icon-container">
                <DollarCircleOutlined />
              </h1>

              <h2 className="no-margin-bottom text-container">
                <strong className="white-label-text">
                  ≈ {formatString(installmentAmount, 'currency')}
                </strong>
              </h2>
            </div>
          </div>
        )}
        middleContent={state === 'pre-approved' && (
          <div>
            <p className={integrationsCount > 0 ? '' : 'no-margin-bottom'}>
              <b className="white-label-text">
                <span className="secondary-text">*</span>
                O valor final das parcelas será calculado após a configuração da proposta ser concluída.
              </b>
            </p>

            {integrationsCount > 0 && installmentAmount && (
              <Button
                link
                onClick={() => {
                  localStorage.setItem('integrationCount', integrationsCount - (integrationsCount === 0 && currentContext?.partner ? 1 : 0));
                  history.push('/cadastro/integracoes');
                }}
                icon={(<ApiOutlined />)}
              >
                Melhore sua proposta
              </Button>
            )}
          </div>
        )}
        okButton={{
          text: handleButtonText(),
          loading: loading || requestedLoading || saveLoading,
          disabled: state === 'type' && !loanType,
          className: state === 'pre-approved' && !installmentAmount ? 'pulse-animation' : '',
          onClick: () => {
            if (state === 'type') {
              return saveLoan({
                variables: {
                  id: loan.id,
                  typeValue: loanType,
                },
              });
            }

            if (integrationsCount === 0) {
              return history.push('/cadastro/pre-aprovado/liberar');
            }

            if (!installmentAmount) {
              return setQuestionModalVisible(true);
            }

            return handleConfirmModalSubmit();
          },
        }}
        cancelButton={!loading && possibleTypesValues?.length > 1 && state === 'pre-approved' && {
          text: '< Voltar',
          onClick: () => {
            setLoanType();
            saveLoan({
              variables: {
                id: loan.id,
                typeValue: '',
              },
            });
          },
          loading: saveLoading,
        }}
      />

      <Modal
        className="pre-approved-question-modal"
        visible={questionModalVisible}
        centered
        title="Deseja negociar sua proposta?"
        onClose={() => setQuestionModalVisible(false)}
        okButton={{
          id: 'whatsapp-negotiate-button',
          text: 'Negocie por WhatsApp',
          disabled: requestedLoading,
          onClick: () => {
            window.open(`https://api.whatsapp.com/send?phone=55${process.env.GYRAMAIS_WHATSAPP}&text=Ol%C3%A1%2C%20GYRA%2B!%20Gostaria%20de%20saber%20como%20melhorar%20minha%20proposta!`, '_blank');
            setQuestionModalVisible(false);
          },
        }}
        cancelButton={{
          id: 'connect-more-accounts-button',
          text: 'Conectar contas',
          loading: requestedLoading,
          onClick: () => {
            localStorage.setItem('integrationCount', integrationsCount - (integrationsCount === 0 && currentContext?.partner ? 1 : 0));

            const variables = {
              loanId: loan?.id,
              requestedValues: {
                amount,
                paymentDay,
                installmentsCount: installments,
              },
            };

            return saveRequestedValues({ variables });
          },
        }}
      >
        <div align="middle">
          <WarningOutlined className="question-modal-icon" />
        </div>

        <h6>
          <b>
            Você tambem pode aumentar seu valor pré-aprovado comprovando mais do seu faturamento conectando suas contas.
            <br />
            <br />
            <strong>
              Importante!
            </strong>
            {' '}
            Sua proposta poderá passar por um nova análise e as condições não são garantidas.
          </b>
        </h6>
      </Modal>
    </Layout>
  );
};

export default PreApprovedScreen;
