import LOANS from './loans.gql';
import LOAN from './loan.gql';
import LOAN_UPDATED from './loanUpdated.gql';
import ME from './me.gql';
import SAVE_LOAN_EVENT from './saveLoanEvent.gql';
import CONNECTORS from './connectors.gql';

export {
  LOAN,
  LOANS,
  LOAN_UPDATED,
  ME,
  SAVE_LOAN_EVENT,
  CONNECTORS,
};
