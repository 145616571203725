// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#badesc-interest-alert-card {
  padding: 1.4rem 2.5rem;
  margin-bottom: 1.4rem;
  max-width: 551px;
}
#badesc-interest-alert-card .badesc-interest-alert-card-title {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.4rem;
}
#badesc-interest-alert-card .badesc-interest-alert-card-body {
  font-size: 1.1rem;
  font-weight: 400;
}
#badesc-interest-alert-card .badesc-interest-alert-card-body > span {
  font-weight: 400;
}
#badesc-interest-alert-card .badesc-interest-alert-card-body .highlight-text {
  font-weight: bold;
  margin: 0 0.3rem;
}
#badesc-interest-alert-card b {
  font-weight: 400;
  color: var(--link-button-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/BadescInterestAlertCard/styles.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAJA;EAMI,iBAAA;EACA,gBAAA;EACA,qBAAA;AACJ;AATA;EAWI,iBAAA;EACA,gBAAA;AACJ;AAbA;EAcM,gBAAA;AAEN;AAhBA;EAiBM,iBAAA;EACA,gBAAA;AAEN;AApBA;EAuBI,gBAAA;EACA,+BAAA;AAAJ","sourcesContent":["#badesc-interest-alert-card {\n  padding: 1.4rem 2.5rem;\n  margin-bottom: 1.4rem;\n  max-width: 551px;\n  \n  .badesc-interest-alert-card-title {\n    font-size: 1.4rem;\n    font-weight: 400;\n    margin-bottom: 1.4rem;\n  }\n  .badesc-interest-alert-card-body {\n    font-size: 1.1rem;\n    font-weight: 400;\n    > span {\n      font-weight: 400;\n    }\n    .highlight-text {\n      font-weight: bold;\n      margin: 0 0.3rem;\n    }\n  }\n\n  b {\n    font-weight: 400;\n    color: var(--link-button-color)\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
