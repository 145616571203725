import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Alert,
  Divider,
  Checkbox,
} from 'antd';
import { Information } from 'components';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { formatAndNotificateError, formatDate, formatString } from 'utils';
import { useMutation } from '@apollo/client';
import { SAVE_OPTIN } from './graphql';
import { useSignatureContext } from '../..';

import './styles.less';

const PrepaymentSummary = () => {
  const { ip } = useBusinessLoanContext();
  const {
    prepayment,
    variables,
    setStepVariables,
    setStepHandle,
    setStepLoading,
    onFinishStep,
  } = useSignatureContext();

  const [saveOptIn, { loading }] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      setStepLoading(false);
      onFinishStep();
    },
    onError: (error) => {
      setStepLoading(false);
      formatAndNotificateError(error);
    },
  });

  useEffect(() => setStepHandle(() => () => saveOptIn({ variables: { ip } })), [variables.done]);
  useEffect(() => setStepLoading(loading), [loading]);

  return (
    <Row gutter={[24, 24]} className="domicile-bank-account-step-container">
      <Col span={24}>
        <Alert showIcon message="Caso a sua solicitação seja realizada após as 14 horas, efetivaremos a operação no próximo dia util." />
      </Col>

      <Col span={24}>
        <Alert
          showIcon
          message="O valor solicitado pode sofrer alteração. Após sua confirmação, verificaremos a disponibilidade dos valores a serem antecipados na agenda de recebíveis (registradora). Caso ocorra mudanças nos valores solicitados, informaremos via e-mail."
          type="warning"
        />
      </Col>

      <Col xs={12} sm={12} md={12} lg={6}>
        <Information
          label="Data"
          value={prepayment.createdAt && formatDate(new Date(prepayment.createdAt))}
        />
      </Col>

      <Col xs={12} sm={12} md={12} lg={6}>
        <Information
          label="Valor solicitado"
          value={formatString(prepayment.amount, 'currency')}
        />
      </Col>

      <Col xs={12} sm={12} md={12} lg={6}>
        <Information
          label="Taxa efetiva"
          value={`${prepayment.effectiveFee}%`}
        />
      </Col>

      <Col xs={12} sm={12} md={12} lg={6}>
        <Information
          label="Taxa mensal"
          value={`${prepayment.cet}%`}
        />
      </Col>

      <Col xs={12} sm={12} md={12} lg={6}>
        <Information
          label="Frequência"
          value={undefined}
        />
      </Col>

      <Col xs={12} sm={12} md={12} lg={6}>
        <Information
          label="Dia"
          value={undefined}
        />
      </Col>

      <Col xs={24} sm={24} md={12} lg={6}>
        <Information
          label="Adquirentes"
          value={prepayment.integrationTypes?.map(({ name }) => name).join(', ')}
        />
      </Col>

      <Col xs={24} sm={24} md={12} lg={6}>
        <Information
          label="Conta domicílio"
          value={prepayment.bankAccount ? `${prepayment.bankAccount.bank.name} - ${prepayment.bankAccount.branchCode}/${prepayment.bankAccount.accountNumber}` : '-'}
          maxLen={21}
        />
      </Col>

      <Col span={24}>
        <Divider className="no-margin" />
      </Col>

      <Col span={24}>
        <Checkbox
          checked={variables.done}
          onChange={({ target: { checked } = {} }) => setStepVariables({ done: checked })}
        >
          <p className="no-margin-bottom">
            <b>
              Li e aceito os Termos de Antecipação
            </b>
          </p>
        </Checkbox>
      </Col>

    </Row>

  );
};

export default {
  id: 'prepayment-summary-step',
  title: 'Dados da antecipação',
  component: <PrepaymentSummary />,
};
