/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { formatAndNotificateError } from 'utils';
import { Alert } from 'components/gyramais';
import { Modal } from 'components/sdk';
import {
  REMOVE_BUSINESS_USER,
  SAVE_BUSINESS_USER,
} from './graphql';
import './styles.less';

/**
 * @type {React.FC<{
 *  visible: boolean
 *  onDelete: () => void
 *  onClose: () => void
 *  businessUser: {
 *    fullName: string
 *    id: string
 *    types: string[]
 *  }
 *  section: {
 *    type: string
 *    label: string
 *  }
 * }>}
 */
const ConfirmRemoveBusinessUserModal = ({
  onDelete,
  visible,
  onClose,
  businessUser: {
    id,
    fullName,
    types,
  },
  section: {
    label,
    type,
  },
}) => {
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: () => {
      onDelete();
      notification.success({
        message: 'Removido da responsabilidade com sucesso',
      });
    },
    onError: (error) => formatAndNotificateError(error),
  });
  const [removeBusinessUser, { loading }] = useMutation(REMOVE_BUSINESS_USER, {
    onCompleted: () => {
      onDelete();
      notification.success({
        message: 'Removido da empresa com sucesso',
      });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const removeFromResponsability = types.length > 1 && type !== 'avalista';

  return (
    <Modal
      visible={visible}
      title={`Deseja remover ${fullName}?`}
      onClose={onClose}
      centered
      okButton={{
        text: 'Remover da empresa',
        loading: loading || saveLoading,
        onClick: () => {
          removeBusinessUser({
            variables: {
              id,
            },
          });
        },
      }}
      cancelButton={label !== 'Avalistas' ? {
        text: `Remover dos ${label}`,
        color: 'secondary',
        loading: loading || saveLoading,
        onClick: () => {
          saveBusinessUser({
            variables: {
              id,
              typesValues: [
                ...types.filter(({ value }) => value !== type).map(({ value }) => value),
              ],
            },
          });
        },
      } : undefined}
    >
      <Alert message="Ao confirmar a remoção, a ação não poderá ser revertida." />
      <div className="confirm-remove-subtitle">
        {removeFromResponsability ? (
          <>
            Você pode remover o usuário apenas da
            {' '}
            <b>responsabilidade atual</b>
            {' '}
            ou remover da
            {' '}
            <b>empresa</b>
          </>
        ) : (
          <>
            Como o usuário selecionado
            {' '}
            {type === 'avalista' ? (
              <>
                é avalista
              </>
            ) : (
              <>
                só tem
                {' '}
                <b>um cargo</b>
              </>
            )}
            , só pode ser feita a remoção do usuário da
            {' '}
            <b>empresa</b>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmRemoveBusinessUserModal;
