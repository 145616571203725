import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
  Row,
  Col,
  Select,
  Radio,
  notification,
} from 'antd';
import {
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Card,
  Drawer,
  Input,
  Alert,
} from 'components/gyramais';
import {
  Modal,
  Button,
} from 'components/sdk';
import {
  formatAndNotificateError,
} from 'utils';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import {
  ME,
  SAVE_BUSINESS,
} from './graphql';

import './styles.less';

const { Step } = Steps;
const { Option } = Select;

const BusinessAdditionalInfoDrawer = ({
  visible,
  title,
  onClose,
  onFinish,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [captations, setCaptations] = useState([{
    value: undefined,
    mesano: undefined,
  }]);
  const [socials, setSocials] = useState([{
    network: undefined,
    link: undefined,
  }]);
  const [addAvalistIntention, setAddAvalistIntention] = useState(2);
  const [description, setDescription] = useState('');

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const {
    data: {
      me: {
        currentBusiness,
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'cache-only',
    onCompleted: ({ me: { currentBusiness } = {} }) => {
      const {
        captations,
        socials,
        addAvalistIntention,
        description,
      } = currentBusiness?.additionalInfo || {};

      if (captations?.length) {
        setCaptations(captations.map(({ value, mesano }) => ({ value, mesano })));
      }
      if (socials?.length) {
        setSocials(socials.map(({ network, link }) => ({ network, link })));
      }
      if (addAvalistIntention !== undefined && addAvalistIntention !== null) {
        setAddAvalistIntention(addAvalistIntention ? 1 : 2);
      }
      if (description !== undefined && description !== null) {
        setDescription(description);
      }
    },
  });

  const [saveBusiness, { loading }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      } else {
        onFinish();
        onClose();
      }
    },
    onError: (error) => {
      formatAndNotificateError(error);
    },
  });

  const steps = [
    {
      title: 'Histórico',
      content: (
        <>
          <Alert message={'Caso não tenha captado, clique no botão "Prosseguir" para continuar.'} />

          <Card
            title="Já captou crédito em outra fintech?"
            size="small"
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Row gutter={[0, 16]}>
                  {captations.map((captation, index) => (
                    <Col key={Math.random()} span={24}>
                      <Row gutter={[32, 16]} align="middle">
                        <Col xs={24} sm={24} md={12}>
                          <Input
                            id={`captation-value-${index + 1}`}
                            type="currency"
                            placeholder="Valor captado"
                            defaultValue={captation.value}
                            onBlur={(e) => {
                              const temp = [...captations];
                              temp[index].value = e.target.value;

                              setCaptations(temp);
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Input
                            id={`captation-mesano-${index + 1}`}
                            type="masked"
                            guide
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            placeholder="Mês/Ano da captação"
                            defaultValue={captation.mesano}
                            onBlur={(e) => {
                              const temp = [...captations];
                              temp[index].mesano = e.target.value;

                              setCaptations(temp);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col span={24}>
                <div align="right">
                  <Button
                    id="add-captation-button"
                    link
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      const temp = [...captations];

                      const empty = {};
                      Object.keys(captations[0]).forEach((key) => {
                        empty[key] = undefined;
                      });

                      temp.push(empty);
                      setCaptations(temp);
                    }}
                  >
                    Adicionar nova captação
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </>
      ),
      onFinish: async () => {
        const completedCaptations = captations
          .filter((captation) => captation.mesano && captation.value);
        const incompletedCaptations = captations
          .filter((captation) => (captation.value && !captation.mesano)
            || (!captation.value && captation.mesano));
        const date = new Date();

        for await (const captation of completedCaptations) {
          const [mes, ano] = captation.mesano.split('/');

          if (mes.includes('_') || ano.includes('_')) {
            return notification.error({ message: 'Data de captação inválida' });
          }

          if ((Number(mes) < 0 || Number(mes) > 12)
          || (Number(ano) === date.getFullYear() && Number(mes) > date.getMonth() + 1)
          || Number(ano) > date.getFullYear() || Number(ano) < 1900) {
            return notification.error({ message: 'Data de captação inválida' });
          }
        }

        if (incompletedCaptations.length > 0 && !confirmModalVisible) {
          return setConfirmModalVisible(true);
        }

        if (confirmModalVisible) {
          setConfirmModalVisible(false);
        }

        return saveBusiness({
          variables: {
            id: currentBusiness.id,
            captations: completedCaptations,
          },
        });
      },
    },
    {
      title: 'Redes sociais',
      content: (
        <>
          <Alert message={'Caso não tenha redes socials, clique no botão "Prosseguir" para continuar.'} />

          <Card
            title="Deseja informar suas redes sociais?"
            size="small"
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Row gutter={[0, 16]}>
                  {socials.map((social, index) => (
                    <Col key={Math.random()} span={24}>
                      <Row gutter={[32, 16]} align="middle">
                        <Col xs={24} sm={24} md={12}>
                          <Select
                            id={`social-network-${index + 1}`}
                            className="gyramais-input"
                            placeholder="Selecione a rede social"
                            value={social.network}
                            onChange={(value) => {
                              const temp = [...socials];
                              temp[index].network = value;

                              setSocials(temp);
                            }}
                          >
                            <Option value="facebook">Facebook</Option>
                            <Option value="instagram">Instagram</Option>
                            <Option value="tiktok">TikTok</Option>
                            <Option value="twitter">Twitter</Option>
                          </Select>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Input
                            id={`social-link-${index + 1}`}
                            placeholder={['twitter', 'tiktok', 'instagram'].includes(social.network) ? 'Nome de perfil' : 'URL da rede social'}
                            addonBefore={['twitter', 'tiktok', 'instagram'].includes(social.network) && '@'}
                            defaultValue={social.link}
                            onBlur={(e) => {
                              const temp = [...socials];
                              temp[index].link = e.target.value;

                              setSocials(temp);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col span={24}>
                <div align="right">
                  <Button
                    id="add-social-button"
                    link
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      const temp = [...socials];

                      const empty = {};
                      Object.keys(socials[0]).forEach((key) => {
                        empty[key] = undefined;
                      });

                      temp.push(empty);
                      setSocials(temp);
                    }}
                  >
                    Adicionar nova rede social
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </>
      ),
      onFinish: async () => {
        const completedSocials = socials
          .filter((social) => social.network && social.link);
        const incompletedSocials = socials
          .filter((social) => (social.network && !social.link)
                || (!social.network && social.link));

        if (incompletedSocials.length > 0 && !confirmModalVisible) {
          return setConfirmModalVisible(true);
        }

        if (confirmModalVisible) {
          setConfirmModalVisible(false);
        }

        return saveBusiness({
          variables: {
            id: currentBusiness.id,
            socials: completedSocials,
          },
        });
      },
    },
    {
      title: 'Avalista',
      content: (
        <>
          <Alert message="Ao adicionar um avalista sua proposta tem maiores chances de aprovação." />

          <Card
            title={(
              <Row>
                <Col xs={24} sm={24} md={18}>
                  <h5 className="no-margin-bottom">Deseja incluir avalista?</h5>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <div align="right">
                    <Radio.Group
                      onChange={(e) => setAddAvalistIntention(e.target.value)}
                      value={addAvalistIntention}
                    >
                      <Radio value={1}>
                        <h5 id="add-avalist-intention-button" className="no-margin-bottom">
                          Sim
                        </h5>
                      </Radio>
                      <Radio value={2}>
                        <h5 id="not-add-avalist-intention-button" className="no-margin-bottom">
                          Não
                        </h5>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            )}
            size="small"
          />
        </>
      ),
      onFinish: () => saveBusiness({
        variables: {
          id: currentBusiness.id,
          addAvalistIntention: addAvalistIntention === 1,
        },
      }),
    },
    {
      title: 'Segmento',
      content: (
        <>
          <Alert message="Ao detalhar seu tipo de exercício, sua proposta tem maiores chances de aprovação" />

          <Card
            size="small"
            title={(
              <>
                <h5>
                  Como você define o seu negócio?
                </h5>
                {' '}
                <p className="no-margin-bottom gray-text">
                  Ex: &quot;Possuo uma loja de roupas femininas&quot;
                </p>
              </>
            )}
          >
            <Row>
              <Col span={24}>
                <Input
                  id="description-input"
                  type="textarea"
                  rows={6}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="Conte-nos um pouco sobre o seu negócio"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
          </Card>
        </>
      ),
      onFinish: () => saveBusiness({
        variables: {
          id: currentBusiness.id,
          description,
        },
      }),
    },
  ];

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={() => {
        onClose();
        setCurrentStep(0);
      }}
      primaryButton={{
        children: 'Prosseguir',
        id: 'additional-info-continue-button',
        loading,
        onClick: steps[currentStep].onFinish,
      }}
      id="business-additional-info-drawer"
    >
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <br />

      <div className="business-additional-info-content">{steps[currentStep].content}</div>

      <Modal
        visible={confirmModalVisible}
        centered
        onCancel={() => setConfirmModalVisible(false)}
        title="Você não preencheu todas as informações por completo."
        footer={(
          <>
            <Button
              link
              onClick={() => setConfirmModalVisible(false)}
            >
              Não
            </Button>
            <Button id="confirm-incomplete-info-button" onClick={steps[currentStep].onFinish}>
              Sim
            </Button>
          </>
        )}
      >
        <h5 className="no-margin-bottom">
          <span className="secondary-text">
            As informações preenchidas pela metade não serão salvas.
          </span>
          <br />
          Deseja continuar mesmo assim?
        </h5>
      </Modal>
    </Drawer>
  );
};

BusinessAdditionalInfoDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default BusinessAdditionalInfoDrawer;
