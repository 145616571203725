import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Layout,
} from 'components/sdk';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import {
  SuccessMessage,
  BusinessAdditionalInfoDrawer,
  PreApprovedCard,
  PreApprovedBadescCard,
} from 'components';
import {
  SubHeader,
  Footer,
} from 'components/gyramais';
import {
  useQuery,
  useMutation,
  useSubscription,
  useReactiveVar,
} from '@apollo/client';
import { meVar } from 'services/graphql/cache';
import { Gif23 } from 'assets/images';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';

import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import {
  formatAndNotificateError,
} from 'utils';
import {
  LOAN_UPDATED,
  ME,
  SAVE_LOAN_EVENT,
  CONNECTORS,
} from './graphql';

import './styles.less';

const { Content } = Layout;

const statuses = {
  'pending-business-infos': 'Informações da empresa',
  'pending-docs': 'Documentos da empresa',
  'pending-bank-account': 'Dados da conta bancária',
  'pending-users': 'Dados dos sócios da empresa',
};

const availableStatuses = [
  'pending',
  'registered',
  'integrated',
  'verifying',
  'score',
  'final-verifying',
  'review',
  'pending-bank-account',
  'pending-users',
  'pending-business-infos',
  'pending-docs',
  'awaiting-partner',
  'check-documents',
];

const RequestScreen = () => {
  const history = useHistory();
  const { stone } = useReactiveVar(meVar);
  const { width } = useWindowDimensions();

  const { checkActions } = usePartnerActionsContext();
  const isScoreOnly = checkActions(['only-score']);

  const { currentContext, contextLoading } = useBusinessLoanContext();
  const { partner, loan: currentLoan = {} } = currentContext;

  const [onPending, setOnPending] = useState();
  const [additionalInfoDone, setAdditionalInfoDone] = useState(true);
  const [additionalDrawerVisible, setAdditionalDrawerVisible] = useState(false);
  const [specificsIds, setSpecificsIds] = useState([]);
  const [integrationsRequests, setIntegrationsRequests] = useState([]);

  const handleStartRegister = () => {
    history.push('/cadastro/empresa');
  };
  const handleReviewLoan = () => {
    if (integrationsRequests.length > 0) {
      history.push('/cadastro/integracoes');
      return;
    }
    history.push('/cadastro/revisao');
  };
  const handleImproveLoan = () => {
    history.push('/cadastro/integracoes');
  };

  useQuery(CONNECTORS, {
    variables: {
      limit: 40,
      specificsIds,
    },
    skip: specificsIds.length === 0,
    fetchPolicy: 'network-only',
    onCompleted: ({ connectors }) => {
      const integrations = [];
      connectors.forEach((connectors) => {
        let count = 0;
        specificsIds.forEach((specificId) => {
          if (specificId === connectors.id) {
            count += 1;
          }
        });
        integrations.push({ name: connectors.name, quantity: count });
      });
      setIntegrationsRequests([...integrations, ...integrationsRequests]);
    },
  });

  const {
    data: {
      me: {
        firstName,
      } = {},
      me,
    } = {},
    loading: meLoading,
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me: { currentBusiness } = {} }) => {
      const {
        captations,
        socials,
        addAvalistIntention,
        description,
      } = currentBusiness?.additionalInfo || {};
      if (!captations
        || !socials
        || (addAvalistIntention === undefined || addAvalistIntention === null)
        || (description === undefined || description === null)) {
        setAdditionalInfoDone(false);
      }
      const businessRequestsPendings = currentBusiness?.businessRequests.filter(({ completedAt, type }) => !completedAt && type?.value === 'add-integration');
      if (businessRequestsPendings.length > 0) {
        const ids = [];
        const notSpecified = { name: 'not-specified', quantity: 0 };

        businessRequestsPendings.forEach(({ specificsIds }) => {
          if (!specificsIds || specificsIds.length === 0) {
            notSpecified.quantity += 1;
          } else if (specificsIds && specificsIds.length > 0) {
            ids.push(specificsIds[0]);
          }
        });
        setIntegrationsRequests([notSpecified]);
        setSpecificsIds(ids);
      }
    },
  });

  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: () => history.push('/cadastro/pre-aprovado'),
    onError: (error) => formatAndNotificateError(error),
  });

  useSubscription(LOAN_UPDATED, {
    variables: { ids: [currentLoan?.id] },
    skip: !currentLoan?.id,
  });

  const handleBeforeTitleMessage = () => {
    if (currentLoan.status?.value === 'check-documents') {
      return 'Olá! Precisamos que você revise alguns dados.';
    }

    if (onPending) {
      if (currentLoan.improvedAmountInPercentage) {
        return 'Concluímos a análise dos seus dados';
      }
      return 'Vamos concluir sua aprovação?';
    }

    return 'Precisamos que revise as seguintes informações para prosseguirmos:';
  };

  const handleTitle = () => {
    if (currentLoan.status?.value === 'check-documents') {
      return 'Confira abaixo alguns detalhes do que foi solicitado:';
    }
    if (onPending) {
      if (currentLoan.improvedAmountInPercentage && !checkActions(['partner-analyze-credit'])) {
        return `Sua proposta teve um aumento de ${currentLoan.improvedAmountInPercentage}% no valor pré-aprovado inicial`;
      }
      return 'Tenha seus documentos em mãos.';
    }

    return '';
  };
  const handleReview = () => {
    const review = [];
    const currentBusiness = me?.currentBusiness;

    // eslint-disable-next-line array-callback-return, consistent-return
    currentBusiness?.bankAccounts.map((bankAccount) => {
      if (bankAccount?.analysis?.reason) {
        review.push(
          <li key={bankAccount.analysis.id}>
            <b>
              Conta bancária
            </b> de conta
            <b>
              {' '}
              {bankAccount.accountNumber}
              {' '}
            </b>
            {bankAccount.analysis.reason}
          </li>,
        );
      }
    });
    currentBusiness
      // eslint-disable-next-line array-callback-return, consistent-return
      ?.users.map(({ user }) => {
        if (user?.proofOfIncome?.analysis?.reason) {
          review.push(
            <li key={user.proofOfIncome.analysis.id}>
              <b>
                Comprovante de renda
              </b> do sócio <b>{user.fullName}</b> {user.proofOfIncome.analysis.reason}
            </li>,
          );
        }
        if (user?.address?.analysis?.reason) {
          review.push(
            <li key={user.address.analysis.id}>
              <b>
                Endereço
              </b> do sócio
              <b> {user.fullName}
              </b> {user.address.analysis.reason}
            </li>,
          );
        }
      });
    if (currentBusiness?.address?.analysis?.reason) {
      review.push(
        <li key={currentBusiness?.address?.analysis.id}>
          <b>
            Endereço
          </b> {currentBusiness?.address?.analysis?.reason}
        </li>,
      );
    }
    if (currentBusiness?.bylaws?.analysis?.reason) {
      review.push(
        <li key={currentBusiness?.bylaws?.analysis.id}>
          <b>
            Contrato social
          </b> {currentBusiness?.bylaws?.analysis?.reason}
        </li>,
      );
    }

    const existAvalistRequest = currentBusiness?.businessRequests.some((request) => !request.completedAt && request.type?.value === 'add-avalist');

    if (currentBusiness?.businessRequests?.length > 0 && existAvalistRequest) {
      let count = 0;
      currentBusiness.businessRequests.forEach((businessRequest) => {
        if (businessRequest.type?.value === 'add-avalist') {
          count++;
        }
      });

      review.push(
        <li key={currentBusiness?.businessRequests[0]?.id}>
          Necessário
          <b>{`adicionar ${count > 1 ? `${count} + novos avalistas}` : `${count} + novo avalista.`}`}</b>
        </li>,
      );
    }
    if (integrationsRequests.length > 0) {
      integrationsRequests.forEach(({ name, quantity }) => {
        if (name === 'not-specified' && quantity > 0) {
          review.push(
            <li key={name}>
              Necessário
              que você faça <b>{quantity} {' '} {quantity > 1 ? 'outras integrações.' : 'outra integração.'} </b>
            </li>,
          );
        } else if (name !== 'not-specified') {
          review.push(
            <li key={name}>
              Necessário
              que você
              <b> integre {quantity} {' '} {quantity > 1 ? 'contas' : 'conta'}: {' '} {name} {' '}
              </b>
            </li>,
          );
        }
      });
    }
    return review;
  };

  const handleSubTitle = () => {
    if (onPending) {
      if (currentLoan.improvedAmountInPercentage && !checkActions(['partner-analyze-credit'])) {
        let textMessage = 'Esses são os novos valores do seu crédito, baseados nas informações fornecidas por você durante o nosso processo de melhoria de proposta.';
        if (currentLoan.improvedAmountInPercentage === 5) {
          textMessage += ' Você pode melhorar ainda mais a sua proposta caso conecte mais contas!';
        }
        return textMessage;
      }
      return 'Para finalizar a aprovação do seu crédito, precisamos de mais algumas informações.';
    }

    if (currentLoan.status.value === 'check-documents') {
      // IRPF E ENDEREÇO DO SÓCIO E CONTA BANCÁRIA
      return (
        <ul className="revision-fields">
          {handleReview()}
        </ul>
      );
    }

    return (
      <ul className="revision-fields">
        {currentLoan?.business?.revision?.map(({ completedAt, status }) => completedAt === ''
          && (checkActions(['partner-is-banker']) && status !== 'pending-bank-account')
          && (
            <li key={status}>{statuses[status]}</li>
          ))}
      </ul>
    );
  };

  const handlePrimaryButton = () => {
    let textMessage = 'Começar agora';
    let onClick = handleStartRegister;

    if (currentLoan.status.value === 'check-documents') {
      if (handleReview().length === 0) {
        return false;
      }
      textMessage = 'Iniciar revisão de documentos';
      onClick = handleReviewLoan;
    }

    if (currentLoan.improvedAmountInPercentage === 5) {
      textMessage = 'Quero melhorar ainda mais!';
      onClick = handleImproveLoan;
    } else if (currentLoan.improvedAmountInPercentage === 10) {
      textMessage = 'Continuar cadastro';
    }

    return {
      id: 'status-continue-button',
      text: textMessage,
      onClick,
    };
  };

  const handleSecondaryButton = () => {
    if (currentLoan.improvedAmountInPercentage === 5) {
      return {
        id: 'status-continue-button',
        text: 'Continuar cadastro',
        onClick: handleStartRegister,
      };
    }
    return null;
  };

  useEffect(() => {
    if (!currentLoan?.id) {
      return false;
    }

    if (!availableStatuses.includes(currentLoan.status?.value)) {
      history.push('/');
    }

    if (['pending-bank-account', 'pending-users', 'pending-business-infos', 'pending-docs', 'pending'].includes(currentLoan.status?.value)) {
      setOnPending(true);
    }

    return true;
  }, [currentLoan]);

  const handleVerificationSubtitle = () => {
    const beAwareText = 'Fique atento! Em breve você receberá uma resposta por email ou SMS com os resultados da análise.';

    if (checkActions(['partner-analyze-credit'])) {
      return (
        <>
          {beAwareText}
        </>
      );
    }

    if (!additionalInfoDone) {
      return (
        <>
          {beAwareText}
          <br />
          Enquanto isso, gostaríamos de te conhecer melhor
        </>
      );
    }

    if (partner?.flow === 'badesc') {
      return (
        <>
          Fique atento!
          Em breve você receberá um e-mail ou SMS indicando a continuidade da sua solicitação.
          Ao recebê-lo faça login e insira os documentos necessários. Até breve!
        </>
      );
    }

    return (
      <>
        {beAwareText}
        <br />
        Enquanto isso, confira nosso blog com dicas exclusivas para empreendedores
      </>
    );
  };
  const handleVerificationPrimaryButton = () => {
    if (checkActions(['partner-analyze-credit'])) {
      return null;
    }

    if (!additionalInfoDone) {
      return {
        text: 'Responder',
        id: 'open-additional-info-drawer-button',
        onClick: () => setAdditionalDrawerVisible(true),
      };
    }
    if (partner?.flow === 'badesc') {
      return null;
    }

    return {
      text: 'Visitar o blog Gyra+',
      onClick: () => {
        window.open(process.env.GYRAMAIS_BLOG_URL);
      },
    };
  };

  return (
    <Layout id="request-screen">
      {(['pending', 'review'].includes(currentLoan?.status?.value) || currentLoan?.status?.parent?.value === 'review') && (
        <SubHeader
          title={`Olá, ${firstName}!${width <= 768 ? '\n' : ' '} Confira abaixo a proposta${width > 768 ? '\n' : ' '}que conseguimos para você.`}
        />
      )}

      <Content className="request-content">
        {(!contextLoading) && (
          <>
            {(['check-documents'].includes(currentLoan?.status?.value)) && handleReview().length > 0 && (
              <SuccessMessage
                image={Gif23}
                beforeTitle={handleBeforeTitleMessage()}
                title={handleTitle()}
                subtitle={handleSubTitle()}
                primaryButton={handlePrimaryButton()}
                secondaryButton={handleSecondaryButton()}
              />
            )}
            {
              (
                ['pending', 'review'].includes(currentLoan?.status?.value)
                || currentLoan?.status?.parent?.value === 'review'
              ) && (
                partner?.flow === 'badesc' ? (
                  <>
                    <PreApprovedBadescCard
                      loan={currentLoan}
                      className="request-screen-pre-approved-card"
                      type="success"
                    />
                  </>
                ) : (
                  <>
                    <PreApprovedCard
                      loan={currentLoan}
                      className="request-screen-pre-approved-card"
                      disabled
                      type="success"
                    />
                  </>
                )
              )
            }

            {['integrated', 'verifying', 'score', 'final-verifying'].includes(currentLoan?.status?.value) && (
              <>
                <SuccessMessage
                  loading={meLoading}
                  beforeTitle={additionalInfoDone && 'Obrigado por completar a etapa adicional!'}
                  title={additionalInfoDone ? 'Agora é só aguardar o retorno da sua solicitação' : 'Sua solicitação foi concluída.'}
                  centered
                  withoutPadding
                  subtitle={handleVerificationSubtitle()}
                  primaryButton={handleVerificationPrimaryButton()}
                />

                {!checkActions(['partner-analyze-credit']) && (
                  <BusinessAdditionalInfoDrawer
                    title="Informações adicionais"
                    visible={additionalDrawerVisible}
                    onClose={() => setAdditionalDrawerVisible(false)}
                    onFinish={() => {
                      setAdditionalDrawerVisible(false);
                      setAdditionalInfoDone(true);
                    }}
                  />
                )}
              </>
            )}

            {(currentLoan?.status?.value === 'registered' && handleReview().length === 0) && (
              <SuccessMessage
                title="Sua documentação foi enviada."
                centered
                withoutPadding
                subtitle={(
                  <>
                    Ufa! Agora é com a gente. Vamos fazer a análise final
                    {' '}
                    <b className="secondary-text">em breve</b>
                    . Se estiver faltando algo,
                    {' '}
                    <b className="secondary-text">entramos em contato</b>
                    . Mas se tudo estiver ok,
                    {' '}
                    <b className="secondary-text">vamos enviar o contrato por SMS para TODOS os responsáveis</b>
                    {' '}
                    assinarem. {(currentLoan?.status?.value !== 'check-documents' || handleReview().length !== 0) && (
                      'Enquanto isso leia conteúdos e dicas para empreendedores em nosso blog:')}
                  </>
                )}
                primaryButton={(currentLoan?.status?.value !== 'check-documents' || handleReview().length !== 0) && {
                  id: 'go-to-home-button',
                  text: 'Ir para tela inicial',
                  onClick: () => history.push('/'),
                }}
              />
            )}

            {(currentLoan?.status?.value === 'check-documents' && handleReview().length === 0) && (
              <SuccessMessage
                title="Sua documentação foi revisada e enviada para análise."
                centered
                withoutPadding
                subtitle={(
                  <>
                    Ufa! Agora é com a gente. Vamos fazer a análise final
                    {' '}
                    e se estiver faltando algo,
                    {' '}
                    <b className="secondary-text">entramos em contato</b>
                    . Mas se tudo estiver ok,
                    {' '}
                    <b className="secondary-text">vamos enviar o contrato para TODOS os responsáveis</b>
                    {' '}
                    assinarem.
                  </>
                )}
                primaryButton={{
                  id: 'go-to-blog-button',
                  text: 'Visitar o blog Gyra+',
                  onClick: () => window.open(process.env.GYRAMAIS_BLOG_URL),
                }}
              />
            )}

            {currentLoan?.status?.value === 'awaiting-partner' && (
              <SuccessMessage
                title="Seu cadastro foi concluído."
                centered
                withoutPadding
                subtitle={(
                  <>
                    Fique atento!
                    Em breve, você receberá uma resposta por email ou SMS com o resultado da análise
                    <br />
                    <br />
                    Enquanto isso,  confira nosso blog com dicas exclusivas para empreendedores!
                  </>
                )}
                primaryButton={{
                  text: 'Visitar blog Gyra+',
                  onClick: () => window.open(process.env.GYRAMAIS_BLOG_URL),
                }}
              />
            )}

            {!currentLoan?.id && (stone || partner?.flow === 'stone') && (
              <SuccessMessage
                extraIcon={(<CheckCircleOutlined className="stone-success-icon" />)}
                title="Sua solicitação foi concluída."
                centered
                withoutPadding
                subtitle="Por favor, aguarde que em até 3 dias a Stone enviará um e-mail com sua resposta"
              />
            )}

            {isScoreOnly && (
              <SuccessMessage
                extraIcon={(<CheckCircleOutlined className="stone-success-icon" />)}
                title="Obrigado por compartilhar suas informações conosco"
                centered
                withoutPadding
                primaryButton={{
                  text: 'Visitar blog Gyra+',
                  onClick() {
                    window.open(process.env.GYRAMAIS_BLOG_URL);
                  },
                }}
              />
            )}
          </>
        )}
      </Content>

      {(['pending'].includes(currentLoan?.status?.value)) && (
        <Footer
          loading={meLoading}
          leftContent={(
            <div>
              <p className="no-margin-bottom">
                <b className="white-label-text">
                  <span className="secondary-text">*</span>
                  {/*  eslint-disable-next-line max-len */}
                  Sua proposta poderá sofrer alterações conforme analise de crédito, após envio dos documentos.
                </b>
              </p>
            </div>
          )}
          okButton={{
            id: 'status-continue-button',
            text: 'Aceitar proposta',
            loading: contextLoading,
            onClick: () => history.push('/cadastro/empresa'),
          }}
          cancelButton={currentLoan?.preApprovedOptions?.length > 0 && {
            text: '< Ajustar',
            loading: saveLoanLoading,
            onClick: () => saveLoanEvent({ variables: { loanId: currentLoan.id, statusValue: 'pre-approved' } }),
          }}
        />
      )}
    </Layout>
  );
};

export default RequestScreen;
