import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Checkbox,
} from 'antd';
import {
  Button,
} from 'components/sdk';
import {
  termsOfUseBadesc,
  privacyPolicy,
} from 'utils';

const OptInStep = ({
  acceptedCheckbox,
  setCheckBox,
  setAcceptedCheckbox,
  loading,
  handleAccept,
}) => (
  <>
    <div className="flow-opt-in-content gyra-custom-scroll">
      {termsOfUseBadesc}
      <br />
      <Divider />
      {privacyPolicy}
      <br />
      <Divider />
      <Checkbox
        checked={acceptedCheckbox}
        id="opt-in-checkbox"
        ref={(c) => setCheckBox(c)}
        onChange={({ target: { checked } }) => setAcceptedCheckbox(checked)}
      >
        Estes termos são gerados e assinados eletronicamente,
        tendo as suas autenticidades,
        integridades e validades garantidas na forma da Lei.
        Eu reconheço como válida a assinatura eletrônica desses termos.
      </Checkbox>
    </div>
    <div className="flow-badesc-footer">
      <Button loading={loading} onClick={handleAccept}>
        Aceitar
      </Button>
    </div>
  </>
);

OptInStep.propTypes = {
  acceptedCheckbox: PropTypes.bool,
  setCheckBox: PropTypes.func,
  setAcceptedCheckbox: PropTypes.func,
  loading: PropTypes.bool,
  handleAccept: PropTypes.func,
};

export default OptInStep;
