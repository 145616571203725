import React from 'react';
import PropTypes from 'prop-types';
import { whatsappUrl } from 'utils';
import { Modal } from 'components/sdk';
import './styles.less';
import { Gif25, Success } from 'assets/images';

const IntegrationMotivationModal = ({
  visible,
  onClose,
  title,
  message,
  loading,
  cancelButtonOnlyClose,
  checkListIcon,
  onComplete,
}) => (
  <Modal
    className="no-padding-modal"
    destroyOnClose
    visible={visible}
    centered
    onClose={onClose}
    title={title}
    keyboard
    okButton={{
      id: 'btn-integration-modal-confirm',
      text: 'Continuar solicitação',
      loading,
      onClick: () => {
        onClose?.();
        onComplete?.();
      },
    }}
    cancelButton={
      cancelButtonOnlyClose
        ? {
          text: 'Adicionar outra conta',
          onClick: onClose,
        } : {
          text: 'Dúvidas? Entre em contato!',
          onClick: () => window.open(whatsappUrl, '_blank'),
        }
}
  >
    <div id="integration-motivation-modal">
      <img
        src={checkListIcon ? Success : Gif25}
        alt="mao ou cachorrinho, sei explicar nao rapa"
      />
      <div
        className="body"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  </Modal>
);

IntegrationMotivationModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  cancelButtonOnlyClose: PropTypes.bool,
  checkListIcon: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default IntegrationMotivationModal;
