import EmailVerificationModal from './EmailVerificationModal';
import PolicyOrTermsModal from './PolicyOrTermsModal';
import DeleteVerificationModal from './DeleteVerificationModal';
import IntegrationVideoMotivationModal from './IntegrationVideoMotivationModal';
import ConfirmLoanConfigModal from './ConfirmLoanConfigModal';
import OptInModal from './OptInModal';
import BadescFlowModal from './BadescFlowModal';
import BankSlipModal from './BankSlipModal';
import StoneIntegrationModal from './StoneIntegrationModal';
import MergeModal from './MergeModal';
import TutorialModal from './TutorialModal';
import ScheduleInstallmentsModal from './ScheduleInstallmentsModal';
import DigitalSignTutorialModal from './DigitalSignTutorialModal';
import DocumentExampleModal from './DocumentExampleModal';
import LoanChancesAlertModal from './LoanChancesAlertModal';
import ConfirmRemoveBusinessUserModal from './ConfirmRemoveBusinessUserModal';
import IntegrationMotivationModal from './IntegrationMotivationModal';
import AcquirerIntegrationModal from './AcquirerIntegrationModal';

export {
  EmailVerificationModal,
  DeleteVerificationModal,
  PolicyOrTermsModal,
  IntegrationMotivationModal,
  ConfirmLoanConfigModal,
  OptInModal,
  BadescFlowModal,
  BankSlipModal,
  StoneIntegrationModal,
  MergeModal,
  TutorialModal,
  ScheduleInstallmentsModal,
  DigitalSignTutorialModal,
  DocumentExampleModal,
  LoanChancesAlertModal,
  ConfirmRemoveBusinessUserModal,
  IntegrationVideoMotivationModal,
  AcquirerIntegrationModal,
};
