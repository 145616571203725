// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#user-screen .infos-divider {
  margin: 1.5rem 0;
  margin-bottom: 2rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/UserScreen/styles.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;EACA,8BAAA;AAAJ","sourcesContent":["#user-screen {\n  .infos-divider {\n    margin: 1.5rem 0;\n    margin-bottom: 2rem !important;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
