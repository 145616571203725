// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loan-type-card {
  padding: 1.5rem !important;
  background-color: #ffffff;
  position: relative;
  transition: 0.3s;
}
.loan-type-card .loan-type-card-tag {
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.22rem;
  background-color: var(--warning-color);
}
.loan-type-card .loan-type-card-image-container {
  min-height: 132px;
}
.loan-type-card .gyramais-information {
  margin-bottom: 0 !important;
}
.loan-type-card .loan-type-card-divider {
  margin: 0;
}
.loan-type-card.loan-type-card-selected {
  border-color: var(--primary-button-color);
}
.loan-type-card.loan-type-card-not-selected {
  opacity: 0.3;
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/LoanTypeCard/styles.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AALA;EAOI,iBAAA;EACA,gBAAA;EACA,YAAA;EAEA,sBAAA;EACA,sBAAA;EAEA,sCAAA;AADJ;AAbA;EAkBI,iBAAA;AAFJ;AAhBA;EAsBI,2BAAA;AAHJ;AAnBA;EA0BI,SAAA;AAJJ;AAOE;EACE,yCAAA;AALJ;AAQE;EACE,YAAA;AANJ","sourcesContent":[".loan-type-card {\n  padding: 1.5rem !important;\n  background-color: #ffffff;\n  position: relative;\n  transition: .3s;\n\n  .loan-type-card-tag {\n    font-size: .8rem;\n    font-weight: 500;\n    color: white;\n\n    padding: .2rem .5rem;\n    border-radius: .22rem;\n\n    background-color: var(--warning-color);\n  }\n\n  .loan-type-card-image-container {\n    min-height: 132px;    \n  }\n\n  .gyramais-information {\n    margin-bottom: 0 !important;\n  }\n\n  .loan-type-card-divider {\n    margin: 0;\n  }\n\n  &.loan-type-card-selected {\n    border-color: var(--primary-button-color);\n  }\n\n  &.loan-type-card-not-selected {\n    opacity: .3;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
