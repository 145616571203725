// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-receivable-card {
  padding: 1.5rem;
  min-width: 260px;
}
.integration-receivable-card .integration-icon {
  max-height: 100%;
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .integration-receivable-card .integration-icon {
    max-width: 100%;
    height: 40px;
    width: auto;
  }
}
.integration-receivable-card .gyra-card .gyramais-information {
  margin-bottom: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/PrePaymentScreen/components/IntegrationReceivableCard/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;AAHA;EAKI,gBAAA;EACA,WAAA;EAEA,YAAA;AAAJ;AAEI;EAAA;IACE,eAAA;IACA,YAAA;IAEA,WAAA;EAAJ;AACF;AAfA;EAqBM,2BAAA;AAHN","sourcesContent":[".integration-receivable-card {\n  padding: 1.5rem;\n  min-width: 260px;\n\n  .integration-icon {\n    max-height: 100%;\n    width: 100%;\n\n    height: auto;\n\n    @media (max-width: @md-screen) {\n      max-width: 100%;\n      height: 40px;\n\n      width: auto;\n    }\n\n  }\n\n  .gyra-card {\n    .gyramais-information {\n      margin-bottom: 0 !important;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
