import React, {
  useState,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Alert,
  Divider,
  notification,
  Checkbox,
} from 'antd';
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Information, UploadCard } from 'components';
import { Button } from 'components/sdk';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import { formatAndNotificateError } from 'utils';
import {
  BUSINESS_USERS,
  SAVE_BUSINESS_USER,
  SAVE_BUSINESS,
  ME,
} from './graphql';
import { useSignatureContext } from '../..';

const BusinessUsers = () => {
  const { businessId } = useBusinessLoanContext();
  const {
    setStepVariables,
    setStepHandle,
    setStepLoading,
  } = useSignatureContext();

  const [initialBusinessUserData, setInitialBusinessUserData] = useState([]);
  const [businessUsersData, setBusinessUsersData] = useState([]);
  const [allUsersAreValid, setAllUsersAreValid] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState();

  const {
    data: {
      me: {
        currentBusiness,
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  const [saveBusiness] = useMutation(SAVE_BUSINESS, {
    onError: (error) => formatAndNotificateError(error),
  });

  useQuery(BUSINESS_USERS, {
    fetchPolicy: 'no-cache',
    skip: !businessId,
    variables: { businessId },
    onCompleted: ({ businessUsers }) => {
      setInitialBusinessUserData(businessUsers);
      setBusinessUsersData(Array.from(businessUsers.map((item) => ({ ...item, edit: false }))));

      setAllUsersAreValid(
        businessUsers.every(({ user }) => user.fullName && user.email && user.phoneNumber),
      );
    },
  });

  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: ({ saveBusinessUser }) => {
      const temp = [...initialBusinessUserData];
      const index = temp.findIndex(({ id }) => id === saveBusinessUser.id);
      temp[index] = saveBusinessUser;

      setInitialBusinessUserData(temp);
      setBusinessUsersData(Array.from(temp.map((item, i) => ({
        ...item,
        edit: index === i ? false : businessUsersData[i].edit,
      }))));

      setAllUsersAreValid(
        temp.every(({ user }) => user.fullName && user.email && user.phoneNumber),
      );
    },
    onError: (error) => formatAndNotificateError(error),
  });

  useEffect(() => setStepHandle(undefined), []);
  useEffect(() => setStepLoading(saveLoading), [saveLoading]);
  useEffect(() => setStepVariables({ done: allUsersAreValid && checkboxActive }),
    [allUsersAreValid, checkboxActive]);

  return (
    <Row gutter={[24, 24]} className="business-users-step-container">
      {businessUsersData.length > 0 && !allUsersAreValid && (
        <Col span={24}>
          <Alert
            showIcon
            message="Seus dados estão incompletos. Altere e/ou inclua nome, e-mail e telefone para prosseguir."
            type="warning"
          />
        </Col>
      )}

      {businessUsersData.map(({ id, user, edit }, index) => (
        <Col span={24} key={id}>
          <Row gutter={[16, 24]} className="business-users-step-item" align="middle" justify="space-between">
            <Col xs={24} sm={24} md={24} lg={7}>
              <Information
                label="Nome"
                value={user.fullName}
                editable={edit}
                onChange={({ target: { value } }) => {
                  const temp = Array.from(businessUsersData);
                  temp[index].user = { ...user, fullName: value };

                  setBusinessUsersData(temp);
                }}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={7}>
              <Information
                label="E-mail"
                value={user.email}
                editable={edit}
                onChange={({ target: { value } }) => {
                  const temp = Array.from(businessUsersData);
                  temp[index].user = { ...user, email: value };

                  setBusinessUsersData(temp);
                }}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={7}>
              <Information
                label="Telefone"
                value={user.phoneNumber}
                editable={edit}
                onChange={({ target: { value } }) => {
                  const temp = Array.from(businessUsersData);
                  temp[index].user = { ...user, phoneNumber: value };

                  setBusinessUsersData(temp);
                }}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={3}>
              <Row gutter={8} align="middle" justify="end">
                {edit && (
                  <Col>
                    <Button
                      action
                      icon={<CloseOutlined />}
                      lg
                      disabled={saveLoading}
                      onClick={() => {
                        const temp = [...businessUsersData];

                        const initialItem = {
                          ...initialBusinessUserData
                            .find(({ id: businessUserId }) => businessUserId === id),
                          edit: false,
                        };
                        temp[index] = initialItem;

                        setBusinessUsersData(temp);
                      }}
                    />
                  </Col>
                )}

                <Col>
                  <Button
                    action
                    icon={edit ? <CheckOutlined /> : <EditOutlined />}
                    lg
                    loading={saveLoading}
                    onClick={() => {
                      if (edit) {
                        const { fullName, email, phoneNumber } = user;
                        if (!fullName || !email || !phoneNumber) {
                          notification.error({ message: 'Preencha todos os camps para salvar a alteração' });
                          return;
                        }

                        saveBusinessUser({
                          variables: {
                            id,
                            fullName,
                            email,
                            phoneNumber,
                          },
                        });
                        return;
                      }

                      const temp = [...businessUsersData];
                      temp[index].edit = true;

                      setBusinessUsersData(temp);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Divider className="no-margin" />
            </Col>
          </Row>
        </Col>
      ))}

      <Col span={24}>
        <Alert showIcon message="O documento precisa estar completo, com assinaturas e carimbos da junta comercial." />
      </Col>

      <Col span={24}>
        <UploadCard
          id="upload-file"
          label="Documento contratual da empresa"
          accept="application/pdf"
          actualFile={currentBusiness?.bylaws}
          onCompleted={(bylawsFileId) => {
            saveBusiness({
              variables: {
                id: currentBusiness.id,
                bylawsFileId: bylawsFileId.id,
              },
            });

            setCheckboxActive(false);
          }}
        >
          <Button className="upload-button-wrapper">Enviar arquivo</Button>
        </UploadCard>
      </Col>

      <Col span={24}>
        <Checkbox
          defaultChecked={false}
          checked={checkboxActive}
          onChange={({ target: { checked } = {} }) => setCheckboxActive(checked)}
        >
          <p className="no-margin-bottom">
            <b>
              O contrato social enviado acima é o mais atualizado.
            </b>
          </p>
        </Checkbox>
      </Col>
    </Row>
  );
};

export default {
  id: 'business-users-step',
  title: 'Dados dos sócios',
  component: <BusinessUsers />,
};
