// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opt-in-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.opt-in-content img {
  width: 40%;
}
.opt-in-content .badesc-modal-button {
  text-decoration: underline;
  font-weight: 500;
}
.badesc-terms-container {
  max-height: 50vh;
  overflow-y: auto;
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/OptInModal/optIns/TermsAndPoliciesOptIn/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AALA;EAOI,UAAA;AACJ;AARA;EAWI,0BAAA;EACA,gBAAA;AAAJ;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,qBAAA;AADF","sourcesContent":[".opt-in-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n\n  img {\n    width: 40%;\n  }\n\n  .badesc-modal-button{\n    text-decoration: underline;\n    font-weight: 500;\n  }\n}\n.badesc-terms-container{\n  max-height: 50vh;\n  overflow-y: auto;\n  white-space: pre-wrap;\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
