import { useState, useEffect } from 'react';

/**
 * @param {{
 *  active?: boolean,
 *  words: string[],
 *  ms?: number,
 * }} args
 * @returns
 */
const useWordSlider = ({ active = true, words = [], ms = 1000 }) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!active) return;

    const { length } = words;

    setTimeout(() => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    }, ms);
  }, [active, current]);

  return words[current];
};

export default useWordSlider;
