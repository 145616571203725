// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opt-in-modal .opt-in-logo {
  max-width: 6rem;
}
.opt-in-modal .ant-steps .step-without-title .ant-steps-item-title::after {
  top: 0 !important;
}
.opt-in-modal .steps-content {
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/OptInModal/styles.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;AAAJ;AAOQ;EACE,iBAAA;AALV;AALA;EAiBI,gBAAA;AATJ","sourcesContent":[".opt-in-modal {\n  .opt-in-logo {\n    max-width: 6rem;\n  }\n\n  .ant-steps {\n    \n    .step-without-title {\n      .ant-steps-item-title {\n        &::after {\n          top: 0 !important;\n        }\n      }\n    }\n  }\n\n  .steps-content {\n    margin-top: 1rem;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
