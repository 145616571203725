import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Tabs,
} from 'antd';
import {
  BranchesOutlined,
  ExclamationCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import {
  SubHeader,
  Footer,
} from 'components/gyramais';
import {
  Layout,
  Button,
} from 'components/sdk';
import {
  AccountCard,
  IntegrationVideoMotivationModal,
} from 'components';
import {
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import ConnectorsList from 'components/others/ConnectorsList';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  INTEGRATIONS,
  ME,
  BANK_ACCOUNTS,
} from './graphql';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {
  isVideoMotivationalSeen,
} from '../../services/graphql/cache';

import './styles.less';

const { Content } = Layout;
const { TabPane } = Tabs;

const EnablePreApprovedScreen = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('1');
  const { width } = useWindowDimensions();
  const { currentContext } = useBusinessLoanContext();

  const validationModalSeen = useReactiveVar(isVideoMotivationalSeen);

  const {
    loading: meLoading,
    data: {
      me: {
        currentBusiness: {
          id: businessId,
        } = {},
      } = {},
    } = {},
  } = useQuery(ME);

  const {
    data: {
      integrations = [],
    } = {},
    refetch: refetchIntegration,
  } = useQuery(INTEGRATIONS, { variables: { businessId } });

  const {
    data: {
      bankAccounts = [],
    } = {},
    refetch: refetchBankAccount,
  } = useQuery(BANK_ACCOUNTS, { variables: { businessId } });

  const closeMotivationModal = () => {
    isVideoMotivationalSeen(true);
    localStorage.setItem('motivationalModalSeen', true);
  };

  useEffect(() => {
    if (!currentContext?.loan) {
      return false;
    }

    const { loan } = currentContext;
    if (loan.status?.value !== 'pre-approved') {
      return history.push('/');
    }

    return true;
  }, [currentContext?.loan]);

  return (
    <Layout id="first-integration-screen">
      <SubHeader
        title="Vamos liberar sua proposta?"
        subtitle="Ao comprovar seu faturamento, conseguimos garantir as condições da proposta. Verifique nas opções abaixo quais contas sua empresa usa, conecte com o maior número possível e nós iremos te avisar assim que finalizarmos a análise."
        loading={meLoading}
        buttonsPlacement="bottom"
        buttons={(
          <Button
            onClick={() => {
              isVideoMotivationalSeen(!validationModalSeen);
            }}
            icon={<PlayCircleOutlined className="white-label-text" />}
            color="white"
            link
          >
            <span className="white-label-text underline">Assista nosso vídeo explicativo sobre Plataformas!</span>
          </Button>
          )}
      />

      <Content>
        <Tabs
          activeKey={activeKey}
          animated
          onChange={(e) => setActiveKey(e)}
          className="tabs"
        >
          <TabPane tab={(<h5 className="no-margin-bottom">Lista de plataformas</h5>)} key="1">
            <ConnectorsList
              refetchBankAccount={refetchBankAccount}
              refetchIntegration={refetchIntegration}
              handleSendSolicitation={() => history.push('/cadastro/pre-aprovado')}
              sendSolicitation
            />
          </TabPane>

          <TabPane
            tab={(
              <h5 className={`no-margin-bottom ${(integrations.length + bankAccounts.length) === 0 ? ' gray-text' : ''}`}>
                Conectadas
                {' '}
                (
                {integrations.length + bankAccounts.length}
                )
              </h5>
            )}
            disabled={integrations.length === 0 && bankAccounts.length === 0}
            key="2"
          >
            {integrations.map(({
              id, credentials, type, removedAt,
            }) => (
              <AccountCard
                id={id}
                key={id}
                status={{ name: `${removedAt ? 'REINTEGRAR' : 'CONECTADA'}`, value: `${removedAt ? 'review' : 'active'}` }}
                type="Integration"
                typeInfo={type}
                removedAt={removedAt}
                platformName={type.name}
                logo={type.icon?.url}
                credential={JSON.parse(credentials)}
                refetch={refetchIntegration}
                onRemove={refetchIntegration}
              />
            ))}
            {bankAccounts.map(({
              id, bank, branchCode, accountNumber,
            }) => (
              <AccountCard
                id={id}
                key={id}
                status={{ name: 'CONECTADA', value: 'active' }}
                type="BankAccount"
                platformName={bank?.name}
                logo={bank?.logo?.url}
                credential={{
                  branchCode,
                  accountNumber,
                }}
                refetchBankAccount={refetchBankAccount}
                bypass
                onRemove={refetchBankAccount}
              />
            ))}
          </TabPane>
        </Tabs>
      </Content>

      <Footer
        leftContent={width > 768 && (
          <div>
            <h2 className="no-margin-bottom">
              <strong className="white-label-text">
                SUAS CONTAS
              </strong>
            </h2>

            <div className="your-accounts-container">
              <h1 className="secondary-text no-margin-bottom">
                <BranchesOutlined />
              </h1>

              <h1 className="white-label-text no-margin-bottom your-accounts-container-label">
                {integrations.length + bankAccounts.length}
              </h1>

              <h5 className="white-label-text no-margin-bottom">
                CONTAS
                <br />
                CONECTADAS
              </h5>
            </div>
          </div>
        )}
        middleContent={width > 768 && (
          <div>
            <p className="no-margin-bottom">
              <b className="white-label-text">
                <ExclamationCircleOutlined className="secondary-text footer-icon" />
                Comprove seu faturamento para liberar sua proposta
              </b>
            </p>
          </div>
        )}
        okButton={{
          text: 'Liberar proposta',
          onClick: () => history.push('/cadastro/pre-aprovado'),
          id: 'enable-pre-approve-button',
          disabled: bankAccounts.length + integrations.length === 0,
          className: (integrations.length === 0 && bankAccounts.length === 0) ? '' : 'pulse-animation',
          block: width <= 768,
        }}
        cancelButton={{
          onClick: () => history.push('/cadastro/pre-aprovado'),
        }}
      />

      <IntegrationVideoMotivationModal
        title="Mantenha-se! A Gyra+ não tem acesso aos seus dados."
        onClose={closeMotivationModal}
        visible={!meLoading && !validationModalSeen}
      />
    </Layout>
  );
};

export default EnablePreApprovedScreen;
