// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spin-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spin-indicator span,
.spin-indicator span svg {
  text-align: center;
  width: 120px;
  height: 120px;
  fill: var(--primary-button-color);
}
.spin-indicator span {
  margin-bottom: 2.5rem;
}
.spin-indicator h3 {
  color: var(--primary-button-color);
  margin-bottom: 0px;
}
.spin-indicator h3,
.spin-indicator p {
  font-size: 1.5rem !important;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/screens/PrePaymentScreen/components/SpinIndicator/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AALA;;EAOI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,iCAAA;AAEJ;AAZA;EAaI,qBAAA;AAEJ;AAfA;EAgBI,kCAAA;EACA,kBAAA;AAEJ;AAnBA;;EAoBI,4BAAA;EACA,gBAAA;AAGJ","sourcesContent":[".spin-indicator{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  span, span svg{\n    text-align: center;\n    width: 120px;\n    height: 120px;\n    fill: var(--primary-button-color);\n  }\n  span{\n    margin-bottom: 2.5rem;\n  }\n  h3{ \n    color: var(--primary-button-color);\n    margin-bottom: 0px;\n  }\n  h3,p{\n    font-size: 1.5rem !important;\n    font-weight: 400;\n  }\n\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
