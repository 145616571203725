// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blur-container {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF7d;
  border-radius: 3rem;
  backdrop-filter: blur(1.5px);
  z-index: 2;
}
.blur-container .blur-content {
  max-width: 331px;
  text-align: center;
}
.blur-container.active {
  pointer-events: all;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/screens/PrePaymentScreen/components/BlurWithMessage/styles.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EAEA,UAAA;EACA,gBAAA;EAEA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,2BAAA;EACA,mBAAA;EACA,4BAAA;EAEA,UAAA;AAJF;AAjBA;EAwBI,gBAAA;EACA,kBAAA;AAJJ;AAOE;EACE,mBAAA;EAEA,UAAA;AANJ","sourcesContent":[".blur-container {\n  pointer-events: none;\n  cursor: not-allowed;\n  \n  opacity: 0;\n  transition: .2s;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  background-color: #FFFFFF7d;\n  border-radius: 3rem;\n  backdrop-filter: blur(1.5px);\n\n  z-index: 2;\n \n  .blur-content {\n    max-width: 331px;\n    text-align: center;\n  }\n\n  &.active {\n    pointer-events: all;\n    \n    opacity: 1;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
