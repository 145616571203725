// @ts-check
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import {
  notification,
  Skeleton,
} from 'antd';
import {
  SubHeader,
} from 'components/gyramais';
import {
  Layout,
} from 'components/sdk';
import {
  LoanCard,
  NewLoanDrawer,
  BankAccountDrawer,
  SignatureDrawer,
  BankSlipModal,
} from 'components';
import {
  formatAndNotificateError, memoizeValues, whatsappUrl,
} from 'utils';
import {
  useQuery, useSubscription, useMutation, useReactiveVar,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useURL } from 'utils/hooks/useURL';
import { meVar } from 'services/graphql/cache';
import PayInvoiceModal from 'components/modals/PayInvoiceModal';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  ME,
  LOANS,
  LOAN_UPDATED,
  SAVE_INVOICE,
  LOAN_AVAILABLE_FOR_PROMO_RENEGOTIATE,
} from './graphql';

import './styles.less';
import useTitle from './hooks/useTitle';
import useSubtitle from './hooks/useSubtitle';
import HomeSubHeaderButton from './components/HomeSubHeaderButton';

dayjs.extend(utc);

const { Content } = Layout;

const activeStatuses = ['active', 'active-renegotiated'];

// ordena as loans baseadas na prioridade dos status
const sortLoans = (unsortedLoans) => {
  if (!unsortedLoans) {
    return unsortedLoans;
  }

  const cloneLoans = [...unsortedLoans];

  cloneLoans.sort((a, b) => {
    const statusA = a.status.value;
    const statusB = b.status.value;

    if (statusA === statusB) {
      return 0;
    }

    if (['active', 'active-renegotiated', 'awaiting-signature-additive'].includes(statusA)) {
      return -1;
    }

    if (['active', 'active-renegotiated', 'awaiting-signature-additive'].includes(statusB)) {
      return 1;
    }

    if (['give-up', 'canceled'].includes(statusA)) {
      return 1;
    }

    if (['give-up', 'canceled'].includes(statusB)) {
      return -1;
    }

    return 0;
  });

  return cloneLoans;
};

const HomeScreen = () => {
  const history = useHistory();
  const { currentContext } = useBusinessLoanContext();

  const [newLoanVisible, setNewLoanVisible] = useState(false);
  const [bankAccountVisible, setBankAccountVisible] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);
  const [bankSlipModalVisible, setBankSlipModalVisible] = useState(false);
  const [paymentInvoiceModalVisible, setPayInvoiceModalVisible] = useState(false);
  const [lateInstallment, setLateInstallment] = useState(undefined);
  const [lateInstallments, setLateInstallments] = useState([]);
  const [nextPendingInstallment, setNextPendingInstallment] = useState(undefined);
  const [nextInvoice, setNextInvoice] = useState(null);
  const [loan, setLoan] = useState();
  const me = useReactiveVar(meVar);
  const { removeQueryParam, action } = useURL();

  const aux = new Date(nextPendingInstallment && nextPendingInstallment.dueDate);
  const invoiceDueDate = new Date(
    aux.getUTCFullYear(),
    aux.getUTCMonth(),
    aux.getUTCDate(), 0, 0, 0,
  );

  const filters = {
    late: (i) => i.values.penalty.debitDays > 0 && i.values.status.value === 'late',
    pending: (i) => i.values.penalty.debitDays === 0 && i.values.status.value === 'pending',
    paid: (i) => i.values.status.value === 'paid',
  };

  const {
    data: {
      me: {
        // @ts-ignore
        isBureau,
        currentBusiness: {
          // @ts-ignore
          id: businessId,
        } = {},
      } = {},
    } = {},
    loading: meLoading,
  } = useQuery(ME);

  const {
    data: {
      loanAvailableForPromoRenegotiate,
    } = {},
    loading: promoLoanLoading,
  } = useQuery(LOAN_AVAILABLE_FOR_PROMO_RENEGOTIATE, { fetchPolicy: 'cache-and-network' });

  const {
    cashPaymentRealDiscount,
    installmentPaymentRealDiscount,
    chargePolicy: {
      // @ts-ignore
      noDiscount: noDiscountPolicy,
    } = {},
  } = loanAvailableForPromoRenegotiate || {};

  const discount = useMemo(
    () => Math.floor((cashPaymentRealDiscount || installmentPaymentRealDiscount) * 100),
    [cashPaymentRealDiscount, installmentPaymentRealDiscount],
  );

  // somente mostrar o subheader de promo se já estiver na loan eleita a ser renegociada
  const showPromoSubHeader = loanAvailableForPromoRenegotiate?.business?.id === businessId
    && loanAvailableForPromoRenegotiate?.partner?.id === currentContext?.partner?.id
    && (discount || noDiscountPolicy);

  const {
    data: {
      loans: unsortedLoans = [],
    } = {},
    refetch,
    loading: loadingLoans,
  } = useQuery(LOANS, {
    skip: !businessId || !meVar(),
    variables: {
      businessId,
      sort: [
        { descending: 'createdAt' },
        { descending: 'number' },
      ],
      partnerId: currentContext?.partner?.id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ loans: unsortedLoans }) => {
      const loans = sortLoans(unsortedLoans);
      const loan = loans[0];

      if (loan?.installments) {
        const lateInstallment = loan.installments.find(filters.late);
        const nextInstallment = loan.installments.find(filters.pending);
        setLateInstallment(lateInstallment);
        setLateInstallments(loan.installments.filter(filters.late));
        setNextPendingInstallment(nextInstallment);
        if (lateInstallment || nextInstallment) {
          const nextInvoices = lateInstallment?.invoices || nextInstallment.invoices;
          setNextInvoice(
            nextInvoices.find((i) => !i.canceledAt
              && new Date(new Date(i.dueDate).setHours(0, 0, 0, 0))
              >= new Date(new Date().setHours(0, 0, 0, 0))),
          );
        }
      }
    },
  });

  const loans = useMemo(
    () => sortLoans(unsortedLoans),
    [unsortedLoans],
  );

  const meSignature = useMemo(
    () => loans?.[0]?.signatures?.find(({ user }) => user?.id === me?.id),
    [loans, me],
  );

  const alreadySigned = meSignature && meSignature.signedAt;
  const needToSign = meSignature && !meSignature.signedAt;

  const downPaymentInvoice = loans?.[0]?.downPaymentInvoice;

  const pendingDownPaymentInvoice = downPaymentInvoice && !downPaymentInvoice?.paidAt
    ? downPaymentInvoice
    : undefined;

  const useSignatureDrawer = useMemo(() => {
    const hasLoanWithSignature = loans.some(
      (loan) => [
        'awaiting-signature',
        'awaiting-signature-additive',
        'signed',
      ].includes(loan.status.value),
    );

    if (!hasLoanWithSignature) {
      return false;
    }

    if (!needToSign && !alreadySigned) {
      return false;
    }

    return true;
  }, [loans, alreadySigned, needToSign]);

  useSubscription(LOAN_UPDATED, {
    variables: { ids: loans.map(({ id }) => id) },
    onSubscriptionData: () => refetch(),
  });

  const [saveInvoice, { loading: confirmLoading }] = useMutation(SAVE_INVOICE, {
    onCompleted: async ({ saveInvoice = {} }) => {
      setNextInvoice(saveInvoice);
      navigator.clipboard.writeText(saveInvoice.barcode);
      notification.success({ message: 'Código de Barras copiado com sucesso' });
      refetch();
    },
    onError: (error) => formatAndNotificateError(error),
  });
  const flow = currentContext?.partner?.flow;
  const badescPortfolios = ['Pronampe Emergencial SE', 'Pronampe Emergencial CP', 'Pronampe Geral', 'Pronampe Mulher'];
  const isBadesc = loans?.some((loan) => badescPortfolios.includes(loan?.portfolioName));

  const actions = loans[0]?.partner?.actions;

  const awaitingSignatureLoan = loans.find(
    (loan) => ['awaiting-signature', 'awaiting-signature-additive'].includes(loan.status.value),
  );

  const openSignatureDrawer = (loan) => {
    setLoan(loan || awaitingSignatureLoan);
    setSignatureVisible(true);
  };

  useEffect(() => {
    if (pendingDownPaymentInvoice && action === 'entrada') {
      setPayInvoiceModalVisible(true);
      removeQueryParam('action');
    }
  }, [action, pendingDownPaymentInvoice]);

  const subtitle = useSubtitle(
    memoizeValues({
      activeStatuses,
      awaitingSignatureLoan,
      flow,
      actions,
      isBureau,
      lateInstallment,
      lateInstallments,
      loans,
      nextPendingInstallment,
      pendingDownPaymentInvoice,
      showPromoSubHeader,
      noDiscountPolicy,
    }),
  );

  const title = useTitle(
    memoizeValues({
      activeStatuses,
      alreadySigned,
      awaitingSignatureLoan,
      flow,
      actions,
      isBureau,
      lateInstallment,
      lateInstallments,
      loans,
      needToSign,
      pendingDownPaymentInvoice,
      showPromoSubHeader,
      noDiscountPolicy,
    }),
  );

  /**
   * @param {import('./components/HomeSubHeaderButton').HomeBtnAction} action
   */
  const handleOnClick = async (action) => {
    switch (action) {
      case 'CopyBarcode':
        if (!nextInvoice) {
          await saveInvoice({
            variables: {
              installmentId: lateInstallment
                ? lateInstallment?.id
                : nextPendingInstallment?.id,
            },
          });
        } else {
          navigator.clipboard.writeText(nextInvoice.barcode);
          notification.success({ message: 'Código de Barras copiado com sucesso' });
        }
        break;

      case 'PayDownPayment':
        setPayInvoiceModalVisible(true);
        break;

      case 'WinDiscount':
        history.push('/promo-renegociacao');
        break;

      case 'SpeakWithUs':
        window.open(whatsappUrl);
        break;

      case 'SignContract':
        openSignatureDrawer();
        break;

      case 'SendBankReceipt':
        setBankAccountVisible(true);
        break;

      case 'NewLoan':
        setNewLoanVisible(true);
        break;

      case 'OpenBlog':
        window.open(process.env.GYRAMAIS_BLOG_URL);
        break;

      case 'CompleteRegister':
        history.push('/cadastro/status');
        break;

      case 'PayInvoice':
        setBankSlipModalVisible(true);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {/** @ts-ignore  VAI TOMAR NO CU TS */}
      <Layout id="home-screen">
        <SubHeader
          title={title}
          subtitle={subtitle}
          buttons={(
            <HomeSubHeaderButton
              loans={loans}
              pendingDownPaymentInvoice={pendingDownPaymentInvoice}
              flow={flow}
              showPromoSubHeader={showPromoSubHeader}
              confirmLoading={confirmLoading}
              installmentPaymentRealDiscount={installmentPaymentRealDiscount}
              cashPaymentRealDiscount={cashPaymentRealDiscount}
              discount={discount}
              awaitingSignatureLoan={awaitingSignatureLoan}
              invoiceDueDate={invoiceDueDate}
              isBureau={isBureau}
              needToSign={needToSign}
              noDiscountPolicy={noDiscountPolicy}
              onClick={handleOnClick}
            />
          )}
          buttonsPlacement="bottom"
          loading={loadingLoans || meLoading || promoLoanLoading}
        />

        {/** @ts-ignore  VAI TOMAR NO CU TS */}
        <Content className="main-content">
          {loadingLoans ? (
            <Skeleton.Button
              active
              className="home-loading"
              shape="square"
              size="large"
            />
          ) : (
            <>
              {loans?.map((loan, index) => (
                <LoanCard
                  flow={flow}
                  index={index}
                  key={loan?.id}
                  loan={loan}
                  refetch={refetch}
                  onClickSign={() => openSignatureDrawer(loan)}
                />
              ))}
            </>
          )}
        </Content>
        <NewLoanDrawer
          bypass={
            loans.length === 0 || loans?.[0]?.partner?.actions.some((action) => action.value === 'payment-upload' || action.value === 'order-upload')
          }
          visible={newLoanVisible}
          onClose={() => setNewLoanVisible(false)}
        />
        {loans?.[0]?.status?.value === 'awaiting-re-evaluation-tm-volume' && (
          <BankAccountDrawer
            visible={bankAccountVisible}
            refetch={refetch}
            onClose={() => setBankAccountVisible(false)}
          />
        )}

        {useSignatureDrawer && (
          <SignatureDrawer
            isBadesc={isBadesc}
            loan={loan}
            refetch={refetch}
            visible={signatureVisible}
            onClose={() => {
              setSignatureVisible(false);
            }}
          />
        )}

        {loans?.[0]?.status?.value === 'awaiting-payment' && (
          <BankSlipModal
            visible={bankSlipModalVisible}
            loan={loans?.[0]}
            onClose={() => {
              setBankSlipModalVisible(false);
            }}
          />
        )}

        <PayInvoiceModal
          visible={paymentInvoiceModalVisible}
          onClose={() => setPayInvoiceModalVisible(false)}
          invoiceId={downPaymentInvoice?.id}
          loanId={loans?.[0]?.id}
        />
      </Layout>
    </>
  );
};

export default HomeScreen;
