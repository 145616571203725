import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'components/sdk';
import {
  termsOfUse,
  privacyPolicy,
} from 'utils';
import './styles.less';

const PolicyOrTermsModal = ({ type = 'terms', visible, onClose }) => (
  <Modal
    style={{ whiteSpace: 'pre-line' }}
    title={type === 'policy' ? 'Política de Privacidade' : 'Termos de Uso'}
    centered
    visible={visible}
    onClose={onClose}
    okButton={{
      text: 'Fechar',
      onClick: onClose,
    }}
  >
    <div className="policy-or-terms-container">
      <p>
        {type === 'policy' ? privacyPolicy : termsOfUse}
      </p>
    </div>
  </Modal>
);

PolicyOrTermsModal.propTypes = {
  type: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(PolicyOrTermsModal);
