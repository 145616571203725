import VALIDATE_TOKEN from './validateToken.gql';
import REMOVE_SESSION from './removeSession.gql';
import SET_LOGGED_OUT from './setLoggedOut.gql';
import LOG_OUT from './logOut.gql';
import ADD_SESSION from './addSession.gql';

export {
  VALIDATE_TOKEN,
  REMOVE_SESSION,
  SET_LOGGED_OUT,
  LOG_OUT,
  ADD_SESSION,
};
