// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyramais-avatar {
  background-color: transparent;
  color: var(--primary-button-color);
  font-weight: 300;
  border: 2px dashed var(--primary-button-color);
}
.gyramais-avatar.gyramais-avatar-with-image {
  border: none;
}
.gyramais-avatar.gyramais-avatar-with-icon {
  border: none;
  background-color: var(--primary-button-color);
  color: var(--primary-button-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/sdk/Avatar/styles.less"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,kCAAA;EACA,gBAAA;EAEA,8CAAA;AAAF;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,YAAA;EAEA,6CAAA;EACA,uCAAA;AADJ","sourcesContent":[".gyramais-avatar {\n  background-color: transparent;\n  color: var(--primary-button-color);\n  font-weight: 300;\n  \n  border: 2px dashed var(--primary-button-color);\n\n  &.gyramais-avatar-with-image {\n    border: none;\n  }\n  &.gyramais-avatar-with-icon {\n    border: none;\n\n    background-color: var(--primary-button-color);\n    color: var(--primary-button-text-color);\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
