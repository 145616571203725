import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  notification,
} from 'antd';
import {
  formatAndNotificateError as formatAndNotifyError,
} from 'utils';
import { PlatformCard } from 'components/gyramais';
import { useQuery, useMutation } from '@apollo/client';
import { PluggyConnect } from 'react-pluggy-connect';
import { Images } from 'assets';
import { BankAccountDrawer } from 'components/drawers';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  SAVE_INTEGRATION,
  PLUGGY_CONNECT_TOKEN,
  PLUGGY_TYPE_ID,
} from './graphql';
import {
  INTEGRATIONS,
} from '../../../screens/FirstIntegrationScreen/graphql';

import './styles.less';
import ByAnnualDrawer from './components/ByAnnualDrawer';

const { Dollar } = Images;

const IntegrationTypesList = ({
  integrationType: selectedIntegrationType,
  onSet: onChooseIntegration,
  refetchBankAccount,
  connectors = [],
  businessRequestsIds,
  refetchBusinessRequests,
  forceComplete,
}) => {
  const [accessToken, setAccessToken] = useState();
  const [isBankOpen, setIsBankOpen] = useState(false);
  const [isByAnnualOpen, setIsByAnnualOpen] = useState(false);
  const [connectorIdToPluggy, setConnectorIdToPluggy] = useState();
  const [selectedBank, setSelectedBank] = useState(null);

  const { checkActions, loadingActions } = usePartnerActionsContext();
  const { currentContext, businessId, isBusinessRegistrationCompleted } = useBusinessLoanContext();

  const onlyByAnnualBilling = checkActions(['show-only-bi-anual-billing']);

  const { data: { pluggyTypeId = '' } = {} } = useQuery(PLUGGY_TYPE_ID);
  const [pluggyConnectToken] = useMutation(PLUGGY_CONNECT_TOKEN, {
    onCompleted: ({ pluggyConnectToken }) => {
      setAccessToken(pluggyConnectToken);
    },
    onError: ({ message } = {}) => formatAndNotifyError({
      message: message.includes('400') ? message : 'Ocorreu um erro interno! Por favor tente novamente mais tarde!',
    }),
  });

  const [saveIntegration] = useMutation(SAVE_INTEGRATION, {
    onCompleted: async () => {
      notification.success({
        message: 'Conta cadastrada com sucesso.',
        duration: 0,
      });
      await refetchBusinessRequests?.();
    },
    onError: (error) => {
      setAccessToken();
      formatAndNotifyError(error);
    },
    refetchQueries: [{ query: INTEGRATIONS, variables: { businessId } }],
  });

  const hasToShowBankButton = () => {
    if (loadingActions || checkActions(['only-score'])) {
      return false;
    }

    if (selectedIntegrationType) {
      if (selectedIntegrationType === 'Banco') {
        return true;
      }
      return false;
    }

    if (!connectors[0]) {
      return false;
    }

    return true;
  };

  const handleConnectorClick = (integration) => {
    const isPluggy = !!integration.connectorId;
    const isBankWithOCR = integration.type === 'Banco PDF';

    if (isPluggy) {
      pluggyConnectToken();

      setConnectorIdToPluggy(integration.connectorId);
      return;
    }

    if (isBankWithOCR) {
      setIsBankOpen(true);
      setSelectedBank({ ...integration, code: integration.value });
      return;
    }

    onChooseIntegration(integration);
  };
  useEffect(() => {
    const loan = currentContext?.loan;
    const loanStatus = loan?.status?.value;

    if (onlyByAnnualBilling && loanStatus === 'new') {
      setIsByAnnualOpen(true);
    }
  }, [onlyByAnnualBilling, currentContext]);

  return (
    <>
      <div id="integrations-container">
        <PlatformCard
          onClick={() => setIsByAnnualOpen(true)}
          iconComponent={<Dollar width={50} height={50} className="upload-bank-icon" />}
          description="Extrato Bancário Bi-Anual"
          className="platform-card upload-bank"
          id="integration-bank-account"
        />

        {hasToShowBankButton() && (
          <PlatformCard
            onClick={() => setIsBankOpen(true)}
            iconComponent={<Dollar width={50} height={50} className="upload-bank-icon" />}
            description={`Extrato Bancário
             em PDF`}
            className="platform-card upload-bank"
            id="integration-bank-account"
          />
        )}

        {connectors.map((integration) => (
          <PlatformCard
            key={integration.id}
            onClick={() => handleConnectorClick(integration)}
            logo={integration.icon}
            name={integration.name}
            className={`platform-card ${businessRequestsIds?.includes(integration.id) ? 'requested' : ''} `}
            id={`integration-${integration.value}`}
            description={integration.description}
          />
        ))}

        {Array.from({ length: 10 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="jutso-das-sombras platform-card" />
        ))}

        {accessToken && (
          <PluggyConnect
            connectToken={accessToken}
            selectedConnectorId={connectorIdToPluggy}
            onClose={() => {
              setAccessToken();
            }}
            onSuccess={({ item }) => {
              saveIntegration({
                variables: {
                  businessId,
                  typeId: pluggyTypeId,
                  credentials: JSON.stringify(item),
                },
              });
            }}
            onError={({ data: { item = {} } = {} }) => {
              const { status, executionStatus } = item;

              if (
                ['UPDATING', 'UPDATED'].includes(status)
                || (
                  status === 'OUTDATED'
                  && ['WAITING_USER_INPUT', 'USER_AUTHORIZATION_PENDING'].includes(executionStatus)
                )
              ) {
                return saveIntegration({
                  variables: {
                    businessId,
                    typeId: pluggyTypeId,
                    credentials: JSON.stringify(item),
                  },
                });
              }

              return true;
            }}
            countries={['BR']}
            language="pt"
          />
        )}
      </div>

      <ByAnnualDrawer
        visible={isByAnnualOpen && isBusinessRegistrationCompleted}
        onClose={() => {
          if (!onlyByAnnualBilling) {
            setIsByAnnualOpen(false);
          }
        }}
        onComplete={() => {
          if (onlyByAnnualBilling) {
            forceComplete();
          }
        }}
      />

      <BankAccountDrawer
        visible={isBankOpen}
        integrationScreen
        refetch={refetchBankAccount}
        selectedBank={selectedBank}
        onClose={() => {
          setIsBankOpen(false);
        }}
        onSelectMain={() => {
          setIsBankOpen(false);
        }}
        disableOpenBanks
      />
    </>
  );
};

IntegrationTypesList.propTypes = {
  onSet: PropTypes.func,
  refetchBankAccount: PropTypes.func,
  integrationType: PropTypes.string,
  connectors: PropTypes.arrayOf(PropTypes.shape({})),
  businessRequestsIds: PropTypes.arrayOf(PropTypes.string),
  refetchBusinessRequests: PropTypes.func,
};

export default IntegrationTypesList;
