import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
} from 'components/gyramais';
import {
  Modal,
  Button,
} from 'components/sdk';
import { ImagesCarousel } from 'components';

const DocumentExampleModal = ({
  visible, onClose, alert, imagesArray, footer = (
    <Button onClick={onClose}>
      Fechar
    </Button>
  ),
}) => (
  <Modal
    title="Envie a foto do seu documento de acordo com os exemplos abaixo"
    centered
    visible={visible}
    onCancel={onClose}
    footer={footer}
    className="document-example-modal"
  >
    {Boolean(alert) && (
    <Alert
      className="upload-card-alert"
      message={alert}
    />
    )}
    <ImagesCarousel imagesArray={imagesArray} />
  </Modal>
);

DocumentExampleModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  imagesArray: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  footer: PropTypes.element,
};

export default memo(DocumentExampleModal);
