import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spin,
} from 'antd';
import ReactCodeInput from 'react-verification-code-input';

import './styles.less';

const SignatureForm = ({
  signatureLoading,
  setVerificationCode,
  isBadesc,
}) => (
  <Spin id="signature-form" spinning={signatureLoading}>
    <div className="signature-form-body">
      <h4>
        Assinatura
        {' '}
        {isBadesc ? 'digital' : 'eletrônica'}
      </h4>
      <Row>
        <Col md={12}>
          <p>
            <b>
              Um código foi gerado e enviado para o seu celular.
              <br />
              Insira o código abaixo para confirmar a sua assinatura
              {' '}
              {isBadesc ? 'digital' : 'eletrônica'}
              .
            </b>
          </p>
        </Col>
      </Row>

      <Row className="code-row">
        <Col md={24}>
          <ReactCodeInput
            type="number"
            id="verification-code-input"
            inputMode="tel"
            fields={6}
            onChange={(value) => setVerificationCode(value.toString())}
            autoFocus
          />
        </Col>
      </Row>
    </div>
  </Spin>
);

SignatureForm.propTypes = {
  signatureLoading: PropTypes.bool,
  setVerificationCode: PropTypes.func,
  isBadesc: PropTypes.bool,
};
export default SignatureForm;
