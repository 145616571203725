import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import queryString from 'query-string';
import {
  Alert,
  Input,
  LogInScreenWrapper,
  LoginWithLinkForm,
  LostPasswordForm,
  PasswordForm,
  UserTag,
} from 'components';
import { loggedOutVar, meVar } from 'services/graphql/cache';
import {
  Divider,
  Form,
  notification,
} from 'antd';
import { changeColors, isBrightColor } from 'utils/colors';
import { formatAndNotificateError, formatError, formatString } from 'utils';
import { Button } from 'components/sdk';
import {
  ExclamationCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import {
  PARTNER,
  REMOVE_SESSION,
  SET_LOGGED_OUT,
  LOG_IN,
  ADD_SESSION,
  EMAIL_IS_VERIFIED,
  SEND_EMAIL_LOGIN_WITH_LINK,
  VALIDATE_SELLER,
  VALIDATE_SELLER_USER,
  SAVE_SELLER_USER_FROM_LOGIN,
} from './graphql';
import {
  BusinessUserForm,
  CnpjForm,
  UserForm,
} from './forms';

import './styles.less';

const { useForm, Item } = Form;
const STEPS = {
  'ENTER-CNPJ': 0,
  'ENTER-EMAIL': 1,
  'CREATE-ACCOUNT': 2,
  'SELECT-TYPE': 3,
  'ENTER-PASSWORD': 4,
  'CREATE-PASSWORD': 5,
  'FORGET-PASSWORD': 6,
  'LOGIN-WITHOUT-PASSWORD': 7,
};

const LogInScreen = () => {
  // 🤪🤪🤪🤪🤪🤪🤪🤪🤪🤪🤪🤪
  const [session] = useState();
  const [whitelabel, setWhitelabel] = useState(false);
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEPS['ENTER-EMAIL']);
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [setLoggedOut, { loading }] = useMutation(SET_LOGGED_OUT);
  const [entryPassword, setEntryPassword] = useState(0);

  const [sellerId, setSellerId] = useState();
  const [user, setUser] = useState();
  const [sellerUserType, setSellerUserType] = useState();

  const me = meVar();

  const history = useHistory();
  const location = useLocation();
  const [cnpjForm] = useForm();
  const [emailForm] = useForm();
  const [accountForm] = useForm();
  const [passwordForm] = useForm();

  const { loggedOut } = loggedOutVar();

  const {
    id,
    deleted,
    cnpj,
    email = '',
    cpf,
    code,
    firstName = '',
    redirect,
    input,
    'verification-code': verificationCode,
    'update-password-token': updatePasswordToken,
  } = queryString.parse(location.search);

  const [isCpfInput, setIsCpfInput] = useState(input === 'cpf');
  const [ready, setReady] = useState(!id);
  const [cnpjIsPreset] = useState(!!cnpj);

  const { data: { partner = {} } = {}, loading: loadingPartner } = useQuery(PARTNER, {
    skip: !id,
    variables: { id },
    onCompleted: (response) => {
      if (!response) {
        return;
      }
      const {
        appColors,
        darkLogo,
        lightLogo,
        activationFlowInApp,
      } = response?.partner || {};

      if (appColors) {
        setWhitelabel(true);
        changeColors(appColors);

        if (isBrightColor(appColors.primary)) {
          setPartnerLogo(darkLogo);
        } else {
          setPartnerLogo(lightLogo);
        }
      }

      if (activationFlowInApp) {
        setCurrentStep(STEPS['ENTER-CNPJ']);
      }

      setReady(true);
    },
  });

  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => {
      history.push(redirect || '/', {
        params: {
          loggedInNow: true,
        },
      });
    },
  });

  const [logIn, { loading: loadingLogIn }] = useMutation(LOG_IN, {
    onCompleted: ({ logIn }) => {
      addSession({
        variables: {
          me: logIn,
        },
      });
    },
    onError: async (error) => {
      const message = formatError(error);
      setPasswordError(message);

      const passwordErrorsCount = entryPassword + 1;
      if (passwordErrorsCount === 3) {
        setCurrentStep(STEPS['FORGET-PASSWORD']);
        setEntryPassword(0);

        return;
      }

      setEntryPassword(passwordErrorsCount);

      await passwordForm.validateFields();
    },
  });

  const [validateSellerUser, { loading: loadingSellerUser }] = useMutation(VALIDATE_SELLER_USER, {
    onCompleted: ({ validateSellerUser }) => {
      if (!validateSellerUser) {
        setCurrentStep(STEPS['SELECT-TYPE']);
        return;
      }

      if (emailVerified === true) {
        setCurrentStep(STEPS['ENTER-PASSWORD']);
        return;
      }

      if (!emailVerified && !user?.email) {
        setCurrentStep(STEPS['CREATE-ACCOUNT']);
        return;
      }

      setCurrentStep(STEPS['CREATE-PASSWORD']);
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [emailIsVerified, { loading: loadingEmailIsVerified }] = useMutation(EMAIL_IS_VERIFIED, {
    onCompleted: ({ emailIsVerified }) => {
      let link = '/login';
      if (id) {
        link += `${link.length === 6 ? '?' : '&'}id=${id}`;
      }
      if (cnpj) {
        link += `${link.length === 6 ? '?' : '&'}cnpj=${cnpj}`;
      }
      if (verificationCode) {
        link += `${link.length === 6 ? '?' : '&'}verification-code=${verificationCode}`;
      }
      if (input) {
        link += `${link.length === 6 ? '?' : '&'}input=${input}`;
      }
      if (redirect) {
        link += `${link.length === 6 ? '?' : '&'}redirect=${redirect}`;
      }

      history.push(link);

      const {
        id: userId,
        emailVerified,
      } = emailIsVerified;

      setUser(emailIsVerified);
      setEmailVerified(emailVerified);

      if (partner.activationFlowInApp) {
        validateSellerUser({ variables: { businessId: sellerId, userId } });
        return;
      }

      if (emailVerified === true) {
        setCurrentStep(STEPS['ENTER-PASSWORD']);
        return;
      }

      setCurrentStep(STEPS['CREATE-PASSWORD']);
    },
    onError: async (error) => {
      let link = '/login';
      if (id) {
        link += `${link.length === 6 ? '?' : '&'}id=${id}`;
      }
      if (cnpj) {
        link += `${link.length === 6 ? '?' : '&'}cnpj=${cnpj}`;
      }
      if (verificationCode) {
        link += `${link.length === 6 ? '?' : '&'}verification-code=${verificationCode}`;
      }
      if (input) {
        link += `${link.length === 6 ? '?' : '&'}input=${input}`;
      }
      history.push(link);

      setUser();
      setEmailVerified(false);

      const formattedError = formatError(error);
      const errorToRegisterFlow = 'Opa, parece que não encontramos uma proposta! Tente novamente.';

      if (partner.activationFlowInApp && formattedError === errorToRegisterFlow) {
        setCurrentStep(STEPS['CREATE-ACCOUNT']);
        return;
      }

      setEmailError(formattedError);
      await emailForm.validateFields();

      history.replace('/login');
      setCurrentStep(STEPS['ENTER-EMAIL']);
    },
  });

  const [validateSeller, { loading: loadingSeller }] = useMutation(VALIDATE_SELLER, {
    onCompleted: ({ validateSeller }) => {
      if (!validateSeller && !partner.slug) {
        notification.error({ message: 'CNPJ não encontrado' });
        return;
      }
      if (!validateSeller) {
        window.location.replace(`${process.env.GYRAMAIS_LANDING_URL}${partner.slug}/bem-vindo?cnpj=${cnpjForm.getFieldValue('cnpj')}`);
        return;
      }

      setSellerId(validateSeller.id);
      setCurrentStep(STEPS['ENTER-EMAIL']);

      if (email || cpf) {
        emailForm.setFieldsValue({ emailOrCpf: email || cpf });

        emailIsVerified({
          variables: {
            emailOrCpf: email || cpf,
          },
        });
      }
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveSellerUserFromLogin, {
    loading: loadingSaveSeller,
  }] = useMutation(SAVE_SELLER_USER_FROM_LOGIN, {
    onCompleted: ({ saveSellerUserFromLogin }) => {
      if (user) {
        if (emailVerified === true) {
          setCurrentStep(STEPS['ENTER-PASSWORD']);
          return;
        }

        setCurrentStep(STEPS['CREATE-PASSWORD']);
        return;
      }

      setEmailVerified(false);
      setUser(saveSellerUserFromLogin);
      setCurrentStep(STEPS['CREATE-PASSWORD']);
    },
    onError: (error) => {
      formatAndNotificateError(error);
      setSellerUserType();

      if (!user) {
        setCurrentStep(STEPS['CREATE-ACCOUNT']);
      }
    },
  });

  const [
    sendEmailLoginWithLink,
    { loading: loadingLoginWithLink },
  ] = useMutation(SEND_EMAIL_LOGIN_WITH_LINK, {
    onCompleted: () => {
      notification.success({ message: 'E-mail enviado com sucesso!' });
    },
    onError: (error) => {
      formatAndNotificateError(error);
    },
  });

  const [removeSession] = useMutation(REMOVE_SESSION, {
    onCompleted: () => setLoggedOut(),
  });
  const isOlistUser = email && code;

  const handleTitle = () => {
    if (isOlistUser) {
      return (
        <>
          <h2>
            Olá,
            {' '}
            {firstName}
            ! Entre e
            <br />
            <span className="secondary-text">visualize sua oportunidade!</span>
          </h2>

          <Alert
            className="alert warning-card"
            message={
              cpf ? (
                <span>
                  Opa! Parece que você tem uma conta com o e-mail
                  {' '}
                  <b>{email}</b>
                </span>
              )
                : (
                  <span>
                    Opa! Parece que você tem uma conta com o cpf
                    {' '}
                    <b>{cpf}</b>
                  </span>
                )
            }
            type="info"
            showIcon
            icon={<ExclamationCircleOutlined className="bank-alert-icon" />}
            style={{ marginTop: '16px' }}
          />

          <p>
            Faça login na conta acima e prossiga
            com sua solicitação:
          </p>
        </>
      );
    }

    if (sellerId) {
      return (
        <>
          <h2>
            Confirme sua
            {' '}
            <span className="secondary-text">
              identidade
            </span>
          </h2>

          <p>
            Para sua segurança, precisamos validar algumas informações.
          </p>
          <br />
        </>
      );
    }

    return (
      <>
        <h2>
          Bem-vindo(a) à
          {' '}
          <span className="secondary-text">GYRA+</span>!
        </h2>
        <br />
      </>
    );
  };

  const handleFooter = () => {
    if (isOlistUser) {
      return (
        <>
          <br />
          <div>
            <p>Não reconhece esta conta?</p>
            <Button
              link
              block
              onClick={() => {
                window.location = `https://api.whatsapp.com/send?phone=55${process.env.GYRAMAIS_WHATSAPP}&text=${encodeURI(`Olá, bom dia. Estou tentando realizar uma solicitação de crédito via parceria olist e GYRA+, porém, não reconheço o usuário cadastrado com o código ${code}.`)}`;
              }}
            >
              Entre em contato com a GYRA+
            </Button>
          </div>
        </>
      );
    }

    if (partner.activationFlowInApp) {
      return undefined;
    }

    return (
      <>
        <br />
        <hr />
        <br />

        <div align="center">
          <p>Ainda não é cliente?</p>
          <Button
            link
            block
            onClick={() => {
              window.location = `${process.env.GYRAMAIS_LANDING_URL}bem-vindo`;
            }}
          >
            Solicite uma proposta
          </Button>
        </div>
      </>
    );
  };

  const handleBackButton = () => (!cnpjIsPreset || (cnpjIsPreset && currentStep !== STEPS['ENTER-EMAIL'])) && (
    <>
      <Button
        link
        onClick={() => {
          switch (currentStep) {
            case STEPS['ENTER-EMAIL']: setCurrentStep(STEPS['ENTER-CNPJ']); break;
            case STEPS['ENTER-PASSWORD']: setCurrentStep(STEPS['ENTER-EMAIL']); break;
            case STEPS['FORGET-PASSWORD']: setCurrentStep(STEPS['ENTER-PASSWORD']); break;
            case STEPS['CREATE-PASSWORD']: setCurrentStep(STEPS['ENTER-EMAIL']); break;
            default: break;
          }
        }}
        icon={(<LeftOutlined />)}
      >
        Voltar
      </Button>
      <br />
    </>
  );

  const handleInput = () => {
    if (input === 'email') {
      return 'E-mail';
    }
    if (input === 'cpf') {
      return 'CPF';
    }

    return 'E-mail ou CPF';
  };

  useEffect(() => {
    if (currentStep !== STEPS['ENTER-PASSWORD']) {
      passwordForm.setFieldsValue({
        password: '',
      });
    }
  }, [currentStep]);

  useEffect(async () => {
    if (deleted) {
      notification.success({
        message: 'Conta deletada com sucesso!',
        description: 'Sua conta foi deletada e todos os seus dados pessoais foram removidos da Gyra+.',
      });

      // limpa os parâmetros na url
      history.push(window.location.pathname);
      return;
    }

    if (meVar()) history.push('/');
  }, []);

  useEffect(() => {
    if (updatePasswordToken) {
      setCurrentStep(STEPS['CREATE-PASSWORD']);
      return;
    }

    if (ready) {
      if (partner.activationFlowInApp) {
        if (cnpj) {
          cnpjForm.setFieldsValue({ cnpj });

          validateSeller({
            variables: {
              cnpj,
              partnerId: id,
            },
          });
        }
      } else if (email || cpf) {
        emailForm.setFieldsValue({ emailOrCpf: email || cpf });

        emailIsVerified({
          variables: {
            emailOrCpf: email || cpf,
          },
        });
      }
    }
  }, [ready]);

  useEffect(() => {
    if (me && !loggedOut && loading === false) {
      removeSession();
    } else {
      setLoggedOut();
    }
  }, [session]);

  return (
    <LogInScreenWrapper
      className="login-layout"
      logo={whitelabel && partnerLogo}
      loading={loadingPartner}
      primaryColor={partner.appColors?.primary}
    >
      <div id="form-login-component">
        {partner.activationFlowInApp && (
          <div className={`carousel-content ${currentStep === STEPS['ENTER-CNPJ'] ? 'visible-content' : ''}`}>
            <CnpjForm
              partner={partner}
              form={cnpjForm}
              onFinish={({ cnpj }) => validateSeller({
                variables: {
                  cnpj,
                  partnerId: partner.id,
                },
              })}
              loading={loadingSeller}
            />
          </div>
        )}

        <div className={`carousel-content ${currentStep === STEPS['ENTER-EMAIL'] ? 'visible-content' : ''}`}>
          <Form
            form={emailForm}
            layout="vertical"
            onFinish={async (values) => {
              const { emailOrCpf } = values;

              await emailIsVerified({ variables: { emailOrCpf } });
            }}
          >
            {partner.activationFlowInApp && handleBackButton()}
            {handleTitle()}

            <Item
              name="emailOrCpf"
              rules={[
                { required: true, message: `Por favor insira seu ${handleInput()}.` },
                {
                  validator: () => {
                    if (emailError) {
                      return Promise.reject(new Error(emailError));
                    }

                    return Promise.resolve();

                  },
                },
              ]}
            >
              <Input
                placeholder={`Insira seu ${handleInput()}`}
                type={isCpfInput ? 'masked' : 'text'}
                mask={(input === 'cpf' && 'cpf') || (isCpfInput && 'emailOrCpf')}
                guide={false}
                autoFocus={isCpfInput || !isCpfInput}
                onBlur={(e) => {
                  if (input) {
                    return;
                  }

                  const { value } = e.target;

                  if (value.length > 0 && !(/[a-zA-Z]/).test(value.replace(/[-.]/g, ''))) {
                    setIsCpfInput(true);
                  }

                  if (e.target.value.length === 11 && !(/[a-zA-Z]/g).test(value)) {
                    setIsCpfInput(true);
                    emailForm.setFieldsValue({ emailOrCpf: formatString(value, 'cpf') });
                  }
                }}
                onChange={(e) => {
                  if (input) {
                    return;
                  }

                  let temp = e.target.value;

                  if ((!temp || (temp && (/[a-zA-Z]/g).test(temp)) || temp.length > 14)) {
                    if (isCpfInput) {
                      temp = temp.replaceAll(/[.-]/g, '');
                    }

                    setIsCpfInput(false);
                  } else if (temp.length < 11) {
                    setIsCpfInput(true);
                  }

                  emailForm.setFieldsValue({ emailOrCpf: temp });
                  setEmailError();
                }}
              />
            </Item>
            <br />

            <Button
              block
              loading={loadingEmailIsVerified || loadingSellerUser}
              id="login-button"
              htmlType="submit"
            >
              Próximo
            </Button>
            {handleFooter()}
          </Form>
        </div>

        {partner.activationFlowInApp && (
          <div className={`carousel-content ${currentStep === STEPS['CREATE-ACCOUNT'] ? 'visible-content' : ''}`}>
            <UserForm
              form={accountForm}
              credentialIsCpf={isCpfInput}
              onClickBack={() => setCurrentStep(STEPS['ENTER-EMAIL'])}
              onFinish={() => setCurrentStep(STEPS['SELECT-TYPE'])}
              user={user}
            />
          </div>
        )}

        {partner.activationFlowInApp && (
          <div className={`carousel-content ${currentStep === STEPS['SELECT-TYPE'] ? 'visible-content' : ''}`}>
            <BusinessUserForm
              selectedType={sellerUserType}
              onSelectType={(type) => {
                if (!sellerUserType) {
                  const variables = {
                    partnerId: partner.id,
                    businessId: sellerId,
                  };

                  if (type === 'associate') {
                    variables.typeValues = ['socio'];
                  }

                  const { emailOrCpf } = emailForm.getFieldsValue();
                  const {
                    cpf,
                    email,
                    fullName,
                    phoneNumber,
                  } = accountForm.getFieldsValue();
                  if (!user) {
                    variables.fullName = fullName;
                    variables.phoneNumber = phoneNumber;

                    if (isCpfInput) {
                      variables.email = email;
                      variables.cpf = emailOrCpf;
                    } else {
                      variables.email = emailOrCpf;
                      variables.cpf = cpf;
                    }
                  } else {
                    variables.userId = user.id;
                    variables.email = email || emailOrCpf;
                    variables.cpf = cpf || emailOrCpf;
                    variables.fullName = fullName;
                    variables.phoneNumber = phoneNumber;
                  }

                  saveSellerUserFromLogin({ variables });
                  setSellerUserType(type);
                }
              }}
              onClickBack={() => setCurrentStep(STEPS['ENTER-EMAIL'])}
              loading={loadingSaveSeller}
            />
          </div>
        )}

        <div className={`carousel-content ${currentStep === STEPS['ENTER-PASSWORD'] ? 'visible-content' : ''}`}>
          <Form
            form={passwordForm}
            layout="vertical"
            onFinish={(values) => {
              const { password } = values;

              logIn({ variables: { email: user.email, password } });
            }}
          >
            {handleBackButton()}

            <h2>
              <span className="secondary-text">Bem-vindo(a)</span>
              {' '}
              de volta!
            </h2>
            <br />

            <UserTag>
              {emailForm.getFieldValue('emailOrCpf') || email || (cpf && formatString(cpf, 'cpf'))}
            </UserTag>
            <br />

            <Item
              name="password"
              rules={[
                { required: true, message: 'Por favor insira sua senha.' },
                {
                  validator: () => {
                    if (passwordError) {
                      return Promise.reject(new Error(passwordError));
                    }

                    return Promise.resolve();

                  },
                },
              ]}
            >
              <Input
                id="password"
                autoComplete="new-password"
                type="password"
                placeholder="Inserir senha"
                onChange={() => setPasswordError()}
              />
            </Item>
            <br />

            <Button
              block
              loading={loadingLogIn}
              id="login-button"
              htmlType="submit"
            >
              Entrar no Portal
            </Button>

            <br />

            <Button
              link
              block
              onClick={() => {
                setCurrentStep(STEPS['FORGET-PASSWORD']);
              }}
            >
              Esqueceu sua senha?
            </Button>

            <Divider className="password-step-divider">OU</Divider>

            <Button
              color="secondary"
              block
              loading={loadingLoginWithLink}
              onClick={async () => {
                await sendEmailLoginWithLink({ variables: { email: user.email } });
                setCurrentStep(STEPS['LOGIN-WITHOUT-PASSWORD']);
              }}
            >
              Entrar sem digitar senha
            </Button>
            <br />
          </Form>
        </div>

        <div className={`carousel-content ${currentStep === STEPS['CREATE-PASSWORD'] ? 'visible-content' : ''}`}>
          {handleBackButton()}

          <PasswordForm
            tag={emailForm.getFieldValue('emailOrCpf') || email || (cpf && formatString(cpf, 'cpf'))}
            email={user?.email}
            verificationCode={verificationCode}
            cpf={emailForm.getFieldValue('emailOrCpf') || cpf}
            loginWithLink={() => setCurrentStep(STEPS['LOGIN-WITHOUT-PASSWORD'])}
          />
        </div>

        <div className={`carousel-content ${currentStep === STEPS['FORGET-PASSWORD'] ? 'visible-content' : ''}`}>
          {handleBackButton()}

          <LostPasswordForm
            tag={emailForm.getFieldValue('emailOrCpf') || email || (cpf && formatString(cpf, 'cpf'))}
            email={user?.email}
          />
        </div>

        <div className={`carousel-content ${currentStep === STEPS['LOGIN-WITHOUT-PASSWORD'] ? 'visible-content' : ''}`}>
          <Button
            link
            onClick={() => setCurrentStep(emailVerified === true ? STEPS['ENTER-PASSWORD'] : STEPS['CREATE-PASSWORD'])}
            icon={(<LeftOutlined />)}
          >
            Entrar digitando a senha
          </Button>
          <br />

          <LoginWithLinkForm
            email={user?.email}
            tag={emailForm.getFieldValue('emailOrCpf') || email || (cpf && formatString(cpf, 'cpf'))}
          />
        </div>
      </div>
    </LogInScreenWrapper>
  );
};

export default LogInScreen;
