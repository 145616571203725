/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import {
  Col,
  Row,
  Skeleton,
} from 'antd';

import './styles.less';

/**
 * @type {React.FC<{
 *  title: string
 *  subtitle: string
 *  buttons?: React.ReactNode | () => React.ReactNode
 *  buttonsPlacement?: 'bottom' | 'right'
 *  loading?: boolean
 *  extra?: any
 * }>}
 */
const SubHeader = ({
  title,
  subtitle,
  buttons = [],
  buttonsPlacement = 'bottom',
  loading,
  extra,
  extraButtons = [],
  extraButtonsPlacement,
}) => {
  // variáveis internas para lidar com as props no formato de funções
  const _buttons = () => {
    if (typeof buttons === 'function') {
      return buttons();
    }
    return buttons;
  };

  const _extraButtons = () => {
    if (typeof buttons === 'function') {
      return extraButtons();
    }
    return extraButtons;
  };

  return (
    <div className="gyramais-sub-header">
      <Row align={buttonsPlacement === 'right' ? 'middle' : 'bottom'} className="content">
        <Col xs={24} sm={24} md={14} lg={((buttonsPlacement === 'right' && _buttons()) || extra) ? 12 : 24} className="left-side">
          {loading ? (
            <>
              <Skeleton active className="sub-header-skeleton" />
              <Skeleton.Button active className="sub-header-button-skeleton" />
            </>
          ) : (
            <>
              {title && (
                <div className="title-container">
                  <h1 className={`white-label-text ${(subtitle || buttonsPlacement === 'bottom' || extraButtonsPlacement === 'bottom') ? '' : 'no-margin-bottom'}`}>{title}</h1>
                </div>
              )}

              {subtitle && (
                <div className="subtitle-container">
                  <p className={`white-label-text text-justified ${(buttonsPlacement && _buttons()) ? '' : 'no-margin-bottom'}`}>{subtitle}</p>
                </div>
              )}

              {!loading
                && buttonsPlacement
                && (buttonsPlacement === 'bottom' || extraButtonsPlacement === 'bottom')
                && (
                  <>
                    {buttonsPlacement === 'bottom' && _buttons()}
                    {extraButtonsPlacement === 'bottom' && _extraButtons()}
                  </>
                )}
            </>
          )}

        </Col>
        {((buttonsPlacement === 'right' && _buttons()) || extra) && (
          <>
            <Col xs={24} sm={24} md={10} lg={12} className="right-side">
              {!loading
                && (buttonsPlacement === 'right' || extraButtonsPlacement === 'right')
                ? (
                  <>
                    {buttonsPlacement === 'right' && _buttons()}
                    {extraButtonsPlacement === 'right' && _extraButtons()}
                  </>
                )
                : (
                  <div className="extra-container">
                    {extra}
                  </div>
                )}
            </Col>
          </>
        )}

      </Row>

    </div>
  );
};

export default memo(SubHeader);
