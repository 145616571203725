import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Steps,
  Row,
  Col,
} from 'antd';
import { Drawer } from 'components/gyramais';
import { SuccessMessage } from 'components';
import client from 'services/graphql';
import { useQuery } from '@apollo/client';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import { CURRENT_PREPAYMENT } from './graphql';
import STEPS from './steps';

import './styles.less';

const SignatureContext = createContext();

/**
 * @returns {{
 *  me: any
 *  current: number
 *  steps: {
 *   id: string
 *   title: string
 *   component: React.ReactNode
 *  }[]
 *  contractSummary: any
 *  setContractSummary: (contractSummary: any) => void
 *  variables: any
 *  setVariables: (variables: any) => void
 *  setStepValidation: (validation: () => void) => void
 *  setStepLoading: (loading: boolean) => void
 *  onFinishStep: () => void
 * }}
 */
const useSignatureContext = () => useContext(SignatureContext);

const { Step } = Steps;

const SignatureFeatureDrawer = ({
  enabled,
  onFinish,
  onCancel,
  title,
  stepsIds = [],
  onBackToPrepayments,
}) => {
  const history = useHistory();
  const {
    businessId,
    partnerId,
  } = useBusinessLoanContext();
  const {
    requestConfirmation,
    setRequestConfirmationTitle,
    setRequestConfirmationMessage,
  } = useConfirmModalContext();

  const [visible, setVisible] = useState(false);

  const [current, setCurrent] = useState(0);
  const [stepHandle, setStepHandle] = useState();
  const [stepLoading, setStepLoading] = useState(false);
  const [stepsVariables, setStepsVariables] = useState({});

  const {
    data: {
      currentPrepayment: prepayment,
    } = {},
  } = useQuery(CURRENT_PREPAYMENT, {
    skip: !enabled,
    variables: {
      businessId,
      partnerId,
    },
  });

  const steps = useMemo(() => stepsIds.map((id) => STEPS[id]), [stepsIds]);
  const stepId = useMemo(() => steps[current]?.id, [current, steps]);

  const setStepVariables = (variables) => {
    const temp = { ...stepsVariables };
    temp[stepId] = variables;

    setStepsVariables(temp);
  };

  const updatePrepayment = (data) => {
    client.writeQuery({
      query: CURRENT_PREPAYMENT,
      variables: {
        businessId,
        partnerId,
      },
      data: {
        currentPrepayment: data,
      },
    });
  };

  const onFinishStep = async () => {
    if (current === steps.length - 1) {
      await onFinish({ prepaymentId: prepayment.id });
    }

    setCurrent(current + 1);
  };

  const handleClose = () => {
    onCancel?.();
    setVisible(false);
    setCurrent(0);
  };

  useEffect(() => {
    const temp = stepsIds.reduce((acc, id) => {
      acc[id] = {};

      return acc;
    }, {});

    setStepsVariables(temp);
  }, [stepsIds.length]);

  useEffect(() => {
    if (enabled && prepayment) {
      const { signature } = prepayment;

      if (!signature?.signedAt) {
        setVisible(true);
      }
    }

  }, [enabled, prepayment]);

  useEffect(() => {
    setRequestConfirmationTitle('Encerrar assinatura? Sua operação será cancelada');
    setRequestConfirmationMessage((
      <h6>
        Você precisa completar
        {' '}
        <b className="secondary-text">todas as etapas da assinatura</b>
        {' '}
        para que sua operação seja concluída. Caso feche agora,
        {' '}
        <b className="secondary-text">sua operação será cancelada</b>
        . Deseja continuar?
      </h6>
    ));
  }, []);

  return (
    <SignatureContext.Provider
      value={{
        prepayment,
        current,
        steps,
        variables: stepsVariables[stepId],
        setStepVariables,
        setStepHandle,
        setStepLoading,
        updatePrepayment,
        onFinishStep,
      }}
    >
      <SignatureContext.Consumer>
        {({
          current,
          steps,
          variables: { done } = {},
        }) => (
          <Drawer
            id="signature-feature-drawer"
            className="signature-feature-drawer"
            visible={visible}
            onClose={() => {
              requestConfirmation({
                onOk: () => {
                  handleClose();
                },
                onCancel: () => {},
              });
            }}
            title={stepId && title}
            closable={!!stepId}
            hideFooter={!stepId || stepId === 'caf-step'}
            primaryButton={{
              children: 'Avançar',
              loading: stepLoading,
              onClick: () => {
                if (stepHandle) {
                  stepHandle();
                  return;
                }

                onFinishStep();
              },
              disabled: !done,
            }}
            backButton={{
              onClick: () => setCurrent(current - 1),
              disabled: stepLoading,
              visible: current > 0,
            }}
          >
            {stepId ? (
              <Row gutter={[0, 40]} className="signature-feature-drawer-content">
                <Col span={24}>
                  <Steps current={current}>
                    {steps.map(({ id, title }) => (
                      <Step key={id} title={title} />
                    ))}
                  </Steps>
                </Col>

                <Col span={24} className="steps-content">
                  {steps[current].component}
                </Col>
              </Row>
            ) : (
              <SuccessMessage
                centered
                title="Você assinou o contrato."
                subtitle={(
                  <>
                    Esse processo levará um tempo para ser validado.
                    <br />
                    Fique tranquilo! Você receberá a atualização da situação por email.
                  </>
                )}
                primaryButton={{
                  text: 'Voltar para Antecipações',
                  onClick: () => {
                    setVisible(false);
                    onBackToPrepayments();
                  },
                }}
                secondaryButton={{
                  text: 'Ir para a Página Inicial',
                  onClick: () => {
                    setVisible(false);
                    history.push('/');
                  },
                }}
              />
            )}
          </Drawer>
        )}
      </SignatureContext.Consumer>
    </SignatureContext.Provider>
  );
};

SignatureFeatureDrawer.propTypes = {
  enabled: PropTypes.bool,
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  stepsIds: PropTypes.arrayOf(PropTypes.string),
};

export { useSignatureContext };
export default SignatureFeatureDrawer;
