import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import Youtube from 'react-youtube';
import {
  Modal,
} from 'components/sdk';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';

const IntegrationVideoMotivationModal = ({
  visible,
  onClose,
  videoIdWidthLarge = 'pfrXCGdbbTw',
  videoIdWidthThin = 'cmfUyte4Vic',
}) => {
  const { width } = useWindowDimensions();
  const [layoutSize, setLayoutSize] = useState('large');
  const { currentContext, contextLoading } = useBusinessLoanContext();

  const closeCallback = () => {
    onClose();
  };

  const opts = {
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };
  const optsThin = {
    playerVars: {
      autoplay: 1,
      controls: 0,
    },
  };

  useEffect(() => {
    if (width < 768) setLayoutSize('thin');
  }, []);

  if (
    contextLoading
    || currentContext?.partner?.name === 'BADESC'
  ) {
    return null;
  }

  return (
    <Modal
      className="no-padding-modal-video"
      destroyOnClose
      visible={visible}
      onCancel={onClose}
      title={false}
      footer={false}
      centered
    >
      <div id={`integration-motivation-modal-${layoutSize}`}>
        <div className="container">
          {layoutSize === 'large' && opts && optsThin ? (
            <div className="content">
              <Youtube
                className="video-large"
                opts={opts}
                onEnd={closeCallback}
                videoId={videoIdWidthLarge}
                title="YouTube video player"
                allowFullScreen
                onReady={(event) => event.target.playVideo()}
              />
            </div>
          ) : (
            <div className="content-thin">
              <Youtube
                className="video-thin"
                opts={optsThin}
                onEnd={closeCallback}
                videoId={videoIdWidthThin}
                title="YouTube video player"
                onReady={(event) => event.target.playVideo()}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

IntegrationVideoMotivationModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  videoIdWidthLarge: PropTypes.string,
  videoIdWidthThin: PropTypes.string,
};

export default IntegrationVideoMotivationModal;
