import React, { useEffect } from 'react';
import {
  notification,
  Spin,
} from 'antd';
import {
  Layout,
} from 'components/sdk';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { formatError } from 'utils';
import { meVar } from 'services/graphql/cache';
import {
  ADD_SESSION,
  VALIDATE_TOKEN,
  LOG_OUT,
  REMOVE_SESSION,
} from './graphql';

const { Content } = Layout;

const AuthenticationScreen = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    token, from, redirect, fallback, customerId,
  } = queryString.parse(location.search);

  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => {
      history.push(redirect || '/', {
        params: {
          loggedInNow: true,
          usedRedirect: !!redirect,
          customerId,
        },
      });
    },
  });

  const [validateToken, { loading }] = useMutation(VALIDATE_TOKEN, {
    onCompleted: ({ validateToken }) => {
      // eslint-disable-next-line no-param-reassign
      validateToken.stone = from === 'stone';

      addSession({
        variables: {
          me: validateToken,
        },
      });
    },
    onError: (error) => {
      if (fallback) {
        window.open(fallback, '_self');
      } else {
        history.push('/login');
        notification.error({ message: 'Autenticação', description: formatError(error) });
      }
    },
  });

  const [removeSession] = useMutation(REMOVE_SESSION);

  const [logOut] = useMutation(LOG_OUT, {
    onCompleted: async () => {
      await removeSession();
    },
  });

  const runAuthentication = async () => {
    if (meVar()) {
      await logOut();
    }

    if (token) {
      validateToken({ variables: { token } });
    }
  };

  useEffect(() => runAuthentication(), []);

  return (
    <Layout className="register-layout">
      <Content>
        <Spin spinning={loading} />
      </Content>
    </Layout>
  );
};

export default AuthenticationScreen;
