import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  notification,
} from 'antd';
import {
  Alert,
} from 'components/gyramais';
import {
  Modal,
} from 'components/sdk';
import ReactCodeInput from 'react-verification-code-input';
import { useMutation } from '@apollo/client';
import {
  maskPhoneNumber,
  formatAndNotificateError,
} from 'utils';
import {
  CREATE_MERGE_VERIFICATION_CODE,
  MERGE_ACCOUNTS,
  ADD_SESSION,
} from './graphql';
import './styles.less';

const MergeModal = ({
  visible,
  onClose,
  cpf,
  email,
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [unifyNumber, setUnifyNumber] = useState();
  const [verificationCodeSent, setVerificationCodeSent] = useState(localStorage.getItem('verificationCodeSent'));

  const [createMergeVerificationCode,
    { loading: mergeCodeLoading }] = useMutation(CREATE_MERGE_VERIFICATION_CODE, {
    onCompleted: ({
      createMergeVerificationCode: { phoneNumber } = {},
    }) => {
      setUnifyNumber(phoneNumber);
      onClose();
      setConfirmModalVisible(true);
      setVerificationCodeSent(true);
      localStorage.setItem('verificationCodeSent', true);
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => window.location.reload(),
  });

  const [mergeAccounts,
    { loading: mergeLoading }] = useMutation(MERGE_ACCOUNTS, {
    onCompleted: ({ mergeAccounts }) => {
      addSession({
        variables: {
          me: mergeAccounts,
        },
      });
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const handleClose = () => {
    setUnifyNumber();
    setVerificationCode();
    setConfirmModalVisible(false);
    onClose();
  };

  useEffect(() => {
    if (verificationCodeSent === true) {
      setTimeout(() => {
        setVerificationCodeSent(false);
        localStorage.removeItem('verificationCodeSent');
      }, 60000);
    }
  }, [verificationCodeSent]);

  return (
    <>
      <Modal
        visible={visible}
        onClose={handleClose}
        title={`Opa! Notamos que o ${(cpf && 'CPF') || (email && 'e-mail')} utilizado pertence a outra conta`}
        okButton={{
          text: 'Unificar',
          loading: mergeCodeLoading || mergeLoading,
          disabled: verificationCodeSent,
          onClick: async () => {
            if (unifyNumber) {
              await createMergeVerificationCode({
                variables: { cpf, email },
              });
            } else {
              await mergeAccounts({
                variables: { cpf },
              });
            }
          },
        }}
        centered
      >
        <div id="unify-modal">
          <b className="title">Gostaria de unificar as contas?</b>
          <div className="subtitle">
            <br />
            <b>Será necessário:</b>
            <ul>
              <li>
                Ter acesso ao celular cadastrado na primeira conta
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal
        visible={confirmModalVisible}
        centered
        width={550}
        title="Confirmar unificação das contas"
        okButton={{
          id: 'btn-delete-user',
          text: 'Unificar contas',
          loading: mergeLoading,
          onClick: async () => {
            if (verificationCode.length !== 6) {
              return notification.error({ message: 'O código de verificação é composto por 6 dígitos' });
            }

            await mergeAccounts({
              variables: {
                cpf,
                email,
                verificationCode,
              },
            });
            return true;
          },
        }}
        cancelButton={{
          text: 'Reenviar código',
          onClick: async () => {
            await createMergeVerificationCode({
              variables: { cpf, email },
            });
          },
          loading: mergeCodeLoading,
        }}
        onClose={handleClose}
        closable
      >
        <Row
          id="sms-verification-modal-body"
          className="modal-body"
        >
          <Col xs={24}>
            <Alert
              message={(
                <>
                  Enviamos um código de 6 dígitos
                  {' '}
                  <b>
                    para o celular
                    {' '}
                    {unifyNumber && maskPhoneNumber(unifyNumber)}
                  </b>
                  {' '}
                  . Ao unificar sua conta,
                  {' '}
                  <b>você perderá acesso total à plataforma GYRA+</b>
                  {' '}
                  nesta conta.
                  {' '}
                  <b>
                    Todas as informações da conta excluída estarão presentes na conta que permanecer
                  </b>
                </>
            )}
            />
          </Col>

          <Col xs={24}>
            <ReactCodeInput
              type="number"
              inputMode="tel"
              id="verification-code-input"
              fields={6}
              onChange={async (code) => {
                setVerificationCode(code);
              }}
              autoFocus
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

MergeModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  cpf: PropTypes.string,
  email: PropTypes.string,
};

export default MergeModal;
