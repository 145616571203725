/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Layout } from 'antd';

import './styles.less';

const {
  Header,
  Content,
  Footer,
  Sider,
} = Layout;

/**
 * @param {{
 *  children: import('react').ReactElement,
 *  id?: string
 *  className?: string
 *  data-cy?: string
 * }} props
 */
const GyramaisLayout = ({
  children,
  id,
  'data-cy': dataCy,
  className,
}) => (
  <Layout
    id={id}
    className={`gyramais-layout ${className || ''}`}
    data-cy={dataCy}
  >
    {children}
  </Layout>
);

/**
 * @param {{
 *  children: import('react').ReactElement,
 *  className?: string
 * }} props
 */
GyramaisLayout.Header = ({ children, className, ...rest }) => (
  <Header className={`gyramais-layout-header ${className || ''}`} {...rest}>
    {children}
  </Header>
);

/**
 * @param {{
 *  children: import('react').ReactElement[],
 *  className?: string
 * }} props
 */
GyramaisLayout.Content = ({ children, className, ...rest }) => (
  <Content className={`gyramais-layout-content ${className || ''}`} {...rest}>
    {children}
  </Content>
);

/**
 * @param {{
 *  children: import('react').ReactElement,
 *  className?: string
 * }} props
 */
GyramaisLayout.Footer = ({ children, className, ...rest }) => (
  <Footer className={`gyramais-layout-footer ${className || ''}`} {...rest}>
    {children}
  </Footer>
);

/**
 * @param {{
 *  children: import('react').ReactElement,
 *  width?: number,
 *  className?: string
 * }} props
 */
GyramaisLayout.Sider = ({ children, className, ...rest }) => (
  <Sider className={`gyramais-layout-sider ${className || ''}`} {...rest}>
    {children}
  </Sider>
);

export default GyramaisLayout;
