/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import {
  Col,
  Row,
} from 'antd';
import {
  Card,
} from 'components/gyramais';
import {
  useQuery,
} from '@apollo/client';
import {
  Button,
} from 'components/sdk';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import useResize from 'screens/PrePaymentScreen/hook/size';
import {
  SETTINGS,
} from '../../graphql';
import IntegrationReceivableCard from '../IntegrationReceivableCard';
/**
 * @typedef {{
*  amount: number
*  cardBrand: string
*  date: string
*  presentValue: number
* }} Receivable
*/

const GreenButton = ({
  toolboxSetup,
  toolboxLocation,
  setPlatformDrawerVisible,
  handleUserInteraction,
  integrationLoading,
  registratorLoading,
  loading,
  flow,
  registerClientReceivables,
}) => {
  if (
    (flow === 'integration' && integrationLoading)
    || (flow === 'registrator' && registratorLoading)
  ) {
    return (
      <Card className="pre-payment-card">
        <Row gutter={[0, 22]}>
          <Col span={24}>
            <h4 className="no-margin-bottom">
              <b className="white-label-text weight-500">
                Inicie sua antecipação!
              </b>
            </h4>
          </Col>

          <Col span={24}>
            <Button loading block>
              Buscando recebíveis
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  if (toolboxLocation?.interaction === 'user-connect-integrations') {
    const handleConnectIntegrations = () => {
      if (toolboxLocation.config.useRegistrator) {
        registerClientReceivables({
          variables: {
            acquirersCnpj: toolboxLocation.config.acquirersCnpj,
          },
        });

      } else if (flow === 'integration') {
        setPlatformDrawerVisible(true);
      }
    };
    return (
      <Card className="pre-payment-card">
        <Row gutter={[0, 22]}>
          <Col span={24}>
            <h4 className="no-margin-bottom">
              <b className="white-label-text weight-500">
                Inicie sua antecipação!
              </b>
            </h4>
          </Col>

          <Col span={24}>
            <Button block onClick={handleConnectIntegrations} loading={loading}>
              Começar agora
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  if (toolboxLocation?.interaction === 'user-want-prepayment') {
    return (
      <Card className="pre-payment-card">
        <Row gutter={[0, 22]}>
          <Col span={24}>
            <h4 className="no-margin-bottom">
              <b className="white-label-text weight-500">
                Deseja antecipar?
              </b>
            </h4>
          </Col>

          <Col span={24}>
            <Button block onClick={() => handleUserInteraction()} loading={loading}>
              Clique aqui para começar
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }
  const hasUseRegistratorFlag = toolboxSetup.some((setup) => setup?.config?.useRegistrator);
  if (hasUseRegistratorFlag) {
    return null;
  }

  return (
    <Card className="pre-payment-card">
      <Row gutter={[0, 22]}>
        <Col span={24}>
          <h4 className="no-margin-bottom">
            <b className="white-label-text weight-500">
              Liberar mais valores?
            </b>
          </h4>
        </Col>

        <Col span={24}>
          <Button block onClick={() => setPlatformDrawerVisible(true)} loading={loading}>
            Conectar mais contas
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

const handleCardPlace = (receivables, columns) => {
  const tempIntegrations = Array.from(receivables).filter((item) => item !== 'green-button');

  tempIntegrations.splice(columns - 1, 0, 'green-button');
  return tempIntegrations;
};

const IntegrationList = ({
  receivableUnits,
  setPlatformDrawerVisible,
  handleUserInteraction,
  integrations: _integrations,
  setSelectedIntegrations,
  selectedIntegrations,
  pageLoading,
  receivablesDataByIntegrationTypeId,
  registerClientReceivables,
}) => {

  const integrationWrapperRef = useRef(null);
  const { width: integrationWrapperWidth } = useResize(integrationWrapperRef);
  const integrationWrapperFinalWidth = integrationWrapperWidth || document.querySelector('#integrations-wrapper')?.clientWidth;
  const columns = Math.max(Math.min(4, Math.floor((integrationWrapperFinalWidth - 50) / 260)), 1);

  const {
    toolboxSetup,
    toolboxLocation,
    toolboxLoading,
  } = useBusinessLoanContext();

  const integrations = handleCardPlace(
    receivableUnits, columns,
  );

  const {
    data: {
      settings: {
        prePaymentIntegrationFlow,
      } = {},
    } = {},
  } = useQuery(SETTINGS);

  return (
    <div
      id="integrations-wrapper"
      ref={integrationWrapperRef}
      className="integrations-wrapper"
      data-columns={columns}
    >
      {integrations.map((item) => {
        if (item === 'green-button') {
          return (
            <GreenButton
              toolboxSetup={toolboxSetup}
              toolboxLocation={toolboxLocation}
              setPlatformDrawerVisible={setPlatformDrawerVisible}
              handleUserInteraction={handleUserInteraction}
              integrationLoading={_integrations?.some(({ lastSync }) => !lastSync?.syncedAt)}
              loading={toolboxLoading || pageLoading}
              flow={prePaymentIntegrationFlow}
              registerClientReceivables={registerClientReceivables}
            />
          );
        }

        const { integrationType, summary } = item;
        return (
          <IntegrationReceivableCard
            integrationType={integrationType}
            summary={summary}
            selectable={toolboxLocation?.interaction === 'user-choose-prepayment-offer'}
            onSelect={() => {
              const isSelected = selectedIntegrations.includes(integrationType.id);

              if (isSelected) {
                setSelectedIntegrations(
                  selectedIntegrations.filter((id) => id !== integrationType.id),
                );
              } else {
                setSelectedIntegrations([...selectedIntegrations, integrationType.id]);
              }
            }}
            selected={selectedIntegrations.includes(integrationType.id)}
            loading={pageLoading}
            availableAmount={receivablesDataByIntegrationTypeId[item.id]?.presentValue}
          />
        );
      })}
    </div>
  );
};

module.exports = IntegrationList;
