import ME from './me.gql';
import PARTNER from './partner.gql';
import LOG_OUT from './logOut.gql';
import REMOVE_SESSION from './removeSession.gql';
import ABLE_TO_CREATE_NEW_LOAN from './ableToCreateNewLoan.gql';

export {
  ME,
  PARTNER,
  LOG_OUT,
  REMOVE_SESSION,
  ABLE_TO_CREATE_NEW_LOAN,
};
