// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-installments-content svg {
  margin-right: 0.5rem;
}
.schedule-installments-content .calendar-option-container + .calendar-option-container {
  border-top: 1px solid #C9D3CC;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/ScheduleInstallmentsModal/styles.less"],"names":[],"mappings":"AAAA;EAEI,oBAAA;AAAJ;AAFA;EAMI,6BAAA;AADJ","sourcesContent":[".schedule-installments-content {\n  svg {\n    margin-right: 0.5rem;\n  }\n\n  .calendar-option-container + .calendar-option-container {\n    border-top: 1px solid #C9D3CC;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
