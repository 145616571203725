/* eslint-disable react/prop-types */
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  notification,
  Spin,
  Tooltip,
} from 'antd';
import {
  Modal,
  Button,
} from 'components/sdk';
import { CopyOutlined } from '@ant-design/icons';
import { Alert, Information } from 'components';
import { useQuery } from '@apollo/client';
import { INVOICE } from './graphql';

import './styles.less';

dayjs.extend(utc);

const PayInvoiceModal = ({
  invoiceId, visible, onClose, loanId,
}) => {
  const history = useHistory();

  const { data: { invoice } = {}, loading } = useQuery(INVOICE, {
    onError: () => {
      notification.error({
        message: 'Erro ao consultar boleto, por favor tente novamente mais tarde.',
      });
      onClose();
    },
    variables: {
      id: invoiceId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !visible || !invoiceId,
  });

  return (
    <Modal
      className="pay-invoice-modal"
      visible={visible && invoiceId}
      centered
      width={803}
      onCancel={() => onClose(false)}
      title="Estamos quase lá! Pague o boleto e libere a assinatura do aditivo"
      okButton={{
        text: 'Abrir boleto',
        onClick: () => window.open(invoice?.url, '_blank'),
      }}
      cancelButton={{
        text: 'Ver detalhes',
        onClick: () => {
          history.push(`/emprestimos?id=${loanId}`);
          onClose(false);
        },
      }}
    >
      <Spin spinning={loading}>
        <div className="pay-invoice-modal-body">
          <Row>
            <Col span={24}>
              <Alert
                message="Pagamentos via boleto bancário podem demorar de 1 a 3 dias úteis para serem compensados, já pagamentos via PIX podem demorar alguns minutos. Por favor, certifique-se de não realizar mais de um pagamento"
              />
            </Col>
          </Row>
          <Row className="infos-invoice-row">
            <Information
              type="currency"
              label="Valor da entrada"
              value={invoice?.amount}
            />

            <Information
              label="Vencimento do boleto"
              value={dayjs.utc(invoice?.dueDate).format('DD/MM/YYYY')}
            />
          </Row>
          <Row className="barcode-row">
            <Col className="col-barcode-image" xs={0} md={24}>
              <img src={invoice?.barcodeUrl} alt="código de barras" />
            </Col>

            <Col className="col-barcode" md={24}>
              <span className="barcode">
                {invoice?.barcode}
              </span>
              <Tooltip
                title="Copiar código de barras"
              >
                <Button
                  link
                  icon={<CopyOutlined />}
                  onClick={async () => {
                    navigator.clipboard.writeText(invoice?.barcode);
                    notification.success({ message: 'Código de Barras copiado com sucesso' });
                  }}
                >
                  <span className="text-btn-barcode-copy">Copiar código de barras</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="pix-row">
            <Col xs={24} md={16}>
              <h2>Pague agora com seu Pix!</h2>
              <p className="message-mobile">
                Basta acessar pelo seu smartphone o
                aplicativo onde seu
                {' '}
                <b>PIX</b>
                {' '}
                está ativo e copiar a chave aleatória.
              </p>
              <p className="message-desktop">
                Basta acessar pelo seu smartphone o
                aplicativo onde seu
                {' '}
                <b>PIX</b>
                {' '}
                está ativo, acionar
                a função de pagamento e apontar a câmera para o QR Code,
                ou copiar a chave aleatória.
              </p>
              <Button
                link
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(invoice.pixCopyPaste);
                  notification.success({ message: 'Código de Barras copiado com sucesso' });
                }}
              >
                Pix Copia e Cola
              </Button>
            </Col>

            <Col className="col-pix-image" xs={0} md={8}>
              <img src={invoice?.pixUrl} alt="" />
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
};

export default PayInvoiceModal;
