import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'antd';

import './styles.less';

const GyramaisTable = ({
  columns = [],
  dataSource,
  emptyText,
  loading,
  paginationProps,
  onRow,
}) => (
  <Table
    loading={loading}
    rowKey="id"
    className="gyramais-table"
    pagination={paginationProps || false}
    onRow={onRow}
    columns={columns.map((column, index) => {
      const temp = column;

      if (index === 0) {
        temp.align = 'left';
      } else if (index === columns.length - 1) {
        temp.align = 'right';
      } else {
        temp.align = 'center';
      }

      return temp;
    })}
    scroll={{ x: 'max-content' }}
    dataSource={dataSource || []}
    locale={{ emptyText }}
  />
);

GyramaisTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  emptyText: PropTypes.string,
  loading: PropTypes.bool,
  paginationProps: PropTypes.shape(),
  onRow: PropTypes.func,
};

export default GyramaisTable;
