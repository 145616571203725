import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Select,
} from 'antd';
import {
  Alert,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import { formatError } from 'utils';
import {
  GET_DIGITAL_SIGNATURE_PARAMS,
  ME,
} from './graphql';
import './styles.less';

const { Option } = Select;

const DigitalSignatureForm = ({
  loan,
  setDigitalSignature,
  setDigitalSignatureNonce,
  digitalSignatureError,
  setDigitalSignatureError,
}) => {
  const [loading, setLoading] = useState(true);
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState();
  const [error, setError] = useState();

  const {
    data: {
      me: {
        cpf,
      } = {},
    } = {},
  } = useQuery(ME);

  const [getDigitalSignatureParams,
    { loading: paramsLoading }] = useMutation(GET_DIGITAL_SIGNATURE_PARAMS, {
    onCompleted: async ({ getDigitalSignatureParams }) => {
      setError();
      setDigitalSignatureError();

      const { nonce, signature } = getDigitalSignatureParams;

      const payload = {
        algoritmoHash: 'SHA256',
        formatoDadosEntrada: 'Base64',
        formatoDadosSaida: 'Base64',
        assinaturas: [{ hashes: [signature.messageDigest] }],
      };

      window.BryExtension.sign(selectedCertificate.certId, JSON.stringify(payload))
        .then(({ assinaturas }) => {
          const hash = assinaturas?.[0]?.hashes?.[0];
          setDigitalSignatureNonce(nonce);
          setDigitalSignature({
            signature: {
              nonce: signature.nonce,
              cifrado: hash,
            },
            cpf: selectedCertificate.cpf,
          });
        }).catch(({ description }) => {
          setError(description);
          setDigitalSignatureError();
        });
    },
    onError: (error) => {
      setDigitalSignature();
      setDigitalSignatureNonce();
      setDigitalSignatureError();
      setError(formatError(error));
    },
  });

  useEffect(() => {
    if (selectedCertificate) {
      if (selectedCertificate.cpf.replace(/[^0-9]/g, '') !== cpf) return setDigitalSignatureError('O certificado e-CPF selecionado não condiz com o CPF cadastrado para esta conta');

      getDigitalSignatureParams({
        variables: {
          loanId: loan.id,
          certificate: selectedCertificate.certificateData,
        },
      });
    }

    return true;
  }, [selectedCertificate]);
  useEffect(async () => {
    if (window.BryExtension) {
      const data = await window.BryExtension.listCertificates();

      if (process.env.ENVIRONMENT !== 'production') {
        data[0].icpBrasil = true;
        data[0].cpf = cpf;
      }

      setCertificates(data);
      setLoading(false);
    }
  }, []);
  return (
    <div id="digital-signature-form">
      <h3>
        Selecione o certificado para a assinatura
      </h3>
      <Row gutter={[14, 14]}>
        <Col xs={24} sm={24} md={16}>
          <Alert
            message="Todos deverão assinar utilizando um certificado e-CPF"
            className="digital-signature-alert"
          />
          <Select
            placeholder="Selecione o  certificado"
            loading={loading || paramsLoading}
            className="gyramais-input"
            dropdownClassName="gyramais-input-options"
            notFoundContent={(
              <div align="center">
                <br />
                <b>
                  Não há certificados e-CPF
                  <br />
                  Assim que for instalado, o certificado irá aparecer aqui
                </b>
                <br />
                <br />
              </div>
            )}
            onChange={(e) => {
              const selected = certificates.find(({ certId }) => e === certId);
              setSelectedCertificate(selected);
            }}
          >
            {certificates
              .filter(({ icpBrasil, cpf, cnpj }) => icpBrasil && (cpf && !cnpj))
              .map(({ certId, name }) => (
                <Option
                  key={certId}
                  value={certId}
                >
                  {name}
                </Option>
              ))}
          </Select>
          {selectedCertificate && (!loading && !paramsLoading) && (
            <>
              {error || digitalSignatureError ? (
                <>
                  <Alert className="digital-signature-alert-feedback" color="red" description={error || digitalSignatureError} />
                  {digitalSignatureError
                  && digitalSignatureError === 'Tempo para finalização de assinatura excedido. Tente novamente. Se o problema persistir, entre em contato com o suporte' && (
                    <Button
                      className="try-digital-sign-button"
                      onClick={() => getDigitalSignatureParams({
                        variables: {
                          loanId: loan.id,
                          certificate: selectedCertificate.certificateData,
                        },
                      })}
                    >
                      Tentar novamente
                    </Button>
                  )}
                </>
              ) : (
                <Alert className="digital-signature-alert-feedback" color="green" description="O certificado é válido. Conclua a assinatura!" />
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

DigitalSignatureForm.propTypes = {
  loan: PropTypes.shape(),
  setDigitalSignature: PropTypes.func,
  setDigitalSignatureNonce: PropTypes.func,
  digitalSignatureError: PropTypes.string,
  setDigitalSignatureError: PropTypes.func,
};

export default DigitalSignatureForm;
