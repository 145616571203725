// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sms-verification-modal-body .styles_react-code-input__CRulA {
  width: fit-content;
  margin: 2rem auto;
}
@media (max-width: 768px) {
  #sms-verification-modal-body .styles_react-code-input__CRulA input {
    width: 2.813rem !important;
    height: 2.813rem !important;
    font-size: 1.625rem;
  }
}
#sms-verification-modal-body .error-msg {
  padding-top: 0.62rem;
  padding-left: 1.87rem;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/DeleteVerificationModal/styles.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,iBAAA;AAAJ;AAEI;EAAA;IAEI,0BAAA;IACA,2BAAA;IACA,mBAAA;EAAN;AACF;AAVA;EAeI,oBAAA;EACA,qBAAA;EACA,UAAA;AAFJ","sourcesContent":["#sms-verification-modal-body {\n  .styles_react-code-input__CRulA {\n    width: fit-content;\n    margin: 2rem auto;\n\n    @media(max-width: @md-screen) {\n      input {\n        width: 2.813rem !important;\n        height: 2.813rem !important;\n        font-size: 1.625rem;\n      }\n    }\n  }\n\n  .error-msg {\n    padding-top: 0.62rem;\n    padding-left: 1.87rem;\n    color: red;\n  }\n}\n\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
