import React from 'react';
import { Collapse, Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import './styles.less';
import PropTypes from 'prop-types';

const { Panel } = Collapse;

const TutorialPanel = ({ tutorial }) => (
  <div id="tutorial">
    <h5>Como conseguir suas credenciais</h5>
    <hr />
    <Collapse
      bordered={false}
      expandIconPosition="right"
      defaultActiveKey={tutorial && tutorial.map(({ title }) => (title))}
    >
      {tutorial && tutorial.map(({ title, description, image }, index) => (
        <Panel
          key={title}
          header={(
            <>
              <span className="title-step">
                {!title?.includes('Passo')
                  ? `Passo ${index + 1}:`
                  : title}
              </span>

              {!title?.includes('Passo') && (
              <span>
                {' '}
                {title}
              </span>
              )}
            </>
        )}
        >
          <div className="content-step">
            <span>
              {description}
            </span>
            <Image
              src={image}
              preview={{
                mask: (
                  <div>
                    <EyeOutlined />
                    Visualizar
                  </div>
                ),
              }}
            />

          </div>
        </Panel>
      ))}
    </Collapse>
  </div>
);

TutorialPanel.propTypes = {
  tutorial: PropTypes.instanceOf(Object),
};

export default TutorialPanel;
