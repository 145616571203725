import BANK_ACCOUNTS from './bankAccounts.gql';
import INTEGRATIONS from './integrations.gql';
import ME from './me.gql';
import PLUGGY_CONNECT_TOKEN from './pluggyConnectToken.gql';
import SAVE_INTEGRATION from './saveIntegration.gql';

export {
  BANK_ACCOUNTS,
  INTEGRATIONS,
  ME,
  PLUGGY_CONNECT_TOKEN,
  SAVE_INTEGRATION,
};
